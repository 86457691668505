<template>
  <div>
    <gl-form-group
      label="Encryption Algorithm"
      label-size="sm"
      :label-for="`${prefix}cryptoAlgo`"
      :invalid-feedback="invalidAlgoFeedback"
      class="m-4"
    >
      <p
        v-if="originalCryptoAlgo && originalCryptoAlgo === cryptoAlgo"
        class="mb-1"
      >
        <small>
          {{ updateCryptoMesssage }}
          <gl-button
            size="small"
            category="secondary"
            variant="danger"
            @click="resetCrypto"
            v-if="!encryptionToBeRemoved"
          >
            remove it
          </gl-button>
        </small>
      </p>
      <gl-form-select
        :id="`${prefix}cryptoAlgo`"
        v-model="cryptoAlgoMut"
        :options="cryptoAlgoOptions"
        :selected="cryptoAlgoMut ? cryptoAlgoMut : null"
        class="mt-1"
        :state="isValidAlgo"
      />
    </gl-form-group>
    <gl-form-group
      v-if="cryptoAlgoMut"
      class="m-4"
      label="Decryption key"
      label-size="sm"
      :label-for="`${prefix}cryptoKey`"
      :invalid-feedback="invalidKeyFeedback"
    >
      <p
        v-if="
          originalCryptoAlgo &&
            originalCryptoAlgo === cryptoAlgo &&
            !cryptoKeyIsEdit
        "
        class="mb-1"
      >
        <small class="mr-2">[Hidden]</small>
        <gl-button
          @click="cryptoKeyIsEdit = true"
          size="small"
          category="secondary"
          variant="default"
          >Update</gl-button
        >
      </p>
      <gl-form-input
        v-else
        :id="`${prefix}cryptoKey`"
        type="text"
        v-model="cryptoKeyMut"
        maxlength="250"
        class="mt-1"
        :state="isValidKey"
      />
    </gl-form-group>
  </div>
</template>
<script>
import { GlFormSelect, GlFormInput, GlFormGroup, GlButton } from "@gitlab/ui";
import userInfo from "@/mixins/userInfo";
const EVENT_NAME = "change";

export default {
  name: "Crypto",
  mixins: [userInfo],
  components: {
    GlFormSelect,
    GlFormInput,
    GlFormGroup,
    GlButton
  },
  mounted() {
    this.emitChange();
  },
  watch: {
    cryptoAlgoMut: {
      handler() {
        this.emitChange();
      }
    },
    cryptoKeyMut: {
      handler() {
        this.emitChange();
      }
    }
  },
  methods: {
    emitChange() {
      this.$emit(EVENT_NAME, {
        cryptoAlgo: this.cryptoAlgoMut,
        cryptoKey: this.cryptoKeyMut,
        isValid: this.isValidKey
      });
    },
    resetCrypto() {
      this.cryptoAlgoMut = null;
      this.cryptoKeyMut = "UNSET";
      this.cryptoKeyIsEdit = false;
      this.updateCryptoMesssage =
        "Encryption is set to be removed, you can either update it below or please save the changes.";
      this.encryptionToBeRemoved = true;
    }
  },
  computed: {
    isValidKey() {
      if (this.cryptoAlgoMut === null) {
        return true;
      }

      if (
        this.cryptoAlgoMut === this.originalCryptoAlgo &&
        !this.cryptoKeyMut
      ) {
        return true;
      } else {
        return !!this.cryptoKeyMut;
      }
    },
    invalidKeyFeedback() {
      return this.isValidKey && this.cryptoAlgoMut ? "" : `Please enter key.`;
    },

    isValidAlgo() {
      return this.cryptoAlgoMut !== undefined;
    },
    invalidAlgoFeedback() {
      return this.isValidAlgo ? "" : `Please select desired algo.`;
    },

    // restricts standard users from changing the crypto algo
    restrictEncryptionAlgorithmChange() {
      return !(this.isArqadeAdminRoleActive || this.isOrgAdminRoleActive);
    }
  },
  data() {
    return {
      updateCryptoMesssage:
        "Key has been already set, you can either update it below or",
      encryptionToBeRemoved: false,
      cryptoAlgoMut: this.cryptoAlgo ? this.cryptoAlgo : null,
      cryptoKeyMut: this.cryptoKey,
      cryptoKeyIsEdit: false,
      cryptoAlgoOptions: [
        { value: null, text: "Unencrypted" },
        { value: "aes128", text: "AES-128" },
        { value: "aes192", text: "AES-192" },
        { value: "aes256", text: "AES-256" }
      ]
    };
  },
  props: {
    prefix: {
      type: String,
      default: "crypto_"
    },
    originalCryptoAlgo: {
      type: String
    },
    cryptoAlgo: {
      type: String
    },
    cryptoKey: {
      type: String
    }
  }
};
</script>
