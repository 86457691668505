<template>
  <Page contributionType="event_contribution" status="cancelled" />
</template>

<script>
import Page from "@/components/Contributions/All/Page.vue";

export default {
  name: "EventsCancelled",
  components: {
    Page
  }
};
</script>
