<template>
  <div>
    <gl-button
      v-for="(item, index) in this.buttons"
      :key="index"
      class="mr-3 mb-3"
      @click="item.onClick ? item.onClick() : emptyFunc()"
      >{{ item.text }}</gl-button
    >
    <hr />
    <p class="d-flex align-items-center sourceDistibute__lid mt-4 mb-5">
      <gl-icon
        name="check"
        class="mr-2 sourceDistibute__iconCheck"
        :size="32"
      />
      <span>
        The
        <strong data-testid="source_name">{{ itemUnderEdit.name }}</strong>
        source is now generated with IP
        <strong>{{ itemUnderEdit.host }}</strong> and can be distributed as a
        Channel or as an Event. Also you may choose to add a redundancy source
        for your contribution.
      </span>
    </p>
    <hr />
    <div class="sourceDistibute mt-4" v-if="!currentItem.isRedundancy">
      <h4>Redundancy</h4>
      <div class="sourceDistibute__text">
        <p class="mr-3">
          Choose this option to create a redundant source for 1+1 contributions.
          Please note that redundant contributions will incur increased charges.
        </p>
        <div class="sourceDistibute__btn">
          <gl-button
            class="mb-3 w-100"
            variant="confirm"
            @click="$emit('redirect', 'redundancy')"
            >Add Redundancy</gl-button
          >
        </div>
      </div>
    </div>
    <div class="sourceDistibute mt-4">
      <h4>Channels</h4>
      <div class="sourceDistibute__text">
        <p class="mr-3">
          Choose this option to create a channel contribution from your source.
          Channel contributions can be open-ended and have minimum contribution
          and delivery charges of 1 month increments.
        </p>
        <div class="sourceDistibute__btn">
          <gl-button
            class="mb-3 w-100"
            variant="confirm"
            @click="
              $emit('redirectToCreateContribution', 'channel_contribution')
            "
            >Create Channel</gl-button
          >
        </div>
      </div>
    </div>
    <div class="sourceDistibute mt-4">
      <h4>Event</h4>
      <div class="sourceDistibute__text">
        <p class="mr-3">
          Choose this option to create an event contribution from your source.
          Event contributions have a fixed duration of minimum 1 hour and incur
          hourly contribution charges and delivery charges of a one-off setup
          fee and hourly delivery fee.
        </p>
        <div class="sourceDistibute__btn">
          <gl-button
            class="mb-3 w-100"
            variant="confirm"
            @click="$emit('redirectToCreateContribution', 'event_contribution')"
            >Create Event</gl-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import currentItem from "@/mixins/currentItem";
import { ALIAS_ITEMS_SOURCE, ITEMS_SOURCES } from "@/constants.js";
import { GlButton, GlIcon } from "@gitlab/ui";

const { EDIT_FIRST } = ALIAS_ITEMS_SOURCE;
const { SHOW } = ITEMS_SOURCES;

export default {
  components: { GlButton, GlIcon },
  name: "AddFourth",
  mixins: [currentItem],
  computed: {
    buttons() {
      return [
        {
          text: `Show this source`,
          onClick: () => {
            this.$router.push(`${SHOW}/${this.currentItem.id}`);
          }
        },
        {
          text: `Create another source`,
          onClick: () => {
            this.$emit("redirect", "new");
          }
        },
        {
          text: `Clone this source`,
          onClick: () => {
            this.$emit("redirect", "clone");
          }
        },
        {
          text: `Edit this source`,
          onClick: () => {
            this.$router.push(EDIT_FIRST);
          }
        }
      ];
    }
  }
};
</script>
<style scoped>
.sourceDistibute__iconCheck {
  color: var(--theme-color);
}
.sourceDistibute__lid {
  width: 600px;
  max-width: 100%;
}
.sourceDistibute {
  width: 600px;
  max-width: 100%;
  box-shadow: inset 0px -1px 0px #dbdbdb;
}
.sourceDistibute__text {
  display: flex;
  align-items: center;
}
.sourceDistibute__btn {
  flex: 0 0 10em;
}
</style>
