<template>
  <div v-show="selectedContribution">
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">Deliveries</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__delivery monitoring__delivery">
      <gl-filtered-search
        :available-tokens="tokens"
        @submit="activate"
        @clear="clear"
        class="mb-2"
      >
        <template #history-item="{ historyItem }">
          <template v-for="(token, idx) in historyItem">
            <span :key="idx" class="px-1">
              {{ token.type }} =
              <strong>{{ token.value }}</strong>
            </span>
          </template>
        </template>
      </gl-filtered-search>
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItems"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <p
                  class="mb-0 deliverySingle__name"
                  v-b-tooltip
                  :title="r.contributionName"
                >
                  {{ r.contributionName }}
                </p>
              </div>
              <div>
                <b-badge variant="secondary" class="deliverySingle__badge">{{
                  countDestinations(r)
                }}</b-badge>
              </div>
            </div>
            <div class="d-flex align-items-center deliverySingle__bottom">
              <b-badge :variant="getBadgeVariant(r)"
                ><gl-icon :name="getBadgeIcon(r)" />{{
                  getBadgeText(r)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlFilteredSearch, GlButton } from "@gitlab/ui";
import { DELIVERY_SEARCH_BAR_TOKENS } from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo";

export default {
  name: "Delivery",
  mixins: [metricsApiClient, userInfoMixin],
  components: {
    ArqDataGrid,
    GlButton,
    GlFilteredSearch,
    GlLoadingIcon
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    selectedContribution: {
      type: Object
    }
  },
  watch: {
    selectedContribution: function() {
      this.onDataNeeded(this.sortModel, this.pageModel, this.phrase);
    }
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      this.searchParams = {};
      this.searchPhrase = "";
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    contextMenuClick() {},
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(row) {
      if (row.scheduleStatus === "CANCELLED" || row.state === "ERROR") {
        return "danger";
      } else if (row.state === "STARTING" || row.state === "IN_PROGRESS") {
        return "warning";
      } else {
        return "success";
      }
    },
    getBadgeText(row) {
      return row.scheduleStatus === "CANCELLED" || row.state === "ERROR"
        ? "ERROR"
        : row.state;
    },
    getBadgeIcon(row) {
      return row.scheduleStatus === "CANCELLED" || row.state === "ERROR"
        ? "status_warning"
        : "check-circle";
    },
    countDestinations(row) {
      if (row.destinationId0 && row.destinationId1) {
        return 2;
      } else if (row.destinationId0 || row.destinationId1) {
        return 1;
      } else {
        return 0;
      }
    },
    async onDataNeeded(sortModel, pageModel) {
      this.isLoading = true;
      this.sortModelDelivery = sortModel;
      this.pageModelDelivery = pageModel;
      if (!this.selectedContribution) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = (this.isArqadeAdminRoleActive
        ? this.getMetricsHealth("deliveries")
        : this.getMetricsHealth("deliveries/own")
      )
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query = query.param("contributionId", this.selectedContribution.id);

      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
      this.$emit("generateLines");
    }
  },
  computed: {
    contextMenuItems() {
      return {};
    },
    tokens() {
      return DELIVERY_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      sortModel: {},
      pageModel: {
        itemsPerPage: 10
      },
      selectedRow: undefined,
      data: {},
      isLoading: true,
      searchParams: {},
      searchPhrase: "",
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style>
.monitoring__delivery .selected-row:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: calc(50% - 1px);
  right: -16px;
  background-color: #999999;
  height: 2px;
  width: 16px;
}
</style>
<style scoped>
.deliverySingle__bottom .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.deliverySingle__bottom .badge .gl-icon {
  margin-right: 5px;
}
.deliverySingle__bottom .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.deliverySingle__bottom .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.deliverySingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.deliverySingle__name {
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
.deliverySingle__badge {
  font-size: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
</style>
