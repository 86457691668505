<template>
  <tr>
    <th>
      <!-- Expand dropdown -->
    </th>
    <th v-if="multiSelection">
      <gl-form-checkbox @change="selectAll()" :checked="allSelected" />
    </th>
    <Search
      :value="searchFields.q || ''"
      @updateSearch="newValue => updateSearchParams('q', newValue)"
    />
    <th>Redundant</th>
    <DateRangePicker
      :searchFields="searchFields"
      @changeSearchParams="onSearchParamsChange"
    />
    <th>
      <span class="nowrap">Schedule Status</span>
      <gl-form-select
        :disabled="isScheduleStatusPrefiltered()"
        data-testid="schedule--status"
        @input="updateSearchParams('scheduleStatus', $event)"
        v-bind:value="searchFields.scheduleStatus"
        :options="[
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Scheduled',
            value: 'SCHEDULED'
          },
          {
            text: 'Cancelled',
            value: 'CANCELLED'
          }
        ]"
      >
      </gl-form-select>
    </th>
    <th>
      <span class="nowrap">Delivery Status</span>
      <gl-form-select
        :disabled="isDeliveryStatusPrefiltered()"
        data-testid="delivery--status"
        @input="updateSearchParams('deliveryStatus', $event)"
        v-bind:value="searchFields.deliveryStatus"
        :options="[
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Pending',
            value: 'pending'
          },
          {
            text: 'Current',
            value: 'current'
          },
          {
            text: 'Completed',
            value: 'completed'
          }
        ]"
      >
      </gl-form-select>
    </th>
    <th>
      <!-- Context menu -->
    </th>
  </tr>
</template>
<script>
import Search from "@/components/Table/SearchFields/Search";
import { GlFormCheckbox } from "@gitlab/ui";
import { GlFormSelect } from "@gitlab/ui";
import DateRangePicker from "@/components/Date/DateRangePicker.vue";

export default {
  name: "TableHead",
  components: {
    Search,
    GlFormCheckbox,
    DateRangePicker,
    GlFormSelect
  },
  props: {
    searchFields: {
      type: Object,
      default: () => {
        return {};
      }
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    allSelected: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: "all"
    }
  },
  methods: {
    isScheduleStatusPrefiltered() {
      if (this.status === "cancelled" || this.status === "scheduled") {
        this.searchFields.scheduleStatus = this.status.toUpperCase();
        return true;
      }
      return false;
    },

    isDeliveryStatusPrefiltered() {
      if (
        this.status === "completed" ||
        this.status === "pending" ||
        this.status === "current"
      ) {
        this.searchFields.deliveryStatus = this.status;
        return true;
      }
      return false;
    },

    onSearchParamsChange(value) {
      this.$emit("changeSearchParams", value);
    },

    updateSearchParams(column, value) {
      this.$set(this.searchFields, column, value);
      this.$emit("changeSearchParams", this.searchFields);
    },
    selectAll() {
      this.$emit("selectAll");
    }
  }
};
</script>
<style scoped lang="scss">
.search {
  min-width: 10rem;
}

th > span {
  display: block;
  margin-bottom: 0.5rem;
}
.nowrap {
  white-space: nowrap;
}
</style>
