var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('hr'),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',{attrs:{"cols":"2"}},[_c('gl-form-select',{attrs:{"options":_vm.dateOptions},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"d-flex mt-3"},[_c('gl-button',{attrs:{"variant":"confirm","disabled":_vm.isDownloading || _vm.isBusy},on:{"click":function($event){return _vm.downloadAsCsv()}}},[_vm._v("Download as CSV")]),_c('gl-loading-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDownloading),expression:"isDownloading"}],staticClass:"mt-1 ml-3",attrs:{"label":"Loading","size":"md"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Monthly Total")]),_c('p',[_vm._v(" Please note that costs are shown here the day after they are accrued. ")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('h3',[_vm._v(_vm._s(_vm.formatPrice(this.totalPrice)))])])],1),_c('ArqDataGrid',{attrs:{"data":_vm.data,"columns":_vm.columns,"itemsPerPage":_vm.itemsPerPage,"selectable":false},on:{"dataNeeded":_vm.onDataNeeded},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var r = ref.row;
var field = ref.field;
return [(
          field === 'recurringPrice' ||
            field === 'setupPrice' ||
            field === 'totalPrice'
        )?_c('div',[_c('div',{staticClass:"text-right",class:{ 'font-weight-bold': field === 'totalPrice' }},[_vm._v(" "+_vm._s(_vm.formatPrice(r[field], field))+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }