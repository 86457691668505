<template>
  <p class="datetime">{{ date }} ({{ computedTimeZone }})</p>
</template>
<style scoped lang="scss">
.datetime {
  font-size: inherit;
  margin: 0;
}
</style>
<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "DateTimeComponent",
  props: {
    dateTime: {
      type: String
    },
    timeZone: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    computedTimeZone() {
      return this.timeZone ?? dayjs.tz.guess();
    },
    date() {
      return dayjs
        .utc(this.dateTime)
        .tz(this.computedTimeZone)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }
};
</script>
