<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <gl-form-group
      class="m-4"
      label="S3 Bucket Name"
      label-size="sm"
      :invalid-feedback="invalidBucketNameFeedback()"
      label-for="bucketName"
    >
      <gl-form-input
        type="text"
        id="bucketName"
        v-model="currentItem.bucketName"
        placeholder="i.e.: bucket-name"
        class="mt-1"
        required
        :state="isBucketNameValid()"
      />
    </gl-form-group>

    <gl-form-group
      class="m-4"
      label="S3 Folder Name"
      label-size="sm"
      :invalid-feedback="invalidFolderPathFeedback()"
      label-for="folderPath"
    >
      <gl-form-input
        type="text"
        id="folderPath"
        v-model="currentItem.folderPath"
        placeholder="i.e.: folder-name"
        class="mt-1"
        required
        :state="isFolderPathValid()"
      />
    </gl-form-group>

    <gl-form-group
      class="m-4"
      label="Role ARN (Optional)"
      label-size="sm"
      :invalid-feedback="invalidRoleARNFeedback()"
      label-for="roleARN"
    >
      <gl-form-input
        type="text"
        id="roleARN"
        v-model="currentItem.roleArn"
        placeholder="i.e.: arn:aws:iam::123456789012:role/example-role"
        class="mt-1"
        :state="isRoleARNValid()"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { GlFormGroup, GlFormInput } from "@gitlab/ui";

export default {
  components: { GlFormGroup, GlFormInput, RegionComponent },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    invalidBucketNameFeedback() {
      return this.isBucketNameValid()
        ? ""
        : "The bucket name isn't valid. Check AWS S3 bucket naming rules";
    },
    invalidFolderPathFeedback() {
      return this.isFolderPathValid()
        ? ""
        : "The folder path isn't valid. Check AWS S3 folder naming rules";
    },
    invalidRoleARNFeedback() {
      return this.isRoleARNValid()
        ? ""
        : "The role ARN isn't valid. Enter a value that adheres to AWS rules.";
    },
    isBucketNameValid() {
      // https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html
      const regexp = /(?!(^xn--))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9](?<!-s3alias)$/;
      return (
        !!this.currentItem.bucketName &&
        regexp.test(this.currentItem.bucketName)
      );
    },
    isFolderPathValid() {
      // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
      const regexp = /^([^/])([-/a-zA-Z0-9!-_.*'()]{1,921})([^/])$/;

      return (
        !!this.currentItem.folderPath &&
        regexp.test(this.currentItem.folderPath) &&
        this.currentItem.folderPath.length <= 64
      );
    },
    isDestinationValid() {
      switch (false) {
        case this.isRegionValid:
        case this.isBucketNameValid():
        case this.isFolderPathValid():
        case this.isRoleARNValid():
          return false;
        default:
          return true;
      }
    },
    isRoleARNValid() {
      if (!this.currentItem.roleArn) {
        return true;
      }
      // https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_identifiers.html

      const regexp = /^arn:aws:iam::\d{12}:role(?:\/[a-zA-Z0-9_-]+|(?:\/[a-zA-Z0-9_-]+){2,}|(?:\/[a-zA-Z0-9_\-.]+){2,})$/;

      return (
        regexp.test(this.currentItem.roleArn) &&
        this.currentItem.roleArn.length <= 2048
      );
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },

  data() {
    return {
      cryptoKeyRemoved: false,
      isRegionValid: false
    };
  },
  props: {
    currentItem: Destination,
    isUpdate: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
