<template>
  <div v-show="selectedContribution">
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">Delivery</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__delivery">
      <gl-filtered-search
        :available-tokens="tokens"
        @submit="activate"
        @clear="clear"
        class="mb-2"
      >
        <template #history-item="{ historyItem }">
          <template v-for="(token, idx) in historyItem">
            <span :key="idx" class="px-1">
              {{ token.type }} =
              <strong>{{ token.value }}</strong>
            </span>
          </template>
        </template>
      </gl-filtered-search>
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItems"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div
            v-if="field === 'name'"
            class="deliverySingle"
            :class="{
              'deliverySingle--inactive': isInactive(r)
            }"
          >
            <div class="d-flex align-items-center mb-2">
              <div class="ml-2">
                <p
                  class="mb-0 deliverySingle__name"
                  v-b-tooltip
                  :title="r.contributionName"
                >
                  {{ r.contributionName }}
                </p>
                <span>{{ r.status }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <b-badge :variant="getBadgeVariant(r.source0_health)"
                ><gl-icon
                  :name="getBadgeIcon(r.source0_health)"
                />Source</b-badge
              >
              <b-badge
                :variant="getBadgeVariant(r.source1_health)"
                v-show="r.source1_health != 'N/A'"
                ><gl-icon
                  :name="getBadgeIcon(r.source1_health)"
                />Redundancy</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlFilteredSearch, GlButton, GlLoadingIcon } from "@gitlab/ui";
import { DELIVERY_SEARCH_BAR_TOKENS } from "@/constants.js";

export default {
  name: "Delivery",
  mixins: [metricsApiClient],
  components: {
    ArqDataGrid,
    GlButton,
    GlFilteredSearch,
    GlLoadingIcon
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    selectedContribution: {
      type: Object
    }
  },
  watch: {
    selectedContribution: function() {
      this.onDataNeeded(this.sortModel, this.pageModel, this.phrase);
    }
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      this.searchParams = {};
      this.searchPhrase = "";
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    contextMenuClick() {},
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(health) {
      switch (health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(health) {
      switch (health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    isInactive(row) {
      return new Date(row.contributionPlannedEnd).getTime() < Date.now();
    },
    async onDataNeeded(sortModel, pageModel) {
      this.isLoading = true;
      this.sortModelDelivery = sortModel;
      this.pageModelDelivery = pageModel;
      if (!this.selectedContribution) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.getMetricsHealth("deliveries")
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query = query.param("contributionId", this.selectedContribution.id);

      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
      this.$emit("generateLines");
    }
  },
  computed: {
    contextMenuItems() {
      return {};
    },
    tokens() {
      return DELIVERY_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      sortModel: {},
      pageModel: {
        itemsPerPage: 10
      },
      selectedRow: undefined,
      data: {},
      isLoading: true,
      searchParams: {},
      searchPhrase: "",
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style scoped>
.deliverySingle--inactive {
  opacity: 0.5;
}
.deliverySingle__name {
  font-weight: bold;
  font-size: 15px;
}
.deliverySingle .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.deliverySingle .badge .gl-icon {
  margin-right: 5px;
}
.deliverySingle .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.deliverySingle .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
</style>
