export default {
  data() {
    return {
      store: null
    };
  },
  methods: {
    beforeRouteLeaveWithCondition(condition, to, from, next) {
      if (condition) {
        this.$bvModal
          .msgBoxConfirm(
            "You have unsaved changes. Are you sure you want to leave?",
            {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Yes",
              cancelTitle: "No",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true
            }
          )
          .then(value => {
            if (value) {
              next();
            } else {
              next(false);
            }
          })
          .catch(() => {
            // An error occurred
          });
      } else {
        next();
      }
    }
  }
};
