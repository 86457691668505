<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <Description
          :labelName="labelName"
          @change="onDescriptionChange"
          :valueName="itemUnderEdit.name"
          :valueDesc="itemUnderEdit.description"
        />
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm } from "@gitlab/ui";
import Description from "@/components/Description";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import { ALIAS_ITEMS_PACKAGES, ITEMS_PACKAGES } from "@/constants.js";
const { ADD_SECOND, EDIT_SECOND } = ALIAS_ITEMS_PACKAGES;
const { EDIT } = ITEMS_PACKAGES;

export default {
  name: "AddFirst",
  components: {
    Wizard,
    GlForm,
    Description
  },
  mixins: [currentItem],
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDescFulfilled: this.currentItem.name && this.currentItem.description,
      labelName: "Package name",
      leftSectionTitle: `Description`,
      buttons: [{ text: `Next Step`, type: `submit` }]
    };
  },
  watch: {
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name && this.currentItem.description;
      },
      deep: true
    }
  },
  methods: {
    nextStep() {
      if (this.isDescFulfilled) {
        if (this.isUpdate) {
          this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`);
        } else {
          this.$router.push(ADD_SECOND);
        }
      }
    },
    onDescriptionChange(data) {
      const { name, description } = data;
      this.itemUnderEdit.name = name;
      this.itemUnderEdit.description = description;
    }
  }
};
</script>
<style>
.gl-token-selector-token-container {
  display: none;
}
</style>
