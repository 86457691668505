import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      deliveryApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.DELIVERIES,
        "deliveries"
      ),
      deliveryTypes: [
        { text: "Channel", value: `channel_delivery` },
        { text: `Event`, value: `event_delivery` }
      ]
    };
  },
  methods: {
    async getDestinationDeliveryStatus(destinationId) {
      const path = `get-destination-availability/${destinationId}`;
      const url = new URL(this.deliveryApiClient.baseUrl + path);
      return this.deliveryApiClient.apiClient.get(url);
    }
  }
};
