<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { MAIN_ITEMS, ITEMS_VIRTUAL_CONNECT_SITES } from "@/constants.js";
const { LIST } = ITEMS_VIRTUAL_CONNECT_SITES;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [{ title: `Virtual Connect Sites`, subPageName: LIST }];
    }
  },
  data() {
    return {
      pageTitle: `Virtual Connect Sites`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Virtual Connect Sites`, href: LIST }
      ]
    };
  }
};
</script>
