import PriceSet from "@/views/Pricing/PriceSet";
import PriceConfigView from "@/views/Pricing/PriceConfig";
import {
  MediaConnectActiveResources,
  MediaConnectDataTransferInterRegion,
  MediaConnectDataTransferInternet,
  MediaLiveInputs,
  MediaLiveOutputs,
  MediaLiveDataTransferInterRegion,
  PriceConfig
} from "@/components/Pricing";
import { MAIN_ITEMS, ITEMS_PRICING } from "@/constants.js";
const { PRICING } = MAIN_ITEMS;
const {
  MEDIA_CONNECT_ACTIVE_RESOURCES,
  MEDIA_CONNECT_DATA_TRANSFER_INTER_REGION,
  MEDIA_CONNECT_DATA_TRANSFER_INTERNET,
  MEDIA_LIVE_INPUTS,
  MEDIA_LIVE_OUTPUTS,
  PRICE_CONFIG,
  MEDIA_LIVE_DATA_TRANSFER_INTER_REGION
} = ITEMS_PRICING;

const pricing = [
  {
    path: PRICING,
    name: `PriceSet`,
    component: PriceSet,
    children: [
      {
        path: MEDIA_CONNECT_ACTIVE_RESOURCES,
        component: MediaConnectActiveResources
      },
      {
        path: MEDIA_CONNECT_DATA_TRANSFER_INTER_REGION,
        component: MediaConnectDataTransferInterRegion
      },
      {
        path: MEDIA_CONNECT_DATA_TRANSFER_INTERNET,
        component: MediaConnectDataTransferInternet
      },
      {
        path: MEDIA_LIVE_INPUTS,
        component: MediaLiveInputs
      },
      {
        path: MEDIA_LIVE_OUTPUTS,
        component: MediaLiveOutputs
      },
      {
        path: MEDIA_LIVE_DATA_TRANSFER_INTER_REGION,
        component: MediaLiveDataTransferInterRegion
      }
    ]
  },
  {
    path: PRICING,
    name: `PriceConfig`,
    component: PriceConfigView,
    children: [
      {
        path: PRICE_CONFIG,
        component: PriceConfig
      }
    ]
  }
];

export default pricing;
