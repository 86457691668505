<template>
  <div>
    <MainSection />
    <div class="mx-3 my-1">
      <Dashboard />
    </div>
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import Dashboard from "../components/Dashboard/Dashboard.vue";

export default {
  components: { MainSection, Dashboard }
};
</script>
