<template>
  <fragment>
    <tr>
      <td class="expand-td">
        <gl-button
          data-testid="expand-button"
          class="expand-button"
          :icon="expanded ? 'chevron-down' : 'chevron-right'"
          @click="toggleDetails"
        />
      </td>
      <slot></slot>
    </tr>
    <tr v-if="expanded" class="expanded_data">
      <td :colspan="colspan" class="expanded-td">
        <slot name="expanded"></slot>
      </td>
    </tr>
  </fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import { GlButton } from "@gitlab/ui";

export default {
  name: "ExpandableItem",
  components: {
    GlButton,
    Fragment
  },
  props: {
    colspan: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    toggleDetails() {
      this.expanded = !this.expanded;
    },
    expandDetails() {
      this.expanded = true;
    },
    closeDetails() {
      this.expanded = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.expand-td {
  padding: 0 !important;
  display: flex;
  align-items: center;
  border: none;
}
.expanded-td {
  padding: 0 !important;
}
.expand-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 0.2rem;
  padding: 0.2rem !important;

  &:focus {
    box-shadow: 0px 0px 4px 0px var(--theme-color) !important;
  }
}
.expand-button::v-deep .gl-button-icon {
  width: 1.8rem !important;
  height: 1.8rem !important;
}
.expanded_data {
  background-color: #eeeeee;

  &::v-deep {
    .info-title {
      font-size: 14px;
      font-weight: bold;
      color: #8e8e8e;
    }
    p,
    dd {
      font-size: 12px;
      font-weight: bold;
    }

    .timezones {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 600;
    }

    .times {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
    }

    .times h3 {
      font-size: 12px;
      font-weight: bold;
      color: #8e8e8e;
    }
  }
}
</style>
