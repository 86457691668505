<template>
  <div>
    <p v-if="currentItem.contributionType === 'channel_contribution'">
      Specify a start date and time for your contribution and specify and end
      date and time <strong>OR</strong> select <strong>Open Ended</strong> to
      keep your contribution running until manually cancelled.
    </p>
    <p v-if="currentItem.contributionType === 'event_contribution'">
      Specify a start date and time for your contribution and specify and end
      date and time.
    </p>
    <p class="text-danger" v-if="currentItem.id">
      Please note, if you edit the planned start and end times, you will affect
      any pre defined deliveries that are already consuming the contribution
    </p>
    <Schedule
      class="mt-5"
      @dateRangeSet="saveRangeSet"
      @openEnded="setOpenEnded"
      :isOpenEnded="currentItem.isOpenEnded"
      :showOpenEnded="currentItem.contributionType === 'channel_contribution'"
      :disableStarts="currentItem.editLevel > CONTRIBUTION_EDIT_LEVELS.ALL"
      :disableEnds="currentItem.editLevel > CONTRIBUTION_EDIT_LEVELS.END_DATES"
      :eventStart="plannedStart ? plannedStart : null"
      :eventEnd="plannedEnd ? plannedEnd : null"
      :contributionType="currentItem.contributionType"
    />
    <p></p>
  </div>
</template>
<style scoped lang="scss">
.datetime {
  font-size: inherit;
  margin: 0;
}
</style>
<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Schedule from "@/components/Contributions/addSecond/Schedule.vue";
import Contribution from "@/models/Contribution";
import { CONTRIBUTION_EDIT_LEVELS } from "@/constants";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    Schedule
  },
  name: "ScheduleSection",
  props: {
    currentItem: Contribution
  },
  data() {
    return {
      plannedStart: this.currentItem.plannedStart,
      plannedEnd: this.currentItem.plannedEnd
    };
  },
  computed: {
    CONTRIBUTION_EDIT_LEVELS() {
      return CONTRIBUTION_EDIT_LEVELS;
    }
  },
  methods: {
    saveRangeSet(dateRange) {
      this.currentItem.plannedStart = dateRange.start;
      this.currentItem.plannedEnd = dateRange.end;
      this.$emit("change", this.currentItem);
    },

    setOpenEnded($event) {
      this.currentItem.isOpenEnded = $event;
      this.currentItem.plannedEnd = null;
      this.$emit("change", this.currentItem);
    }
  }
};
</script>
