import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      sourceApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.SOURCES,
        "sources"
      )
    };
  },
  created() {
    this.sourceApiClient.sendSourcesDetails = ids => {
      return this.sourceApiClient.postCustomPath("send-details", "", { ids });
    };
    0;
  }
};
