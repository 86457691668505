<style lang="scss" src="./DetailScreenComponent.scss" scoped></style>
<template>
  <div id="detailsShow">
    <Loading v-if="!item || !item.id"></Loading>
    <div id="header">
      <h1 class="d-flex justify-content-left mt-3">{{ item.name }}</h1>
      <p id="description">{{ item.description }}</p>
      <div id="actions" v-if="item && item.id">
        <div v-for="actionIcon in actionIcons" :key="actionIcon.id">
          <gl-button
            @click="actionIcon.action(item)"
            variant="default"
            :title="actionIcon.title"
            :disabled="actionIcon.disabled"
          >
            <gl-icon :name="actionIcon.icon" />
            {{ actionIcon.label }}
          </gl-button>
        </div>
        <div
          class="row align-items-center justify-content-center"
          v-if="item.destinationDeliveryId"
        >
          <gl-button
            variant="success"
            :to="`/delivery/all?id=${item.destinationDeliveryId}`"
            class="ml-3"
          >
            <span class="blinking">Go to delivery...</span>
          </gl-button>
        </div>
        <div id="status" v-if="item.status" :class="item.status">
          <div class="row align-items-center ml-2">
            <gl-icon name="status-active" :size="8" />
            <span class="ml-2">{{ item.status }}</span>
          </div>
        </div>
      </div>
    </div>

    <div id="columnsWrapper">
      <div id="details" v-if="item && item.id">
        <gl-form-input placeholder="Type to search" v-model="filterBy" />
        <br />
        <gl-table
          id="detailsTable"
          :items="itemPairs"
          :filter="filterBy"
          :fixed="true"
          :stacked="false"
          :foot-clone="false"
          hover
          :striped="true"
          :no-border-collapse="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { DESTINATION_TYPES, SOURCE_TYPES } from "@/constants";
import userInfo from "@/mixins/userInfo.js";
import { GlButton, GlFormInput, GlIcon, GlTable } from "@gitlab/ui";
import Loading from "@/components/Loading.vue";

export default {
  name: "DetailScreenComponent",
  mixins: [userInfo],
  data() {
    return {
      capitalizeFields: ["accessLevel", "protocol"],
      filterBy: null,
      fieldsToExclude: ["accessLevel", "organisationName"]
    };
  },
  components: {
    Loading,
    GlButton,
    GlIcon,
    GlFormInput,
    GlTable
  },
  methods: {
    findKeyByValue(obj, value) {
      const key = Object.keys(obj).find(key => obj[key] === value);
      return key || null;
    },
    sanitizeFieldName(field) {
      switch (field) {
        case "whitelistCidr":
          return "Allowed list Cidr";
      }
      return field
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, c => c.toUpperCase());
    },
    sanitizeFieldValue(value) {
      if (Array.isArray(value)) {
        return value.join(", ");
      } else if (typeof value === "number") {
        return value;
      } else if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      } else {
        if (value.endsWith("destination")) {
          const key = this.findKeyByValue(DESTINATION_TYPES, value);
          if (key) {
            return key.replace("_", " ");
          }
        }

        if (value.endsWith("source")) {
          const key = this.findKeyByValue(SOURCE_TYPES, value);
          if (key) {
            return key.replace("_", " ");
          }
        }
        if (value.endsWith("contribution")) {
          value.replace("_contribution", "");
        }
      }
      return value.replace(/_/g, " ");
    },
    filterDataBasedOnRoles() {
      if (this.isArqadeAdminRoleActive || this.isMasquerading) {
        return this.item;
      } else {
        return Object.entries(this.item).reduce((acc, [key, value]) => {
          if (!this.fieldsToExclude.includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {});
      }
    }
  },
  computed: {
    itemPairs() {
      const filtered = this.filterDataBasedOnRoles();
      const filteredItem = Object.keys(filtered)
        .filter(key => this.fieldsToInclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = filtered[key];
          return obj;
        }, {});
      return Object.entries(filteredItem)
        .map(([field, value]) => {
          if (this.capitalizeFields.includes(field)) {
            value = value.toUpperCase();
          }
          return {
            field: this.sanitizeFieldName(field),
            value: value ? this.sanitizeFieldValue(value) : null,
            _cellVariants: {
              value: this.alertFields.includes(field) ? "danger" : null
            }
          };
        })
        .filter(item => item.value !== null)
        .sort((a, b) => a.field.localeCompare(b.field));
    }
  },
  props: {
    links: Object || null,
    item: {
      type: Object,
      required: true
    },
    actionIcons: {
      type: Array,
      required: true
    },
    goToList: {
      type: Function,
      required: false,
      default: () => {}
    },
    fieldsToInclude: {
      type: Array,
      required: true
    },
    alertFields: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
</script>
