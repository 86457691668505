import { Auth } from "aws-amplify";
export default {
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.error(`error signing out: `, error);
      }
    }
  }
};
