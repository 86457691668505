import { MAIN_ITEMS } from "../constants";
import SearchView from "@/views/SearchView";
const { SEARCH } = MAIN_ITEMS;
const HOST = "SEARCH";
const search = [
  {
    path: SEARCH,
    name: `${HOST}`,
    component: SearchView
  }
];

export default search;
