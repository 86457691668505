<template>
  <your-all status="ENABLED"></your-all>
</template>
<script>
import YourAll from "./YourAll.vue";
export default {
  components: { YourAll },
  name: "Enabled"
};
</script>
