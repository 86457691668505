import { debounce } from "debounce";

export default {
  methods: {
    debounceInput: debounce(function(e, field = "searchPhrase") {
      this.$emit("updateInput", e);
      if (this.searchFields) {
        this.searchFields = {
          ...this.searchFields,
          [field]: e
        };
      } else {
        this[field] = e;
      }
    }, 300)
  }
};
