<template>
  <div class="mx-3 my-1 pt-9">
    <h1>
      Entitlement Configuration
    </h1>
    <form class="mt-5">
      <h3>Discount</h3>
      <gl-form-group
        class="m-4"
        label="Event Setup"
        label-size="sm"
        label-for="eventSetupDiscount"
      >
        <gl-form-input
          type="number"
          id="eventSetupDiscount"
          v-model="entitlement.eventSetupDiscount"
          placeholder="Event Setup Discount Percentage"
          class="mt-1"
          min="0"
          max="100"
        />
      </gl-form-group>
      <gl-form-group
        class="m-4"
        label="Event Hourly"
        label-size="sm"
        label-for="eventHourlyDiscount"
      >
        <gl-form-input
          type="number"
          id="eventHourlyDiscount"
          v-model="entitlement.eventHourlyDiscount"
          placeholder="Event Hourly Discount Percentage"
          class="mt-1"
          min="0"
          max="100"
        />
      </gl-form-group>
      <gl-form-group
        class="m-4"
        label="Channel Delivery Data"
        label-size="sm"
        label-for="channelDataDeliveryDiscount"
      >
        <gl-form-input
          type="number"
          id="channelDataDeliveryDiscount"
          v-model="entitlement.channelDataDeliveryDiscount"
          placeholder="Channel Delivery Data Discount Percentage"
          class="mt-1"
          min="0"
          max="100"
        />
      </gl-form-group>
      <gl-form-group
        class="m-4"
        label="Channel Delivery Transcode"
        label-size="sm"
        label-for="channelTranscodeDiscount"
      >
        <gl-form-input
          type="number"
          id="channelTranscodeDiscount"
          v-model="entitlement.channelTranscodeDiscount"
          placeholder="Channel Delivery Transcode Discount Percentage"
          class="mt-1"
          min="0"
          max="100"
        />
      </gl-form-group>

      <h4>Auto-cancellation Delay</h4>
      <gl-form-group
        class="m-4"
        label="Days to Cancel the Delivery"
        label-size="sm"
        label-for="deliveryCancellationDelay"
      >
        <gl-form-input
          type="number"
          id="deliveryCancellationDelay"
          v-model="entitlement.deliveryCancellationDelay"
          class="mt-1"
          min="0"
          max="365"
        />
      </gl-form-group>
      <div class="d-flex justify-content-end m-5" style="gap: 3%">
        <gl-button
          @click="$router.push(`${ALIAS_ITEMS_PACKAGES.REQUESTS_ALL}`)"
        >
          Back
        </gl-button>
        <gl-button @click="save" variant="danger">
          Save
        </gl-button>
      </div>

      <div>
        <h4>Upload Overlay Image</h4>
        <input
          type="file"
          ref="fileInput"
          @change="handleFileChange"
          class="mt-1"
          :key="reloadSelectedFileName"
        />
        <div class="d-flex flex-row mt-4 align-items-baseline">
          <h4>Current File:</h4>
          <p>{{ entitlement.overlayOriginalFilename }}</p>
        </div>
        <div class="d-flex justify-content-end" style="gap: 3%">
          <gl-button v-if="entitlement.hasOverlay" @click="deleteFile">
            Remove
          </gl-button>
          <gl-button @click="uploadFile" variant="danger">
            Upload
          </gl-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { ALIAS_ITEMS_PACKAGES } from "@/constants";
import entitlementApiClient from "@/mixins/entitlementApiClient.js";
import { GlButton, GlFormGroup, GlFormInput } from "@gitlab/ui";
import axios from "axios";
export default {
  name: "EntitlementConfig",
  computed: {
    ALIAS_ITEMS_PACKAGES() {
      return ALIAS_ITEMS_PACKAGES;
    }
  },
  components: { GlFormGroup, GlFormInput, GlButton },
  mixins: [entitlementApiClient],
  created() {
    this.fetchEntitlement();
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.$router.push(`${ALIAS_ITEMS_PACKAGES.REQUESTS_ALL}`);
      }
    },
    async fetchEntitlement() {
      const id = this.$route.params.id;
      await this.entitlementApiClient.getById(id).then(response => {
        this.entitlement.channelDataDeliveryDiscount =
          response.channelDataDeliveryDiscount;
        this.entitlement.channelTranscodeDiscount =
          response.channelTranscodeDiscount;
        this.entitlement.deliveryCancellationDelay =
          response.deliveryCancellationDelay;
        this.entitlement.eventHourlyDiscount = response.eventHourlyDiscount;
        this.entitlement.eventSetupDiscount = response.eventSetupDiscount;
        this.entitlement.hasOverlay = response.hasOverlay;
        this.entitlement.id = response.id;
        this.entitlement.orgId = response.grantingOrganisationId;
        this.entitlement.overlayOriginalFilename =
          response.overlayOriginalFilename;
      });
    },
    async save() {
      try {
        await this.entitlementApiClient.patch(
          this.entitlement.id,
          this.entitlement
        );
        this.handleApiSuccess("Entitlement updated successfully");
      } catch (e) {
        this.handleApiError(e);
      }
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    deleteFile() {
      this.selectedFile = null;
      this.entitlementApiClient
        .patch(this.entitlement.id, {
          remove_overlay: true
        })
        .then(
          () => {
            this.handleApiSuccess(`Overlay removed successfully`);
          },
          error => {
            this.handleApiError(error);
          }
        );
    },
    uploadFile() {
      if (!this.selectedFile) {
        alert("No file selected.");
        return;
      }
      try {
        this.entitlementApiClient
          .getCustomPath(this.entitlement.id, "overlay")
          .then(response => {
            let signed = response;
            let formData = new FormData();
            for (let field in signed.fields) {
              formData.append(field, signed.fields[field]);
            }
            formData.append("file", this.selectedFile);
            axios
              .post(signed.url, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              })
              .then(() => {
                this.entitlementApiClient
                  .patch(this.entitlement.id, {
                    overlay_original_filename: this.selectedFile.name
                  })
                  .then(() => {
                    this.handleApiSuccess(`File uploaded successfully`);
                    this.fetchEntitlement();
                    // This is a hack to force the file input to reset
                    this.reloadSelectedFileName++;
                  });
              })
              .catch(error => {
                this.handleApiError(error);
              });
          });
      } catch (e) {
        this.handleApiError(e);
      }
    }
  },
  data() {
    return {
      selectedFile: null,
      reloadSelectedFileName: 0,
      entitlement: {
        id: undefined,
        eventSetupDiscount: undefined,
        eventHourlyDiscount: undefined,
        channelDataDeliveryDiscount: undefined,
        channelTranscodeDiscount: undefined,
        deliveryCancellationDelay: undefined,
        overlayOriginalFilename: undefined
      }
    };
  }
};
</script>
<style scoped>
form {
  max-width: 40%;
}
</style>
