<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">Packages</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__packages">
      <gl-filtered-search
        :available-tokens="tokens"
        @submit="activate"
        @clear="clear"
        class="mb-2"
      >
        <template #history-item="{ historyItem }">
          <template v-for="(token, idx) in historyItem">
            <span :key="idx" class="px-1">
              {{ token.type }} =
              <strong>{{ token.value }}</strong>
            </span>
          </template>
        </template>
      </gl-filtered-search>
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItemsByRow"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <div
                    class="monitoring_packages__status"
                    :class="{
                      'monitoring_packages__status--success': r.health === 'OK',
                      'monitoring_packages__status--warning':
                        r.health === 'WARN',
                      'monitoring_packages__status--danger': r.health === 'NOK'
                    }"
                  ></div>
                </div>
                <span class="cellTitle" v-b-tooltip :title="r.name">{{
                  r.name
                }}</span>
              </div>
              <b-badge variant="secondary" class="monitoring_packages__badge">{{
                r.contributions_count
              }}</b-badge>
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { PACKAGES_SEARCH_BAR_TOKENS, ITEMS_PACKAGES } from "@/constants.js";
import { GlLoadingIcon, GlButton, GlFilteredSearch } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";
const { EDIT } = ITEMS_PACKAGES;

export default {
  name: "Packages",
  mixins: [metricsApiClient, userInfoMixin],
  components: { ArqDataGrid, GlLoadingIcon, GlButton, GlFilteredSearch },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  created: function() {
    this.$parent.$on("clearPackages", this.clearPackages);
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      this.searchParams = {};
      this.searchPhrase = "";
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    clearPackages() {
      this.data = {};
      this.searchParams = {};
      this.searchPhrase = "";
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT}/${row.id}`);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    async onDataNeeded(sortModel, pageModel) {
      this.isLoading = true;
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = (this.isArqadeAdminRoleActive
        ? this.getMetricsHealth("packages")
        : this.getMetricsHealth("packages/own")
      )
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.isLoading = false;
      this.data = d;
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          if (row.id !== "unpackaged") {
            out[row.id] = JSON.parse(JSON.stringify(this.contextMenuItems));
          }
        }
      }
      return out;
    },
    tokens() {
      return PACKAGES_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      isLoading: true,
      sortModel: {},
      pageModel: {
        itemsPerPage: 10
      },
      selectedRow: undefined,
      data: {},
      searchParams: {},
      searchPhrase: "",
      columns: [
        {
          text: "",
          field: "name"
        }
      ],
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        }
      ]
    };
  }
};
</script>
<style scoped>
.monitoring_packages__status {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 15px;
}
.monitoring_packages__status--success {
  background-color: #91d4a8;
}
.monitoring_packages__status--warning {
  background-color: #f5d9a8;
}
.monitoring_packages__status--danger {
  background-color: #f56c7e;
}
.monitoring_packages__badge {
  font-size: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
</style>
