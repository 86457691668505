<template>
  <ExpandableItem ref="expand" :colspan="7">
    <template>
      <td v-if="multiSelection">
        <gl-form-checkbox
          @change="selectRow($event, row)"
          :checked="isSelected"
        />
      </td>
      <td
        @click="handleClick(row.id)"
        style="cursor: pointer;"
        class="font-weight-bold"
      >
        {{ row.name }}
      </td>
      <td>
        <b-badge v-if="row.source1Id" variant="primary" class="is-redundant">
          <gl-icon name="check" />
        </b-badge>
        <gl-icon v-else name="close" class="not-multi-source" />
      </td>
      <DateTime :date="row.plannedStart" />
      <DateTime :date="row.plannedEnd" :isOpenEnded="row.isOpenEnded" />
      <td>
        <StatusCell :status="row.scheduleStatus" class="l" />
        <AlertsBadge :resource="row" />
      </td>
      <td>
        <StatusCell :status="'Pending'" class="l" v-if="isPending(row)" />
        <StatusCell
          :status="'Completed'"
          class="l"
          v-else-if="isCompleted(row)"
        />
        <StatusCell :status="'Current'" class="l" v-else-if="isCurrent(row)" />
      </td>
      <td>
        <ContextMenu
          v-if="this.isOrgAdminRoleActive || this.isArqadeAdminRoleActive"
        >
          <li
            v-if="row.scheduleStatus !== 'cancelled' && !this.isCompleted(row)"
          >
            <button class="context-button" @click="deliver(row)">
              Deliver
            </button>
          </li>
          <li>
            <button class="context-button" @click="clone(row)">
              Clone
            </button>
          </li>
          <li
            v-if="
              row.status !== 'cancel' &&
                (this.isPending(row) || this.isCurrent(row))
            "
          >
            <button class="context-button" @click="cancelContribution(row)">
              Cancel
            </button>
          </li>
        </ContextMenu>
      </td>
    </template>
    <template v-slot:expanded>
      <ExpandedContent :item="row" />
    </template>
  </ExpandableItem>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import dateUtils from "@/mixins/dateUtils";
import ExpandableItem from "@/components/Table/Expand/ExpandableItem.vue";
import DateTime from "@/components/Table/TD/DateTime.vue";
import { ITEMS_CONTRIBUTIONS, ITEMS_DESTINATIONS } from "@/constants.js";
import ExpandedContent from "./ExpandedContent.vue";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import AlertsBadge from "@/components/DataGrid/AlertsBadge.vue";
import { contributionApiClient } from "@/mixins/apiClients";
import ContextMenu from "@/components/ContextMenu/ContextMenu";
import msgUtils from "@/mixins/msgUtils.js";
const { DELIVERY } = ITEMS_CONTRIBUTIONS;
import { GlFormCheckbox, GlIcon } from "@gitlab/ui";
const { SHOW } = ITEMS_DESTINATIONS;

export default {
  mixins: [msgUtils, dateUtils, userInfo, contributionApiClient],
  components: {
    ExpandedContent,
    GlFormCheckbox,
    GlIcon,
    ExpandableItem,
    DateTime,
    StatusCell,
    AlertsBadge,
    ContextMenu
  },
  props: {
    row: {
      type: Object
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contributionType: "",
      SHOW_DESTINATION: SHOW,
      DELIVERY: DELIVERY
    };
  },
  methods: {
    selectRow(event, row) {
      this.$emit("selectRow", event, row);
    },
    handleClick(id) {
      this.$emit("rowClick", id);
    },
    checkAlert(row) {
      return !!(
        (row.currentAlerts0 && row.currentAlerts0.length > 0) ||
        (row.currentAlerts1 && row.currentAlerts1.length > 0)
      );
    },
    deliver(row) {
      this.$router.push(`${DELIVERY}/${row.id}`);
    },
    clone(row) {
      const cloned = JSON.parse(JSON.stringify(row));
      cloned.name += " (copy)";
      this.$router.push({
        name: `ContributionsAdd`,
        params: { cloning: cloned }
      });
    },

    async cancelContribution(item) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to cancel "${item.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        try {
          await this.contributionApiClient.patch(item.id, {
            scheduleStatus: "cancelled"
          });
          this.row.scheduleStatus = "cancelled";
          this.handleApiSuccess(
            `The contribution "${item.name}" has been cancelled.`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~vue-context/dist/css/vue-context.css";
.linkToDestination {
  font-size: 11px;
}
.context-button {
  border: none;
  background-color: transparent;
  max-width: none;
}
.center {
  margin: auto;
  display: block;
}
.not-multi-source {
  color: #aaa;
}
.is-redundant {
  background-color: #c3e6e1 !important;
  color: #24663b !important;
}
</style>
