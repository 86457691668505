<template>
  <gl-button-group class="mr-2">
    <gl-button @click="expandAllItems(items)" data-testid="expand">
      Expand All
    </gl-button>

    <gl-button @click="collapseAllItems(items)" data-testid="collapse"
      >Collapse All</gl-button
    >
  </gl-button-group>
</template>
<script>
import { GlButton, GlButtonGroup } from "@gitlab/ui";

export default {
  name: "ExpandToggles",
  components: {
    GlButton,
    GlButtonGroup
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    expandAllItems(items) {
      if (items) items.map(item => item?.$refs?.expand?.expandDetails());
    },
    collapseAllItems(items) {
      if (items) items.map(item => item?.$refs?.expand?.closeDetails());
    }
  }
};
</script>
