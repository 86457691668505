<template>
  <div>
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { ALIAS_ITEMS_CONTRIBUTION, MAIN_ITEMS } from "@/constants.js";

const {
  CHANNELS_ALL,
  CHANNELS_PENDING,
  CHANNELS_COMPLETED,
  CHANNELS_CURRENT,
  CHANNELS_CANCELLED
} = ALIAS_ITEMS_CONTRIBUTION;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [
        { title: `All channels`, subPageName: CHANNELS_ALL },
        { title: `Current`, subPageName: CHANNELS_CURRENT },
        { title: `Completed`, subPageName: CHANNELS_COMPLETED },
        { title: `Pending`, subPageName: CHANNELS_PENDING },
        { title: `Cancelled`, subPageName: CHANNELS_CANCELLED }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Channels`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Contributions`, href: CHANNELS_ALL },
        { text: `Channels`, href: CHANNELS_ALL }
      ]
    };
  }
};
</script>
