<template>
  <All state="PENDING" scheduleStatus="SCHEDULED" />
</template>
<script>
import All from "@/components/Delivery/All/Page";

export default {
  name: "deliveryComplete",
  components: {
    All
  }
};
</script>
