export default {
  props: {
    currentItem: {
      type: Object
    }
  },
  data() {
    return {
      itemUnderEdit: this.currentItem || {}
    };
  },
  watch: {
    currentItem(val) {
      this.itemUnderEdit = val || {};
    }
  }
};
