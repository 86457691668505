<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button
          v-if="owned"
          variant="confirm"
          class="mr-3"
          :href="`#${ADD_FIRST}`"
          >Create package</gl-button
        >
        <gl-dropdown
          text="Bulk actions"
          class="mr-3"
          v-if="bulkContextMenuItems.length > 0"
        >
          <gl-dropdown-item
            v-for="item in bulkContextMenuItems"
            :key="item.id"
            @click="bulkMenuClick(item.id)"
            >{{ item.text }}</gl-dropdown-item
          >
        </gl-dropdown>
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          :subColumns="subColumns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          @drawerRowSelected="onDrawerRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot:drawer="{ drawerRow: r, drawerField: field }">
            <div v-if="field === 'name'">
              <span class="cellTitle">{{ r.name }}</span>
            </div>
            <div v-else-if="field === 'plannedStart' || field === 'plannedEnd'">
              <StatusCell
                v-if="r.isOpenEnded && field === 'plannedEnd'"
                :showIcon="true"
                :status="'On-Going'"
                class="l"
              />
              <div v-else>
                <span class="cellTitle">{{ toLocalDate(r[field]) }}</span
                ><br />
                <span>{{ toLocalTime(r[field]) }}</span>
              </div>
            </div>
            <div v-else-if="field === 'status'">
              <StatusCell
                :showIcon="true"
                :status="r.scheduleStatus"
                class="l"
              />
            </div>
          </template>
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'name'">
              <span class="cellTitle">{{ r.name }}</span>
            </div>
            <div v-else-if="field === 'starts'">
              <span class="cellTitle">{{ toLocalDate(r.starts) }}</span
              ><br />
              <span>{{ toLocalTime(r.starts) }}</span>
            </div>
            <StatusCell
              v-else-if="field === 'status'"
              :status="r.status"
              class="l"
            />
          </template>
        </ArqDataGrid>
        <p>
          <small>{{ messageLocalTime }}</small>
        </p>
      </b-col>
      <b-col cols="4">
        <div class="border-bottom border-light mb-3">
          <p>
            <gl-button
              class="standard-w"
              variant="confirm"
              @click="requestPaneClick"
              v-if="!isRequestDisabled"
              >Request</gl-button
            >
          </p>
        </div>
        <ArqDataGridDetailsPane
          v-if="selectedRow"
          :columns="detailsColumns"
          :row="selectedRow"
        />
        <ArqDataGridDetailsPane
          v-if="selectedChildRow"
          :columns="childDetailsColumns"
          :row="selectedChildRow"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import {
  ITEMS_CONTRIBUTIONS,
  ALIAS_ITEMS_PACKAGES,
  ITEMS_PACKAGES
} from "@/constants.js";
const { ADD_FIRST } = ALIAS_ITEMS_PACKAGES;
const { DELIVERY } = ITEMS_CONTRIBUTIONS;
const { SHOW } = ITEMS_PACKAGES;
import {
  contributionApiClient,
  packageApiClient,
  entitlementApiClient
} from "@/mixins/apiClients";
import userInfoMixin from "@/mixins/userInfo.js";
import debouncedInput from "@/mixins/debouncedInput.js";
import {
  GlForm,
  GlDropdownItem,
  GlDropdown,
  GlButton,
  GlSearchBoxByType
} from "@gitlab/ui";
import { mapState } from "vuex";

const STORE = `auth`;
export default {
  name: "YourAll",
  mixins: [
    dateUtils,
    debouncedInput,
    packageApiClient,
    contributionApiClient,
    entitlementApiClient,
    userInfoMixin
  ],
  components: {
    ArqDataGrid,
    StatusCell,
    GlForm,
    GlButton,
    ArqDataGridDetailsPane,
    GlDropdown,
    GlDropdownItem,
    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    owned: {
      type: Boolean,
      default: false
    },
    entitled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getSubMenuContextItems() {
      if (!this.entitled) {
        return [];
      }
      return [
        {
          id: "deliver",
          text: "Deliver",
          emphasize: true
        }
      ];
    },
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    requestPaneClick() {
      if (this.selectedRows.length > 0) {
        const item = this.selectedRows[0];
        this.request(item);
      }
    },
    async request(item) {
      const value = await this.$bvModal.msgBoxConfirm(
        <textarea
          maxLength="5000"
          rows="3"
          placeholder="Optional note here..."
          v-model={item.requestNote}
          style="display: block; width: 100%;"
        />,
        {
          title: `Are you sure you want to request ${item.name}?`,
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.entitlementApiClient.create({
            packageId: item.id,
            grantingOrganisationId: item.organisationId,
            requestNote: item.requestNote
          });
          this.handleApiSuccess("Entitlement requested");
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `request`:
          this.request(row);
          break;
        case `deliver`:
          this.$router.push(`${DELIVERY}/${row.id}?packageId=${row.packageId}`);
          break;
        case `clone`: {
          const cloned = JSON.parse(JSON.stringify(row));
          cloned.name += " (copy)";
          cloned.contributions = cloned.contributions.map(c => c.id);
          this.entitlementApiClient.getForPackage(row.id).then(entitlements => {
            cloned.entitlements = entitlements.items
              .filter(e => e.status === "Approved")
              .map(e => e.organisation.id);
          });
          this.$router.push({
            name: "PackagesCreate",
            params: { cloning: cloned }
          });
          break;
        }
      }
    },
    bulkMenuClick(option) {
      switch (option) {
        case `request`: {
          for (const row of this.selectedRows) {
            if (!this.checkRequestDisabled(row)) {
              this.request(row);
            }
          }
          break;
        }
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    onDrawerRowSelected(row, selectedRows) {
      this.selectedChildRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedChildRow = selectedRows[0].data;
      } else {
        this.selectedChildRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.packageApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (this.owned) {
        query = query.param("owned", this.owned);
      }
      if (this.entitled) {
        query = query.param("entitled", this.entitled);
      }
      if (!this.owned && !this.entitled) {
        query = query.param("owned", this.owned);
        query = query.param("entitled", this.entitled);
      }

      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }
      query.filterBy(phrase);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        for (let item of response.items) {
          for (let contribution of item.contributions) {
            contribution.packageId = item.id;
          }
        }
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }

      // The UI is expecting the children attribute
      // to represent contributions on an item, so we
      // assign that here:

      for (let item of d.items) {
        item.children = item.contributions;
      }

      this.data = JSON.parse(JSON.stringify(d));
      if (this.owned === false) {
        this.data.items = this.data.items.filter(
          item => item.organisationId !== this.getUserOrganizationId
        );
      }
    },
    checkRequestDisabled(row) {
      return !!row && (this.owned || this.entitled);
    },
    checkCloneDisabled(row) {
      return !!row && this.entitled;
    }
  },
  watch: {
    data: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state[STORE].userInfo
    }),
    isRequestDisabled() {
      let enabled = this.selectedRows.length === 1;
      if (this.checkRequestDisabled(this.selectedRow)) {
        enabled = false;
      }
      return !enabled;
    },
    bulkContextMenuItems() {
      if (this.selectedRows.length > 0) {
        let bulkMenu = this.contextMenuItemsByRow[this.selectedRows[0].id];
        if (bulkMenu) {
          bulkMenu = bulkMenu.filter(row => row.id !== "clone");
          for (const row of this.selectedRows) {
            if (this.checkRequestDisabled(row)) {
              bulkMenu = bulkMenu.filter(row => row.id !== "request");
            }
          }
        }
        return bulkMenu || [];
      } else {
        return [];
      }
    },
    contextMenuItemsByRow() {
      let out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = JSON.parse(JSON.stringify(this.contextMenuItems));
          if (this.checkRequestDisabled(row)) {
            out = Object.fromEntries(
              Object.entries(out).map(([key, value]) => [
                key,
                value.filter(item => item.id !== "request")
              ])
            );
          }
          if (this.checkCloneDisabled(row)) {
            out = Object.fromEntries(
              Object.entries(out).map(([key, value]) => [
                key,
                value.filter(item => item.id !== "clone")
              ])
            );
          }

          if (row.children) {
            for (const child of row.children) {
              out[child.id] = this.subMenuContextItems;
            }
          }
        }
      }
      return out;
    },
    isCreateContributionDisabled() {
      return this.selectedRows.length > 2 || this.selectedRows.length === 0;
    },
    messageLocalTime() {
      const offset = new Date().getTimezoneOffset() / 60;
      return `All dates/times are specified in local timezone (UTC${
        offset > 0 ? "+" + offset : offset
      })`;
    }
  },
  data() {
    return {
      ADD_FIRST: ADD_FIRST,
      searchPhrase: "",
      selectedRow: undefined,
      selectedChildRow: undefined,
      selectedRows: [],
      selectedChildRows: [],
      data: {},
      subMenuContextItems: this.getSubMenuContextItems(),
      contextMenuItems: [
        {
          id: "request",
          text: "Request",
          emphasize: true
        }
      ],
      subColumns: [
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Start",
          field: "plannedStart"
        },
        {
          text: "End",
          field: "plannedEnd"
        },
        {
          text: "Status",
          field: "status"
        }
      ],
      columns: [
        {
          text: "Name",
          field: "name",
          isSortable: true,
          defaultSort: true,
          clicked: row => {
            if (this.checkRequestDisabled(row)) {
              this.$router.push(`${SHOW}/${row.id}`);
            }
          }
        },
        {
          text: "Visibility",
          field: "visibility"
        }
      ],
      detailsColumns: [
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Owner",
          field: "organisationId"
        },
        {
          text: "Description",
          field: "description"
        },
        {
          text: "Visibility",
          field: "visibility"
        }
      ],
      childDetailsColumns: [
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Contribution",
          field: "contributionName"
        },
        {
          text: "Start",
          field: "contributionPlannedStart"
        },
        {
          text: "End",
          field: "contributionPlannedEnd"
        },
        {
          text: "Status",
          field: "scheduleStatus"
        }
      ]
    };
  }
};
</script>
