<template>
  <div class="mx-3 my-1">
    <h1 class="mt-9">Deliveries</h1>

    <div v-if="this.form === 'destinations'" class="destinations">
      <p>
        Contribution
        <b>{{ contribution ? contribution.name : "" }}</b> can be delivered
        directly to destinations. Select destinations below.
      </p>
      <Destinations
        :destination0="destination0"
        @selectDestination0="selectDestination0"
        :destination1="destination1"
        @selectDestination1="selectDestination1"
        :contribution="contribution"
        @submitSpecification="changeForm('generate')"
        @removeSelectedDestinations="removeSelectedDestinations"
        :allowMultiSelect="!!contribution.source1Id"
      />
    </div>

    <Generate
      v-if="this.form === 'generate'"
      :destinationId0="destination0 ? destination0.id : null"
      :destinationId1="destination1 ? destination1.id : null"
      :contribution="contribution"
      @generateSuccess="changeForm('complete')"
      @previous="changeForm('destinations')"
    />
    <Complete
      v-if="this.form === 'complete'"
      :destinationId0="destination0 ? destination0.id : null"
      :destinationId1="destination1 ? destination1.id : null"
      :contribution="contribution"
    />
  </div>
</template>
<script>
import Destinations from "@/components/Contributions/Delivery/Destinations.vue";
import Generate from "@/components/Contributions/Delivery/Generate.vue";
import Complete from "@/components/Contributions/Delivery/Complete.vue";
import { contributionApiClient } from "@/mixins/apiClients";

export default {
  mixins: [contributionApiClient],
  components: { Destinations, Generate, Complete },
  data() {
    return {
      form: "destinations",
      contributionId: this.$route.params.id,
      destination0: null,
      destination1: null,
      contribution: {},
      packageId: null
    };
  },
  created() {
    this.contributionApiClient
      .getById(this.contributionId)
      .then(contribution => {
        this.contribution = contribution;
        const plannedStart = new Date(contribution.plannedStart);
        const plannedEnd = new Date(contribution.plannedEnd);
        const durationInMilliseconds =
          plannedEnd.getTime() - plannedStart.getTime();
        const durationInHours = durationInMilliseconds / (1000 * 60 * 60);
        this.contribution.duration = durationInHours;
      });
  },
  methods: {
    changeForm(formName) {
      this.form = formName;
    },
    selectDestination0(item) {
      this.destination0 = item;
    },
    selectDestination1(item) {
      this.destination1 = item;
    },
    removeSelectedDestinations() {
      this.destination0 = null;
      this.destination1 = null;
    }
  }
};
</script>
<style scoped>
.destinations {
  max-width: 500px;
}
</style>
