<template>
  <div class="noTabs">
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_PACKAGES,
  ITEMS_PACKAGES
} from "@/constants.js";

const {
  REQUESTS_ALL,
  REQUESTS_REQUESTED,
  REQUESTS_APPROVED,
  REQUESTS_REJECTED,
  REQUESTS_RESCINDED
} = ALIAS_ITEMS_PACKAGES;
const { YOUR } = ITEMS_PACKAGES;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [
        { title: `All requests`, subPageName: REQUESTS_ALL },
        { title: `Requested`, subPageName: REQUESTS_REQUESTED },
        { title: `Approved`, subPageName: REQUESTS_APPROVED },
        { title: `Rejected`, subPageName: REQUESTS_REJECTED },
        { title: `Rescinded`, subPageName: REQUESTS_RESCINDED }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Requests`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Packages`, href: YOUR },
        { text: `Requests`, href: REQUESTS_ALL }
      ]
    };
  }
};
</script>
