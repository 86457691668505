var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__packages pt-1 pr-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',[_vm._v("My packages")]),_c('gl-button',{attrs:{"icon":"ellipsis_v","category":"tertiary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.contextMenuClick()}}})],1),_c('div',{staticClass:"mt-4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c('gl-loading-icon',{attrs:{"label":"Loading","size":"xl"}})],1),_c('ArqDataGrid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"data":_vm.data,"columns":_vm.columns,"itemsPerPage":_vm.itemsPerPage,"showHeader":false,"selectOnClick":true},on:{"dataNeeded":_vm.onDataNeeded,"rowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var r = ref.row;
var field = ref.field;
return [(field === 'name')?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dashboard__packages__status",class:{
                    'dashboard__packages__status--success': r.health === 'OK',
                    'dashboard__packages__status--warning':
                      r.health === 'WARN',
                    'dashboard__packages__status--danger': r.health === 'NOK'
                  }})]),_c('span',{staticClass:"cellTitle"},[_vm._v(_vm._s(r.name))])]),_c('b-badge',{staticClass:"dashboard__packages__badge",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(r.contributions_count))])],1)]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }