<template>
  <div class="mx-3 my-1">
    <h1 class="mt-9">Bulk Deliveries</h1>
    <p>
      This is the summary of your chosen bulk contributions list from the
      package, your specified destination, and pricing quote. Please confirm
      these are correct before proceeding to generate delivery.
    </p>
    <hr class="mb-6" />
    <div v-if="this.form === 'destinations'" class="destinations">
      <Destinations
        :destination0="destination0"
        @selectDestination0="selectDestination0"
        :destination1="destination1"
        @selectDestination1="selectDestination1"
        @submitSpecification="changeForm('generate')"
        @removeSelectedDestinations="removeSelectedDestinations"
        :allowMultiSelect="needsMultipleDestinations()"
      />
    </div>

    <Generate
      v-if="form === 'generate'"
      :destination0="destination0"
      :destination1="destination1"
      :packageId="packageId"
      @generateSuccess="complete"
      @previous="changeForm('destinations')"
    />
    <Complete v-if="form === 'complete'" :deliveryCount="deliveryCount" />
  </div>
</template>
<script>
import Destinations from "@/components/Contributions/Delivery/Destinations.vue";
import Generate from "@/components/Contributions/BulkDelivery/Generate.vue";
import Complete from "@/components/Contributions/BulkDelivery/Complete.vue";
import { contributionApiClient } from "@/mixins/apiClients";
import { mapGetters } from "vuex";
import userInfoMixin from "@/mixins/userInfo";

export default {
  mixins: [contributionApiClient, userInfoMixin],
  components: { Destinations, Generate, Complete },
  data() {
    return {
      form: "destinations",
      packageId: this.$route.params.id,
      destination0: null,
      destination1: null,
      deliveryCount: null
    };
  },
  methods: {
    needsMultipleDestinations() {
      if (!this.bulkContributions) return false;
      return !!this.bulkContributions.find(contri => contri.source1Id);
    },
    changeForm(formName) {
      this.form = formName;
    },
    complete(deliveryCount) {
      this.deliveryCount = deliveryCount;
      this.form = "complete";
    },
    selectDestination0(item) {
      this.destination0 = item;
    },
    selectDestination1(item) {
      this.destination1 = item;
    },
    removeSelectedDestinations() {
      this.destination0 = null;
      this.destination1 = null;
    }
  },
  computed: {
    ...mapGetters("packageBulkContributions", ["contributions"]),
    bulkContributions() {
      return this.contributions(this.packageId);
    }
  }
};
</script>
<style scoped>
.destinations {
  max-width: 500px;
}
</style>
