<template>
  <b-row>
    <b-col cols="6">
      <div class="page-indicator" v-if="pagingModelData.totalItems > 0">
        <p class="ml-1 pt-2">
          <span
            >{{ fromItemNum }} - {{ toItemNum }} of
            {{ pagingModelData.totalItems }} items
          </span>
        </p>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="text-right">
        <b-button-group>
          <b-button
            @click="firstPage"
            :disabled="
              pagingModelData.totalPages === 0 ||
                pagingModelData.currentPage === 0
            "
            title="Type ↑ for first page."
            ><gl-icon name="chevron-double-lg-left"
          /></b-button>
          <b-button
            @click="previousPage"
            :disabled="
              pagingModelData.totalPages === 0 ||
                pagingModelData.currentPage === 0
            "
            title="Type ← for previous page."
            ><gl-icon name="chevron-left"
          /></b-button>
          <b-button
            @click="nextPage"
            :disabled="
              pagingModelData.totalPages === 0 ||
                pagingModelData.currentPage === pagingModelData.totalPages - 1
            "
            title="Type → for next page."
            ><gl-icon name="chevron-right"
          /></b-button>
          <b-button
            @click="lastPage"
            :disabled="
              pagingModelData.totalPages === 0 ||
                pagingModelData.currentPage === pagingModelData.totalPages - 1
            "
            title="Type ↓ for last page."
            ><gl-icon name="chevron-double-lg-right"
          /></b-button>
        </b-button-group>
      </div> </b-col
  ></b-row>
</template>
<script>
import { BCol, BRow, BButtonGroup, BButton } from "bootstrap-vue";
import { GlIcon } from "@gitlab/ui";
export default {
  name: "ArqDataGridPaginator",
  components: {
    BCol,
    BRow,
    BButtonGroup,
    BButton,
    GlIcon
  },
  props: {
    pagingModel: {
      type: Object,
      default: () => {
        return {
          totalPages: 0,
          currentPage: 0
        };
      }
    }
  },
  data() {
    return {
      pagingModelData: this.pagingModel
    };
  },
  computed: {
    fromItemNum() {
      return (
        this.pagingModelData.currentPage * this.pagingModelData.itemsPerPage + 1
      );
    },

    toItemNum() {
      if (
        this.pagingModelData.currentPage ===
        this.pagingModelData.totalPages - 1
      ) {
        return this.pagingModelData.totalItems;
      } else {
        return (
          (this.pagingModelData.currentPage + 1) *
          this.pagingModelData.itemsPerPage
        );
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  methods: {
    handleKey(evt) {
      if (evt.target.tagName === "INPUT" || evt.target.tagName === "TEXTAREA") {
        return;
      }
      if (evt.code === "ArrowLeft") {
        this.previousPage();
      }
      if (evt.code === "ArrowRight") {
        this.nextPage();
      }
      if (evt.code === "ArrowUp") {
        this.firstPage();
      }
      if (evt.code === "ArrowDown") {
        this.lastPage();
      }
    },
    firstPage() {
      this.pagingModelData.currentPage = 0;
      this.$emit("firstPage", this.pagingModelData);
    },
    nextPage() {
      if (
        this.pagingModelData.currentPage <
        this.pagingModelData.totalPages - 1
      ) {
        this.pagingModelData.currentPage++;
      }
      this.$emit("nextPage", this.pagingModelData);
    },
    previousPage() {
      if (this.pagingModelData.currentPage > 0) {
        this.pagingModelData.currentPage--;
      }
      this.$emit("previousPage", this.pagingModelData);
    },
    lastPage() {
      this.pagingModelData.currentPage = this.pagingModelData.totalPages - 1;
      this.$emit("lastPage", this.pagingModelData);
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/datagrid.scss";
</style>
