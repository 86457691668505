import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";
import axios from "axios";

export default {
  mixins: [msgUtils],
  methods: {
    async getOrganisation(id) {
      try {
        return await this.organizationApiClient.getById(id);
      } catch (error) {
        console.error("Error getting organisation from backend:", error);
        throw error;
      }
    },
    getLogo(id) {
      return this.organizationApiClient.getById(id, "logo");
    },

    async getSignedUrlFromBackend(id) {
      try {
        return await this.organizationApiClient.getCustomPath(
          id,
          "logo/upload",
          {}
        );
      } catch (error) {
        console.error("Error getting signed URL from backend:", error);
        throw error;
      }
    },
    async uploadLogo(id, data) {
      try {
        this.getSignedUrlFromBackend(id).then(async r => {
          const formData = new FormData();
          for (let field in r.fields) {
            formData.append(field, r.fields[field]);
          }
          formData.append("file", data);

          axios
            .post(r.url, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(() => {
              console.log("File uploaded successfully");
            })
            .catch(error => {
              this.handleApiError(error);
            });
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    async deleteLogo(id) {
      try {
        return await this.organizationApiClient.remove(id, "logo");
      } catch (error) {
        console.error("Error deleting logo:", error);
        throw error;
      }
    }
  },
  data() {
    return {
      organizationApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.ENTITLEMENTS,
        "organisations"
      )
    };
  }
};
