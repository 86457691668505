<template>
  <div>
    <gl-form-group
      class="m-4"
      label="Region"
      label-size="sm"
      label-for="region"
      :invalid-feedback="isInvalidRegionFeedback()"
    >
      <gl-form-select
        id="region"
        v-model="currentItem.region"
        :options="regionOptions"
        :required="isRequired()"
        class="mt-1"
        @change="getAvailabilityZonesForRegion"
        :state="isValidRegionAndZone()"
      />
      <gl-form-group
        class="mt-2 ml-4"
        :label="availabilityZoneLabel()"
        label-size="sm"
        label-for="availabilityZone"
        v-if="currentItem.region"
      >
        <gl-form-select
          id="availabilityZone"
          v-model="currentItem.availabilityZone"
          :options="availabilityZoneOptions"
          :required="currentItem.isPrecreated"
          placeholder="Availability zone"
          class="mt-1"
          @change="isValidRegionAndZone()"
        />
      </gl-form-group>
    </gl-form-group>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import AppConfig from "@/app-config";
import { GlFormGroup, GlFormSelect } from "@gitlab/ui";
import Destination from "@/models/Destination";

export default {
  components: { GlFormGroup, GlFormSelect },
  created() {
    this.getRegionOptions();
    if (this.currentItem.region) {
      this.getAvailabilityZonesForRegion(this.currentItem.region);
    }
  },
  methods: {
    getRegionOptions() {
      for (const region in AppConfig.instance.config.options.regions) {
        this.regionOptions[
          region
        ] = `${region} (${AppConfig.instance.config.options.regions[region]})`;
      }
    },
    getAvailabilityZonesForRegion(region) {
      this.availabilityZoneOptions = [];
      this.currentItem.availabilityZone = null;
      if (region === null) {
        return;
      }
      const zones = {};
      const allZones = AppConfig.instance.config.options.availabilityZones;
      for (const zone in allZones) {
        if (zone.includes(region)) {
          zones[zone] = `${zone} (${allZones[zone]})`;
        }
      }

      this.availabilityZoneOptions = [
        { value: null, text: "Auto Specify" },
        ...Object.entries(zones).map(([key, value]) => {
          return { value: key, text: value };
        })
      ];
      if (this.currentItem.isPrecreated) {
        this.availabilityZoneOptions.shift();
        this.currentItem.availabilityZone = this.availabilityZoneOptions[0].value;
      }
    },
    availabilityZoneLabel() {
      const label = "Availability zone";
      if (
        this.originalAvailabilityZone &&
        this.currentItem.region !== this.originalAvailabilityZone
      ) {
        return `${label} currently set to: ${this.originalAvailabilityZone}`;
      }
      return label;
    },
    isRequired() {
      return (
        this.currentItem.isPrecreated ||
        this.currentItem.destinationType === DESTINATION_TYPES.MEDIACONNECT ||
        this.currentItem.destinationType === DESTINATION_TYPES.S3_ARCHIVE
      );
    },
    isValidRegionAndZone() {
      let isValid = false;
      if (this.currentItem.isPrecreated && !this.currentItem.availabilityZone) {
        isValid = false;
      } else {
        isValid = !!this.currentItem.region;
        if (!this.isRequired()) {
          isValid = true;
        }
      }
      this.$emit("change", isValid);
      return isValid;
    },
    isInvalidRegionFeedback() {
      return this.isValidRegionAndZone() ? "" : "Please select a valid region";
    }
  },
  data() {
    return {
      availabilityZoneOptions: [],
      originalAvailabilityZone: null, // TODO: this should come from the props as storedItem:
      regionOptions: {
        0: { value: null, text: "No region specified" }
      }
    };
  },
  props: {
    currentItem: Destination // 2-way binding to the parent, name can be different
  }
};
</script>
