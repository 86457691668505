import Vue from "vue";
import Vuex from "vuex";
import topMenu from "@/store/topMenu";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/auth";
import packageBulkContributions from "@/store/packageBulkContributions";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: `red`
  },
  mutations: {
    changeTheme(state, theme) {
      state.theme = theme;
    }
  },
  modules: {
    auth,
    topMenu,
    packageBulkContributions
  },
  plugins: [createPersistedState()]
});
