<template>
  <div>
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { MAIN_ITEMS, ITEMS_PARTNER_GRANTS } from "@/constants.js";

const STORE = `source`;
const { ALL, GRANTED, NOT_GRANTED } = ITEMS_PARTNER_GRANTS;

export default {
  components: { MainSection },
  created() {
    this.store = STORE;
  },
  computed: {
    tabsItems() {
      return [
        { title: `All partners`, subPageName: ALL },
        { title: `Granted`, subPageName: GRANTED },
        { title: `Not granted`, subPageName: NOT_GRANTED }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Partner Grants`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Patner Grants`, href: ALL }
      ]
    };
  }
};
</script>
