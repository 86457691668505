<template>
  <div class="dashboard__packages pt-1 pr-1">
    <div class="d-flex justify-content-between">
      <h4>My packages</h4>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4">
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        @rowSelected="onRowSelected"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <div
                    class="dashboard__packages__status"
                    :class="{
                      'dashboard__packages__status--success': r.health === 'OK',
                      'dashboard__packages__status--warning':
                        r.health === 'WARN',
                      'dashboard__packages__status--danger': r.health === 'NOK'
                    }"
                  ></div>
                </div>
                <span class="cellTitle">{{ r.name }}</span>
              </div>
              <b-badge variant="secondary" class="dashboard__packages__badge">{{
                r.contributions_count
              }}</b-badge>
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlButton } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";

export default {
  name: "Packages",
  mixins: [metricsApiClient, userInfoMixin],
  components: { ArqDataGrid, GlLoadingIcon, GlButton },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    contextMenuClick() {},
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    async onDataNeeded(_, pageModel) {
      this.isLoading = true;
      this.pageModel = pageModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;

      let query = (this.isArqadeAdminRoleActive
        ? this.getMetricsHealth("packages")
        : this.getMetricsHealth("packages/own")
      )
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
    }
  },
  data() {
    return {
      pageModel: {
        itemsPerPage: this.itemsPerPage
      },
      selectedRow: undefined,
      data: {},
      isLoading: false,
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style scoped>
h4 {
  color: var(--theme-color);
}
.dashboard__packages {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard__packages__status {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 15px;
}
.dashboard__packages__status--success {
  background-color: #91d4a8;
}
.dashboard__packages__status--warning {
  background-color: #f5d9a8;
}
.dashboard__packages__status--danger {
  background-color: #f56c7e;
}
.dashboard__packages__badge {
  font-size: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
</style>
