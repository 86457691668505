<template>
  <div class="dashboard__destinations pt-1 pr-1">
    <div class="d-flex justify-content-between">
      <h4>My destinations</h4>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4">
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="selectedDelivery && !isLoading"
        :data="data"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="2"
        :selectable="false"
        :showHeader="false"
        :showPager="false"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'" class="destinationSingle">
            <div class="d-flex align-items-center mb-2">
              <p class="mb-0 destinationSingle__name">
                {{ r.name }}
              </p>
            </div>
            <div>
              <b-badge :variant="getBadgeVariant(r)"
                ><gl-icon :name="getBadgeIcon(r)" />{{
                  getBadgeText(r)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlButton } from "@gitlab/ui";

export default {
  name: "Destinations",
  mixins: [metricsApiClient],
  components: { ArqDataGrid, GlLoadingIcon, GlButton },
  props: {
    itemsPerPage: {
      type: Number,
      default: 2
    },
    selectedDelivery: {
      type: Object
    }
  },
  watch: {
    selectedDelivery: function() {
      this.onDataNeeded();
    }
  },
  methods: {
    contextMenuClick() {},
    getBadgeVariant(row) {
      switch (row.health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(row) {
      switch (row.health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    getBadgeText(row) {
      switch (row.health) {
        case "OK":
          return "Connected";
        case "WARN":
          return "Warning";
        case "NOK":
          return "Error";
        default:
          return "Unknown";
      }
    },
    async onDataNeeded() {
      if (!this.selectedDelivery) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      this.isLoading = true;
      const query = this.getMetricsHealth(
        `deliveries/${this.selectedDelivery.id}/destinations`
      ).list();
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d.items = [response.destination0];
        if (response.destination1) {
          d.items.push(response.destination1);
        }
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
    }
  },
  data() {
    return {
      data: {},
      isLoading: false,
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style scoped>
h4 {
  color: var(--theme-color);
}
.dashboard__destinations {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.destinationSingle .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.destinationSingle .badge .gl-icon {
  margin-right: 5px;
}
.destinationSingle .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.destinationSingle .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.destinationSingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.destinationSingle__name {
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
