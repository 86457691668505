<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3 mb-3">{{ pageTitle }}</h1>
    <add-user :currentItem="currentItem" @create="userCreated"></add-user>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import AddUser from "@/components/UserManagement/AddUser.vue";
import * as eq from "deep-equal";
import { userMgmtApiClient } from "@/mixins/apiClients";
import userInfoMixin from "@/mixins/userInfo";
const { EDIT_USER } = ITEMS_USER_MGMT;
const { USERS_ALL, EDIT_USER_FIRST } = ALIAS_ITEMS_USER_MGMT;

export default {
  components: { Breadcrumb, AddUser },
  mixins: [wizardGuard, userMgmtApiClient, userInfoMixin],
  methods: {
    userCreated() {
      this.created = true;
      this.$router.push(USERS_ALL);
    }
  },
  created() {
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.userMgmtApiClient
        .getById(id)
        .then(user => {
          this.currentItem = user;
          this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
        })
        .catch(e => {
          this.handleApiError(e);
          this.$router.push(USERS_ALL);
        });
    } else {
      this.$router.push(USERS_ALL);
    }
  },
  watch: {
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.shortName && this.currentItem.legalName;
      },
      deep: true
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Users`, href: USERS_ALL }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `Details `,
          subPageName: `${EDIT_USER}/${this.currentItem.id}/${EDIT_USER_FIRST}`
        }
      ];
    }
  },
  data() {
    return {
      created: false,
      selectedTab: null,
      currentItem: {},
      originalItem: undefined,
      pageTitle: `Users`,
      isDescFulfilled: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  }
};
</script>
