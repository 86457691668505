<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button variant="confirm" class="mr-3" :href="toCreate"
          >Create organization</gl-button
        >
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot="{ row: r, field }">
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div v-if="field === 'shortName'">
                <div class="org-box">
                  <b-img
                    class="mr-3"
                    v-bind:src="'data:image/jpeg;base64,' + logos[r.id]"
                    width="50"
                    @error="fixLogo(r.id)"
                  >
                  </b-img>
                  <div class="org-info mt-2">
                    <span class="cellTitle">{{ r.shortName }}</span>
                    <br />
                    <span>{{ r.legalName }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  r.registrationType ===
                    ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
                "
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <img
                  class="responsive-image"
                  src="../../assets/AWS Marketplace.png"
                  v-b-tooltip.hover
                  title="AWS Marketplace Organisation"
                />
                <gl-button-group>
                  <gl-button class="gl-font-monospace" disabled>
                    {{ r.awsCustomerId }}
                  </gl-button>
                  <gl-button
                    icon="duplicate"
                    id="copy-to-clipboard"
                    aria-describedby="copy-to-clipboard"
                    aria-label="Copy AWS Customer ID"
                    @click.prevent.stop="copyToClipboard(r.awsCustomerId)"
                  />
                </gl-button-group>
              </div>
            </div>
          </template>
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import {
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT,
  ORGANIZATION_REGISTRATION_TYPE,
  orgDefaultLogoData
} from "@/constants.js";
import { organizationApiClient } from "@/mixins/apiClients";
import dateUtils from "@/mixins/dateUtils";
import debouncedInput from "@/mixins/debouncedInput.js";
import msgUtils from "@/mixins/msgUtils";
import {
  GlButton,
  GlButtonGroup,
  GlForm,
  GlSearchBoxByType,
  GlToast
} from "@gitlab/ui";
const { ADD_ORGANIZATION_FIRST } = ALIAS_ITEMS_USER_MGMT;
const { EDIT_ORGANIZATION } = ITEMS_USER_MGMT;
export default {
  name: "YourAll",
  mixins: [dateUtils, debouncedInput, organizationApiClient, msgUtils, GlToast],
  components: {
    ArqDataGrid,
    GlButton,
    GlButtonGroup,
    ArqDataGridDetailsPane,
    GlForm,
    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  methods: {
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.showSuccess("Copied to clipboard");
      } catch (err) {
        this.$toast.showError("Failed to copy text: ", err);
      }
    },
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    fixLogo(id) {
      this.logos[id] = orgDefaultLogoData;
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT_ORGANIZATION}/${row.id}`);
          break;
        case `users`:
          this.$router.push(`${ALIAS_ITEMS_USER_MGMT.USERS_ALL}?org=${row.id}`);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.organizationApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }
      query.filterBy(phrase);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        const logoPromises = [];
        for (const org of response.items) {
          this.logos[org.id] = orgDefaultLogoData;
          logoPromises.push(
            this.getLogo(org.id).then(l => {
              return {
                id: org.id,
                logo: l
              };
            })
          );
        }
        Promise.allSettled(logoPromises).then(res => {
          res.forEach(r => {
            if (r.value) {
              const logo = atob(r.value.logo.body);
              this.logos[r.value.id] = logo;
            }
          });
          this.logos = JSON.parse(JSON.stringify(this.logos));
          this.$forceUpdate();
        });
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  computed: {
    logoImages() {
      return this.logos;
    },
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = [];
          out[row.id].push(this.contextMenuItems[0]);
          out[row.id].push(this.contextMenuItems[1]);
        }
      }
      return out;
    },

    bulkContextMenuItems() {
      const bulkContextMenu = [];
      return bulkContextMenu;
    }
  },
  data() {
    return {
      ORGANIZATION_REGISTRATION_TYPE: ORGANIZATION_REGISTRATION_TYPE,
      logos: {},
      sortModel: undefined,
      pageModel: undefined,
      toCreate: `#${ADD_ORGANIZATION_FIRST}`,
      searchPhrase: "",
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        },
        {
          id: "users",
          text: "Manage users"
        }
      ],
      columns: [
        {
          text: "Name",
          field: "shortName",
          isSortable: true,
          clicked: row => {
            this.$router.push(`${EDIT_ORGANIZATION}/${row.id}`);
          }
        }
      ],
      detailsColumns: [
        {
          text: "Short name",
          field: "shortName"
        },
        {
          text: "Legal name",
          field: "legalName"
        }
      ]
    };
  }
};
</script>
<style>
.org-box {
  display: flex;
}
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.responsive-image {
  max-width: 105px;
  object-fit: contain;
}
</style>
