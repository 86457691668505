import Your from "@/views/Destinations/Your.vue";
import Add from "@/views/Destinations/Add.vue";
import Show from "@/views/Destinations/Show.vue";
import { YourDestinations, Archived } from "@/components/Destinations";

import { ITEMS_DESTINATIONS, ALIAS_ITEMS_DESTINATIONS } from "@/constants.js";

const { ADD, EDIT, SHOW } = ITEMS_DESTINATIONS;

const {
  YOUR,
  ARCHIVED,
  ADD_FIRST,
  ADD_SECOND,
  EDIT_FIRST,
  EDIT_SECOND
} = ALIAS_ITEMS_DESTINATIONS;

const HOST = `Destinations`;

const destinations = [
  {
    path: ITEMS_DESTINATIONS.YOUR,
    name: `${HOST}Your`,
    redirect: YOUR,
    component: Your,
    children: [
      {
        path: YOUR,
        component: YourDestinations
      },
      {
        path: ARCHIVED,
        component: Archived
      }
    ]
  },
  {
    path: ADD,
    name: `${HOST}CreateADD`,
    redirect: ADD_FIRST
  },
  {
    path: ADD_FIRST,
    name: `${HOST}Create`,
    component: Add,
    alias: [ADD_SECOND]
  },

  {
    path: `${EDIT}/:id`,
    name: `${HOST}Edit`,
    component: Add,
    alias: [`${EDIT}/:id/${EDIT_FIRST}`, `${EDIT}/:id/${EDIT_SECOND}`]
  },

  {
    path: `${SHOW}/:id`,
    name: `${HOST}Show`,
    component: Show
    // alias: [`${EDIT}/:id/${EDIT_FIRST}`, `${EDIT}/:id/${EDIT_SECOND}`]
  }
];

export default destinations;
