<template>
  <gl-form
    class="registration-form"
    @submit.prevent="submit"
    @reset.prevent="reset"
  >
    <b-form-group
      id="userGivenName"
      label="First Name"
      label-for="userGivenName"
      label-size="md"
    >
      <b-form-input
        id="userGivenName"
        size="md"
        v-model="userGivenName"
        placeholder="First Name"
        type="text"
        required
      />
    </b-form-group>
    <b-form-group
      id="userFamilyName"
      label="Last Name"
      label-for="userFamilyName"
      label-size="md"
    >
      <b-form-input
        id="userFamilyName"
        size="md"
        v-model="userFamilyName"
        placeholder="Last Name"
        type="text"
        required
      />
    </b-form-group>
    <b-form-group id="email" label="Email" label-for="email" label-size="md">
      <b-form-input
        id="email"
        size="md"
        v-model="email"
        placeholder="Email"
        type="email"
        required
      />
    </b-form-group>
    <b-form-group
      id="phoneNumber"
      label="Phone Number"
      label-for="phoneNumber"
      label-size="md"
    >
      <b-form-input
        id="phoneNumber"
        size="md"
        v-model="phoneNumber"
        placeholder="Phone Number"
        type="tel"
        required
      />
    </b-form-group>
    <b-form-group
      id="website"
      label="Website"
      label-for="website"
      label-size="md"
    >
      <b-form-input
        id="website"
        size="md"
        v-model="website"
        placeholder="Website"
        type="url"
      />
    </b-form-group>
    <b-form-group
      id="orgName"
      label="Organisation"
      label-for="orgName"
      label-size="md"
    >
      <b-form-input
        id="orgName"
        size="md"
        v-model="orgName"
        placeholder="Organisation"
        type="text"
      />
    </b-form-group>
    <div class="d-flex flex-row justify-content-around">
      <b-button size="md" type="submit" variant="danger">Submit</b-button>
      <b-button size="md" type="reset" variant="outline-danger">Reset</b-button>
    </div>
  </gl-form>
</template>
<script>
import { GlForm } from "@gitlab/ui";

export default {
  name: "RegistrationForm",
  components: {
    GlForm
  },
  data() {
    return {
      userGivenName: "",
      userFamilyName: "",
      email: "",
      phoneNumber: "",
      website: "",
      orgName: ""
    };
  },
  methods: {
    submit() {
      this.$emit("submit", {
        userGivenName: this.userGivenName,
        userFamilyName: this.userFamilyName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        website: this.website,
        orgName: this.orgName
      });
      this.reset();
    },
    reset() {
      this.userGivenName = "";
      this.userFamilyName = "";
      this.email = "";
      this.phoneNumber = "";
      this.website = "";
      this.orgName = "";
    }
  }
};
</script>

<style scoped>
.registration-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
</style>
