<template>
  <gl-form @submit.prevent="submitGenerateForm">
    <Wizard
      leftSectionTitle="Summary"
      :buttons="buttons"
      rightSectionTitle="Pricing"
    >
      <template v-slot:left-section>
        <p>
          This is the combined summary of your destination and your chosen
          contribution from the package. Please confirm that these are correct
          before proceeding to generate delivery. <br />
          <strong v-if="contribution.isOpenEnded"
            >Warning: This contribution is open ended. The delivery will need to
            be cancelled manually when the contribution is no longer
            required.</strong
          >
        </p>
        <gl-table :items="tableSummaryItems" :fields="tableSummaryFields">
        </gl-table>
      </template>
      <template v-slot:right-section>
        <p>
          <b
            >The pricing shown below includes totals from sources and
            contributions that have been previously validated</b
          >
        </p>
        <BillableStatus
          :billableStatus="billableStatus"
          @update-billing-status="updateBillableStatus"
        />
        <div v-if="isWaitingForPrice">
          <gl-loading-icon label="Loading" />
        </div>
        <div v-else>
          <p class="mb-0">
            <span v-if="isTranscode"
              ><strong>This delivery will trigger a transcode</strong> because
              <span
                v-if="
                  destination0 &&
                    destination0.destinationType === DESTINATION_TYPES.RTMP
                "
                >the destination is a RTMP destination</span
              >
              <span
                v-else-if="
                  destination0 &&
                    destination0.destinationType === DESTINATION_TYPES.HLS
                "
                >the destination is a HLS destination</span
              >
              <span v-else>the source and destination profiles differ</span>
            </span>
            <span v-else>This delivery does not require a transcode</span>
          </p>
          <gl-table :items="tablePricingItems" :fields="tablePricingFields">
          </gl-table>
          <p
            v-if="contribution.contributionType == 'channel_contribution'"
            class="mx-2"
          >
            <small
              >The price below shall be billed monthly, starting from the
              delivery start date, for as long as the delivery is in effect.
              Note that no discount is applied for part-used months.</small
            >
          </p>
        </div>
        <gl-form-group
          class="m-4 form-group-confirmed"
          label=""
          label-size="sm"
          label-for="confirmed"
        >
          <gl-form-checkbox
            id="confirmed"
            v-model="confirmed"
            required
            :disabled="isWaitingForPrice"
            ><strong
              >I confirm all the details are correct and the pricing shown above
              will be incurred as an Order per the agreement.</strong
            ></gl-form-checkbox
          >
        </gl-form-group>
      </template>
    </Wizard>
  </gl-form>
</template>

<script>
import {
  GlLoadingIcon,
  GlForm,
  GlFormCheckbox,
  GlFormGroup,
  GlTable
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import {
  destinationApiClient,
  pricingQuoteApiClient
} from "@/mixins/apiClients";
import userInfoMixin from "@/mixins/userInfo";
import pricingUtils from "@/mixins/pricingUtils";
import { DESTINATION_TYPES } from "@/constants.js";
import AppConfig from "@/app-config";
import { deliveryApiClient } from "@/mixins/apiClients";
import BillableStatus from "@/components/Billing/BillableStatus.vue";

export default {
  mixins: [
    destinationApiClient,
    pricingQuoteApiClient,
    userInfoMixin,
    pricingUtils,
    deliveryApiClient
  ],
  components: {
    Wizard,
    GlForm,
    GlLoadingIcon,
    GlTable,
    GlFormCheckbox,
    GlFormGroup,
    BillableStatus
  },
  data() {
    return {
      isBusy: false,
      isWaitingForPrice: true,
      billableStatus: "DEFAULT",
      pricing: {
        price: 0,
        unit: "",
        setupPrice: 0,
        hourlyPrice: 0,
        setupDiscount: 0,
        hourlyDiscount: 0,
        dataDeliveryPrice: 0,
        dataDeliveryDiscount: 0
      },
      confirmed: false,
      destination0: undefined,
      destination1: undefined,
      isTranscode: undefined,
      tableSummaryFields: [
        {
          key: "column_one",
          label: "",
          tdClass: "font-weight-bold"
        },
        {
          key: "column_two",
          label: "",
          tdClass: "text-right"
        }
      ],
      tablePricingFields: [
        {
          key: "column_one",
          label: "",
          tdClass: "font-weight-bold"
        },
        {
          key: "column_two",
          label: "",
          tdClass: "text-right"
        }
      ],
      DESTINATION_TYPES
    };
  },
  props: {
    destinationId0: {
      type: String,
      default: null
    },
    destinationId1: {
      type: String,
      default: null
    },
    contribution: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: "Previous",
          onClick: () => this.$emit("previous"),
          type: "button"
        },
        {
          text: "Generate Delivery",
          type: "submit",
          disabled: this.isBusy || !this.confirmed
        }
      ];
    },
    adminOnly() {
      return (
        (this.isArqadeAdminRoleActive || this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    },
    tableSummaryItems() {
      let items = [
        {
          column_one: "Name",
          column_two: this.contribution.name
        },
        {
          column_one: "Destination 1",
          column_two: this.destination0 ? this.destination0.name : ""
        }
      ];
      if (this.destination1) {
        items.push({
          column_one: "Destination 2",
          column_two: this.destination1 ? this.destination1.name : ""
        });
      }
      return items;
    }
  },
  methods: {
    async tablePricingItems() {
      let items = [];
      let pricing = this.pricing;

      const numberOfHoursInMonth = 730.5;
      if (this.contribution.contributionType === "channel_contribution") {
        if (pricing.dataDeliveryPrice) {
          items.push({
            column_one: `Data Delivery ${
              parseFloat(pricing.dataDeliveryDiscount)
                ? `(discount applied)`
                : ``
            }`,
            column_two: await this.formatPricingDisplayDelivery(
              pricing.dataDeliveryPrice,
              "USD/month",
              pricing.dataDeliveryPrice * numberOfHoursInMonth
            )
          });
        }
        if (pricing.transcodePrice) {
          items.push({
            column_one: `Transcode ${
              parseFloat(pricing.transcodeDiscount) ? `(discount applied)` : ``
            }`,
            column_two: await this.formatPricingDisplayDelivery(
              pricing.transcodePrice,
              "USD/month",
              pricing.transcodePrice * numberOfHoursInMonth
            )
          });
        }
        if (items.length === 0 || pricing.price) {
          const cost = parseFloat(pricing.price)
            ? parseFloat(pricing.price)
            : 0;

          items.push({
            column_one: "Total",
            column_two: await this.formatPricingDisplayDelivery(
              cost,
              "USD/month",
              cost * numberOfHoursInMonth
            )
          });
        }
      }
      if (this.contribution.contributionType === "event_contribution") {
        if (parseFloat(pricing.setupPrice)) {
          items.push({
            column_one: `Setup price ${
              parseFloat(pricing.setupDiscount) ? `(discount applied)` : ``
            }`,
            column_two: await this.formatPricingDisplayDelivery(
              pricing.setupPrice,
              "USD"
            )
          });
        }
        if (parseFloat(pricing.hourlyPrice)) {
          items.push({
            column_one: `Hourly price  ${
              parseFloat(pricing.hourlyDiscount) ? `(discount applied)` : ``
            }`,
            column_two: await this.formatPricingDisplayDelivery(
              pricing.hourlyPrice,
              pricing.unit
            )
          });
        }
        if (items.length === 0 || pricing.price) {
          items.push({
            column_one: "Total",
            column_two: await this.formatPricingDisplayDelivery(
              pricing.hourlyPrice,
              "USD",
              pricing.price,
              pricing.setupPrice
            )
          });
        }
      }
      this.pricing = pricing;
      return items;
    },
    async submitGenerateForm() {
      if (this.destinationId0 === null) return;
      this.isBusy = true;
      try {
        const delivery = this.getDelivery();
        await this.deliveryApiClient.create(delivery).then(result => {
          if (result.source0Encrypted) {
            this.handleApiSuccess(
              "The primary source is encrypted, please contact the originator"
            );
          }
          if (result.source1Encrypted) {
            this.handleApiSuccess(
              "The secondary source is encrypted, please contact the originator"
            );
          }
        });
        this.handleApiSuccess("The delivery was created successfully!");
        this.isBusy = false;
        this.$emit("generateSuccess");
      } catch (e) {
        this.handleApiError(e);
        this.isBusy = false;
      }
    },
    getDelivery() {
      const packageId = this.$route.query.packageId;
      let delivery = {
        contributionId: this.contribution.id,
        packageId: packageId ? packageId : null,
        billableStatus: this.billableStatus
      };
      if (this.destinationId0) {
        delivery.destinationId0 = this.destinationId0;
      }
      if (this.destinationId1) {
        delivery.destinationId1 = this.destinationId1;
      }
      delivery.quote = this.contribution.quote;
      return delivery;
    },
    updateBillableStatus(newValue) {
      this.billableStatus = newValue;
    }
  },
  created() {
    if (this.destinationId0) {
      this.destinationApiClient
        .getById(this.destinationId0)
        .then(destination => {
          this.destination0 = destination;
        })
        .catch(e => this.handleApiError(e));
    }
    if (this.destinationId1) {
      this.destinationApiClient
        .getById(this.destinationId1)
        .then(destination => {
          this.destination1 = destination;
        })
        .catch(e => this.handleApiError(e));
    }

    let url = `delivery/?contribution_id=${this.contribution.id}&destination_id=${this.destinationId0}`;
    if (this.destinationId1) {
      url += `&destination2_id=${this.destinationId1}`;
    }
    this.pricingQuoteApiClient
      .update(url)
      .then(pricing => {
        this.pricing = pricing.offer;
        this.isWaitingForPrice = false;
        this.contribution.quote = pricing;
        this.isTranscode = !!pricing.metadata.is_transcode;
      })
      .catch(e => {
        this.handleApiError(e);
      });
  }
};
</script>
