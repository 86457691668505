<template>
  <div>
    <sidebar-menu
      v-for="topOption in topMenuOptions"
      :key="topOption"
      v-show="currentTopMenuOption === topOption"
      :menu="menu[topOption]"
      width="210px"
      :showOneChild="true"
      class="relative-sidebar"
      theme="white-theme"
      @toggle-collapse="onToggleCollapse"
      :collapsed="collapsed"
    >
    </sidebar-menu>
  </div>
</template>

<script>
import AppConfig from "@/app-config";
import SidebarMenu from "@/components/SideBarMenu/SidebarMenu.vue";
import { SIDE_BAR_OPTIONS, TOP_MENU_OPTIONS } from "@/constants.js";
import { mapState } from "vuex";
const STORE = `topMenu`;
export default {
  name: "SideBar",
  components: {
    SidebarMenu
  },
  data() {
    return {
      menuCollapsed: undefined
    };
  },
  computed: {
    ...mapState({
      currentTopMenuOption: state => state[STORE].currentOption
    }),
    topMenuOptions() {
      return Object.values(TOP_MENU_OPTIONS);
    },
    menu() {
      let allOptions = { ...SIDE_BAR_OPTIONS };
      let connectOptions = allOptions.Connect;
      let newConnectOptions = connectOptions.filter(
        option => option.title !== "Search"
      );
      allOptions.Connect = newConnectOptions;
      return AppConfig.instance.config.options.features.displayAdvancedSearch
        ? SIDE_BAR_OPTIONS
        : allOptions;
    },
    collapsed() {
      return this.menuCollapsed !== undefined
        ? this.menuCollapsed
        : window && window.localStorage
        ? window.localStorage.getItem("menuCollapsed") === "true"
        : false;
    }
  },
  methods: {
    onToggleCollapse(collapsed) {
      if (window && window.localStorage) {
        window.localStorage.setItem("menuCollapsed", collapsed);
      }
      this.menuCollapsed = collapsed;
      this.$emit("toggleCollapse", collapsed);
    }
  }
};
</script>
<style lang="scss">
.relative-sidebar {
  margin-top: 36px;
}
.v-sidebar-menu .vsm--link {
  font-size: 14px !important;
}
</style>
