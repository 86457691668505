<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <IpProfile
      prefix="zixi_"
      @change="onIpProfileChange"
      :portValue="currentItem.port"
      :ipTypeValue="currentItem.ipDestinationType"
      :ipAddressValue="currentItem.ipAddress"
      :showIpAddress="true"
      :showIpType="false"
    />
    <MaxLatencyComponent
      :currentItem="currentItem"
      @change="onMaxLatencyChange"
    ></MaxLatencyComponent>
    <StreamIdComponent
      :currentItem="currentItem"
      @change="onStreamIdChange"
    ></StreamIdComponent>
    <Crypto
      @change="onCryptoChange"
      :cryptoKey="currentItem.cryptoKey"
      :cryptoAlgo="currentItem.cryptoAlgo"
      :originalCryptoAlgo="originalItem ? originalItem.cryptoAlgo : null"
    />
  </div>
</template>

<script>
import Crypto from "@/components/Crypto.vue";
import { DESTINATION_TYPES } from "@/constants";
import IpProfile from "@/components/IpProfile.vue";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import MaxLatencyComponent from "@/components/Destinations/AddSecond/Specs/Sections/MaxLatencyComponent.vue";
import StreamIdComponent from "@/components/Destinations/AddSecond/Specs/Sections/StreamIdComponent.vue";

export default {
  components: {
    MaxLatencyComponent,
    Crypto,
    IpProfile,
    RegionComponent,
    StreamIdComponent
  },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    isDestinationValid() {
      switch (false) {
        case this.isCryptoValid:
        case this.isIpProfileValid:
        case this.isMaxLatencyValid:
        case this.isRegionValid:
        case this.isStreamIdValid:
          return false;
        default:
          return true;
      }
    },
    onCryptoChange(cryptoDetails) {
      const { cryptoAlgo, cryptoKey, isValid } = cryptoDetails;
      this.isCryptoValid = isValid;
      this.currentItem.cryptoAlgo = cryptoAlgo;
      this.currentItem.cryptoKey = cryptoKey;
      this.$emit("change", this.isDestinationValid()); // emit the event to the parent
    },
    onIpProfileChange(ipDetails) {
      const { port, address, isValid } = ipDetails;
      this.isIpProfileValid = isValid;
      this.currentItem.port = port;
      this.currentItem.ipAddress = address;
      this.$emit("change", this.isDestinationValid()); // emit the event to the parent
    },
    onMaxLatencyChange(isValid) {
      this.isMaxLatencyValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onStreamIdChange(isValid) {
      this.isStreamIdValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      isIpProfileValid: false,
      originalCryptoAlgo: undefined,
      isCryptoValid: false,
      isMaxLatencyValid: false,
      isRegionValid: false,
      isStreamIdValid: false
    };
  },
  props: {
    currentItem: Destination,
    originalItem: Destination
  },

  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
