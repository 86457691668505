<template>
  <div id="loading">
    <div>
      <gl-loading-icon
        label="Loading"
        size="lg"
        color="dark"
        variant="spinner"
        :inline="false"
      />
      <p>Loading ...</p>
    </div>
  </div>
</template>
<script>
import { GlLoadingIcon } from "@gitlab/ui";

export default {
  components: {
    GlLoadingIcon
  }
};
</script>
<style lang="scss" scoped>
div#loading {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 50vh;

  > div {
    color: grey;
    text-align: center;

    > p {
      margin-top: 16px;
      font-size: 1.2em;
      font-weight: 500;
    }
  }
}
</style>
