<template>
  <fragment>
    <th>
      <span>Start</span>
      <date-picker
        class="date-input"
        data-testid="search--startAtOrAfter"
        v-model="startAtOrAfter"
        :disabled-date="endDateDisabledDates"
        type="datetime"
        value-type="format"
        placeholder="Start at"
        format="DD/MM/YYYY HH:mm:ss"
        @input="updateSearchParams('startAtOrAfter', startAtOrAfter)"
      />
    </th>

    <th>
      <span>End</span>
      <date-picker
        class="date-input"
        data-testid="search--startAtOrBefore"
        v-model="startAtOrBefore"
        :disabled-date="startDateDisabledDates"
        type="datetime"
        value-type="format"
        placeholder="Start until"
        format="DD/MM/YYYY HH:mm:ss"
        @input="updateSearchParams('startAtOrBefore', startAtOrBefore)"
      />
    </th>
  </fragment>
</template>
<script>
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { Fragment } from "vue-frag";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export default {
  components: {
    DatePicker,
    Fragment
  },
  data() {
    return {
      startAtOrAfter: null,
      startAtOrBefore: null
    };
  },
  props: {
    searchFields: Object
  },
  methods: {
    updateSearchParams(column, value) {
      const dt = this.formatDateTime(value);
      this.$set(this.searchFields, column, dt);
      this.$emit("changeSearchParams", this.searchFields);
    },
    startDateDisabledDates(date) {
      // If endDate is selected, disable dates before endDate
      if (this.searchFields["startAtOrAfter"]) {
        const startDateTime = new Date(this.searchFields["startAtOrAfter"]);
        startDateTime.setHours(0, 0, 0, 0);
        return date < startDateTime;
      }
      return false;
    },
    endDateDisabledDates(date) {
      // If startDate is selected, disable dates after startDate
      if (this.searchFields["startAtOrBefore"]) {
        const endDateTime = new Date(this.searchFields["startAtOrBefore"]);
        endDateTime.setHours(23, 59, 59, 999);
        return date > endDateTime;
      }
      return false;
    },
    formatDateTime(dt) {
      const response = dt
        ? dayjs(dt, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
        : null;
      return response;
    }
  },
  watch: {
    searchFields: {
      immediate: true,
      handler() {
        this.startAtOrAfter = this.searchFields["startAtOrAfter"]
          ? dayjs(this.searchFields["startAtOrAfter"]).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          : null;
        this.startAtOrBefore = this.searchFields["startAtOrBefore"]
          ? dayjs(this.searchFields["startAtOrBefore"]).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          : null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
th > span {
  display: block;
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
}
.date-input {
  width: 100%;
  margin-bottom: 1rem;
}
</style>
