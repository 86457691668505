<template>
  <div class="import-container">
    <div v-if="!file && !uploadSuccessful">
      <h1>Import Events</h1>
      <p>
        You can easily create a batch of contributions in Arqade by uploading up
        to 1,000 records directly from a CSV file.
      </p>
      <div
        class="dropzone"
        v-on:drop="handleDrop"
        v-on:dragover.prevent
        v-on:dragleave="handleDragLeave"
      >
        <p id="drop">Drop your CSV file here</p>
        <p id="or">or</p>
        <div id="selectFile">
          <input
            type="file"
            id="file"
            ref="file"
            class="hidden"
            v-on:change="handleFileUpload()"
          />
          <label for="file">Select it</label>
        </div>
      </div>
      <p id="help">
        You can download this
        <a href="/files/eventsToImport.csv" download="eventsToImport.csv"
          >CSV</a
        >
        as a template.
      </p>
    </div>

    <div v-if="file" id="fileLoaded">
      <h1>Import {{ file.name }}</h1>
      <img src="../../assets/csv.png" height="80" width="80" />
      <p>{{ file.size }} bytes</p>
      <gl-button @click="resetForm">Cancel</gl-button>
      <gl-button @click="uploadFile">Import</gl-button>
      <p data-testid="import-billing-terms">
        Your rate card will apply and pricing will be displayed on the Event
        details.
      </p>
    </div>

    <div v-if="uploadSuccessful" id="success">
      <h2 data-testid="import-success-title">Import requested</h2>
      <p>
        Import of Records in progress. Please check your email for further
        details. Once completed, your imported contributions will appear in the
        events list.
      </p>
      <gl-button @click="resetForm">Upload another file</gl-button>
    </div>
    <div v-if="uploadError" id="error">
      <p>{{ uploadError }}</p>
    </div>
    <div class="align-right">
      <gl-button data-testid="events-list-button" @click="goToEvents"
        >Go To Event List</gl-button
      >
    </div>
  </div>
</template>

<script>
import { GlButton } from "@gitlab/ui";
import { ITEMS_CONTRIBUTIONS } from "@/constants";
import organizationApiClient from "@/mixins/organizationApiClient";
import userInfo from "@/mixins/userInfo";
import HttpClient from "@/classes/HttpClient";

const { EVENTS } = ITEMS_CONTRIBUTIONS;

export default {
  name: "EventsImport",
  components: {
    GlButton
  },
  mixins: [organizationApiClient, userInfo],
  data() {
    return {
      httpClient: new HttpClient("", window.localStorage, false),
      file: null,
      uploadSuccessful: false,
      uploadError: null
    };
  },
  methods: {
    getFileLoadError(file) {
      if (!file) {
        return "No file selected";
      }

      if (file.size > 262144) {
        return "File size exceeds 256KB. Please select a smaller file.";
      }

      if (file.type !== "text/csv") {
        return "Invalid file type. Please select a CSV file.";
      }
      return null;
    },
    goToEvents() {
      this.$router.push(EVENTS);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.uploadError = this.getFileLoadError(this.file);
      if (this.uploadError) {
        this.file = null;
      }
    },
    handleDrop(e) {
      e.preventDefault();
      this.file = e.dataTransfer.files[0];
      this.uploadError = this.getFileLoadError(this.file);
      if (this.uploadError) {
        this.file = null;
      }
    },

    handleDragLeave(e) {
      e.preventDefault();
    },
    async uploadToS3(signedData, formData) {
      try {
        const result = await this.httpClient.post(signedData.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        if (result.status !== 204) {
          this.uploadError = "Upload failed. Please try again.";
        }
        this.uploadSuccessful = true;
        return result;
      } catch (e) {
        console.error(e);
        this.uploadError = e.message;
      }
    },
    async uploadFile() {
      const organisationId = this.getUserOrganizationId;
      await this.organizationApiClient
        .getCustomPath(organisationId, "csv/import")
        .then(async response => {
          let signed = response;
          let formData = new FormData();
          for (let field in signed.fields) {
            formData.append(field, signed.fields[field]);
          }
          formData.append("file", this.file);

          await this.uploadToS3(signed, formData);
          this.file = null;
        });
    },
    resetForm() {
      this.file = null;
      this.error = null;
      this.uploadSuccessful = false;
    }
  },
  watch: {
    uploadError(newValue) {
      if (newValue !== null) {
        this.file = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.import-container {
  margin: 10px;
  width: 50%;
  min-width: 300px;
}

h1 {
  margin-top: 80px;
  margin-bottom: 20px;
}

.align-right {
  float: right;
}

.hidden {
  display: none;
}

.dropzone {
  height: 178px;

  > p#drop {
    color: grey;
    font-size: 24px;
  }

  p#or {
    margin: 0px;
    padding: 0px;
    color: grey;
    font-size: 16px;
  }

  div#selectFile {
    font-size: 18px;
    text-align: center;

    label {
      margin-top: 10px;
      color: grey;
      font-size: 16px;
      border: 2px solid #ccc;
      display: inline-block;
      padding: 10px 20px;
      background-color: #f0f0f0;
      color: #333;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  margin-top: 10px;
  text-align: center;
  padding: 40px;
  padding-top: 50px;
}

p#help {
  margin-top: 10px;
  text-align: center;
  color: grey;
}

div#fileLoaded {
  text-align: center;
}

.gl-button {
  margin: 10px;
}

div#error {
  background-color: #c80032;
  color: white;
  margin-top: 30px;
  border: black 1px solid;

  padding: 18px;
  text-align: center;

  > p {
    font-size: 18px !important;
  }
}

div#success {
  margin-top: 60px;
  font-size: 44px;
  padding: 14px;
  text-align: center;
}
</style>
