import Delivery from "@/views/Delivery";
import { MAIN_ITEMS, ITEMS_DELIVERY } from "@/constants.js";
import {
  All,
  Complete,
  Error,
  Stopping,
  Pending,
  Progress,
  Starting,
  Cancelled
} from "@/components/Delivery";

const { DELIVERY } = MAIN_ITEMS;
const {
  ALL,
  PENDING,
  IN_PROGRESS,
  STOPPING,
  COMPLETE,
  ERROR,
  STARTING,
  CANCELLED
} = ITEMS_DELIVERY;

const HOST = `Delivery`;

const delivery = [
  {
    path: DELIVERY,
    name: `${HOST}`,
    redirect: DELIVERY,
    component: Delivery,
    children: [
      {
        path: ALL,
        component: All,
        props: true
      },
      {
        path: PENDING,
        component: Pending
      },
      {
        path: STARTING,
        component: Starting
      },
      {
        path: IN_PROGRESS,
        component: Progress
      },
      {
        path: STOPPING,
        component: Stopping
      },
      {
        path: COMPLETE,
        component: Complete
      },
      {
        path: CANCELLED,
        component: Cancelled
      },
      {
        path: ERROR,
        component: Error
      }
    ]
  }
];

export default delivery;
