<template>
  <div>
    <div class="my-2">
      <gl-button variant="confirm" class="mr-3" :href="toCreate"
        >Create virtual connect site</gl-button
      >
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
        >
        </ArqDataGrid>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import virtualConnectSiteApiClient from "@/mixins/virtualConnectSiteApiClient.js";
import { GlButton } from "@gitlab/ui";
import {
  ALIAS_ITEMS_VIRTUAL_CONNECT_SITES,
  ITEMS_VIRTUAL_CONNECT_SITES
} from "@/constants.js";

const { ADD_FIRST } = ALIAS_ITEMS_VIRTUAL_CONNECT_SITES;
const { EDIT } = ITEMS_VIRTUAL_CONNECT_SITES;

export default {
  name: "VirtualConnectSites",
  mixins: [virtualConnectSiteApiClient],
  components: {
    ArqDataGrid,
    GlButton
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  mounted() {},
  methods: {
    contextMenuClick(row, option) {
      // todo
      switch (option) {
        default:
          break;
      }
    },
    onRowSelected(/* row, selectedRows */) {
      // todo
    },
    async onDataNeeded(sortModel, pageModel) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 10;
      let query = this.virtualConnectSiteApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = this.contextMenuItems;
        }
      }
      return out;
    }
  },
  data() {
    return {
      toCreate: `#${ADD_FIRST}`,
      data: {},
      columns: [
        {
          text: "Name",
          field: "name",
          isSortable: true,
          clicked: row => {
            this.$router.push(`${EDIT}/${row.id}`);
          }
        }
      ]
    };
  }
};
</script>
