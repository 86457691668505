<template>
  <gl-form>
    <Wizard :leftSectionTitle="leftSectionTitle">
      <template v-slot:left-section v-if="getLogoResponse">
        <b-row class="mb-4">
          <b-col class="keep-width">
            <div
              v-if="!!currentItem.logo && !logoUploaded"
              class="image-preview-area"
            >
              <cropper
                :src="`data:image/jpeg;base64,${currentItem.logo}`"
                @change="resize"
              />
            </div>
            <div
              v-if="!!currentItem.logo && logoUploaded"
              class="image-preview-area"
            >
              <img :src="`data:image/jpeg;base64,${currentItem.logo}`" />
            </div>

            <div
              class="file-drop-area"
              @drop.prevent="drop"
              @dragenter.prevent="highlighted = true"
              @dragover.prevent="highlighted = true"
              @dragleave.prevent="highlighted = false"
              :class="{ highlighted: highlighted }"
              v-if="!currentItem.logo"
            >
              <input
                type="file"
                class="hidden-input"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                @change="fileSelected"
                ref="filedrop"
                accept=".jpg,.jpeg,.png"
              />
              <label for="assetsFieldHandle" class="block cursor-pointer">
                Drop your logo image here<br />
                or<br />
                Choose a file<br />
              </label>
            </div>
          </b-col>
          <b-col>
            <p>Format: PNG or JPG</p>
            <p>Max dimensions: 1024 x 1024px</p>
            <p>Upload max: 1MB</p>
            <p v-if="showUploadButton && isUpdate && currentItem.logo">
              <gl-button @click="upload" variant="danger">
                Save Logo
              </gl-button>
            </p>
            <p>
              <input
                ref="fileinput"
                @input="fileSelected"
                type="file"
                name="newImageFile"
                v-if="!currentItem.logo"
              />
            </p>
            <p>
              <gl-button
                v-if="currentItem.logo"
                @click="
                  logoUploaded ? showRemoveModal() : unloadSelectedImage()
                "
                >{{ logoUploaded ? "Remove Saved Logo" : "Cancel" }}
              </gl-button>
            </p>
          </b-col>
        </b-row>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import Wizard from "@/components/Wizard";
import { organizationApiClient } from "@/mixins/apiClients";
import Organisation from "@/models/Organisation";
import { GlButton, GlForm } from "@gitlab/ui";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "AddFirst",
  mixins: [organizationApiClient],
  components: {
    Wizard,
    GlForm,
    Cropper,
    GlButton
  },
  props: {
    currentItem: Organisation,
    isUpdate: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.getLogo(this.currentItem.id)
      .then(l => {
        this.currentItem.logo = atob(l.body);
        this.logoUploaded = true;
      })
      .finally(() => {
        this.getLogoResponse = true;
      });
  },
  computed: {
    buttons() {
      return [
        {
          text: this.isUpdate ? `Update Organization` : `Generate Organization`,
          type: `submit`,
          disabled: !this.isDescFulfilled
        }
      ];
    }
  },
  data() {
    return {
      showUploadButton: false,
      croppedImg: undefined,
      logoUploaded: false,
      highlighted: false,
      filelist: [],
      getLogoResponse: false,
      isDescFulfilled:
        !!this.currentItem.shortName && !!this.currentItem.legalName,
      leftSectionTitle: `Logo`
    };
  },
  watch: {
    currentItem: {
      handler() {
        this.isDescFulfilled =
          !!this.currentItem.shortName && !!this.currentItem.legalName;
      },
      deep: true
    }
  },
  methods: {
    resize({ canvas }) {
      this.croppedImg = this.extractData(
        canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
      );
      this.showUploadButton = true;
    },

    unloadSelectedImage() {
      this.currentItem.logo = undefined;
      this.croppedImg = undefined;
      this.logoUploaded = false;
      this.filelist = [];
      this.showUploadButton = true;
      this.$forceUpdate();
    },
    showRemoveModal() {
      const msg = "Are you sure you want to remove the logo?";
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.unloadSelectedImage();
            if (this.isUpdate) {
              this.removeLogo();
            }
          }
        })
        .catch(err => {
          this.handleApiError(err);
        });
    },
    drop(e) {
      this.highlighted = false;
      this.$refs.filedrop.files = e.dataTransfer.files;
      this.fileSelected();
    },
    fileSelected(e) {
      this.logoUploaded = false;
      const files = e ? e.target.files : [...this.$refs.filedrop.files];
      if (FileReader && files && files.length) {
        const fr = new FileReader();
        fr.onload = () => {
          this.currentItem.logo = this.extractData(fr.result);
          this.showUploadButton = true;

          this.$forceUpdate();
        };
        fr.readAsDataURL(files[0]);
      }
    },
    extractData(logo) {
      logo = logo.toString().replace(/^data:(.*,)?/, "");
      if (logo.length % 4 > 0) {
        logo += "=".repeat(4 - (logo.length % 4));
      }
      return logo;
    },
    async removeLogo() {
      try {
        await this.deleteLogo(this.currentItem.id);
        this.showUploadButton = false;
        this.handleApiSuccess(
          `The organization logo was removed successfully.`
        );
      } catch (e) {
        this.handleApiError(e);
      }
    },

    async upload() {
      try {
        await this.uploadLogo(this.currentItem.id, this.croppedImg);
        this.currentItem.logo = this.croppedImg;
        this.logoUploaded = true;
        this.handleApiSuccess(
          `The organization logo was updated successfully.`
        );
        this.showUploadButton = false;
      } catch (e) {
        this.handleApiError(e);
      }
    }
  }
};
</script>
<style scoped>
.hidden-input {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
}

.file-drop-area {
  width: 216px;
  height: 216px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  border-radius: 6px;
}

.file-drop-area label {
  width: 80%;
  text-align: center;
}

.highlighted {
  border-color: #c80032;
}

.image-preview-area {
  width: 216px;
  height: 216px;
}

.image-preview-area img {
  width: 216px;
  height: 216px;
}

.keep-width {
  min-width: 240px;
  width: 240px;
  max-width: 240px;
}
</style>
