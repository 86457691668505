<template>
  <b-badge v-if="hasAlerts" variant="danger" class="status-badge l">
    <span class="status-text">
      {{ alertCount }}
    </span>
  </b-badge>
</template>

<script>
export default {
  name: "AlertsBadge",
  props: {
    resource: {
      type: Object
    }
  },
  computed: {
    alertCount() {
      return this.resource.alertCount;
    },
    hasAlerts() {
      return this.alertCount > 0;
    }
  }
};
</script>
