import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);
dayjs.extend(utc);
export default {
  methods: {
    displayDate(date, timezone) {
      if (timezone) {
        return dayjs(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      }
      return this.toLocalDate(date);
    },
    displayTime(date, timezone) {
      if (timezone) {
        return dayjs(date)
          .tz(timezone)
          .format("HH:mm:ss");
      }
      return this.toLocalTime(date);
    },
    toLocalDate(date) {
      const d = new Date(Date.parse(date));
      return d.toLocaleDateString();
    },
    toLocalTime(date) {
      const d = new Date(Date.parse(date));
      return d.toLocaleTimeString();
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
    },

    fromUTCtoTZ(date, tz) {
      if (!date) {
        return null;
      }
      const tzDate = dayjs
        .utc(date)
        .tz(tz)
        .format("D/MM/YYYY HH:mm:ss");

      return tzDate;
    },
    convertToDate(dateString) {
      const [day, month, yearTime] = dateString.split("/");
      const [year, time] = yearTime.split(" ");
      const formattedDate = `${month}/${day}/${year} ${time}`;
      return new Date(formattedDate);
    },

    assignActiveTimeZone(date, timezone) {
      const dateStr = dayjs(date).format("YYYY-MM-DD");
      const timeStr = dayjs(date).format("HH:mm:ss");
      return dayjs.tz(`${dateStr}T${timeStr}`, timezone).format();
    },

    fromTZtoUTC(date, tz) {
      if (!date) {
        return null;
      }
      const tzDate = this.assignActiveTimeZone(date, tz);
      return dayjs(tzDate)
        .utc()
        .format();
    },

    changeTimezone(date, tz1, tz2) {
      if (!date) {
        return null;
      }
      const utcDate = this.fromTZtoUTC(date, tz1);
      const tzString = this.fromUTCtoTZ(utcDate, tz2);
      return this.convertToDate(tzString);
    },

    getLocalTimezone() {
      return dayjs.tz.guess();
    },
    calculateHours(plannedStart, plannedEnd) {
      const startDate = new Date(plannedStart);
      const endDate = new Date(plannedEnd);
      const diffInMs = Math.abs(endDate - startDate);
      return diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
    }
  }
};
