<template>
  <td>
    <div v-if="isOpenEnded">
      <!-- TODO status cell is not working -->
      <StatusCell
        :showIcon="true"
        status="On-Going"
        class="l"
        data-testid="date_time__ongoing"
      />
    </div>
    <div v-else>
      <span
        class="font-weight-bold mb-2 d-block"
        data-testid="date_time__date"
        >{{ displayDate(date, getDefaultTimezone()) }}</span
      >
      <span>{{ displayTime(date, getDefaultTimezone()) }}</span>
    </div>
  </td>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";

export default {
  mixins: [dateUtils, userInfo],
  components: [StatusCell],
  props: {
    date: {
      type: String
    },
    isOpenEnded: {
      type: Boolean
    }
  }
};
</script>
