<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div v-if="!isLoading" class="mx-3 my-1">
      <Breadcrumb :items="breadcrumbItems" />
      <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
      <gl-tabs>
        <gl-tab
          v-for="(item, index) in tabsItems"
          :key="`tab${index}`"
          @click="openTab(item.subPageName, item.subComponent, item.data)"
          :active="isSelected(item.subPageName)"
          :disabled="item.disabled"
        >
          <template slot="title">
            <span>{{ item.title }}</span>
            <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge"
              >{{ item.badge }}
            </gl-badge>
          </template>
        </gl-tab>
      </gl-tabs>
      <component
        v-bind:is="currentComponent"
        :currentItem="currentItem"
        :originalItem="originalItem"
        :isUpdate="isEdit"
        @create="destinationCreated"
      ></component>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Destination from "@/models/Destination";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_DESTINATIONS,
  ITEMS_DESTINATIONS
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import destinationApiClient from "@/mixins/destinationApiClient.js";
import AddFirst from "@/components/Destinations/AddFirst.vue";
import AddSecond from "@/components/Destinations/AddSecond.vue";
import { GlTabs, GlTab } from "@gitlab/ui";
import { DESTINATION_TYPES } from "@/constants.js";
import Loading from "@/components/Loading.vue";
import _ from "lodash";

const { EDIT, SHOW } = ITEMS_DESTINATIONS;
const {
  ADD_FIRST,
  ADD_SECOND,
  YOUR,
  EDIT_FIRST,
  EDIT_SECOND
} = ALIAS_ITEMS_DESTINATIONS;

export default {
  components: { Loading, Breadcrumb, GlTabs, GlTab },
  mixins: [wizardGuard, destinationApiClient],
  methods: {
    addDefaults(destination) {
      for (const prop in Destination) {
        if (destination[prop] === undefined) {
          destination[prop] = Destination[prop];
        }
      }
      return destination;
    },
    handleKey(evt) {
      if (evt.code === "Escape") {
        if (this.currentItem.id) {
          this.$router.push(`${SHOW}/${this.currentItem.id}`);
        } else {
          this.goToList();
        }
      }
    },
    goToList() {
      this.$router.push(`${YOUR}`);
    },
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    destinationCreated(id) {
      this.created = true;
      this.$router.push(`${SHOW}/${id}`);
    }
  },
  created() {
    if (this.$route.path === ADD_SECOND) {
      this.$router.push(ADD_FIRST);
    }
    if (this.$route.params.id) {
      this.isEdit = true;
      const id = this.$route.params.id;
      this.destinationApiClient.getById(id).then(dest => {
        dest.cryptoAlgo = dest.cryptoAlgo || null;
        dest.destinationType =
          dest.ipDestinationType === "rist"
            ? DESTINATION_TYPES.RIST
            : dest.destinationType;
        this.currentItem = this.addDefaults(dest);
        if (!this.currentItem.streamProfileId) {
          this.currentItem.isPassthrough = true;
        }
        this.originalItem = { ...this.currentItem };
        this.tabsItems.forEach(tab => {
          if (this.isSelected(tab.subPageName)) {
            this.openTab(tab.subPageName, tab.subComponent, tab.data);
          }
        });
        setTimeout(() => {
          this.isLoading = false;
        }, 10);
      });
    } else {
      if (this.$route.params && this.$route.params.cloning) {
        this.currentItem = this.$route.params.cloning;
      } else {
        this.currentItem = {
          id: null,
          name: null,
          description: null,
          streamProfileId: undefined,
          redundant: false,
          destinationType: DESTINATION_TYPES.RTP,
          port: undefined,
          region: null,
          url: undefined,
          streamName: undefined,
          outputDestinationType: undefined,
          cdnSettingsType: undefined,
          nameModifier: undefined,
          publicOutboundIp: undefined
        };
      }
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name && this.currentItem.description;
      },
      deep: true
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Destinations`, href: YOUR },
        { text: this.isEdit ? "Edit" : "Create new", href: ADD_FIRST }
      ];
    },

    isPristine() {
      return _.isEqual(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `1. Description`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FIRST}`
            : ADD_FIRST,
          subComponent: AddFirst
        },
        {
          title: `2. Specification`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`
            : ADD_SECOND,
          subComponent: AddSecond,
          disabled: !this.isDescFulfilled
        }
      ];
    },
    pageTitle() {
      return this.isEdit ? "Edit Destination" : `Create Destination`;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      isEdit: false,
      isLoading: true,
      selectedTab: null,
      currentItem: { ...Destination },
      originalItem: undefined,
      isDescFulfilled: false
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  }
};
</script>
