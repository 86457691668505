import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  created() {
    this.packageApiClient.updateEntitlements = (id, organisation_ids) => {
      return this.packageApiClient.postCustomPath(
        id,
        "organisation_entitlements",
        {
          organisation_ids
        }
      );
    };

    this.packageApiClient.updateContributions = (id, contributionIds) => {
      return this.packageApiClient.postCustomPath(id, "contributions", {
        contributionIds
      });
    };
  },
  data() {
    return {
      packageApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.PACKAGES,
        "packages"
      )
    };
  }
};
