<template>
  <div>
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
      :description="description"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { ITEMS_DELIVERY, MAIN_ITEMS } from "@/constants.js";
import { mapGetters } from "vuex";
import wizardGuard from "@/mixins/wizardGuard.js";

const STORE = `source`;
const { ALL } = ITEMS_DELIVERY;

export default {
  components: { MainSection },
  mixins: [wizardGuard],
  created() {
    this.store = STORE;
  },
  computed: {
    ...mapGetters("source", ["isDescNotFulfilled", "isSpecNotFulfilled"]),
    tabsItems() {
      return [];
    }
  },
  data() {
    return {
      pageTitle: `Delivery`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Delivery`, href: ALL }
      ],
      description: `Preview or Cancel your delivery from the three vertical dots menu at the end of its row.`
    };
  }
};
</script>
