<template>
  <p class="d-flex align-items-center createDelivery__lid mt-4 mb-5">
    <gl-icon name="check" class="mr-2 createDelivery__iconCheck" :size="32" />
    <span>
      Bulk delivery for your selected <strong>{{ deliveryCount }}</strong> event
      contributions have been generated successfully.
    </span>
  </p>
</template>
<script>
import { GlIcon } from "@gitlab/ui";

export default {
  components: { GlIcon },
  name: "Complete",
  props: {
    deliveryCount: {
      type: Number,
      default: null
    }
  }
};
</script>
<style scoped>
.createDelivery__iconCheck {
  color: var(--theme-color);
}
.createDelivery__lid {
  width: 600px;
  max-width: 100%;
}
</style>
