<template>
  <tr class="loading_wrapper">
    <td colspan="20">
      <Loading id="loading" />
    </td>
  </tr>
</template>
<script>
import Loading from "@/components/Loading.vue";

export default {
  name: "TableLoading",
  components: {
    Loading
  }
};
</script>
<style lang="scss" scoped>
.loading_wrapper #loading {
  width: 100%;
  height: auto;
}
</style>
