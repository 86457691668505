<template>
  <div class="dashboard__deliveries pt-1 pr-1">
    <div class="d-flex justify-content-between">
      <h4>Deliveries</h4>
      <gl-button
        v-show="selectedContribution"
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4">
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="selectedContribution && !isLoading"
        :data="data"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        @rowSelected="onRowSelected"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'" class="deliverySingle">
            <div class="d-flex justify-content-between mb-2">
              <div class="d-flex align-items-center">
                <div class="ml-2">
                  <p class="mb-0 deliverySingle__name">
                    {{ r.contributionName }}
                  </p>
                </div>
              </div>
              <div>
                <b-badge
                  variant="secondary"
                  class="dashboard__delivery__badge"
                  >{{ countDestinations(r) }}</b-badge
                >
              </div>
            </div>
            <div class="d-flex align-items-center deliverySingle_bottom">
              <b-badge :variant="getBadgeVariant(r)"
                ><gl-icon :name="getBadgeIcon(r)" />{{
                  getBadgeText(r)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
      <p v-show="!selectedContribution">
        Select a contribution to display connected deliveries to other
        organizations and to your own destinations
      </p>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlButton } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";

export default {
  name: "Deliveries",
  mixins: [metricsApiClient, userInfoMixin],
  components: { ArqDataGrid, GlLoadingIcon, GlButton },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    selectedContribution: {
      type: Object
    }
  },
  watch: {
    selectedContribution: function() {
      this.pageModel.currentPage = 0;
      this.onDataNeeded({}, this.pageModel);
    }
  },
  methods: {
    contextMenuClick() {},
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(row) {
      if (row.scheduleStatus === "CANCELLED" || row.state === "ERROR") {
        return "danger";
      } else if (row.state === "STARTING" || row.state === "IN_PROGRESS") {
        return "warning";
      } else {
        return "success";
      }
    },
    getBadgeText(row) {
      return row.scheduleStatus === "CANCELLED" || row.state === "ERROR"
        ? "ERROR"
        : row.state;
    },
    getBadgeIcon(row) {
      return row.scheduleStatus === "CANCELLED" || row.state === "ERROR"
        ? "status_warning"
        : "check-circle";
    },
    countDestinations(row) {
      if (row.destinationId0 && row.destinationId1) {
        return 2;
      } else if (row.destinationId0 || row.destinationId1) {
        return 1;
      } else {
        return 0;
      }
    },
    async onDataNeeded(_, pageModel) {
      this.pageModel = pageModel;
      if (!this.selectedContribution) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      this.isLoading = true;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = (this.isArqadeAdminRoleActive
        ? this.getMetricsHealth("deliveries")
        : this.getMetricsHealth("deliveries/own")
      )
        .list()
        .page(currentPage)
        .perPage(itemsPerPage)
        .param("contributionId", this.selectedContribution.id);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
    }
  },
  data() {
    return {
      pageModel: {
        itemsPerPage: this.itemsPerPage
      },
      selectedRow: undefined,
      data: {},
      isLoading: false,
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style scoped>
h4 {
  color: var(--theme-color);
}
.dashboard__deliveries {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.deliverySingle_bottom .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.deliverySingle_bottom .badge .gl-icon {
  margin-right: 5px;
}
.deliverySingle_bottom .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.deliverySingle_bottom .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.deliverySingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.deliverySingle__name {
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
.dashboard__delivery__badge {
  font-size: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
</style>
