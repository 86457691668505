var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-2"},[_c('gl-filtered-search',{attrs:{"available-tokens":_vm.tokens},on:{"submit":_vm.activate,"clear":_vm.clear},scopedSlots:_vm._u([{key:"history-item",fn:function(ref){
var historyItem = ref.historyItem;
return [_vm._l((historyItem),function(token,idx){return [_c('span',{key:idx,staticClass:"px-1"},[_vm._v(" "+_vm._s(token.type)+" = "),_c('strong',[_vm._v(_vm._s(token.value))])])]})]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"border-right border-light"},[_c('ArqDataGrid',{attrs:{"data":_vm.data,"phrase":_vm.searchPhrase,"columns":_vm.columns,"itemsPerPage":_vm.itemsPerPage,"showContextMenu":false,"selectOnClick":true},on:{"dataNeeded":_vm.onDataNeeded,"rowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var r = ref.row;
var field = ref.field;
return [(field === 'created')?_c('div',[_c('span',{staticClass:"f12"},[_vm._v(_vm._s(_vm.toLocalDate(r.created)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.toLocalTime(r.created)))])]):(field === 'operation')?_c('div',[_c('StatusCell',{staticClass:"l",attrs:{"status":r.operation,"showIcon":false}})],1):_c('div',[_c('span',{staticClass:"f12"},[_vm._v(_vm._s(r[field])+" ")])])]}}])}),(_vm.selectedRow)?_c('div',[_c('b-row',[_c('b-col',[_c('p',{staticClass:"font-weight-bold field-header"},[_vm._v("Before")])]),_c('b-col',[_c('p',{staticClass:"font-weight-bold field-header"},[_vm._v("After")])])],1),_vm._l((_vm.detailsItems(_vm.selectedRow)),function(detailRow,index){return _c('b-row',{key:index,staticClass:"my-2"},[_c('b-col',[_c('div',{staticClass:"field-element p-2 h-100",class:{
                changed: !_vm.deepEq(detailRow.before, detailRow.after)
              }},[_c('p',{staticClass:"field-name",class:{
                  'field-changed': !_vm.deepEq(detailRow.before, detailRow.after)
                }},[_vm._v(" "+_vm._s(detailRow.field)+" ")]),_c('p',{staticClass:"field-value",class:{
                  'field-changed': !_vm.deepEq(detailRow.before, detailRow.after)
                }},[_vm._v(" "+_vm._s(detailRow.before)+" ")])])]),_c('b-col',[_c('div',{staticClass:"field-element p-2 h-100",class:{
                changed: !_vm.deepEq(detailRow.before, detailRow.after)
              }},[_c('p',{staticClass:"field-name",class:{
                  'field-changed': !_vm.deepEq(detailRow.before, detailRow.after)
                }},[_vm._v(" "+_vm._s(detailRow.field)+" ")]),_c('p',{staticClass:"field-value",class:{
                  'field-changed': !_vm.deepEq(detailRow.before, detailRow.after)
                }},[_vm._v(" "+_vm._s(detailRow.after)+" ")])])])],1)})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }