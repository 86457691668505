<template>
  <dropdown-menu class="dropdown">
    <template #trigger>
      <button data-testid="clickable-ellipsis_v" class="kebab open-button">
        <gl-icon name="ellipsis_v"></gl-icon>
      </button>
    </template>

    <template #body>
      <ul>
        <slot></slot>
      </ul>
    </template>
  </dropdown-menu>
</template>
<script>
import { GlIcon } from "@gitlab/ui";
import DropdownMenu from "v-dropdown-menu/vue2";

export default {
  components: {
    DropdownMenu,
    GlIcon
  }
};
</script>
<style scoped lang="scss">
@import "~vue-context/dist/css/vue-context.css";
.open-button {
  border: none;
  background-color: transparent;
  max-width: none;
}
.dropdown::v-deep {
  .v-dropdown-menu__container {
    right: 0;
    left: initial;
    border-radius: 0.4rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li button,
      li a {
        padding: 0.4rem;
        font-size: 14px;
        width: 100%;
        transition: background-color 0.3s;
        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
  .v-dropdown-menu {
    position: relative;
    display: inline-block;
  }
  .v-dropdown-menu__trigger {
    position: relative;
  }
  .v-dropdown-menu__container {
    position: absolute;
    top: 100%;
    bottom: auto;
    min-width: 230px;
    max-width: 100%;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .v-dropdown-menu--dropup .v-dropdown-menu__container {
    top: auto;
    bottom: 100%;
  }
  .v-dropdown-menu--direction-left .v-dropdown-menu__container {
    left: 0;
  }
  .v-dropdown-menu--direction-center .v-dropdown-menu__container {
    left: 50%;
    transform: translateX(-50%) translateY(0);
  }
  .v-dropdown-menu--direction-right .v-dropdown-menu__container {
    right: 0;
  }
  .v-dropdown-menu__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .v-dropdown-menu .default-enter-active {
    transition: all 0.2s ease;
  }
  .v-dropdown-menu .default-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .v-dropdown-menu .default-enter,
  .v-dropdown-menu .default-leave-to {
    transform: translateY(12px);
    opacity: 0;
  }
  .v-dropdown-menu--mode-hover .default-enter,
  .v-dropdown-menu--mode-hover .default-leave-active {
    transition-delay: 0.4s;
  }
  .v-dropdown-menu--dropup .default-enter,
  .v-dropdown-menu--dropup .default-leave-to {
    transform: translateY(-12px);
  }
  .v-dropdown-menu--dropup.v-dropdown-menu--direction-center .default-enter,
  .v-dropdown-menu--dropup.v-dropdown-menu--direction-center .default-leave-to {
    transform: translateX(-50%) translateY(-12px);
  }
  .v-dropdown-menu--direction-center .default-enter,
  .v-dropdown-menu--direction-center .default-leave-to {
    transform: translateX(-50%) translateY(12px);
  }
}
</style>
