<template>
  <div>
    <VueDiagramEditor
      ref="diagram"
      :node-color="nodeColor"
      :node-pulsable="nodePulsable"
      :node-deletable="
        () => {
          return false;
        }
      "
    >
      <pre
        slot="node"
        slot-scope="{ node }"
        style="font-size: xx-small; user-select: text;"
        @mousedown.stop
        >{{ format(node) }}</pre
      >
    </VueDiagramEditor>
    <div>
      <gl-button @click="resetZoom">Reset Zoom</gl-button>
      <gl-button @click="fit">Fit</gl-button>
      <gl-button @click="contain">Contain</gl-button>
    </div>
  </div>
</template>

<script>
// TODO: add tests for this component once table repositioning revamp is done
import VueDiagramEditor from "vue-diagram-editor";
import "vue-diagram-editor/dist/vue-diagram-editor.css";
import { GlButton } from "@gitlab/ui";
export default {
  name: "simple-example",
  components: {
    VueDiagramEditor,
    GlButton
  },
  data: () => ({
    nodes: {},
    links: {},
    yLevel: {
      // initial y-coordinate for each level
      InputFlow: 0,
      Channel: 0,
      Output: 0,
      Transcode: 0
    }
  }),
  props: {
    pipeline: {
      type: Array
    }
  },
  mounted() {
    this.generateNodes();
    this.generateLinks();
    this.init();
  },
  methods: {
    init() {
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links
      });
    },
    resetZoom() {
      this.$refs.diagram.resetZoom();
    },
    fit() {
      this.$refs.diagram.fit();
    },
    contain() {
      this.$refs.diagram.contain();
    },
    format(node) {
      return JSON.stringify(node.data, null, 1);
    },
    nodeColor(node) {
      const nodeId = node.data.id;
      if (nodeId.includes("InputFlow")) {
        return "#FFA500";
      } else if (nodeId.includes("Channel")) {
        return "#07e717";
      } else if (nodeId.includes("Output")) {
        return "#0000FF";
      }
    },

    nodePulsable(node) {
      return node.coordinates.y > 200;
    },

    getNodes() {
      const nodes = this.pipeline[this.pipeline.length - 1].nodes;
      return nodes;
    },
    generateNodes() {
      const x = this.pipeline.slice(0, -1).length;
      const nodes = this.getNodes();
      const coordinates = {
        x: 0,
        y: 0
      };
      for (let i = 0; i < x; i++) {
        if (nodes.includes(this.pipeline[i].id)) {
          const nodeType = this.pipeline[i].type;
          if (nodeType === "FlowReference") {
            coordinates.x = 0;
            coordinates.y = this.yLevel.InputFlow;
            this.yLevel.InputFlow += 250; // Update y-coordinate for next InputFlow node
          } else if (nodeType === "FlowOutputReference") {
            coordinates.x = 400;
            coordinates.y = 200;
            this.yLevel.Channel += 250;
          } else if (nodeType === "Channel") {
            coordinates.x = 300;
            coordinates.y = 125;
            this.yLevel.Channel += 250; // Update y-coordinate for next Channel node
          } else if (nodeType === "Flow") {
            coordinates.x = 600;
            coordinates.y = this.yLevel.Output;
            this.yLevel.Output += 250; // Update y-coordinate for next Output node
          } else if (nodeType === "FlowOutput") {
            coordinates.x = 900;
            coordinates.y = this.yLevel.Transcode;
            this.yLevel.Transcode += 250; // Update y-coordinate for next Transcode node
          }
          const newNode = {
            id: this.pipeline[i].id,
            title: this.pipeline[i].id,
            size: {
              width: 210,
              height: 220
            },
            portsIn: { default: "in" },
            portsOut: { default: "out" },
            coordinates: {
              x: coordinates.x,
              y: coordinates.y
            },
            data: this.pipeline[i]
          };
          this.nodes[this.pipeline[i].id] = newNode;
        }
      }
    },
    generateLinks() {
      const links = this.pipeline[this.pipeline.length - 1].links;
      for (let i = 0; i < links.length; i++) {
        const newLink = {
          id: links[i].id,
          start_id: links[i].id.split("/")[0].trim(),
          end_id: links[i].id.split("/")[1].trim()
        };
        this.links[links[i].id] = newLink;
      }
    }
  }
};
</script>
