import Account from "@/views/Account/Account";
import { Preferences } from "@/components/Account";
import { MAIN_ITEMS, ITEMS_ACCOUNT } from "@/constants.js";
const { ACCOUNT } = MAIN_ITEMS;
const { PREFERENCES } = ITEMS_ACCOUNT;

const account = [
  {
    path: ACCOUNT,
    name: `Account`,
    component: Account,
    children: [
      {
        path: PREFERENCES,
        component: Preferences
      }
    ]
  }
];

export default account;
