<template>
  <div>
    <gl-form-group
      class="m-4"
      label="ARN"
      label-size="sm"
      :label-for="`${prefix}arn`"
      v-if="showArn"
    >
      <gl-form-input
        :id="`${prefix}arn`"
        type="text"
        v-model="arn"
        placeholder="arn:aws:iam::123456789012:user"
        maxlength="250"
        :required="required"
        class="mt-1"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="RTP Type"
      label-size="sm"
      :label-for="`${prefix}ipType`"
      v-if="showIpType"
    >
      <gl-form-select
        :id="`${prefix}ipType`"
        v-model="ipType"
        :options="ipTypes"
        :required="required"
        class="mt-1"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="IP Address"
      label-size="sm"
      :label-for="`${prefix}ipAddress`"
      :invalid-feedback="invalidIpFeedback"
      v-if="showIpAddress"
    >
      <gl-form-input
        :id="`${prefix}ipAddress`"
        type="text"
        v-model="ipAddress"
        placeholder="IP Address"
        maxlength="250"
        :required="required"
        class="mt-1"
        :state="isValidIp"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="Port"
      label-size="sm"
      :label-for="`${prefix}port`"
      :invalid-feedback="invalidPortFeedback"
    >
      <gl-form-input
        type="number"
        :id="`${prefix}port`"
        v-model="port"
        placeholder="Port"
        maxlength="5"
        :step="this.ipTypeValue === 'rist' ? 2 : 1"
        :required="required"
        class="mt-1"
        :state="isValidPort"
        min="1024"
        max="65535"
      />
    </gl-form-group>
  </div>
</template>

<script>
import ipRegex from "ip-regex";
import { GlFormSelect, GlFormInput, GlFormGroup } from "@gitlab/ui";

const EVENT_NAME = "change";
export default {
  components: {
    GlFormSelect,
    GlFormInput,
    GlFormGroup
  },
  mounted() {
    this.$emit(EVENT_NAME, {
      port: this.port,
      type: this.ipType,
      address: this.ipAddress,
      arn: this.arnValue,
      isValid: this.isValid
    });
  },
  computed: {
    invalidPortFeedback() {
      return this.isValidPort
        ? ""
        : this.ipTypeValue === "rist"
        ? "Please enter valid IP Port 1024-65535 that is an even number."
        : "Please enter valid IP Port 1024-65535.";
    },
    isValidPort() {
      const portValid = this.port >= 1024 && this.port <= 65535;
      if (this.ipTypeValue === "rist") {
        return portValid && this.port % 2 === 0;
      } else {
        return portValid;
      }
    },
    isValidIp() {
      return ipRegex({ exact: true }).test(this.ipAddress);
    },
    invalidIpFeedback() {
      return this.isValidIp ? "" : "Please enter valid IPv4 or IPv6 address.";
    },
    isValid() {
      return (
        this.isValidPort &&
        ((this.showIpAddress && this.isValidIp) || !this.showIpAddress)
      );
    },
    ipAddress: {
      get() {
        return this.ipAddressMut;
      },
      set(value) {
        this.ipAddressMut = value;
        this.$emit(EVENT_NAME, {
          port: this.port,
          type: this.ipType,
          address: value,
          arn: this.arn,
          isValid: this.isValid
        });
      }
    },
    port: {
      get() {
        return this.portMut;
      },
      set(value) {
        if (!value || value === "") {
          this.portMut = undefined;
        } else {
          this.portMut = parseInt(value);
        }
        this.$emit(EVENT_NAME, {
          port: parseInt(value),
          type: this.ipType,
          address: this.ipAddress,
          arn: this.arn,
          isValid: this.isValid
        });
      }
    },
    ipType: {
      get() {
        return this.ipTypeMut;
      },
      set(value) {
        this.ipTypeMut = value;
        this.$emit(EVENT_NAME, {
          port: this.port,
          type: value,
          address: this.ipAddress,
          arn: this.arn,
          isValid: this.isValid
        });
      }
    },
    arn: {
      get() {
        return this.arnValue;
      },
      set(value) {
        this.$emit(EVENT_NAME, {
          port: this.port,
          type: this.ipType,
          address: this.ipAddress,
          arn: value,
          isValid: this.isValid
        });
      }
    }
  },
  props: {
    ipAddressValue: {
      type: String
    },
    portValue: {
      type: Number
    },
    ipTypeValue: {
      type: String,
      default: "rtp"
    },
    arnValue: {
      type: String
    },
    prefix: {
      type: String,
      default: "ip_"
    },
    showArn: {
      type: Boolean
    },
    showIpType: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    showIpAddress: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    ipAddressValue: {
      handler(val) {
        this.ipAddressMut = val;
      }
    },
    portValue: {
      handler(val) {
        this.portMut = val;
      }
    },
    ipTypeValue: {
      handler(val) {
        this.ipTypeMut = val;
      }
    }
  },
  data() {
    return {
      ipAddressMut: this.ipAddressValue,
      portMut: this.portValue,
      ipTypeMut: this.ipTypeValue,
      ipTypes: [
        { value: "rtp", text: "STANDARD" },
        { value: "rtp-fec", text: "FEC" }
      ]
    };
  }
};
</script>
