import { ALIAS_ITEMS_CONTRIBUTION } from "@/constants";

export default {
  methods: {
    async archive(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to archive "${row.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.sourceApiClient.archive(row.id);
          this.handleApiSuccess(`The source ${row.name} has been archived`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    clone(row) {
      const cloned = JSON.parse(JSON.stringify(row));
      cloned.name += " (copy)";
      this.$router.push({
        name: `SourcesCreate`,
        params: { cloning: cloned }
      });
    },
    createContribution(rowId) {
      rowId = rowId || this.selectedRow.id;
      this.$router.push(
        ALIAS_ITEMS_CONTRIBUTION.ADD_FIRST +
          "?contributionType=channel_contribution&sourceType=" +
          rowId
      );
    },
    async requestPreview(row, extend = false) {
      const rowId = row.id || row;
      try {
        if (extend) {
          await this.sourceApiClient.postCustomPath(rowId, "preview/extend");
        } else {
          await this.sourceApiClient.postCustomPath(rowId, "preview");
        }
        this.handleApiSuccess("The preview has been requested.");
        if (row.previewStatus) {
          row.previewStatus = "STARTING";
        }
      } catch (e) {
        this.handleApiError(e);
      }
    }
  }
};
