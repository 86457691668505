import HttpClient from "@/classes/HttpClient.js";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      baseUrl: AppConfig.instance.config.API_URLS.REPORTS,
      apiClient: new HttpClient(this.baseUrl)
    };
  },
  methods: {
    async getReportBandwidth(
      startDate = undefined,
      endDate = undefined,
      groupBy = undefined
    ) {
      const url = new URL(this.baseUrl + `reports/bandwidth`);
      if (startDate instanceof Date) {
        url.searchParams.append(
          `startDate`,
          startDate.toISOString().slice(0, 10)
        );
      }
      if (endDate instanceof Date) {
        url.searchParams.append(`endDate`, endDate.toISOString().slice(0, 10));
      }
      if (groupBy) {
        url.searchParams.append(`groupBy`, groupBy);
      }
      return this.apiClient.get(url);
    },
    async getReportTranscodes(id) {
      const url = new URL(
        this.baseUrl + `reports/transcodes-by-contribution/` + id
      );
      return this.apiClient.get(url);
    }
  }
};
