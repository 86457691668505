import { MAIN_ITEMS } from "@/constants.js";
import account from "@/router/account";
import audit from "@/router/audit";
import billing from "@/router/billing";
import contributions from "@/router/contributions";
import delivery from "@/router/delivery";
import destinations from "@/router/destinations";
import monitoring from "@/router/monitoring";
import opsPanel from "@/router/ops-panel";
import packages from "@/router/packages";
import partnerGrants from "@/router/partner-grants";
import pricing from "@/router/pricing";
import report from "@/router/report";
import search from "@/router/search";
import sources from "@/router/sources";
import streamProfiles from "@/router/stream-profiles";
import support from "@/router/support";
import userMgmt from "@/router/user-mgmt";
import virtualConnectSites from "@/router/virtual-connect-sites";
import AwsMarketplaceRegistrationVue from "@/views/AwsMarketplace/AwsMarketplaceRegistration.vue";
import Home from "@/views/Home";
import Vue from "vue";
import VueRouter from "vue-router";

const { ROOT } = MAIN_ITEMS;

Vue.use(VueRouter);

const routes = [
  ...packages,
  ...sources,
  ...contributions,
  ...destinations,
  ...delivery,
  ...partnerGrants,
  ...userMgmt,
  ...audit,
  ...opsPanel,
  ...pricing,
  ...monitoring,
  ...billing,
  ...report,
  ...support,
  ...virtualConnectSites,
  ...account,
  ...streamProfiles,
  ...search
];

routes.push(
  {
    path: ROOT,
    name: "Home",
    component: Home
  },
  {
    path: "/aws-marketplace-registration/:id",
    name: "AwsMarketplaceRegistration",
    component: AwsMarketplaceRegistrationVue
  }
);

const router = new VueRouter({
  routes
});

export default router;
