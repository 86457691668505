<template>
  <gl-nav-item-dropdown icon="user" :right="true">
    <gl-dropdown-item v-if="userInfo && userInfo.attributes" disabled>
      <div class="font-weight-bold">
        {{ userInfo.attributes["given_name"] }}
        {{ userInfo.attributes["family_name"] }}
      </div>
      {{ userInfo.attributes["email"] }}
    </gl-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <gl-dropdown-item @click="redirectToAccountPreferences"
      >Account Preferences
    </gl-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <gl-dropdown-item v-if="membershipInfo" disabled>
      <i>{{ membershipInfo }}</i>
    </gl-dropdown-item>
    <gl-dropdown-item
      :disabled="isMasqueradingRole"
      v-if="isArqadeAdmin && !isMasquerading"
      v-b-modal.modal-set-organization
      >Set organization
      <b-modal
        title="Select organization"
        id="modal-set-organization"
        okVariant="danger"
        okTitle="Set"
        :ok-disabled="!selectedOrganization"
        cancelTitle="Cancel"
        @ok="handleOkModalOrganization"
      >
        <smart-select
          v-if="isArqadeAdmin"
          :dataSource="organizationApiClient"
          :textFormatter="organizationNameFormatter"
          @itemSelected="setOrganizationId"
          :selected="selectedOrganization"
        ></smart-select>
      </b-modal>
    </gl-dropdown-item>
    <gl-dropdown-item v-if="isMasquerading" @click="unsetOrganization">
      Unset Organization
    </gl-dropdown-item>

    <b-dropdown-divider v-if="roleInfo"></b-dropdown-divider>
    <gl-dropdown-item v-if="roleInfo" disabled>
      <i>{{ roleInfo }}</i>
    </gl-dropdown-item>
    <gl-dropdown-item
      v-if="isArqadeAdmin && allowRoleSwitch"
      v-b-modal.modal-set-role
      >Switch Role

      <b-modal
        title="Select role"
        id="modal-set-role"
        okVariant="danger"
        okTitle="Set"
        :ok-disabled="!selectedRole || selectedRole == currentRole"
        cancelTitle="Cancel"
        @ok="handleOkModalRole"
      >
        <b-form-radio-group
          v-model="selectedRole"
          :options="roles"
          name="radios-stacked"
          stacked
        ></b-form-radio-group>
      </b-modal>
    </gl-dropdown-item>

    <b-dropdown-divider></b-dropdown-divider>
    <gl-dropdown-item @click="logOut">Sign out</gl-dropdown-item>
  </gl-nav-item-dropdown>
</template>

<script>
import { GlDropdownItem, GlNavItemDropdown } from "@gitlab/ui";
import { ITEMS_ACCOUNT, MENU_OPTIONS } from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo";
import { organizationApiClient, userMgmtApiClient } from "@/mixins/apiClients";
import { mapState } from "vuex";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import utils from "@/mixins/utils";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const { SIGN_OUT } = MENU_OPTIONS;
const STORE = `auth`;

export default {
  components: {
    GlDropdownItem,
    GlNavItemDropdown,
    SmartSelect
  },
  mixins: [organizationApiClient, userInfoMixin, utils, userMgmtApiClient],
  computed: {
    membershipInfo() {
      if (this.orgInfos) {
        return `Member of ${this.orgInfos.map(org => org.shortName).join(",")}`;
      } else {
        return undefined;
      }
    },
    roleInfo() {
      if (this.isArqadeAdmin === false || this.allowRoleSwitch === false) {
        return null;
      }
      return "Current Role: " + this.currentRole;
    },
    ...mapState({
      userInfo: state => state[STORE].userInfo
    })
  },

  data() {
    const roles = [
      { text: "Regular User", value: "user" },
      { text: "Organisation Admin", value: "org_admin" },
      { text: "Arqade Admin", value: "arqade_admin" }
    ];

    const role = this.getCurrentRole();
    return {
      roles: roles,
      vue_env: process.env.VUE_APP_ENV,
      orgInfos: undefined,
      selectedOrganization: undefined,
      currentRole: role,
      selectedRole: this.currentRole
    };
  },

  mounted() {
    this.getOrgInfo();
    this.currentRole = this.getCurrentRole();
    this.selectedRole = this.currentRole;
  },
  methods: {
    async getOrgInfo() {
      if (this.getUserOrganizationId) {
        const org = await this.organizationApiClient.getById(
          this.getUserOrganizationId
        );
        this.orgInfos = [];
        this.orgInfos.push(org);
      }
    },
    getCurrentRole() {
      let role = "user";
      if (this.isOrgAdminRoleActive) {
        role = "org_admin";
      }
      if (this.isArqadeAdminRoleActive) {
        role = "arqade_admin";
      }
      return role;
    },

    logOut() {
      if (this.isMasquerading) {
        this.userMgmtApiClient.remove("me/masquerade-org");
        cookies.remove("arqade-org-id");
      }
      this.$store.commit(`${STORE}/userInfo`, {});
      this.$emit(`onClick`, SIGN_OUT);
    },
    organizationNameFormatter(row) {
      return `${row.shortName} (${row.legalName})`;
    },
    setOrganizationId(orgId) {
      this.selectedOrganization = orgId;
    },
    handleOkModalOrganization() {
      this.userMgmtApiClient
        .update("me/masquerade-org/" + this.selectedOrganization)
        .then(() => {
          this.handleMasqueradeNav();
        });
    },
    handleMasqueradeNav() {
      if (this.$route.path === "/") {
        this.$router.go();
        return;
      } else {
        this.$router.push("/");
        this.$router.go();
      }
    },
    handleOkModalRole() {
      if (this.currentRole === this.selectedRole) {
        return;
      }
      if (this.getRoleCookie) {
        this.userMgmtApiClient.remove("me/masquerade-role").then(() => {
          if (this.selectedRole === "arqade_admin") {
            this.handleMasqueradeNav();
          }
          this.switchRole();
        });
      } else {
        this.switchRole();
      }
    },

    switchRole() {
      this.userMgmtApiClient
        .update("me/masquerade-role/" + this.selectedRole)
        .then(() => {
          this.handleMasqueradeNav();
        })
        .catch(error => {
          this.selectedRole = this.currentRole;
          console.error(error.message);
        });
    },

    unsetOrganization() {
      this.userMgmtApiClient.remove("me/masquerade-org").then(() => {
        this.handleMasqueradeNav();
      });
    },
    redirectToAccountPreferences() {
      if (this.$route.path !== ITEMS_ACCOUNT.PREFERENCES) {
        this.$router.push(ITEMS_ACCOUNT.PREFERENCES);
      }
    }
  }
};
</script>
