var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v(" The pricing shown below includes totals from sources and contributions that have been previously validated. ")]),_c('gl-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitGenerateForm({
        quotedContributions: _vm.quotedContributions,
        packageId: _vm.packageId,
        billableStatus: _vm.billableStatus
      })}}},[_c('BillableStatus',{attrs:{"billableStatus":_vm.billableStatus},on:{"update-billing-status":_vm.updateBillableStatus}}),_c('p',{staticClass:"grand-total"},[_vm._v("Grand Total: "+_vm._s(_vm.formatPrice(_vm.totalPrice)))]),_c('Table',{attrs:{"items":_vm.quotedContributions,"loading":_vm.loading}},[_c('TableHead',{attrs:{"slot":"head"},slot:"head"}),_vm._l((_vm.quotedContributions),function(row){return _c('TableItem',{key:row.id,attrs:{"slot":"body","row":row,"generationError":_vm.getContributionGenerationError(row.id),"quoteError":_vm.getContributionQuoteError(row.id)},on:{"removeItem":_vm.removeItem},slot:"body"})}),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('p',[_vm._v("No contributions selected")]),_c('gl-button',{attrs:{"href":("#" + _vm.SHOW_PACKAGE + "/" + (this.packageId)),"variant":"confirm"}},[_vm._v("Back to Package page ")])],1)],2),(_vm.quotedContributions && _vm.quotedContributions.length > 0)?[(_vm.missingSomeQuotes)?_c('p',[_vm._v(" Some contributions cannot be quoted, they are "),_c('span',{staticClass:"missing-quote"},[_vm._v("indicated")]),_vm._v(" above. Please remove them from the list to be able to continue ")]):_vm._e(),(_vm.bulkContributions.hasInThePast)?_c('p',{staticClass:"invalid-text"},[_vm._v(" Some contributions are in the past, so you cannot deliver them together. Please remove them from the list. ")]):_vm._e(),(_vm.bulkContributions.hasOverlapping)?_c('p',{staticClass:"invalid-text"},[_vm._v(" Some contributions are overlapping, so you cannot deliver them together. Please remove an item that overlaps. ")]):_vm._e(),(_vm.bulkContributions.hasMixedRedundancies)?_c('p',{staticClass:"invalid-text"},[_vm._v(" You have a mix of single and multi source contributions. Currently we cannot bulk deliver when these are mixed. Please deliver single and multi contributions seperately. ")]):_vm._e(),_c('gl-form-checkbox',{attrs:{"id":"confirmed","required":"","disabled":_vm.loading ||
            _vm.missingSomeQuotes ||
            _vm.isSubmitting ||
            !this.bulkContributions.valid},model:{value:(_vm.confirmed),callback:function ($$v) {_vm.confirmed=$$v},expression:"confirmed"}},[_c('strong',[_vm._v("I confirm all the details are correct and the pricing shown above will be incurred as an Order per the agreement.")])])]:_vm._e(),_c('div',{staticClass:"buttons"},[_c('gl-button',{attrs:{"data-testid":"previous-button","type":"button"},on:{"click":function($event){return _vm.$emit('previous')}}},[_vm._v("Previous ")]),_c('gl-button',{attrs:{"type":"submit","variant":"confirm","disabled":_vm.loading ||
            _vm.missingSomeQuotes ||
            !_vm.confirmed ||
            _vm.isSubmitting ||
            !this.bulkContributions.valid}},[_vm._v(_vm._s(_vm.isSubmitting ? "Submitting" : "Submit")+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }