<template>
  <div>
    <b-row class="mt-4">
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="d-flex align-items-center">
                <div
                  class="opsPanel__organizations__status"
                  :class="{
                    'opsPanel__organizations__status--success':
                      selectedOrganization.health === 'OK',
                    'opsPanel__organizations__status--warning':
                      selectedOrganization.health === 'WARN',
                    'opsPanel__organizations__status--danger':
                      selectedOrganization.health === 'NOK'
                  }"
                ></div>
              </div>
              <img
                class="mr-3"
                v-bind:src="'data:image/jpeg;base64,' + organizationLogo"
                width="32"
                @error="fixOrganizationLogo()"
              />
              <div class="mt-2">
                <span class="cellTitle">{{
                  selectedOrganization.shortName
                }}</span>
              </div>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="d-flex align-items-center">
                <div
                  class="opsPanel__organizations__status"
                  :class="{
                    'opsPanel__organizations__status--success':
                      selectedContribution.source0_health === 'OK',
                    'opsPanel__organizations__status--warning':
                      selectedContribution.source0_health === 'WARN',
                    'opsPanel__organizations__status--danger':
                      selectedContribution.source0_health === 'NOK'
                  }"
                ></div>
                <div
                  v-if="selectedContribution.source1Id"
                  class="opsPanel__organizations__status"
                  :class="{
                    'opsPanel__organizations__status--success':
                      selectedContribution.source1_health === 'OK',
                    'opsPanel__organizations__status--warning':
                      selectedContribution.source1_health === 'WARN',
                    'opsPanel__organizations__status--danger':
                      selectedContribution.source1_health === 'NOK'
                  }"
                ></div>
              </div>
              <div class="mt-2">
                <b-badge class="contributionSingle__type">{{
                  selectedContribution.contributionType
                }}</b-badge>
                <span class="cellTitle">{{ selectedContribution.name }}</span>
              </div>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="mt-2">
              <span class="cellTitle">{{
                selectedDelivery.contributionName
              }}</span>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
    </b-row>
    <Charts
      :selectedContribution="selectedContribution"
      :selectedDelivery="selectedDelivery"
    />
  </div>
</template>

<script>
import { GlCard, GlButton } from "@gitlab/ui";
import { organizationApiClient } from "@/mixins/apiClients";
import { orgDefaultLogoData } from "@/constants.js";
import Charts from "@/components/OpsPanel/Charts.vue";

export default {
  name: "Metrics",
  mixins: [organizationApiClient],
  components: {
    GlButton,
    GlCard,
    Charts
  },
  props: {
    selectedOrganization: {
      type: Object
    },
    selectedContribution: {
      type: Object
    },
    selectedDelivery: {
      type: Object
    }
  },
  methods: {
    fixOrganizationLogo() {
      this.organizationLogo = orgDefaultLogoData;
    },
    changeMetric() {
      this.$emit(`changeMetric`);
    }
  },
  mounted() {
    this.getLogo(this.selectedOrganization.id).then(l => {
      this.organizationLogo = l;
    });
  },
  data() {
    return {
      organizationLogo: orgDefaultLogoData
    };
  }
};
</script>
<style>
.cellTitle {
  font-size: 16px;
  font-weight: bold;
}
.contributionSingle__type.badge {
  font-size: 11px;
  margin-right: 7px;
  padding: 3px 10px;
  font-weight: normal;
  border-radius: 12px;
}
</style>
