import Your from "@/views/Sources/Your.vue";
import Add from "@/views/Sources/Add.vue";
import Show from "@/views/Sources/Show.vue";
import {
  YourAll,
  Enabled,
  Disabled,
  Archived,
  AdminOnly
} from "@/components/Sources";
import { ITEMS_SOURCES, ALIAS_ITEMS_SOURCE } from "@/constants.js";

const { YOUR, ADD, EDIT, SHOW } = ITEMS_SOURCES;

const {
  ALL,
  ENABLED,
  DISABLED,
  ARCHIVED,
  ADMIN_ONLY,
  ADD_FIRST,
  ADD_SECOND,
  ADD_THIRD,
  ADD_FOURTH,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_THIRD,
  EDIT_FOURTH
} = ALIAS_ITEMS_SOURCE;

const HOST = `Sources`;

const sources = [
  {
    path: YOUR,
    name: `${HOST}Your`,
    redirect: ALL,
    component: Your,
    children: [
      {
        path: ALL,
        component: YourAll
      },
      {
        path: ENABLED,
        component: Enabled
      },
      {
        path: DISABLED,
        component: Disabled
      },
      {
        path: ARCHIVED,
        component: Archived
      },
      {
        path: ADMIN_ONLY,
        component: AdminOnly
      }
    ]
  },
  {
    path: ADD,
    name: `${HOST}CreateADD`,
    redirect: ADD_FIRST
  },
  {
    path: ADD_FIRST,
    name: `${HOST}Create`,
    component: Add,
    alias: [ADD_SECOND, ADD_THIRD, ADD_FOURTH]
  },

  {
    path: `${EDIT}/:id`,
    name: `${HOST}Edit`,
    component: Add,
    alias: [
      `${EDIT}/:id/${EDIT_FIRST}`,
      `${EDIT}/:id/${EDIT_SECOND}`,
      `${EDIT}/:id/${EDIT_THIRD}`,
      `${EDIT}/:id/${EDIT_FOURTH}`
    ]
  },
  {
    path: `${SHOW}/:id`,
    name: `${HOST}Show`,
    component: Show
  }
];

export default sources;
