<template>
  <tr>
    <td colspan="20">
      <div class="no_results">
        <slot><p>No results</p></slot>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: "TableEmpty"
};
</script>
<style lang="scss" scoped>
.no_results {
  text-align: center;
  padding: 1.4rem;
  margin: 0;
}
</style>
