<template>
  <div class="mr-3 mb-1">
    <gl-icon :name="icon" />
  </div>
</template>

<script>
export default {
  name: "SidebarMenuIcon",
  props: {
    icon: {
      type: [String],
      default: "label"
    }
  }
};
</script>
