<template>
  <gl-button-group class="mr-2">
    <gl-button
      data-testid="contribution_type--all"
      :variant="searchFields.contributionType ? 'default' : 'confirm'"
      @click="updateSearchParams('contributionType', null)"
      >All</gl-button
    >
    <gl-button
      data-testid="contribution_type--channel"
      :variant="
        searchFields.contributionType === 'channel_contribution'
          ? 'confirm'
          : 'default'
      "
      @click="updateSearchParams('contributionType', 'channel_contribution')"
      >Channel</gl-button
    >
    <gl-button
      data-testid="contribution_type--event"
      :variant="
        searchFields.contributionType === 'event_contribution'
          ? 'confirm'
          : 'default'
      "
      @click="updateSearchParams('contributionType', 'event_contribution')"
      >Event</gl-button
    >
  </gl-button-group>
</template>
<script>
import { GlButton, GlButtonGroup } from "@gitlab/ui";

export default {
  name: "DeliveryActions",
  components: {
    GlButton,
    GlButtonGroup
  },
  props: {
    searchFields: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    updateSearchParams(column, value) {
      this.$emit("changeSearchParams", {
        ...this.searchFields,
        [column]: value
      });
    }
  }
};
</script>
