<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <gl-form-group
      class="m-4"
      label="Stream ID"
      label-size="sm"
      label-for="streamId"
    >
      <smart-select
        :dataSource="virtualConnectStreamApiClient"
        :filters="virtualConnectStreamFilters"
        :textFormatter="streamIdEvaler"
        :selected="currentItem.streamId"
        keyField="id"
        :showSelected="false"
        :disabledItemCondition="virtualConnectStreamDisabledItemCondition"
        @itemSelected="setStreamId($event)"
      >
      </smart-select>
      <p class="mt-1">
        Arqade Admin can add new streams by editing the organization
      </p>
    </gl-form-group>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { virtualConnectStreamApiClient } from "@/mixins/apiClients";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import { GlFormGroup } from "@gitlab/ui";
import userInfo from "@/mixins/userInfo.js";

export default {
  components: { GlFormGroup, RegionComponent, SmartSelect },
  mixins: [virtualConnectStreamApiClient, userInfo],
  created() {
    const orgId = this.getUserOrganizationId;
    if (orgId) {
      this.virtualConnectStreamFilters["organisationId"] = orgId;
    }
  },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    isDestinationValid() {
      switch (true) {
        case !this.isRegionValid:
        case !this.currentItem.streamId:
          return false;
        default:
          return true;
      }
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    setStreamId(streamId) {
      this.currentItem.streamId = streamId;
    },
    virtualConnectStreamDisabledItemCondition(item) {
      return (
        !!item.destinationId &&
        (!this.currentItem.id || item.destinationId !== this.currentItem.id)
      );
    }
  },

  data() {
    return {
      isRegionValid: false,
      virtualConnectStreamFilters: {}
    };
  },
  props: {
    currentItem: Destination
  },
  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
