<template>
  <div class="mt-3">
    <hr />
    <p>
      Email:
      <a
        :href="
          'mailto:arqiva.events@arqiva.com?subject=Arqade Support Request&body=User e-mail: ' +
            (userInfo.attributes ? userInfo.attributes.email : '') +
            '%0D%0A' +
            'User role: ' +
            userInfo.role +
            '%0D%0A' +
            'Organization: ' +
            organization
        "
        class="color-primary"
        title="Write to us"
        >arqiva.events@arqiva.com</a
      >
    </p>
    <p class="mt-3">
      Tel:
      <a href="tel:+44 333 032 3210" class="color-primary" title="Call us"
        >+44 333 032 3210</a
      >
    </p>
    <p>
      <i>
        The system features virtual assistant technology and you'll be asked to
        say a phrase to be routed to the right team, for example "Crawley MCR"
        or "IT Service Desk". In an emergency, say the phrase "Crawley MCR".</i
      >
    </p>
  </div>
</template>
<script>
import { ITEMS_GUIDES } from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo";
import { organizationApiClient } from "@/mixins/apiClients";

export default {
  name: "SupportHelp",
  mixins: [userInfoMixin, organizationApiClient],
  methods: {},
  created() {
    this.organizationApiClient
      .getById(this.userInfo.orgId)
      .then(org => (this.organization = org.legalName));
  },
  data() {
    return {
      FAQ: `#${ITEMS_GUIDES.FAQ}`,
      organization: undefined
    };
  }
};
</script>
