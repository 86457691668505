export const helperIsInPast = contribution => {
  const end = new Date(contribution.plannedEnd);
  return end < new Date();
};
export const helperIsOverlapping = (index, contribution, contributions) => {
  return contributions.some((otherConributions, i) => {
    if (i === index) return false;
    const end = new Date(contribution.plannedEnd);
    const start = new Date(contribution.plannedStart);
    const otherStart = new Date(otherConributions.plannedStart);
    const otherEnd = new Date(otherConributions.plannedEnd);

    return (
      (start >= otherStart && start <= otherEnd) ||
      (start <= otherStart && end >= otherStart)
    );
  });
};
export const helperHasMixedRedundancies = contributions => {
  const hasSingleSources = contributions.some(item => item.source1Id === null);
  const hasMultiSources = contributions.some(item => item.source1Id !== null);
  return hasSingleSources && hasMultiSources;
};

const packageBulkContributions = {
  namespaced: true,
  state: {
    package: {}
  },
  mutations: {
    addContributions(state, { packageId, contributions }) {
      const currentList = state.package[packageId] ?? [];
      const filteredContributions = [...contributions].filter(
        newItem => !currentList.some(item => item.id === newItem.id)
      );
      const newPackages = { ...state.package };
      newPackages[packageId] = [...currentList, ...filteredContributions];
      state.package = newPackages;
    },
    removeContribution(state, { packageId, contributionId }) {
      state.package[packageId].splice(
        state.package[packageId].findIndex(i => i.id === contributionId),
        1
      );
    },
    clearContributions(state, packageId) {
      const newPackages = { ...state.package };
      delete newPackages[packageId];
      state.package = newPackages;
    }
  },

  getters: {
    contributions: state => packageId => {
      return state.package[packageId];
    },
    validatedContributions: state => packageId => {
      const contributions = state.package[packageId];
      if (!contributions)
        return {
          contributions: []
        };

      let isValid = true;
      let hasOverlapping = false;
      let hasInThePast = false;
      const validated = contributions.map((contribution, index) => {
        const isInPast = helperIsInPast(contribution);
        const isOverlapping = helperIsOverlapping(
          index,
          contribution,
          contributions
        );
        if (isValid && (isOverlapping || isInPast)) isValid = false;
        if (!hasOverlapping && isOverlapping) hasOverlapping = true;
        if (!hasInThePast && isInPast) hasInThePast = true;

        return {
          _validation: {
            isOverlapping,
            isInPast
          },
          ...contribution
        };
      });

      const hasMixedRedundancies = helperHasMixedRedundancies(contributions);
      if (hasMixedRedundancies) {
        isValid = false;
      }

      validated.sort(function(a, b) {
        return new Date(a.plannedStart) - new Date(b.plannedStart);
      });

      return {
        valid: isValid,
        hasOverlapping,
        hasInThePast,
        hasMixedRedundancies,
        contributions: validated
      };
    }
  }
};

export default packageBulkContributions;
