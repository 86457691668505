<template>
  <div>
    <gl-button
      variant="confirm"
      class="mr-3"
      :href="toCreate"
      data-testid="create_new_contribution"
    >
      Create contribution
    </gl-button>
    <router-link
      :to="toImport"
      v-if="showImport()"
      data-testid="contribution_import_button"
    >
      <gl-button variant="confirm" class="mr-3">
        Import CSV
      </gl-button>
    </router-link>
  </div>
</template>
<script>
import { GlButton } from "@gitlab/ui";
import { ALIAS_ITEMS_CONTRIBUTION } from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo";
const { ADD_FIRST } = ALIAS_ITEMS_CONTRIBUTION;

export default {
  name: "ContributionActions",
  mixins: [userInfoMixin],
  components: {
    GlButton
  },
  data() {
    return {
      toImport: { name: "event-import" },
      toCreate: `#${ADD_FIRST}`
    };
  },
  methods: {
    showImport() {
      const path = this.$route.path;
      if (!path.includes("/events")) {
        return false;
      }
      return this.isOrgAdminRoleActive || this.isArqadeAdminRoleActive;
    }
  }
};
</script>
