<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <Bandwidth />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Bandwidth from "@/components/Report/Bandwidth";
import { MAIN_ITEMS, ITEMS_REPORT } from "@/constants.js";
const { BANDWIDTH } = ITEMS_REPORT;

export default {
  components: { Bandwidth, Breadcrumb },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Bandwidth`, href: BANDWIDTH }
      ];
    }
  }
};
</script>
