<template>
  <div>
    <gl-button
      v-for="(item, index) in this.buttons"
      :key="index"
      class="mr-3 mb-3"
      @click="item.onClick ? item.onClick() : emptyFunc()"
      :disabled="item.disabled"
      >{{ item.text }}</gl-button
    >
    <hr />
    <p class="d-flex align-items-center createDelivery__lid mt-4 mb-5">
      <gl-icon name="check" class="mr-2 createDelivery__iconCheck" :size="32" />
      <span v-if="!destination0">
        <gl-loading-icon label="Loading" />
      </span>
      <span v-else>
        The <strong>{{ contribution.name }}</strong> is now Delivered to
        <strong
          >{{ destination0 ? destination0.name : "" }}
          {{ destination1 ? ` and ${destination1.name}` : "" }}</strong
        >.
      </span>
    </p>
  </div>
</template>
<script>
import { GlButton, GlIcon, GlLoadingIcon } from "@gitlab/ui";
import { destinationApiClient } from "@/mixins/apiClients";
import { ITEMS_CONTRIBUTIONS, ITEMS_DESTINATIONS } from "@/constants.js";

export default {
  mixins: [destinationApiClient],
  components: { GlButton, GlIcon, GlLoadingIcon },
  name: "Complete",
  data() {
    return {
      destination0: undefined,
      destination1: undefined
    };
  },
  props: {
    destinationId0: {
      type: String,
      default: null
    },
    destinationId1: {
      type: String,
      default: null
    },
    contribution: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: `Change Contribution`,
          onClick: () => {
            this.$router.push(
              `${ITEMS_CONTRIBUTIONS.EDIT}/${this.contributionId}`
            );
          }
        },
        {
          text: `Edit first destination`,
          onClick: () => {
            this.$router.push(
              `${ITEMS_DESTINATIONS.EDIT}/${this.destinationId0}`
            );
          }
        },
        {
          text: `Edit second destination`,
          onClick: () => {
            this.$router.push(
              `${ITEMS_DESTINATIONS.EDIT}/${this.destinationId1}`
            );
          },
          disabled: !this.destinationId1
        }
      ];
    }
  },
  created() {
    if (this.destinationId0) {
      this.destinationApiClient
        .getById(this.destinationId0)
        .then(destination => {
          this.destination0 = destination;
        })
        .catch(e => this.handleApiError(e));
    }
    if (this.destinationId1) {
      this.destinationApiClient
        .getById(this.destinationId1)
        .then(destination => {
          this.destination1 = destination;
        })
        .catch(e => this.handleApiError(e));
    }
  }
};
</script>
<style scoped>
.createDelivery__iconCheck {
  color: var(--theme-color);
}
.createDelivery__lid {
  width: 600px;
  max-width: 100%;
}
</style>
