<template>
  <div>
    <div class="box-row">
      <div class="info-box px-3 pt-7" v-for="item in topBoxes" :key="item.id">
        <p class="number">
          <span v-if="isLoading">-</span
          ><span v-else>{{ topBoxesById[item.id].number }}</span>
        </p>
        <p class="desc pt-2">
          {{ topBoxesById[item.id].text }}
        </p>
      </div>
    </div>
    <gl-card class="mt-3">
      <h2 class="smaller mb-3">Network Overview</h2>
      <b-row
        class="position-relative"
        :class="{ 'dashboard-row-with-destinations': showDestinations }"
      >
        <b-col v-show="showDestinations" class="dashboard-column-prev"
          ><div
            class="d-flex justify-content-center align-items-center text-nowrap dashboard-text-rotate-left h-100"
          >
            <gl-button
              @click.prevent.stop="showDestinations = false"
              icon="chevron-lg-up"
            >
            </gl-button>
            <h4 class="ml-3">My Packages</h4>
          </div>
        </b-col>
        <b-col cols="4" v-show="!showDestinations"
          ><gl-card><Packages @rowSelected="onRowSelectedPackage"/></gl-card
        ></b-col>
        <b-col cols="4"
          ><gl-card
            ><Contributions
              @rowSelected="onRowSelectedContribution"
              :selectedPackage="selectedPackage"/></gl-card
        ></b-col>
        <b-col cols="4"
          ><gl-card
            ><Deliveries
              v-show="selectedPackage"
              @rowSelected="onRowSelectedDelivery"
              :selectedContribution="selectedContribution"/></gl-card
        ></b-col>
        <b-col cols="4" v-show="showDestinations"
          ><gl-card
            ><Destinations :selectedDelivery="selectedDelivery"/></gl-card
        ></b-col>
      </b-row>
    </gl-card>
    <gl-card class="mt-3">
      <b-row>
        <b-col><h2 class="smaller">Audit summary</h2></b-col>
        <b-col> </b-col>
        <b-col class="text-right">
          <gl-button-group class="mr-2">
            <gl-button :href="logsHref" v-if="isAdmin"
              ><gl-icon name="symlink"
            /></gl-button>
          </gl-button-group>
        </b-col>
      </b-row>
      <div class="limited-h-scrollable mt-2">
        <ArqDataGrid
          :data="logData"
          :columns="logColumns"
          :showPager="false"
          :selectable="false"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'created'">
              <span class="f12">{{ toLocalDate(r.created) }}</span
              ><br />
              <span>{{ toLocalTime(r.created) }}</span>
            </div>
            <div v-else-if="field === 'operation'">
              <StatusCell :status="r.operation" class="l" :showIcon="false" />
            </div>
            <div v-else>
              <span class="f12">{{ r[field] }} </span>
            </div>
          </template>
        </ArqDataGrid>
      </div>
    </gl-card>

    <b-row>
      <b-col>
        <gl-card class="mt-3">
          <b-row>
            <b-col><h2 class="smaller">Contribution Schedule</h2></b-col>
            <b-col class="text-center"> </b-col>
            <b-col class="text-right">
              <gl-button-group class="mr-2">
                <gl-button :href="contributionsHref"
                  ><gl-icon name="symlink"
                /></gl-button>
              </gl-button-group>
            </b-col>
          </b-row>
          <div class="limited-h-scrollable mt-2">
            <ArqDataGrid
              :data="contributionData"
              :columns="contributionColumns"
              :showPager="false"
              :selectable="false"
            >
              <template v-slot="{ row: r, field }">
                <div v-if="field === 'plannedStart'">
                  <span class="f12">{{ toLocalDate(r.plannedStart) }}</span
                  ><br />
                  <span>{{ toLocalTime(r.plannedStart) }}</span>
                </div>
                <div v-if="field === 'name'">
                  <span class="cellTitle">{{ r.name }}</span
                  ><br />
                  <span>{{ r.contributionType }}</span>
                </div>
                <div v-else-if="field === 'status'">
                  <StatusCell
                    v-if="
                      (r.currentAlerts0 && r.currentAlerts0.length > 0) ||
                        (r.currentAlerts1 && r.currentAlerts1.length > 0)
                    "
                    status="alert"
                    class="l"
                  />
                  <StatusCell v-else :status="r.status" class="l" />
                </div>
              </template>
            </ArqDataGrid>
          </div>
        </gl-card>
      </b-col>
      <b-col
        ><gl-card class="mt-3">
          <b-row>
            <b-col><h2 class="smaller">Delivery Schedule</h2></b-col>
            <b-col class="text-center"> </b-col>
            <b-col class="text-right">
              <gl-button-group class="mr-2">
                <gl-button :href="deliveryHref"
                  ><gl-icon name="symlink"
                /></gl-button>
              </gl-button-group>
            </b-col>
          </b-row>
          <div class="limited-h-scrollable mt-2">
            <ArqDataGrid
              :data="deliveryData"
              :columns="deliveryColumns"
              :showPager="false"
              :selectable="false"
            >
              <template v-slot="{ row: r, field }">
                <div v-if="field === 'contributionPlannedStart'">
                  <span class="f12">{{
                    toLocalDate(r.contributionPlannedStart)
                  }}</span
                  ><br />
                  <span>{{ toLocalTime(r.contributionPlannedStart) }}</span>
                </div>
                <div v-if="field === 'contributionName'">
                  <span class="cellTitle">{{ r.contributionName }}</span
                  ><br />
                  <span>{{ r.contributionType }}</span>
                </div>
                <div v-else-if="field === 'state'">
                  <StatusCell
                    v-if="
                      (r.currentAlerts0 && r.currentAlerts0.length > 0) ||
                        (r.currentAlerts1 && r.currentAlerts1.length > 0)
                    "
                    status="alert"
                    class="l"
                  />
                  <StatusCell v-else :status="r.state" class="l" />
                </div>
              </template>
            </ArqDataGrid>
          </div>
        </gl-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AppConfig from "@/app-config";

const topBoxes = [
  {
    id: "enabled_sources",
    text: "Active Sources",
    number: 0
  },
  {
    id: "live_contributions",
    text: "Live Contributions",
    number: 0
  },
  {
    id: "active_packages",
    text: "Active Packages",
    number: 0
  },
  {
    id: "live_deliveries",
    text: "Live Deliveries",
    number: 0
  }
];

const topBoxesById = {};
topBoxes.forEach(box => (topBoxesById[box.id] = box));

import { BCol, BRow } from "bootstrap-vue";
import { GlCard, GlButton, GlButtonGroup, GlIcon } from "@gitlab/ui";
import {
  ITEMS_AUDIT,
  ALIAS_ITEMS_CONTRIBUTION,
  ITEMS_DELIVERY
} from "@/constants.js";
import {
  auditApiClient,
  contributionApiClient,
  packageApiClient,
  deliveryApiClient,
  sourceApiClient
} from "@/mixins/apiClients";
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import {
  Packages,
  Contributions,
  Deliveries,
  Destinations
} from "@/components/Dashboard";

export default {
  components: {
    GlCard,
    GlButton,
    GlButtonGroup,
    ArqDataGrid,
    StatusCell,
    Packages,
    Contributions,
    Deliveries,
    Destinations,
    BCol,
    BRow,
    GlIcon
  },
  mixins: [
    auditApiClient,
    dateUtils,
    contributionApiClient,
    deliveryApiClient,
    packageApiClient,
    sourceApiClient
  ],
  name: "Dashboard",
  computed: {
    logsHref() {
      return `#${ITEMS_AUDIT.LOGS}`;
    },
    contributionsHref() {
      return `#${ALIAS_ITEMS_CONTRIBUTION.EVENTS_ALL}`;
    },
    deliveryHref() {
      return `#${ITEMS_DELIVERY.ALL}`;
    },
    isAdmin() {
      return (
        (this.isArqadeAdminRoleActive ||
          this.isOrgAdminRoleActive ||
          this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    }
  },
  methods: {
    onRowSelectedPackage(row) {
      if (this.selectedPackage != row) {
        this.selectedContribution = undefined;
        this.selectedDelivery = undefined;
      }
      this.selectedPackage = row;
    },
    onRowSelectedContribution(row) {
      if (this.selectedContribution != row) {
        this.selectedDelivery = undefined;
      }
      this.selectedContribution = row;
    },
    onRowSelectedDelivery(row) {
      this.selectedDelivery = row;
      this.showDestinations = !!this.selectedDelivery;
    }
  },
  data() {
    return {
      isLoading: true,
      selectedPackage: undefined,
      selectedContribution: undefined,
      selectedDelivery: undefined,
      showDestinations: false,
      topBoxes,
      topBoxesById,
      logData: this.getEmptyPagedResponse(),
      contributionData: this.getEmptyPagedResponse(),
      deliveryData: this.getEmptyPagedResponse(),
      contributionColumns: [
        {
          text: "Start date",
          field: "plannedStart",
          defaultSort: true
        },
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Status",
          field: "status"
        }
      ],
      deliveryColumns: [
        {
          text: "Start date",
          field: "contributionPlannedStart",
          defaultSort: true
        },
        {
          text: "Name",
          field: "contributionName"
        },
        {
          text: "Status",
          field: "state"
        }
      ],
      logColumns: [
        {
          text: "Date",
          field: "created",
          isSortable: true,
          defaultSort: true
        },
        {
          text: "Type",
          field: "resourceType"
        },
        {
          text: "Target",
          field: "target"
        },
        {
          text: "User",
          field: "userEmail"
        },
        {
          text: "Action",
          field: "operation"
        }
      ]
    };
  },
  created() {},
  mounted() {
    const promises = [
      this.contributionApiClient
        .list()
        .param("deliveryStatus", "current")
        .param("deliveryStatus", "pending")
        .perPage(1)
        .exec(),
      this.deliveryApiClient
        .list()
        .param("states", "PENDING")
        .param("states", "IN_PROGRESS")
        .param("scheduleStatus", "SCHEDULED")
        .perPage(1)
        .exec(),
      this.packageApiClient
        .list()
        .perPage(1)
        .exec(),
      this.sourceApiClient
        .list()
        .perPage(10)
        .sortBy("created", "desc")
        .param("status", "ENABLED")
        .exec(),
      this.auditApiClient
        .list()
        .page(0)
        .perPage(10)
        .sortBy("created", "desc")
        .exec(),
      this.contributionApiClient
        .list()
        .page(0)
        .perPage(10)
        .param("deliveryStatus", "current")
        .param("deliveryStatus", "pending")
        .sortBy("plannedStart", "desc")
        .exec(),
      this.deliveryApiClient
        .list()
        .page(0)
        .perPage(10)
        .param("states", "PENDING")
        .param("states", "IN_PROGRESS")
        .param("scheduleStatus", "SCHEDULED")
        .sortBy("contributionPlannedStart", "desc")
        .exec()
    ];
    Promise.all(promises)
      .then(results => {
        topBoxesById["live_contributions"].number = results[0].totalItems;
        topBoxesById["live_deliveries"].number = results[1].totalItems;
        topBoxesById["active_packages"].number = results[2].totalItems;
        topBoxesById["enabled_sources"].number = results[3].totalItems;
        this.logData = results[4];
        this.contributionData = results[5];
        this.deliveryData = results[6];
        this.isLoading = false;
      })
      .catch(e => {
        this.handleApiError(e);
      });
  }
};
</script>

<style scoped>
h4 {
  color: var(--theme-color);
}
.box-row {
  display: flex;
  justify-content: space-between;
}
.info-box {
  max-width: 240px;
  height: 128px;
  border-radius: 8px;
  background-color: #f0f0f0;
}
.info-box .number {
  font-weight: bold;
  color: #999999;
  font-size: 45px;
}
.info-box .desc {
  font-weight: 600;
  color: #1f1f1f;
  font-size: 19px;
}
h2.smaller {
  font-size: 19px;
}
.f12 {
  font-size: 12px;
}
.limited-h-scrollable {
  max-height: 260px;
  overflow-y: scroll;
}
.dashboard-column-prev {
  width: 50px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 20px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.2);
}
.dashboard-text-rotate-left {
  transform: rotate(-90deg);
}
.dashboard-row-with-destinations {
  padding-left: 50px;
}
</style>
