import YourView from "@/views/StreamProfile/Your";
import AddView from "@/views/StreamProfile/Add";
import { MAIN_ITEMS, ITEMS_STREAM_PROFILE } from "@/constants.js";
import { Your, Add } from "@/components/StreamProfile";

const { STREAM_PROFILE } = MAIN_ITEMS;
const { YOUR, ADD } = ITEMS_STREAM_PROFILE;

const streamProfile = [
  {
    path: STREAM_PROFILE,
    name: "StreamProfileYour",
    component: YourView,
    redirect: YOUR,
    children: [
      {
        path: YOUR,
        component: Your
      }
    ]
  },
  {
    path: ADD,
    name: "StreamProfileAdd",
    component: AddView,
    children: [
      {
        path: ADD,
        component: Add
      }
    ]
  }
];

export default streamProfile;
