<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <IpProfile
      prefix="rist_"
      @change="onIpProfileChange"
      :portValue="currentItem.port"
      :ipTypeValue="'rist'"
      :ipAddressValue="currentItem.ipAddress"
      :showIpAddress="true"
      :showIpType="false"
    />
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import IpProfile from "@/components/IpProfile.vue";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";

export default {
  components: { RegionComponent, IpProfile },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    isDestinationValid() {
      switch (false) {
        case this.isIpProfileValid:
        case this.isRegionValid:
          return false;
        default:
          return true;
      }
    },
    onIpProfileChange(ipDetails) {
      const { port, type, address, isValid } = ipDetails;
      this.isIpProfileValid = isValid;
      this.currentItem.port = port;
      this.currentItem.ipAddress = address;
      this.currentItem.ipDestinationType = type;
      this.$emit("change", this.isDestinationValid());
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      isIpProfileValid: false,
      isRegionValid: false
    };
  },
  props: {
    currentItem: Destination
  }
};
</script>
