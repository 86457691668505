<template>
  <your-all status="ARCHIVED"></your-all>
</template>
<script>
import YourAll from "./YourAll.vue";
export default {
  components: { YourAll },
  name: "Archived"
};
</script>
