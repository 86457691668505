import { mapState } from "vuex";
import AppConfig from "@/app-config";
import Cookies from "universal-cookie";
import { organizationApiClient } from "@/mixins/apiClients";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const STORE = `auth`;

const cookies = new Cookies();

const roleCookieName = "arqade-role";

export default {
  mixins: [organizationApiClient],
  computed: {
    ...mapState({
      userInfo: state => state[STORE].userInfo
    }),
    isMasquerading() {
      return !!cookies.get("arqade-org-id");
    },
    allowRoleSwitch() {
      return AppConfig.instance.config.options.features.allowRoleSwitching;
    },
    getRoleCookie() {
      return cookies.get(roleCookieName);
    },
    isMasqueradingRole() {
      return !!(
        this.isArqadeAdmin &&
        (this.isOrgAdminRoleActive || this.isUserRoleActive)
      );
    },
    isArqadeAdminRoleActive() {
      const roleCookie = this.getRoleCookie;
      return !!(!roleCookie && this.isArqadeAdmin);
    },
    isOrgAdminRoleActive() {
      if (this.isMasquerading) {
        return true;
      }
      const roleCookie = this.getRoleCookie;
      if (!roleCookie) {
        return (
          this.userInfo.role === AppConfig.instance.config.options.orgAdmin
        );
      }
      return roleCookie === "org_admin";
    },

    isUserRoleActive() {
      const roleCookie = this.getRoleCookie;
      if (!roleCookie && !this.isOrgAdmin && !this.isArqadeAdmin) {
        return true;
      }
      return roleCookie === "user";
    },

    isArqadeAdmin() {
      return (
        !this.isMasquerading &&
        this.userInfo.role === AppConfig.instance.config.options.arqadeAdmin
      );
    },

    isOrgAdmin() {
      return (
        this.userInfo.role === AppConfig.instance.config.options.orgAdmin ||
        this.userInfo.role === AppConfig.instance.config.options.arqadeAdmin
      );
    },
    getUserOrganizationId() {
      return this.isMasquerading
        ? cookies.get("arqade-org-id")
        : this.userInfo.orgId;
    }
  },
  methods: {
    async getUserOrganization() {
      return this.userInfo.orgId
        ? await this.organizationApiClient.getById(this.userInfo.orgId)
        : undefined;
    },
    getOptTimezones() {
      let timezones = [];
      if (this.userInfo.optTimezone1) {
        timezones.push(this.userInfo.optTimezone1);
      }
      if (this.userInfo.optTimezone2) {
        timezones.push(this.userInfo.optTimezone2);
      }
      return timezones;
    },
    getDefaultTimezone() {
      return this.userInfo.favTimezone ?? dayjs.tz.guess();
    }
  }
};
