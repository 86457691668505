<template>
  <div class="mt-3" v-if="user">
    <hr />
    <gl-toggle
      id="subscribed"
      v-model="user.subscribed"
      :disabled="isBusy"
      label="Subscribed to emails"
    ></gl-toggle>
    <hr />
    <TimezonesComponent :user="user"></TimezonesComponent>
  </div>
</template>
<script>
import { GlToggle } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";
import { userMgmtApiClient } from "@/mixins/apiClients";
import TimezonesComponent from "@/components/Account/Timezones.vue";

export default {
  name: "AccountPreferences",
  mixins: [userInfoMixin, userMgmtApiClient],
  components: {
    TimezonesComponent,
    GlToggle
  },
  created() {
    this.userMgmtApiClient
      .getById(this.userInfo.attributes.email)
      .then(user => {
        this.user = user;
        this.isBusy = false;
      })
      .catch(e => this.handleApiError(e));
  },
  data() {
    return {
      isBusy: true,
      user: undefined
    };
  },
  watch: {
    subscribed: {
      handler(_, oldValue) {
        if (oldValue != undefined) {
          this.isBusy = true;
          this.userMgmtApiClient
            .patch(
              this.user.id +
                (this.user.subscribed ? "/subscribe" : "/unsubscribe")
            )
            .then(() => {
              this.handleApiSuccess(
                this.user.subscribed
                  ? `You have been subscribed to emails`
                  : `You have been unsubscribed from emails`
              );
              this.isBusy = false;
            })
            .catch(e => this.handleApiError(e));
        }
      }
    }
  }
};
</script>
