<template>
  <div>
    <gl-form-group label="Name" label-size="sm" label-for="profileName">
      <gl-form-input
        id="profileName"
        type="text"
        v-model="currentStreamProfile.profileName"
        placeholder="Name"
        maxlength="255"
        required
      />
    </gl-form-group>

    <gl-form-group label="Codec" label-size="sm" label-for="codec">
      <gl-form-select
        id="codec"
        v-model="currentStreamProfile.codec"
        :options="codecOptions"
        class="mt-1"
      />
    </gl-form-group>

    <b-row>
      <b-col>
        <gl-form-group
          label="Framerate Numerator"
          label-size="sm"
          label-for="fr-numerator"
        >
          <gl-form-input
            id="fr-numerator"
            type="number"
            min="1"
            step="1"
            v-model.number="currentStreamProfile.framerateNumerator"
            placeholder="framerateNumerator"
            maxlength="2"
            required
          />
        </gl-form-group>
      </b-col>
      <b-col>
        <gl-form-group
          label="Framerate Denominator"
          label-size="sm"
          label-for="fr-denominator"
        >
          <gl-form-input
            id="fr-denominator"
            type="number"
            min="1"
            step="1"
            v-model.number="currentStreamProfile.framerateDenominator"
            placeholder="framerateDenominator"
            maxlength="2"
            required
          />
        </gl-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <gl-form-group
          label="Width"
          label-size="sm"
          label-for="width"
          :invalid-feedback="invalidWidthFeedback"
        >
          <gl-form-input
            id="width"
            type="number"
            step="2"
            v-model.number="currentStreamProfile.width"
            placeholder="width"
            maxlength="4"
            :state="isValidWidth"
            required
          />
        </gl-form-group>
      </b-col>
      <b-col>
        <gl-form-group
          label="Height"
          label-size="sm"
          label-for="height"
          :invalid-feedback="invalidHeightFeedback"
        >
          <gl-form-input
            id="height"
            type="number"
            step="2"
            v-model.number="currentStreamProfile.height"
            placeholder="height"
            :state="isValidHeight"
            maxlength="4"
            required
          />
        </gl-form-group>
      </b-col>
    </b-row>

    <gl-form-group label="Scan Type" label-size="sm" label-for="scanType">
      <gl-form-select
        id="scanType"
        v-model="currentStreamProfile.scanType"
        :options="scanTypeOptions"
        class="mt-1"
      />
    </gl-form-group>

    <gl-form-group
      label="Bitrate (bps)"
      label-size="sm"
      label-for="bitrate"
      :invalid-feedback="invalidBitrateFeedback"
    >
      <gl-form-input
        id="bitrate"
        type="number"
        v-model.number="currentStreamProfile.bitrateBps"
        placeholder="bitrate"
        min="100000"
        :max="currentStreamProfile.codec === 'avc' ? 100000000 : 40000000"
        maxlength="9"
        :state="isValidBitrate"
        required
      />
    </gl-form-group>

    <gl-form-group
      label="GOP (Frames)"
      label-size="sm"
      label-for="gopFrames"
      :invalid-feedback="invalidGopFramesFeedback"
    >
      <gl-form-input
        id="gopFrames"
        type="number"
        v-model.number="currentStreamProfile.gopFrames"
        placeholder="unspecified"
        min="1"
        max="180"
        step="1"
        :state="isValidGopFrames"
      />
    </gl-form-group>
    <gl-form-group
      label="Colorspace"
      label-size="sm"
      label-for="colourSpace"
      :invalid-feedback="invalidColourSpaceFeedback"
    >
      <gl-form-select
        id="colourSpace"
        v-model="currentStreamProfile.colourSpace"
        :options="colourSpaceOptions"
        class="mt-1"
      />
    </gl-form-group>
    <gl-form-group
      label="Rate Control Mode"
      label-size="sm"
      label-for="rateControlMode"
    >
      <gl-form-select
        id="rateControlMode"
        v-model="currentStreamProfile.rateControlMode"
        :options="rateControlModeOptions"
        class="mt-1"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormSelect, GlFormInput } from "@gitlab/ui";
import {
  CODECS,
  FRAMERATES,
  COLOUR_SPACES,
  RATE_CONTROL_MODES,
  SCAN_TYPES
} from "@/constants.js";
import { BCol, BRow } from "bootstrap-vue";

export default {
  name: "StreamProfile",
  components: { GlFormGroup, GlFormSelect, GlFormInput, BCol, BRow },
  props: {
    streamProfile: {
      type: Object,
      default: () => {
        return {
          profileName: "",
          codec: CODECS[0].value,
          framerateNumerator: FRAMERATES[0].value,
          bitrateBps: 100000,
          framerateDenominator: 1,
          width: 1280,
          height: 720,
          gopFrames: null,
          colourSpace: COLOUR_SPACES[0].value,
          rateControlMode: RATE_CONTROL_MODES[0].value,
          scanType: SCAN_TYPES[0].value
        };
      }
    },
    disabledCodecs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    colourSpaceOptions() {
      return this.currentStreamProfile.codec === "hevc"
        ? COLOUR_SPACES.filter(
            item =>
              item.value === "HLG_2020" ||
              item.value === "HDR10" ||
              item.value === null
          )
        : COLOUR_SPACES;
    },
    isValidName() {
      return !!this.currentStreamProfile.profileName;
    },
    isValidWidth() {
      return (
        this.currentStreamProfile.width >= 2 &&
        this.currentStreamProfile.width <= 3840 &&
        this.currentStreamProfile.width % 2 === 0
      );
    },
    invalidWidthFeedback() {
      return this.isValidWidth
        ? ""
        : "Width should be an even number between 2 and 3840";
    },
    isValidHeight() {
      return (
        this.currentStreamProfile.height >= 2 &&
        this.currentStreamProfile.height <= 2160 &&
        this.currentStreamProfile.height % 2 === 0
      );
    },
    invalidHeightFeedback() {
      return this.isValidHeight
        ? ""
        : "Height should be an even number between 2 and 2160";
    },
    isValidBitrate() {
      return (
        (this.currentStreamProfile.codec === "avc" &&
          this.currentStreamProfile.bitrateBps >= 100000 &&
          this.currentStreamProfile.bitrateBps <= 100000000) ||
        (this.currentStreamProfile.codec === "hevc" &&
          this.currentStreamProfile.bitrateBps >= 100000 &&
          this.currentStreamProfile.bitrateBps <= 40000000)
      );
    },
    invalidBitrateFeedback() {
      return this.isValidBitrate
        ? ""
        : this.currentStreamProfile.codec === "avc"
        ? "Bitrate should be between 100000 bps (100 Kbps) and 100,000,000 bps (100 Mbps)"
        : "Bitrate should be between 100000 bps (100 Kbps) and 40,000,000 bps (40 Mbps)";
    },
    isValidGopFrames() {
      return (
        !this.currentStreamProfile.gopFrames ||
        (this.currentStreamProfile.gopFrames >= 1 &&
          this.currentStreamProfile.gopFrames <= 180)
      );
    },
    invalidGopFramesFeedback() {
      return this.isValidGopFrames
        ? ""
        : "GOP (Frames) should be between 1 and 180";
    },
    isValidColourSpace() {
      return this.colourSpaceOptions.some(
        item => item.value === this.currentStreamProfile.colourSpace
      );
    },
    invalidColourSpaceFeedback() {
      return this.isValidColourSpace
        ? ""
        : "Invalid Colourspace. Please select from list";
    },
    isValid() {
      return (
        this.isValidName &&
        this.isValidWidth &&
        this.isValidHeight &&
        this.isValidBitrate &&
        this.isValidGopFrames &&
        this.isValidColourSpace
      );
    },
    codecOptions() {
      return this.disabledCodecs.length
        ? CODECS.filter(codec => !this.disabledCodecs.includes(codec.value))
        : CODECS;
    },

    scanTypeOptions() {
      return SCAN_TYPES;
    }
  },
  mounted() {
    this.update();
  },
  watch: {
    streamProfile: {
      handler(val) {
        this.currentStreamProfile = val;
      }
    },
    currentStreamProfile: {
      handler() {
        this.update();
      },
      deep: true
    }
  },
  methods: {
    update() {
      this.currentStreamProfile.gopFrames =
        this.currentStreamProfile.gopFrames || null;
      this.$emit("change", {
        profile: this.currentStreamProfile,
        isValid: this.isValid
      });
    }
  },
  data() {
    return {
      currentStreamProfile: {
        profileName: this.streamProfile.profileName,
        codec: this.streamProfile.codec,
        width: this.streamProfile.width,
        bitrateBps: this.streamProfile.bitrateBps,
        height: this.streamProfile.height,
        framerateNumerator: this.streamProfile.framerateNumerator,
        framerateDenominator: this.streamProfile.framerateDenominator,
        gopFrames: this.streamProfile.gopFrames,
        colourSpace: this.streamProfile.colourSpace,
        rateControlMode: this.streamProfile.rateControlMode,
        scanType: this.streamProfile.scanType
      },
      framerateOptions: FRAMERATES,
      rateControlModeOptions: RATE_CONTROL_MODES
    };
  }
};
</script>
