import Audit from "@/views/Audit/Audit";
import Logs from "@/components/Audit/Logs";
import { MAIN_ITEMS, ITEMS_AUDIT } from "@/constants.js";
const { LOGS } = ITEMS_AUDIT;
const { AUDIT } = MAIN_ITEMS;
const HOST = "AUDIT";

const audit = [
  {
    path: AUDIT,
    name: `${HOST}`,
    redirect: LOGS,
    component: Audit,
    children: [
      {
        path: LOGS,
        component: Logs
      }
    ]
  }
];
export default audit;
