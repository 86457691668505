export default {
  accountId: null,
  availabilityZone: null,
  bucketName: null,
  cdnSettingsType: null,
  cryptoAlgo: null,
  cryptoKey: null,
  description: null,
  destinationAddress: null,
  destinationType: null,
  folderPath: null,
  ipAddress: null,
  ipDestinationType: null,
  isEncrypted: false,
  isPassthrough: false,
  isPrecreated: false,
  maxBitrateBps: null,
  maxLatencyMs: null,
  minLatencyMs: null,
  name: null,
  outputDestinationType: null,
  port: null,
  region: null,
  roleArn: null,
  streamId: null,
  streamProfileId: null
};
