<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-dropdown
          text="Bulk actions"
          class="mr-3"
          :disabled="bulkContextMenuItems.length === 0 || !isAdmin"
        >
          <gl-dropdown-item
            v-for="item in bulkContextMenuItems"
            :key="item.key"
            @click="contextMenuClick(selectedContributions, item.id)"
            >{{ item.text }}</gl-dropdown-item
          >
        </gl-dropdown>
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'package.name'">
              <span class="cellTitle">{{ r[field] }}</span>
            </div>
            <div v-else-if="field === 'organisation.shortName'">
              <gl-avatar-labeled
                :entity-name="r[field]"
                :variant="randomVariant()"
                :label="r[field]"
                :size="32"
                shape="rect"
              />
            </div>
            <StatusCell
              v-else-if="field === 'status'"
              :status="r.status"
              class="l"
            />
          </template>
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <div
          class="border-bottom border-light mb-3"
          v-if="isOrgAdminRoleActive || isArqadeAdminRoleActive"
        >
          <p>
            <gl-button
              class="standard-w"
              variant="confirm"
              :disabled="isApproveOrRejectDisabled()"
              @click="contextMenuClick(selectedContributions, 'approve')"
              >Approve
            </gl-button>
          </p>
          <p>
            <gl-button
              class="standard-w"
              variant="confirm"
              :disabled="isEditDisabled()"
              @click="contextMenuClick(selectedContributions, 'edit')"
              >Edit Entitlement
            </gl-button>
          </p>
          <p>
            <gl-button
              :disabled="isApproveOrRejectDisabled()"
              @click="contextMenuClick(selectedContributions, 'reject')"
              class="standard-w"
              >Reject</gl-button
            >
          </p>
          <p>
            <gl-button
              :disabled="isRescindDisabled()"
              class="standard-w"
              @click="contextMenuClick(selectedContributions, 'rescind')"
              >Rescind</gl-button
            >
          </p>
        </div>
        <ArqDataGridDetailsPane
          v-if="selectedContribution"
          :columns="detailsColumns"
          :row="selectedContribution"
        />
        <ArqDataGridDetailsPane
          v-if="selectedChildRow"
          :columns="childDetailsColumns"
          :row="selectedChildRow"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import entitlementApiClient from "@/mixins/entitlementApiClient.js";
import debouncedInput from "@/mixins/debouncedInput.js";
import {
  GlForm,
  GlDropdownItem,
  GlDropdown,
  GlButton,
  GlAvatarLabeled,
  GlSearchBoxByType
} from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";
import { ITEMS_PACKAGES } from "@/constants";
import AppConfig from "@/app-config";

// eslint-disable-next-line no-unused-vars
const { ENTITLEMENT_EDIT } = ITEMS_PACKAGES;

export default {
  name: "YourAll",
  mixins: [dateUtils, debouncedInput, entitlementApiClient, userInfoMixin],
  components: {
    ArqDataGrid,
    StatusCell,
    GlForm,
    GlAvatarLabeled,
    GlButton,
    ArqDataGridDetailsPane,
    GlDropdown,
    GlDropdownItem,

    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    status: {
      type: String,
      default: "all"
    }
  },
  methods: {
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    async contextMenuClick(rows, option) {
      if (option === "edit") {
        if (Array.isArray(rows)) {
          this.$router.push(`${ENTITLEMENT_EDIT}/${rows[0].id}`);
        } else {
          this.$router.push(`${ENTITLEMENT_EDIT}/${rows.id}`);
        }
      } else {
        const statusByOption = {
          request: "Requested",
          approve: "Approved",
          reject: "Rejected",
          rescind: "Rescinded"
        };
        if (Array.isArray(rows)) {
          await this.changeStatus(option, rows, statusByOption[option]);
        } else {
          await this.changeStatus(option, [rows], statusByOption[option]);
          this.$router.push(`${ENTITLEMENT_EDIT}/${rows.id}`);
        }
      }
    },
    async changeStatus(verb, items, status) {
      let name;
      if (items.length === 1) {
        name = `"${items[0].package.name}"`;
      } else {
        name = `"${items[0].package.name}" and ${items.length - 1} other(s)`;
      }
      const userResponse = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to ${verb} ${name}?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userResponse) {
        const promises = [];
        for (const item of items) {
          promises.push(
            this.entitlementApiClient.patch(item.id, {
              status
            })
          );
        }
        try {
          await Promise.all(promises);
          const msg =
            items.length > 1
              ? "The statuses have been successfully changed."
              : "The status has been successfully changed.";
          this.handleApiSuccess(msg);
          this.onDataNeeded(
            this.sortModel,
            this.pagingModel,
            this.searchPhrase
          );
          if (status === "Approved") {
            this.$router.push(`${ENTITLEMENT_EDIT}/${items[0].id}`);
          }
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async handleModalEditEntitlement() {
      try {
        await this.entitlementApiClient.patch(
          this.entitlement.id,
          this.entitlement
        );
        this.handleApiSuccess("The entitlement has been successfully changed.");
        this.onDataNeeded(this.sortModel, this.pagingModel, this.searchPhrase);
        this.selectedContributions = [];
      } catch (e) {
        this.handleApiError(e);
      }
    },
    isApproveOrRejectDisabled() {
      let enabled = this.selectedContributions.length > 0;
      for (const row of this.selectedContributions) {
        enabled = enabled && row.status === "Requested";
      }
      return !enabled;
    },
    isEditDisabled() {
      const enabled =
        this.selectedContributions.length === 1 &&
        this.selectedContributions[0].status === "Approved";
      return !enabled;
    },
    isRescindDisabled() {
      let enabled = this.selectedContributions.length > 0;
      for (const row of this.selectedContributions) {
        enabled = enabled && row.status === "Approved";
      }
      return !enabled;
    },
    randomVariant() {
      const variants = [
        "secondary",
        "primary",
        "dark",
        "light",
        "success",
        "danger",
        "warning",
        "info"
      ];
      return variants[Math.floor(Math.random() * variants.length)];
    },
    onRowSelected(row, selectedRows) {
      this.selectedContributions = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedContribution = selectedRows[0];
      } else {
        this.selectedContribution = undefined;
      }
      return row;
    },

    async onDataNeeded(sortModel, pageModel, phrase) {
      this.pagingModel = pageModel;
      this.sortModel = sortModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.entitlementApiClient
        .list()
        .param("owned", "false")
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query.filterBy(phrase);

      if (this.status !== "all") {
        query = query.param("status", this.status);
      }
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        response.items = response.items.map(this.flatten);
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  computed: {
    bulkContextMenuItems() {
      const itemsById = {};
      if (this.selectedContributions.length === 0) {
        return [];
      }
      for (const item of this.contextMenuItems) {
        itemsById[item.id] = item;
      }
      delete itemsById["discount"];
      delete itemsById["edit"];
      for (const row of this.selectedContributions) {
        switch (row.status.toLowerCase()) {
          case "requested":
            delete itemsById["rescind"];
            break;
          case "approved":
            delete itemsById["approve"];
            delete itemsById["reject"];
            break;
          case "rejected":
          case "rescinded":
            delete itemsById["approve"];
            delete itemsById["rescind"];
            delete itemsById["reject"];
            break;
        }
      }
      const out = Object.keys(itemsById).map(k => itemsById[k]);
      return out;
    },
    contextMenuItemsByRow() {
      const out = {};
      if (this.isArqadeAdminRoleActive || this.isOrgAdminRoleActive) {
        if (this.data && this.data.items) {
          for (const row of this.data.items) {
            out[row.id] = [];
            switch (row.status.toLowerCase()) {
              case "requested":
                out[row.id].push(
                  this.contextMenuItems.find(i => i.id === "approve")
                );
                out[row.id].push(
                  this.contextMenuItems.find(i => i.id === "reject")
                );
                break;
              case "approved":
                out[row.id].push(
                  this.contextMenuItems.find(i => i.id === "edit")
                );
                out[row.id].push(
                  this.contextMenuItems.find(i => i.id === "rescind")
                );
                break;
            }
          }
        }
      }

      return out;
    },
    isAdmin() {
      return (
        (this.isArqadeAdminRoleActive ||
          this.isOrgAdminRoleActive ||
          this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    },
    isCreateContributionDisabled() {
      return (
        this.selectedContributions.length > 2 ||
        this.selectedContributions.length === 0
      );
    }
  },
  data() {
    return {
      searchPhrase: "",
      selectedContribution: undefined,
      selectedChildRow: undefined,
      selectedContributions: [],
      selectedChildRows: [],
      pagingModel: {},
      sortModel: {},
      data: {},
      contextMenuItems: [
        {
          id: "approve",
          text: "Approve"
        },
        {
          id: "edit",
          text: "Edit"
        },
        {
          id: "reject",
          text: "Reject"
        },
        {
          id: "rescind",
          text: "Rescind"
        }
      ],
      columns: [
        {
          text: "Package name",
          field: "package.name",
          isSortable: true
        },
        {
          text: "Requester",
          field: "organisation.shortName",
          isSortable: true
        },
        {
          text: "Status",
          field: "status"
        }
      ],
      detailsColumns: [
        {
          text: "Package name",
          field: "package.name"
        },
        {
          text: "Owner",
          field: "organisation.shortName"
        },
        {
          text: "Description",
          field: "description"
        },
        { text: "Note", field: "requestNote" }
      ],
      modalEditEntitlement: false,
      entitlement: {
        id: undefined,
        eventSetupDiscount: undefined,
        eventHourlyDiscount: undefined,
        channelDataDeliveryDiscount: undefined,
        channelTranscodeDiscount: undefined,
        deliveryCancellationDelay: undefined
      }
    };
  }
};
</script>
