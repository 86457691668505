<template v-slot:right-section>
  <div>
    <gl-button-group class="mb-3">
      <gl-button
        v-gl-collapse-toggle.presetProfiles
        @click="setPreset"
        :disabled="isButtonsDisabled || activeTab === 'presetProfiles'"
      >
        Preset profiles
      </gl-button>
      <gl-button
        v-gl-collapse-toggle.newCustomProfile
        @click="setNewCustomPreset"
        :disabled="isButtonsDisabled || activeTab === 'newCustomProfile'"
      >
        New custom profile
      </gl-button>
      <gl-button
        v-gl-collapse-toggle.customProfiles
        @click="setCustomPreset"
        :disabled="isButtonsDisabled || activeTab === 'customProfiles'"
      >
        Custom profiles
      </gl-button>
      <gl-button
        v-gl-collapse-toggle.passthrough
        @click="setPassthrough"
        :disabled="isButtonsDisabled || activeTab === 'passthrough'"
        >Passthrough
      </gl-button>
    </gl-button-group>

    <gl-card v-if="activeTab === 'presetProfiles'" id="presetProfiles">
      <preset-profiles-component
        :currentItem="currentItem"
        @change="onProfileChange"
      />
    </gl-card>

    <gl-card v-if="activeTab === 'newCustomProfile'" id="newCustomProfile">
      <new-custom-profile-component
        :current-item="currentItem"
        :is-custom-profile="isNewCustomProfile"
        @change="onProfileChange"
      />
    </gl-card>

    <gl-card id="customProfiles" v-if="activeTab === 'customProfiles'">
      <custom-profiles-component
        :currentItem="currentItem"
        @change="onProfileChange"
      ></custom-profiles-component>
    </gl-card>

    <gl-card id="passthrough" v-if="activeTab === 'passthrough'">
      <gl-card>
        <p class="mb-0">
          Source encoding will be preserved in delivery, no transcoding will be
          performed.
        </p>
      </gl-card>
    </gl-card>
  </div>
</template>

<script>
import CustomProfilesComponent from "@/components/Destinations/AddSecond/Profiles/CustomProfilesComponent.vue";
import NewCustomProfileComponent from "@/components/Destinations/AddSecond/Profiles/NewCustomProfileComponent.vue";
import PresetProfilesComponent from "@/components/Destinations/AddSecond/Profiles/PresetProfilesComponent.vue";
import { DESTINATION_TYPES } from "@/constants";
import { streamProfileClient } from "@/mixins/apiClients";
import Destination from "@/models/Destination";
import { GlButton, GlButtonGroup, GlCard } from "@gitlab/ui";

const name = "ProfilesComponent";
export default {
  name: name,
  mixins: [streamProfileClient],
  components: {
    NewCustomProfileComponent,
    CustomProfilesComponent,
    GlButton,
    GlButtonGroup,
    GlCard,
    PresetProfilesComponent
  },
  created() {
    this.originalStreamProfileId = this.currentItem.streamProfileId;
    this.setDefaultTab();
  },
  computed: {
    isButtonsDisabled() {
      return this.currentItem.isPrecreated && this.needsMediaLive();
    },
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    // TODO: repeated method, when we migrate to TS move it to the Destination class
    needsMediaLive() {
      const types = [
        DESTINATION_TYPES.RTMP,
        DESTINATION_TYPES.HLS,
        DESTINATION_TYPES.S3_ARCHIVE,
        DESTINATION_TYPES.MEDIASHUTTLE
      ];
      return types.includes(this.currentItem.destinationType);
    },
    setDefaultTab() {
      if (this.currentItem.streamProfileId === null && this.currentItem.id) {
        this.setPassthrough();
      } else {
        this.setPreset();
      }
    },
    onProfileChange(event) {
      this.$emit("change", {
        ...event,
        isNewCustomProfile: this.isNewCustomProfile
      });
    },
    setCustomPreset() {
      if (this.currentItem.streamProfileId === null) {
        this.currentItem.streamProfileId = this.originalStreamProfileId;
      }
      this.activeTab = "customProfiles";
    },
    setNewCustomPreset() {
      this.activeTab = "newCustomProfile";
      this.currentItem.streamProfileId = null;
    },
    setPassthrough() {
      this.activeTab = "passthrough";
      this.currentItem.isPassthrough = true;
      this.currentItem.streamProfileId = null;
      this.$emit("change", { isValid: true, isNewCustomProfile: false });
    },
    setPreset() {
      if (this.currentItem.streamProfileId === null) {
        this.currentItem.streamProfileId = this.originalStreamProfileId;
      }
      this.activeTab = "presetProfiles";
    }
  },
  data() {
    return {
      activeTab: null,
      isNewCustomProfile: false,
      originalStreamProfileId: null
    };
  },
  props: {
    currentItem: Destination
  }
};
</script>
