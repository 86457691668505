<!-- eslint-disable no-unused-vars -->
<template>
  <div class="mx-3">
    <gl-filtered-search
      :available-tokens="tokens"
      @submit="activate"
      @clear="clear"
    >
      <template #history-item="{ historyItem }">
        <template v-for="(token, idx) in historyItem">
          <span :key="idx" class="px-1">
            {{ token.type }} =
            <strong>{{ token.value }}</strong>
          </span>
        </template>
      </template>
    </gl-filtered-search>
    <arq-data-grid
      v-if="showResults"
      :loadOnMount="false"
      :data="data"
      :phrase="searchPhrase"
      :columns="columns"
      @dataNeeded="onDataNeeded"
      :itemsPerPage="itemsPerPage"
      :showContextMenu="false"
      @rowSelected="onRowSelected"
      :selectOnClick="true"
    >
    </arq-data-grid>
    <div v-if="selectedRow">
      <b-row>
        <b-col>
          <h3 class="text-center">Details</h3>
        </b-col>
      </b-row>
      <b-row
        v-for="(detailRow, index) in detailsItems(selectedRow)"
        :key="index"
        class="my-2"
      >
        <b-col>
          <div class="field-element d-flex flex-row p-2 h-100">
            <p class="field-name w-25">
              {{ detailRow.field }}
            </p>
            <p class="field-value">
              {{ detailRow.after }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { userMgmtApiClient } from "@/mixins/apiClients";
import userInfo from "@/mixins/userInfo.js";
import { GlFilteredSearch } from "@gitlab/ui";
import _ from "lodash";

import {
  ITEMS_CONTRIBUTIONS,
  ITEMS_DELIVERY,
  ITEMS_DESTINATIONS,
  ITEMS_PACKAGES,
  ITEMS_SOURCES,
  LOGS_SEARCH_BAR_TOKENS
} from "@/constants";
import searchApiClient from "@/mixins/searchApiClient";

const { SHOW: DESTINATION_SHOW } = ITEMS_DESTINATIONS;
const { SHOW: SOURCE_SHOW } = ITEMS_SOURCES;
const { SHOW: CONTRIBUTION_SHOW } = ITEMS_CONTRIBUTIONS;
const { SHOW: PACKAGE_SHOW } = ITEMS_PACKAGES;
const { ALL } = ITEMS_DELIVERY;

export default {
  name: "SearchComponent",
  mixins: [searchApiClient, userInfo, userMgmtApiClient],
  components: {
    ArqDataGrid,
    GlFilteredSearch
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  computed: {
    tokens() {
      const tokens = LOGS_SEARCH_BAR_TOKENS;
      const filtered = tokens
        .filter(token => token.type !== "userId")
        .filter(token => token.type !== "userEmail")
        .filter(token => token.type !== "operation")
        .filter(token => {
          if (token.type === "resourceType") {
            token.options = token.options.filter(
              option =>
                option.value !== "pipeline" &&
                option.value !== "user" &&
                option.value !== "organisation"
            );
          }
          return token;
        });
      return filtered;
    }
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
      this.showResults = true;
    },
    detailsItems(row) {
      if (!row) {
        return [];
      }

      const items = [];
      const after = row.fields;

      for (const field of Object.keys(after)) {
        items.push({
          field: _.startCase(field),
          after: after[field]
        });
      }
      return items;
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.showResults = false;
    },
    async onDataNeeded(sortModel, pageModel) {
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.searchApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }
      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        d.items.forEach(item => {
          try {
            item.data = JSON.parse(item.data);
          } catch (ex) {
            // continue
          }
        });
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    goToDetails(row) {
      switch (row.resourceType.toLowerCase()) {
        case "contribution":
          return `${CONTRIBUTION_SHOW}/${row.id}`;
        case "delivery":
          return `${ALL}`;
        case "destination":
          return `${DESTINATION_SHOW}/${row.id}`;
        case "package":
          return `${PACKAGE_SHOW}/${row.id}`;
        case "source":
          return `${SOURCE_SHOW}/${row.id}`;
        default:
      }
    }
  },
  data() {
    return {
      availableSearch: LOGS_SEARCH_BAR_TOKENS.find(
        item => item.type === "operation"
      ).options,
      searchParams: {},
      searchPhrase: "",
      data: {},
      selectedRow: undefined,
      selectedRows: [],
      showResults: false,
      columns: [
        {
          text: "Name",
          field: "name",
          isSortable: true,
          clicked: row => {
            let route = this.goToDetails(row);
            if (route.includes("/delivery/all")) {
              let _route = { path: "/delivery/all", query: { id: row.id } };
              route = _route;
            }
            if (this.getUserOrganizationId !== row.organizationId) {
              this.userMgmtApiClient
                .update("me/masquerade-org/" + row.organisationId)
                .then(() => {
                  this.$router.push(route).then(() => {
                    window.location.reload();
                  });
                });
            } else {
              this.$router.push(route);
            }
          }
        },
        {
          text: "Type",
          field: "resourceType"
        }
      ]
    };
  }
};
</script>

<style></style>
