<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <Description
          :labelName="labelName"
          :valueName="itemUnderEdit.name"
          :valueDesc="itemUnderEdit.description"
          @change="onDescriptionChange"
        />
        <gl-toggle
          id="accessLevel"
          label="Admin Only"
          v-if="canEditAccessLevel"
          v-model="changeIsAdminOnly"
        ></gl-toggle>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlToggle } from "@gitlab/ui";
import Description from "@/components/Description";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import AppConfig from "@/app-config";
import userInfoMixin from "@/mixins/userInfo.js";

import { ALIAS_ITEMS_DESTINATIONS, ITEMS_DESTINATIONS } from "@/constants.js";

const { EDIT } = ITEMS_DESTINATIONS;
const { ADD_SECOND, EDIT_SECOND } = ALIAS_ITEMS_DESTINATIONS;
export default {
  name: "AddFirst",
  mixins: [currentItem, userInfoMixin],
  components: {
    Wizard,
    GlForm,
    GlToggle,
    Description
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDescFulfilled: this.currentItem.name && this.currentItem.description,
      labelName: `Destination name`,
      leftSectionTitle: `Description`,
      buttons: [{ text: `Next Step`, type: `submit` }],
      adminOnly: false,
      listOfFieldsToExclude: []
    };
  },
  watch: {
    currentItem: {
      handler() {
        this.$emit("nameModified", true);
        this.isDescFulfilled =
          this.currentItem.name && this.currentItem.description;
      },
      deep: true
    }
  },
  mounted() {
    if (this.listOfFieldsToExclude.length > 0) {
      this.listOfFieldsToExclude.forEach(field => {
        if (this.itemUnderEdit[field]) {
          delete this.itemUnderEdit[field];
        }
      });
    }
  },
  computed: {
    canEditAccessLevel() {
      return (
        (this.isArqadeAdminRoleActive || this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlyDestinations
      );
    },
    changeIsAdminOnly: {
      get() {
        if (this.isUpdate) {
          return this.itemUnderEdit.accessLevel === "arqade_admin";
        }
        return this.adminOnly;
      },
      set(value) {
        value
          ? (this.itemUnderEdit.accessLevel = "arqade_admin")
          : (this.itemUnderEdit.accessLevel = "user");
        this.adminOnly = value;
      }
    }
  },
  methods: {
    nextStep() {
      if (this.isDescFulfilled) {
        if (this.isUpdate) {
          this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`);
        } else {
          this.$router.push(ADD_SECOND);
        }
      }
    },
    onDescriptionChange(data) {
      const { name, description } = data;
      this.itemUnderEdit.name = name;
      this.itemUnderEdit.description = description;
    }
  }
};
</script>
