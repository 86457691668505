<template>
  <requests-all status="Rejected"></requests-all>
</template>
<script>
import RequestsAll from "./RequestsAll.vue";
export default {
  components: { RequestsAll },
  name: "Rejected"
};
</script>
