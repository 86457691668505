<template>
  <your-destinations status="ARCHIVED"></your-destinations>
</template>
<script>
import YourDestinations from "./YourDestinations.vue";
export default {
  components: { YourDestinations },
  name: "Archived"
};
</script>
