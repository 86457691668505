<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3 mb-3">{{ pageTitle }}</h1>
    <add-virtual-connect-stream
      :currentItem="currentItem"
      :isUpdate="isEdit"
      :organizationId="organizationId"
      @create="streamCreated"
    ></add-virtual-connect-stream>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import AddVirtualConnectStream from "@/components/UserManagement/AddVirtualConnectStream.vue";
import { virtualConnectStreamApiClient } from "@/mixins/apiClients";
import * as eq from "deep-equal";
const { EDIT_ORGANIZATION } = ITEMS_USER_MGMT;
const {
  VIRTUAL_CONNECT_STREAMS_ALL,
  ORGANIZATIONS_ALL
} = ALIAS_ITEMS_USER_MGMT;

export default {
  components: { Breadcrumb, AddVirtualConnectStream },
  mixins: [wizardGuard, virtualConnectStreamApiClient],
  methods: {
    streamCreated() {
      this.created = true;
      this.$router.push(
        `${EDIT_ORGANIZATION}/${this.organizationId}/${VIRTUAL_CONNECT_STREAMS_ALL}`
      );
    }
  },
  created() {
    this.organizationId = this.$route.params.organizationId;
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Organizations`, href: ORGANIZATIONS_ALL },
        {
          text: "Virtual Connect Streams",
          href: `${EDIT_ORGANIZATION}/${this.organizationId}/${VIRTUAL_CONNECT_STREAMS_ALL}`
        }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    pageTitle() {
      return this.isEdit
        ? `Edit Virtual Connect Stream`
        : "Add Virtual Connect Stream";
    }
  },
  data() {
    return {
      created: false,
      isEdit: false,
      currentItem: {},
      originalItem: undefined,
      isDescFulfilled: false,
      organizationId: undefined
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  }
};
</script>
