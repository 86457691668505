var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th'),_c('Search',{attrs:{"value":_vm.searchFields.q || ''},on:{"updateSearch":function (newValue) { return _vm.updateSearchParams('q', newValue); }}}),_c('th',[_c('span',[_vm._v("Status")]),_c('gl-form-select',{attrs:{"data-testid":"search--states","value":_vm.searchFields.states,"options":[
        {
          text: 'All',
          value: ''
        },
        {
          text: 'Pending',
          value: 'PENDING'
        },
        {
          text: 'Starting',
          value: 'STARTING'
        },
        {
          text: 'In Progress',
          value: 'IN_PROGRESS'
        },
        {
          text: 'Stopping',
          value: 'STOPPING'
        },
        {
          text: 'Ended',
          value: 'COMPLETE'
        },
        {
          text: 'Error',
          value: 'ERROR'
        }
      ]},on:{"input":function($event){return _vm.updateSearchParams('states', $event)}}})],1),_c('th',[_c('span',[_vm._v("Health")]),_c('gl-form-select',{attrs:{"data-testid":"search--health","value":_vm.searchFields.health,"options":[
        {
          text: 'All',
          value: ''
        },
        {
          text: 'Healthy',
          value: 'healthy'
        },
        {
          text: 'Alert',
          value: 'alert'
        }
      ]},on:{"input":function($event){return _vm.updateSearchParams('health', $event)}}})],1),_c('DateRangePicker',{attrs:{"searchFields":_vm.searchFields},on:{"changeSearchParams":_vm.onSearchParamsChange}}),_vm._m(0),_vm._m(1),_vm._m(2),_c('th')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('span',{staticClass:"noFilter"},[_vm._v("Type")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('span',{staticClass:"noFilter"},[_vm._v("Preview")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('span',{staticClass:"noFilter"},[_vm._v("Transcode")])])}]

export { render, staticRenderFns }