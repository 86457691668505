<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT
} from "@/constants.js";
const { ORGANIZATIONS_ALL } = ALIAS_ITEMS_USER_MGMT;
const { ORGANIZATIONS } = ITEMS_USER_MGMT;
export default {
  components: { MainSection },
  data() {
    return {
      pageTitle: `Organizations`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Organizations`, href: ORGANIZATIONS },
        { text: `All organizations`, href: ORGANIZATIONS_ALL }
      ],
      tabsItems: [
        { title: `All organizations`, subPageName: ORGANIZATIONS_ALL }
      ]
    };
  }
};
</script>
