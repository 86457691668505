<template>
  <gl-form>
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <gl-form-group label="Given name" label-size="sm" label-for="givenName">
          <gl-form-input
            id="givenName"
            type="text"
            v-model="itemUnderEdit.givenName"
            placeholder="Joe"
            maxlength="250"
            required
            class="mt-1"
            :disabled="true"
          />
        </gl-form-group>
        <gl-form-group
          label="Family name"
          label-size="sm"
          label-for="familyName"
        >
          <gl-form-input
            id="familyName"
            type="text"
            v-model="itemUnderEdit.familyName"
            placeholder="Doe"
            maxlength="250"
            required
            class="mt-1"
            :disabled="true"
          />
        </gl-form-group>
        <gl-form-group label="Email" label-size="sm" label-for="email">
          <gl-form-input
            id="email"
            type="email"
            v-model="itemUnderEdit.email"
            :disabled="true"
            placeholder="joe@doe.com"
            maxlength="250"
            required
            class="mt-1"
          />
        </gl-form-group>
        <gl-form-group label="" label-for="subscribed">
          <gl-form-checkbox
            id="subscribed"
            v-model="itemUnderEdit.subscribed"
            @change="updateSubscription"
            ><strong>Subscribed to emails</strong></gl-form-checkbox
          >
        </gl-form-group>
        <smart-select
          v-if="isArqadeAdminRoleActive"
          :disabled="true"
          :dataSource="organizationApiClient"
          @itemSelected="setOrganizationId"
          :selected="currentItem.organisationId"
          :textFormatter="organizationNameFormatter"
        ></smart-select>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlFormInput, GlFormGroup, GlFormCheckbox } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import { userMgmtApiClient, organizationApiClient } from "@/mixins/apiClients";
import userInfoMixin from "@/mixins/userInfo";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";

export default {
  name: "AddFirst",
  mixins: [
    currentItem,
    userMgmtApiClient,
    organizationApiClient,
    userInfoMixin
  ],
  components: {
    Wizard,
    GlForm,
    GlFormInput,
    GlFormGroup,
    SmartSelect,
    GlFormCheckbox
  },
  computed: {
    buttons() {
      return [];
    },
    isDescFulfilled() {
      return (
        !!this.currentItem.givenName &&
        !!this.currentItem.familyName &&
        !!this.currentItem.email &&
        (!!this.organization || !this.isArqadeAdminRoleActive)
      );
    }
  },
  data() {
    return {
      isBusy: false,
      leftSectionTitle: `Details`,
      organizations: [],
      organization: undefined
    };
  },
  watch: {
    currentItem: {
      handler() {
        this.getOrganization(this.currentItem.organisationId);
      },
      deep: true
    }
  },
  methods: {
    async updateSubscription() {
      let path = "";
      // if this.itemUnderEdit.subscribed is true, it means the user wants to be subscribed to emails, and vice versa.
      // 'subscribe' or 'unsubscribe' paths are used based on this.itemUnderEdit.subscribed to manage email subscriptions.
      if (this.itemUnderEdit.subscribed) {
        path = "subscribe";
      } else {
        path = "unsubscribe";
      }
      try {
        await this.userMgmtApiClient.patch(this.itemUnderEdit.id + `/${path}`);
        this.handleApiSuccess(
          `The user "${this.itemUnderEdit.email}" was updated successfully.`
        );
      } catch (e) {
        this.handleApiError(e);
      }
    },
    getOrganization(orgId) {
      if (this.organizations[orgId]) {
        this.organization = this.organizations[orgId];
      } else if (orgId) {
        this.organizationApiClient.getById(orgId).then(organization => {
          this.organizations[orgId] = organization;
          this.organization = organization;
        });
      }
    },
    organizationNameFormatter(row) {
      return `${row.shortName} (${row.legalName})`;
    },
    setOrganizationId(orgId) {
      this.getOrganization(orgId);
      this.$forceUpdate();
    }
  }
};
</script>
