<template>
  <gl-form @submit.prevent="generate">
    <Wizard :buttons="buttons">
      <template v-slot:left-section
        ><stream-profile @change="updateStreamProfileForm"
      /></template> </Wizard
  ></gl-form>
</template>
<script>
import StreamProfile from "@/components/StreamProfile.vue";
import { streamProfileClient } from "@/mixins/apiClients";
import { GlForm } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import { ITEMS_STREAM_PROFILE } from "@/constants.js";
const { YOUR } = ITEMS_STREAM_PROFILE;

export default {
  name: "YourAll",
  mixins: [streamProfileClient],
  components: { StreamProfile, GlForm, Wizard },
  methods: {
    updateStreamProfileForm(streamProfile) {
      this.customStreamProfile = streamProfile;
    },
    async generate() {
      if (this.customStreamProfile.isValid) {
        try {
          this.isBusy = true;
          await this.streamProfileClient.create(
            this.customStreamProfile.profile
          );
          this.handleApiSuccess(
            "Custom Stream Profile was created successfully"
          );
          this.$router.push(`${YOUR}`);
        } catch (e) {
          this.handleApiError(e);
        }
        this.isBusy = false;
      }
    }
  },
  data() {
    return {
      customStreamProfile: {
        isValid: false
      },
      isBusy: false
    };
  },
  computed: {
    buttons() {
      return [
        {
          text: `Generate Stream Profile`,
          type: `submit`,
          disabled: !this.customStreamProfile.isValid || this.isBusy
        }
      ];
    }
  }
};
</script>
