import Browse from "@/views/Packages/Browse";
import Entitled from "@/views/Packages/Entitled";
import Your from "@/views/Packages/Your";
import Add from "@/views/Packages/Add";
import Requests from "@/views/Packages/Requests";
import EntitlementConfigView from "@/views/Packages/EntitlementConfigView.vue";
import {
  YourAll,
  BrowseAll,
  EntitledAll,
  RequestsAll,
  RequestsApproved,
  RequestsRejected,
  RequestsRequested,
  RequestsRescinded
} from "@/components/Packages";
import { ITEMS_PACKAGES, ALIAS_ITEMS_PACKAGES } from "@/constants.js";
import Show from "@/views/Packages/Show.vue";

const {
  BROWSE,
  YOUR,
  ENTITLED,
  ADD,
  REQUESTS,
  EDIT,
  SHOW,
  ENTITLEMENT_EDIT
} = ITEMS_PACKAGES;
const {
  BROWSE_ALL,
  YOUR_ALL,
  ENTITLED_ALL,
  ADD_FIRST,
  ADD_SECOND,
  ADD_FOURTH,
  REQUESTS_ALL,
  REQUESTS_REQUESTED,
  REQUESTS_APPROVED,
  REQUESTS_REJECTED,
  REQUESTS_RESCINDED,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_FOURTH
} = ALIAS_ITEMS_PACKAGES;

const HOST = `Packages`;

const packages = [
  {
    path: YOUR,
    name: `${HOST}YourPackages`,
    redirect: YOUR_ALL,
    component: Your,
    children: [
      {
        path: YOUR_ALL,
        component: YourAll
      }
    ]
  },
  {
    path: BROWSE,
    name: `${HOST}Browse`,
    redirect: BROWSE_ALL,
    component: Browse,
    children: [
      {
        path: BROWSE_ALL,
        component: BrowseAll
      }
    ]
  },
  {
    path: ENTITLED,
    name: `${HOST}EntitledAll`,
    redirect: ENTITLED_ALL,
    component: Entitled,
    children: [
      {
        path: ENTITLED_ALL,
        component: EntitledAll
      }
    ]
  },
  {
    path: REQUESTS,
    name: `${HOST}Requests`,
    redirect: REQUESTS_ALL,
    component: Requests,
    children: [
      {
        path: REQUESTS_ALL,
        component: RequestsAll
      },
      {
        path: REQUESTS_REQUESTED,
        component: RequestsRequested
      },
      {
        path: REQUESTS_APPROVED,
        component: RequestsApproved
      },
      {
        path: REQUESTS_REJECTED,
        component: RequestsRejected
      },
      {
        path: REQUESTS_RESCINDED,
        component: RequestsRescinded
      }
    ]
  },
  {
    path: ADD,
    name: `${HOST}CreateADD`,
    redirect: ADD_FIRST
  },
  {
    path: ADD_FIRST,
    name: `${HOST}Create`,
    component: Add,
    alias: [ADD_SECOND, ADD_FOURTH]
  },

  {
    path: `${EDIT}/:id`,
    name: `${HOST}Edit`,
    component: Add,
    alias: [
      `${EDIT}/:id/${EDIT_FIRST}`,
      `${EDIT}/:id/${EDIT_SECOND}`,
      `${EDIT}/:id/${EDIT_FOURTH}`
    ]
  },
  {
    path: `${SHOW}/:id`,
    name: `${HOST}Show`,
    component: Show
  },
  {
    path: `${ENTITLEMENT_EDIT}/:id`,
    name: `${HOST}EntitlementConfigView`,
    component: EntitlementConfigView
  }
];

export default packages;
