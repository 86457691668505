import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      pricingConfigApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.PRICING,
        "price-config"
      )
    };
  }
};
