<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
    <p class="mt-3 ">{{ description }}</p>
    <Tabs class="mt-3" :items="tabsItems" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Tabs from "@/components/Tabs";
export default {
  name: "MainSection",
  components: { Breadcrumb, Tabs },
  props: {
    breadcrumbItems: {
      type: Array
    },
    pageTitle: {
      type: String,
      default: `Dashboard`
    },
    tabsItems: {
      type: Array
    },
    description: {
      type: String
    }
  }
};
</script>
<style lang="scss">
h1 {
  font-size: 24px;
  line-height: 32px;
}
h2 {
  font-size: 18px;
  line-height: 24px;
}
h1,
h2 {
  color: var(--theme-color);
  font-weight: 700 !important;
}
</style>
