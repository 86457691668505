var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('hr'),_c('p',[_vm._v(" Email: "),_c('a',{staticClass:"color-primary",attrs:{"href":'mailto:arqiva.events@arqiva.com?subject=Arqade Support Request&body=User e-mail: ' +
          (_vm.userInfo.attributes ? _vm.userInfo.attributes.email : '') +
          '%0D%0A' +
          'User role: ' +
          _vm.userInfo.role +
          '%0D%0A' +
          'Organization: ' +
          _vm.organization,"title":"Write to us"}},[_vm._v("arqiva.events@arqiva.com")])]),_vm._m(0),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mt-3"},[_vm._v(" Tel: "),_c('a',{staticClass:"color-primary",attrs:{"href":"tel:+44 333 032 3210","title":"Call us"}},[_vm._v("+44 333 032 3210")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',[_vm._v(" The system features virtual assistant technology and you'll be asked to say a phrase to be routed to the right team, for example \"Crawley MCR\" or \"IT Service Desk\". In an emergency, say the phrase \"Crawley MCR\".")])])}]

export { render, staticRenderFns }