<template>
  <div>
    <MainSection :pageTitle="pageTitle" :description="description" />
    <SearchComponent />
  </div>
</template>

<script>
import MainSection from "@/components/MainSection";
import SearchComponent from "@/components/Search/SearchComponent.vue";
export default {
  components: { MainSection, SearchComponent },

  data() {
    return { pageTitle: `Search`, description: `Search for resources` };
  }
};
</script>

<style></style>
