<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col class="text-right">
        <gl-button-group class="mr-2">
          <gl-button
            v-for="item in timeFrames"
            :class="{ active: item.id === selectedTimeFrame }"
            @click="updateTimeFrame(item.id)"
            :key="item.id"
            >{{ item.text }}</gl-button
          >
        </gl-button-group></b-col
      >
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h2>Source</h2>
        <gl-card>
          <gl-form-select
            v-model="selectedSourceMetric"
            :options="sourceOptions"
            class="mb-2 opsChart__select"
          />
          <div v-if="chartSourceLoading" class="opsChart__loadingIcon">
            <gl-loading-icon label="Loading" size="xl" color="dark" />
          </div>
          <gl-chart v-else :options="chartSourceOption" />
          <p class="mb-1 opsChart__firstLine">Source</p>
          <p
            class="mb-1 opsChart__secondLine"
            v-show="selectedContribution.source1Id"
          >
            Redundancy
          </p>
        </gl-card>
      </b-col>
      <!-- CHART TO BE REPURPOSED  -->
      <b-col v-if="1 === 2">
        <h2>Transcode output MediaConnect Flow(s)</h2>
        <gl-card>
          <gl-form-select
            v-model="selectedTranscodeMetric"
            :options="transcodeOptions"
            class="mb-2 opsChart__select"
          />
          <div v-if="chartTranscodeLoading" class="opsChart__loadingIcon">
            <gl-loading-icon label="Loading" size="xl" color="dark" />
          </div>
          <gl-chart v-else :options="chartTranscodeOption" />
          <p class="mb-1 opsChart__firstLine">Transcode</p>
          <p class="mb-1 opsChart__secondLine" v-if="showTranscodeRedundancy">
            Redundancy
          </p>
        </gl-card>
      </b-col>
      <!--  -->
    </b-row>
    <b-row class="mb-4">
      <b-col v-if="selectedDelivery.isTranscode">
        <div>
          <h2 class="opsChart__header">Transcode (MediaLive)</h2>
          <gl-card
            ><gl-form-select
              v-model="selectedTranscodeMediaLiveMetric"
              :options="metricTranscodeMediaLiveOptions"
              class="mb-2 opsChart__select"/>
            <div
              v-if="chartTranscodeMediaLiveLoading"
              class="opsChart__loadingIcon"
            >
              <gl-loading-icon label="Loading" size="xl" color="dark" />
            </div>
            <gl-chart v-else :options="chartTranscodeMediaLiveOption"
          /></gl-card>
        </div>
      </b-col>
      <b-col v-if="showMediaConnectSourceFlowChart(selectedDelivery)">
        <h2 class="opsChart__header">
          MediaConnect Source Flow(s)
        </h2>
        <gl-card>
          <gl-form-select
            v-model="selectedCrossRegionMetric"
            :options="crossRegionOptions"
            class="mb-2 opsChart__select"
          />
          <div v-if="chartCrossRegionLoading" class="opsChart__loadingIcon">
            <gl-loading-icon label="Loading" size="xl" color="dark" />
          </div>
          <gl-chart v-else :options="chartCrossRegionOption" />
          <p class="mb-1 opsChart__firstLine">Delivery</p>
          <p class="mb-1 opsChart__secondLine" v-if="showCrossRegionRedundancy">
            Redundancy
          </p>
        </gl-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { GlChart } from "@gitlab/ui/dist/charts";
import {
  GlLoadingIcon,
  GlFormSelect,
  GlCard,
  GlButton,
  GlButtonGroup
} from "@gitlab/ui";
import { metricsApiClient } from "@/mixins/apiClients";

const timeFrames = [
  {
    id: "1h",
    value: 60 * 60,
    text: "1h"
  },
  {
    id: "4h",
    value: 4 * 60 * 60,
    text: "4h"
  },
  {
    id: "24h",
    value: 24 * 60 * 60,
    text: "24h"
  },
  {
    id: "1w",
    value: 7 * 24 * 60 * 60,
    text: "1w"
  },
  {
    id: "1m",
    value: 30 * 24 * 60 * 60,
    text: "1m"
  },
  {
    id: "1y",
    value: 365 * 24 * 60 * 60,
    text: "1y"
  }
];

const timeFramesById = {};
timeFrames.forEach(frame => (timeFramesById[frame.id] = frame));

const axisConfig = {
  grid: {
    height: 200
  },
  tooltip: {
    trigger: "axis"
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: function(value) {
        if (value >= 1000000) {
          return Math.round(value / 1000000) + "M";
        } else if (value >= 1000) {
          return Math.round(value / 1000) + "k";
        } else {
          return value;
        }
      }
    },
    boundaryGap: [0, "100%"]
  },
  xAxis: {
    type: "time",
    name: ""
  },
  smooth: true,
  dataZoom: [
    {
      type: "inside",
      start: 0,
      end: 100
    },
    {
      start: 0,
      end: 20
    }
  ]
};

export default {
  name: "Charts",
  mixins: [metricsApiClient],
  components: {
    GlLoadingIcon,
    GlChart,
    GlFormSelect,
    GlButton,
    GlButtonGroup,
    GlCard
  },
  props: {
    selectedContribution: {
      type: Object
    },
    selectedDelivery: {
      type: Object
    }
  },
  methods: {
    showMediaConnectSourceFlowChart(selectedDelivery) {
      return (
        selectedDelivery.isCrossRegion ||
        (selectedDelivery.isTranscode &&
          selectedDelivery.pipelineType !== "hlsdestination" &&
          selectedDelivery.pipelineType !== "rtmpdestination")
      );
    },
    updateTimeFrame(timeFrameId) {
      this.selectedTimeFrame = timeFrameId;
      this.endDate = new Date();
      const diff = timeFramesById[this.selectedTimeFrame].value * 1000;
      this.startDate = new Date(this.endDate.getTime() - diff);
      this.updateSourceChart();
      this.updateMediaConnectSourceFlowChart();
      this.updateTranscodeMediaLiveChart();

      // updateTranscodeChart() is to be reimplemented in the future with a different purpose...
      // this.updateTranscodeChart();
    },
    updateSourceChart() {
      this.chartSourceLoading = true;
      if (this.selectedContribution) {
        let promises = [
          this.getMetrics(
            "source",
            this.selectedContribution.source0Id,
            this.selectedSourceMetric,
            new Date(this.startDate).toISOString().slice(0, -5),
            new Date(this.endDate).toISOString().slice(0, -5)
          )
        ];
        if (this.selectedContribution.source1Id) {
          promises.push(
            this.getMetrics(
              "source",
              this.selectedContribution.source1Id,
              this.selectedSourceMetric,
              new Date(this.startDate).toISOString().slice(0, -5),
              new Date(this.endDate).toISOString().slice(0, -5)
            )
          );
        }
        Promise.allSettled(promises)
          .then(results => {
            let data0 = [];
            let data1 = [];
            let series = [];
            if (results[0] && results[0].status === "fulfilled") {
              for (const item of results[0].value.data) {
                data0.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data0.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.sourceOptions.find(
                  option => option.value === this.selectedSourceMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#359eff"
                },
                data: data0
              });
            }
            if (results[1] && results[1].status === "fulfilled") {
              for (const item of results[1].value.data) {
                data1.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data1.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.sourceOptions.find(
                  option => option.value === this.selectedSourceMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#c41d7f"
                },
                data: data1
              });
            }
            this.chartSourceOption = {
              ...axisConfig,
              series: series
            };
            this.chartSourceLoading = false;
            this.$forceUpdate();
          })
          .catch(e => {
            this.chartSourceLoading = false;
            this.handleApiError(e);
          });
      }
    },
    updateTranscodeMediaLiveChart() {
      this.chartTranscodeMediaLiveLoading = true;
      if (this.selectedDelivery.isTranscode) {
        this.getMetrics(
          "transcode",
          this.selectedDelivery.id,
          this.selectedTranscodeMediaLiveMetric,
          new Date(this.startDate).toISOString().slice(0, -5),
          new Date(this.endDate).toISOString().slice(0, -5)
        )
          .then(results => {
            let data = [];
            for (const item of results.data) {
              data.push([
                +new Date(item.timestamp),
                Math.floor(parseFloat(item.value))
              ]);
            }
            data.sort((a, b) => {
              return a[0] - b[0];
            });
            const series = [
              {
                name: this.metricTranscodeMediaLiveOptions.find(
                  option =>
                    option.value === this.selectedTranscodeMediaLiveMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#359eff"
                },
                data: data
              }
            ];
            this.chartTranscodeMediaLiveOption = {
              ...axisConfig,
              series: series
            };
            this.chartTranscodeMediaLiveLoading = false;
            this.$forceUpdate();
          })
          .catch(() => {
            this.chartTranscodeMediaLiveLoading = false;
            this.showTranscodeMediaLive = false;
          });
      } else {
        this.showTranscodeMediaLive = false;
      }
    },

    updateMediaConnectSourceFlowChart() {
      this.chartCrossRegionLoading = true;
      if (this.selectedDelivery) {
        let promises = [];
        promises.push(
          this.getMetrics(
            "crossregion0",
            this.selectedDelivery.id,
            this.selectedCrossRegionMetric,
            new Date(this.startDate).toISOString().slice(0, -5),
            new Date(this.endDate).toISOString().slice(0, -5)
          )
        );
        if (this.selectedDelivery.sourceRegion1) {
          promises.push(
            this.getMetrics(
              "crossregion1",
              this.selectedDelivery.id,
              this.selectedCrossRegionMetric,
              new Date(this.startDate).toISOString().slice(0, -5),
              new Date(this.endDate).toISOString().slice(0, -5)
            )
          );
        }

        Promise.allSettled(promises)
          .then(results => {
            let data0 = [];
            let data1 = [];
            let series = [];
            if (results[0] && results[0].status === "fulfilled") {
              for (const item of results[0].value.data) {
                data0.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data0.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.crossRegionOptions.find(
                  option => option.value === this.selectedCrossRegionMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#359eff"
                },
                data: data0
              });
            }
            if (results[1] && results[1].status === "fulfilled") {
              for (const item of results[1].value.data) {
                data1.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data1.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.crossRegionOptions.find(
                  option => option.value === this.selectedCrossRegionMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#c41d7f"
                },
                data: data1
              });
            } else {
              this.showCrossRegionRedundancy = false;
            }
            this.chartCrossRegionOption = {
              ...axisConfig,
              series: series
            };
            this.chartCrossRegionLoading = false;
          })
          .catch(() => {
            this.chartCrossRegionLoading = false;
          });
      }
    },
    updateTranscodeChart() {
      this.chartTranscodeLoading = true;
      if (this.selectedDelivery) {
        let promises = [];
        promises.push(
          this.getMetrics(
            "transcode0",
            this.selectedDelivery.id,
            this.selectedTranscodeMetric,
            new Date(this.startDate).toISOString().slice(0, -5),
            new Date(this.endDate).toISOString().slice(0, -5)
          )
        );
        if (this.selectedDelivery.sourceRegion1) {
          promises.push(
            this.getMetrics(
              "transcode1",
              this.selectedDelivery.id,
              this.selectedTranscodeMetric,
              new Date(this.startDate).toISOString().slice(0, -5),
              new Date(this.endDate).toISOString().slice(0, -5)
            )
          );
        }

        Promise.allSettled(promises)
          .then(results => {
            let data0 = [];
            let data1 = [];
            let series = [];
            if (results[0] && results[0].status === "fulfilled") {
              for (const item of results[0].value.data) {
                data0.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data0.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.transcodeOptions.find(
                  option => option.value === this.selectedTranscodeMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#359eff"
                },
                data: data0
              });
            }
            if (results[1] && results[1].status === "fulfilled") {
              for (const item of results[1].value.data) {
                data1.push([
                  +new Date(item.timestamp),
                  Math.floor(parseFloat(item.value))
                ]);
              }
              data1.sort((a, b) => {
                return a[0] - b[0];
              });
              series.push({
                name: this.transcodeOptions.find(
                  option => option.value === this.selectedTranscodeMetric
                ).text,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                  color: "#c41d7f"
                },
                data: data1
              });
            } else {
              this.showTranscodeRedundancy = false;
            }
            this.chartTranscodeOption = {
              ...axisConfig,
              series: series
            };
            this.chartTranscodeLoading = false;
            this.$forceUpdate();
          })
          .catch(() => {
            this.chartTranscodeLoading = false;
          });
      }
    }
  },
  computed: {
    sourceOptions() {
      return [
        { text: "Source ARQ Recovered", value: "SourceARQRecovered" },
        { text: "Source ARQ Requests", value: "SourceARQRequests" },
        { text: "Source BitRate (bps)", value: "SourceBitRate" },
        { text: "Source CAT Error", value: "SourceCATError" },
        { text: "Source Connected", value: "SourceConnected" },
        { text: "Source Continuity Counter", value: "SourceContinuityCounter" },
        { text: "Source CRC Error", value: "SourceCRCError" },
        { text: "Source Disconnections", value: "SourceDisconnections" },
        { text: "Source Dropped Packets", value: "SourceDroppedPackets" },
        { text: "Source FEC Packets", value: "SourceFECPackets" },
        { text: "Source FEC Recovered", value: "SourceFECRecovered" },
        { text: "Source Merge Active", value: "SourceMergeActive" },
        { text: "Source Merge Latency", value: "SourceMergeLatency" },
        {
          text: "Source Not Recovered Packets",
          value: "SourceNotRecoveredPackets"
        },
        { text: "Source Overflow Packets", value: "SourceOverflowPackets" },
        {
          text: "Source Packet Loss Percent",
          value: "SourcePacketLossPercent"
        },
        { text: "Source PAT Error", value: "SourcePATError" },
        { text: "Source PCR Accuracy Error", value: "SourcePCRAccuracyError" },
        { text: "Source PCR Error", value: "SourcePCRError" },
        { text: "Source PID Error", value: "SourcePIDError" },
        { text: "Source PMT Error", value: "SourcePMTError" },
        { text: "Source PTS Error", value: "SourcePTSError" },
        { text: "Source Recovered Packets", value: "SourceRecoveredPackets" },
        { text: "Source Round Trip Time", value: "SourceRoundTripTime" },
        { text: "Source Total Packets", value: "SourceTotalPackets" },
        { text: "Source Transport Error", value: "SourceTransportError" },
        { text: "Source TS Byte Error", value: "SourceTSByteError" },
        { text: "Source TS Sync Loss", value: "SourceTSSyncLoss" }
      ];
    },

    crossRegionOptions() {
      return [
        { text: "ARQ Recovered", value: "ARQRecovered" },
        { text: "ARQ Requests", value: "ARQRequests" },
        { text: "BitRate (bps)", value: "BitRate" },
        { text: "CAT Error", value: "CATError" },
        { text: "Connected", value: "Connected" },
        { text: "Connected Outputs", value: "ConnectedOutputs" },
        { text: "Continuity Counter", value: "ContinuityCounter" },
        { text: "CRC Error", value: "CRCError" },
        { text: "Disconnections", value: "Disconnections" },
        { text: "Dropped Packets", value: "DroppedPackets" },
        { text: "Failover Switches", value: "FailoverSwitches" },
        { text: "FEC Packets", value: "FECPackets" },
        { text: "FEC Recovered", value: "FECRecovered" },
        { text: "Merge Active", value: "MergeActive" },
        { text: "Merge Latency", value: "MergeLatency" },
        { text: "Not Recovered Packets", value: "NotRecoveredPackets" },
        { text: "Output Connected", value: "OutputConnected" },
        { text: "Output Disconnections", value: "OutputDisconnections" },
        { text: "Overflow Packets", value: "OverflowPackets" },
        { text: "Packet Loss Percent", value: "PacketLossPercent" },
        { text: "PAT Error", value: "PATError" },
        { text: "PCR Accuracy Error", value: "PCRAccuracyError" },
        { text: "PCR Error", value: "PCRError" },
        { text: "PID Error", value: "PIDError" },
        { text: "PMT Error", value: "PMTError" },
        { text: "PTS Error", value: "PTSError" },
        { text: "Recovered Packets", value: "RecoveredPackets" },
        { text: "Round Trip Time", value: "RoundTripTime" },
        ...this.sourceOptions
      ];
    },
    transcodeOptions() {
      // transcodeOptions is to be available for usage in other graph implementations. For now, it serves as a placeholder.
      return [...this.crossRegionOptions];
    },
    metricTranscodeMediaLiveOptions() {
      return [
        { text: "Active Alerts", value: "ActiveAlerts" },
        { text: "Active Outputs", value: "ActiveOutputs" },
        {
          text: "Channel Input Error Seconds",
          value: "ChannelInputErrorSeconds"
        },
        {
          text: "Fec Column Packets Received",
          value: "FecColumnPacketsReceived"
        },
        {
          text: "Fec Row Packets Received",
          value: "FecRowPacketsReceived"
        },
        { text: "Fill Msec", value: "FillMsec" },
        { text: "Input Loss Seconds", value: "InputLossSeconds" },
        {
          text: "Input Timecodes Present",
          value: "InputTimecodesPresent"
        },
        {
          text: "Input Video Frame Rate",
          value: "InputVideoFrameRate"
        },
        { text: "Network In", value: "NetworkIn" },
        { text: "Network Out", value: "NetworkOut" },
        { text: "Output 4xx Errors", value: "Output4xxErrors" },
        { text: "Output 5xx Errors", value: "Output5xxErrors" },
        {
          text: "Output Audio Level Dbfs",
          value: "OutputAudioLevelDbfs"
        },
        {
          text: "Output Audio Level Lkfs",
          value: "OutputAudioLevelLkfs"
        },
        { text: "Pipelines Locked", value: "PipelinesLocked" },
        { text: "Primary Input Active", value: "PrimaryInputActive" },
        { text: "Rtp Packets Lost", value: "RtpPacketsLost" },
        { text: "Rtp Packets Received", value: "RtpPacketsReceived" },
        {
          text: "Rtp Packets Recovered Via Fec",
          value: "Rtp Packets Recovered Via Fec"
        }
      ];
    }
  },
  watch: {
    selectedSourceMetric: function() {
      this.updateSourceChart();
    },
    selectedTranscodeMetric: function() {
      this.updateTranscodeChart();
    },
    selectedCrossRegionMetric: function() {
      this.updateMediaConnectSourceFlowChart();
    },
    selectedTranscodeMediaLiveMetric: function() {
      this.updateTranscodeMediaLiveChart();
    }
  },
  mounted() {
    this.updateTimeFrame(timeFrames[0].id);
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      chartOption: axisConfig,
      timeFrames: timeFrames,
      selectedTimeFrame: "1h",

      chartSourceLoading: true,
      selectedSourceMetric: "SourceBitRate",
      chartSourceOption: {},

      chartTranscodeLoading: true,
      selectedTranscodeMetric: "Connected",
      showTranscodeRedundancy: true,
      chartTranscodeOption: {},

      chartCrossRegionLoading: true,
      selectedCrossRegionMetric: "Connected",
      showCrossRegionRedundancy: true,
      chartCrossRegionOption: {},

      chartTranscodeMediaLiveLoading: true,
      selectedTranscodeMediaLiveMetric: "ActiveAlerts",
      showTranscodeMediaLive: true,
      chartTranscodeMediaLiveOption: {}
    };
  }
};
</script>
<style scoped>
.opsChart__loadingIcon {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.opsChart__select {
  width: auto !important;
}
.opsChart__firstLine {
  color: #359eff;
}
.opsChart__secondLine {
  color: #c41d7f;
}
.opsChart__header {
  min-height: 60px;
  display: flex;
  align-items: end;
}
</style>
