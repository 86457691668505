<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button variant="confirm" class="mr-3" :href="toCreate"
          >Create destination</gl-button
        >
        <gl-dropdown
          text="Bulk actions"
          class="mr-3"
          :disabled="bulkContextMenuItems.length === 0"
        >
          <gl-dropdown-item
            v-for="item in bulkContextMenuItems"
            :key="item.key"
            >{{ item.text }}</gl-dropdown-item
          >
        </gl-dropdown>
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'name'">
              <span class="cellTitle">{{ r.name }}</span
              ><br />
              <span>{{ r.sourceType }}</span>
            </div>
            <div v-else-if="field === 'created'">
              <span class="cellTitle">{{ toLocalDate(r.created) }}</span
              ><br />
              <span>{{ toLocalTime(r.created) }}</span>
            </div>
            <div v-else-if="field === 'destinationType'">
              <span v-if="r.destinationType === DESTINATION_TYPES.RTP"
                >RTP ({{
                  r.ipDestinationType === "rtp" ? "STANDARD" : "FEC"
                }})</span
              >
              <span v-else>
                {{ findKeyByValue(DESTINATION_TYPES, r.destinationType) }}
              </span>
            </div>
          </template>
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
        <p v-if="showOutputIpMessage">
          The URL will appear here when the delivery begins
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AppConfig from "@/app-config";
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import {
  ALIAS_ITEMS_DESTINATIONS,
  DESTINATION_TYPES,
  ITEMS_DESTINATIONS
} from "@/constants.js";
import dateUtils from "@/mixins/dateUtils";
import debouncedInput from "@/mixins/debouncedInput.js";
import deliveryApiClient from "@/mixins/deliveryApiClient";
import destinationApiClient from "@/mixins/destinationApiClient.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
const { ADD_FIRST } = ALIAS_ITEMS_DESTINATIONS;
const { EDIT, SHOW } = ITEMS_DESTINATIONS;

import destinationActions from "@/mixins/destinationActions";
import {
  GlButton,
  GlDropdown,
  GlDropdownItem,
  GlForm,
  GlSearchBoxByType
} from "@gitlab/ui";
export default {
  name: "YourDestinations",
  mixins: [
    dateUtils,
    destinationApiClient,
    streamProfileClient,
    debouncedInput,
    destinationActions,
    deliveryApiClient
  ],
  components: {
    ArqDataGrid,
    GlForm,
    GlButton,
    ArqDataGridDetailsPane,
    GlDropdown,
    GlDropdownItem,
    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    status: {
      type: String,
      default: "ENABLED"
    }
  },
  methods: {
    findKeyByValue(obj, value) {
      const key = Object.keys(obj).find(key => obj[key] === value);
      return key.replace("_", " ") || null;
    },
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    async contextMenuClick(row, option) {
      switch (option) {
        case `clone`:
          {
            this.clone(row);
          }
          break;
        case `update`:
          this.$router.push(`${EDIT}/${row.id}`);
          break;
        case `archive`:
          await this.archive(row);
          this.onDataNeeded(this.sortModel, this.pageModel, this.searchPhrase);
          break;
        case `delete`:
          if (row.isPrecreated) {
            return;
          }
          await this.delete(row);
          this.onDataNeeded(this.sortModel, this.pageModel, this.searchPhrase);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
        this.getDestinationDeliveryStatus(this.selectedRow.id)
          .then(result => {
            this.selectedRow.deliveryName = result.data.contributionName;
            this.selectedRow = JSON.parse(JSON.stringify(this.selectedRow));
          })
          .catch(() => {
            this.selectedRow.deliveryName = null;
          });
        if (!this.selectedRow.profile) {
          this.streamProfileClient
            .getById(this.selectedRow.streamProfileId)
            .then(streamProfile => {
              this.selectedRow.profile = this.streamProfileEvaler(
                streamProfile
              );
              this.selectedRow = JSON.parse(JSON.stringify(this.selectedRow));
            });
        }
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.destinationApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }
      query = query.filterBy(phrase);

      if (this.status !== "") {
        query = query.param("status", this.status);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        if (response.items) {
          response.items.forEach(i => {
            i.destinationType =
              i.ipDestinationType === "rist"
                ? DESTINATION_TYPES.RIST
                : i.destinationType;
            if (
              i.destinationType === DESTINATION_TYPES.ZIXI_PULL &&
              i.listenerAddress
            ) {
              i.zixiUrl = `zixi://${i.remoteId}@${i.listenerAddress}:2077/${i.streamId}`;
            }
            i.regionName = i.region
              ? AppConfig.instance.config.options.regions[i.region]
                ? AppConfig.instance.config.options.regions[i.region]
                : i.region
              : "Not set";
          });
        }
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  computed: {
    showOutputIpMessage() {
      return (
        this.selectedRow &&
        this.selectedRow.destinationType === "zixipulldestination"
      );
    },
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = this.contextMenuItems;
          if (row.status && row.status.toLowerCase() === "archived") {
            out[row.id] = out[row.id].filter(item => item.id !== "archive");
          }
          if (row.isPrecreated) {
            out[row.id] = out[row.id].filter(item => item.id !== "delete");
          }
        }
      }
      return out;
    }
  },
  data() {
    return {
      DESTINATION_TYPES: DESTINATION_TYPES,
      toCreate: `#${ADD_FIRST}`,
      searchPhrase: "",
      sortModel: {},
      pageModel: {},
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      bulkContextMenuItems: [],
      contextMenuItems: [
        {
          id: "clone",
          text: "Clone"
        },
        {
          id: "update",
          text: "Edit"
        },
        {
          id: "archive",
          text: "Archive"
        },
        {
          id: "delete",
          text: "Delete"
        }
      ],
      columns: [
        {
          text: "Name",
          field: "name",
          isSortable: true,
          clicked: row => {
            this.$router.push(`${SHOW}/${row.id}`);
          }
        },
        {
          text: "Type",
          field: "destinationType"
        }
      ],
      detailsColumns: [
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Type",
          field: "destinationType"
        },
        {
          text: "RTP Type",
          field: "ipDestinationType"
        },
        {
          text: "IP Address",
          field: "ipAddress"
        },
        {
          text: "IP Address AWS Region",
          field: "ipAddressAwsRegion"
        },
        {
          text: "Port",
          field: "port"
        },
        {
          text: "Entitlement ARN",
          field: "entitlementArn"
        },
        {
          text: "Zixi URL",
          field: "zixiUrl",
          copyable: true
        },
        {
          text: "Stream ID",
          field: "streamId"
        },
        {
          text: "Remote ID",
          field: "remoteId"
        },
        {
          text: "Listener Address",
          field: "listenerAddress"
        },
        {
          text: "Region",
          field: "regionName"
        },
        {
          text: "Profile",
          field: "profile"
        },
        { text: "Public Out IP", field: "publicOutboundIp" },
        { text: "Delivered to", field: "deliveryName" }
      ]
    };
  }
};
</script>
