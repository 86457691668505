<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { ITEMS_MONITORING, MAIN_ITEMS } from "@/constants.js";

export default {
  components: { MainSection },
  data() {
    return {
      pageTitle: `Monitoring`,
      breadcrumbItems: [{ text: `Monitoring`, href: MAIN_ITEMS.MONITORING }]
    };
  },
  computed: {
    tabsItems() {
      return [
        {
          title: `Network Browser`,
          subPageName: ITEMS_MONITORING.NETWORK_BROWSER
        }
      ];
    }
  }
};
</script>
