<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
    <PriceConfig />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import PriceConfig from "@/components/Pricing/PriceConfig";
import { MAIN_ITEMS, ITEMS_PRICING } from "@/constants.js";
const { PRICE_CONFIG } = ITEMS_PRICING;

export default {
  components: { PriceConfig, Breadcrumb },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Price Config`, href: PRICE_CONFIG }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Pricing`
    };
  }
};
</script>
