<template>
  <tr>
    <th>
      Contribution Name
    </th>
    <th>
      First Destination
    </th>
    <th>
      Second Destination
    </th>
    <th>
      Start Date and Time
    </th>
    <th>
      End Date and Time
    </th>
    <th>
      Setup Price
    </th>
    <th>
      Hourly Price
    </th>
    <th>
      Total
    </th>
    <th>
      <!-- Placeholder for the delete/ remove contribution button -->
    </th>
  </tr>
</template>
<script>
export default {
  name: "TableHead"
};
</script>
