<template>
  <div>
    <gl-tabs>
      <gl-tab
        v-for="(item, index) in items"
        :key="`tab${index}`"
        @click="redirectToSubPage(item.subPageName)"
        :active="isSelected(item.subPageName)"
        :disabled="item.disabled"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
          <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge">{{
            item.badge
          }}</gl-badge>
        </template>
      </gl-tab>
    </gl-tabs>
    <router-view class="mt-3" />
  </div>
</template>

<script>
import { GlTabs, GlTab, GlBadge } from "@gitlab/ui";
export default {
  components: { GlTabs, GlTab, GlBadge },
  props: {
    items: {
      type: Array
    }
  },
  methods: {
    redirectToSubPage(subPageName) {
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.$router.currentRoute.path === subPageName;
    }
  }
};
</script>

<style lang="scss">
.gl-tab-nav-item-active-indigo {
  box-shadow: inset 0 -2px 0 0 var(--theme-color) !important;
}
.gl-tab-nav-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0.75rem !important;
  margin-right: 1em;
}
</style>
