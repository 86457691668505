<template>
  <gl-form @submit.prevent="submit">
    <div class="mt-5">
      <p>
        Note: The second destination will only apply to redundant deliveries
      </p>
      <gl-button-group>
        <gl-button
          v-gl-collapse-toggle.collapse-first-destination
          data-testid="first-destination-button"
        >
          First Destination
        </gl-button>
        <gl-button
          v-gl-collapse-toggle.collapse-second-destination
          :disabled="!allowMultiSelect"
          data-testid="second-destination-button"
        >
          Second Destination
        </gl-button>
      </gl-button-group>
      <div class="mt-5 d-flex" v-if="adminOnly">
        <h4>
          Admin only:
        </h4>
        <gl-form-checkbox v-on:change="handleAdminOnlyChange" />
      </div>
      <gl-collapse
        v-if="accessLevel === 'arqade_admin'"
        id="collapse-first-destination"
        class="gray-section"
        v-model="visible"
        accordion="destination-accordion"
      >
        <smart-select
          returnSelectedRow
          :dataSource="destinationApiClient"
          @itemSelected="selectDestination0($event)"
          :filters="{ accessLevel: accessLevel, status: 'ENABLED' }"
          :selected="destination0 ? destination0.id : null"
          :disabledItems="[destination1 ? destination1.id : null]"
        ></smart-select>
      </gl-collapse>

      <gl-collapse
        v-if="accessLevel === 'user'"
        id="collapse-first-destination"
        class="gray-section mt-3"
        v-model="visible"
        accordion="destination-accordion"
      >
        <smart-select
          returnSelectedRow
          :dataSource="destinationApiClient"
          :filters="{ status: 'ENABLED' }"
          @itemSelected="selectDestination0($event)"
          :selected="destination0 ? destination0.id : null"
          :disabledItems="[destination1 ? destination1.id : null]"
        ></smart-select>
      </gl-collapse>
      <gl-collapse
        v-if="accessLevel === 'arqade_admin'"
        id="collapse-second-destination"
        class="gray-section mt-3"
        accordion="destination-accordion"
      >
        <smart-select
          returnSelectedRow
          :dataSource="destinationApiClient"
          @itemSelected="selectDestination1($event)"
          :filters="{ accessLevel: accessLevel, status: 'ENABLED' }"
          :disabledItems="[destination0 ? destination0.id : null]"
          :selected="destination1 ? destination1.id : null"
          :isRequired="false"
        ></smart-select>
      </gl-collapse>

      <gl-collapse
        v-if="accessLevel === 'user'"
        id="collapse-second-destination"
        class="gray-section mt-3"
        accordion="destination-accordion"
      >
        <smart-select
          returnSelectedRow
          :dataSource="destinationApiClient"
          :filters="{ status: 'ENABLED' }"
          @itemSelected="selectDestination1($event)"
          :disabledItems="[destination0 ? destination0.id : null]"
          :selected="destination1 ? destination1.id : null"
          :isRequired="false"
        ></smart-select>
      </gl-collapse>
      <gl-button
        style="float: right; margin-top: 20px"
        class="mr-3 mb-3"
        :disabled="!this.destinationsDeps"
        type="submit"
        variant="confirm"
        >Next step
      </gl-button>
    </div>
  </gl-form>
</template>

<script>
import {
  GlButtonGroup,
  GlButton,
  GlCollapse,
  GlForm,
  GlFormCheckbox
} from "@gitlab/ui";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import destinationApiClient from "@/mixins/destinationApiClient";
import AppConfig from "@/app-config";
import userInfo from "@/mixins/userInfo";
export default {
  name: "Destinations",
  mixins: [destinationApiClient, userInfo],
  components: {
    GlForm,
    SmartSelect,
    GlButtonGroup,
    GlButton,
    GlFormCheckbox,
    GlCollapse
  },
  data() {
    return {
      visible: true,
      accessLevel: "user"
    };
  },
  props: {
    destination0: {
      type: Object,
      default: null
    },
    destination1: {
      type: Object,
      default: null
    },
    allowMultiSelect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: `Next step`,
          type: `submit`,
          disabled: !this.destinationsDeps
        }
      ];
    },
    adminOnly() {
      return (
        (this.isArqadeAdminRoleActive || this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    },
    destinationsDeps() {
      if (this.allowMultiSelect) {
        return !!this.destination0 && !!this.destination1;
      }
      return !!this.destination0;
    }
  },
  methods: {
    handleAdminOnlyChange(e) {
      this.accessLevel = e ? "arqade_admin" : "user";
      this.$emit("removeSelectedDestinations");
    },
    selectDestination0(item) {
      this.$emit("selectDestination0", item);
    },
    selectDestination1(item) {
      this.$emit("selectDestination1", item);
    },
    async submit() {
      if (this.destination0) {
        this.$emit("submitSpecification");
      }
    }
  }
};
</script>
