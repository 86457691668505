import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  created() {
    this.userMgmtApiClient.enableRole = (email, role) => {
      const rolesUrl = `${AppConfig.instance.config.API_URLS.USER_MGMT}users/${email}`;
      const client = new ResourceApiClient(rolesUrl, "roles");
      return client.update(role, {});
    };
    this.userMgmtApiClient.disableRole = (email, role) => {
      const rolesUrl = `${AppConfig.instance.config.API_URLS.USER_MGMT}users/${email}`;
      const client = new ResourceApiClient(rolesUrl, "roles");
      return client.remove(role, {});
    };
  },
  data() {
    return {
      userMgmtApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.USER_MGMT,
        "users"
      )
    };
  }
};
