const auth = {
  namespaced: true,
  state: {
    userInfo: null
  },
  mutations: {
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {},
  modules: {},
  getters: {}
};

export default auth;
