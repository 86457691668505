<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button variant="confirm" class="mr-3" :href="toCreate"
          >Create source</gl-button
        >
        <gl-dropdown
          text="Bulk actions"
          class="mr-3"
          :disabled="bulkContextMenuItems.length === 0"
        >
          <gl-dropdown-item
            v-for="item in bulkContextMenuItems"
            :key="item.key"
            @click="contextMenuClick(selectedRows, item.id)"
            >{{ item.text }}</gl-dropdown-item
          >
        </gl-dropdown>
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :rowPoller="rowPoller"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          @dataUpdated="updateVideoData"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'name'">
              <span class="cellTitle">{{ r.name }}</span
              ><br />
              <span v-if="r.sourceType === 'ipsource' && r.protocol === 'rist'"
                >RIST</span
              >
              <span v-else>{{ getNameOfTypeSource(r.sourceType) }}</span>
            </div>
            <div v-else-if="field === 'created'">
              <span class="cellTitle">{{ toLocalDate(r.created) }}</span
              ><br />
              <span>{{ toLocalTime(r.created) }}</span>
            </div>
            <StatusCell
              v-else-if="field === 'status' || field === 'previewStatus'"
              :status="r[field]"
              class="l"
            />
          </template>
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <div class="border-bottom border-light mb-3">
          <div class="video-container mb-3">
            <video
              class="video-player"
              ref="video-player"
              autoplay
              volume="0"
            ></video>
            <div class="preview-tab" v-if="previewVisible">
              <p v-if="this.selectedRow.previewStatus === 'STARTING'">
                <gl-loading-icon inline color="light" size="sm" /> Preparing
                Preview
              </p>
              <p v-if="this.selectedRow.previewStatus === 'STARTED'">
                {{ timeRemaining }} Remaining
              </p>
            </div>
          </div>
          <p>
            <gl-button
              :disabled="isPreviewButtonDisabled"
              class="standard-w"
              @click="requestPreview(selectedRows[0])"
              >Request preview</gl-button
            >
          </p>
          <p v-if="previewVisible">
            <gl-button
              class="standard-w"
              @click="requestPreview(selectedRows[0], true)"
              >Extend preview</gl-button
            >
          </p>
          <p>
            <gl-button
              :disabled="isCreateContributionDisabled"
              class="standard-w"
              variant="confirm"
              @click="createContribution(selectedRows[0].id)"
              >Create contribution</gl-button
            >
          </p>
          <p>
            <gl-button :disabled="true" class="standard-w"
              >Deliver to Self</gl-button
            >
          </p>
        </div>
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import sourceApiClient from "@/mixins/sourceApiClient.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
import {
  ALIAS_ITEMS_SOURCE,
  ITEMS_SOURCES,
  SOURCE_TYPE_OPTIONS,
  SOURCE_TYPES
} from "@/constants.js";
import debouncedInput from "@/mixins/debouncedInput.js";
const { ADD_FIRST } = ALIAS_ITEMS_SOURCE;
const { EDIT, SHOW } = ITEMS_SOURCES;
import Hls from "hls.js";
import ResourcePoller from "@/classes/ResourcePoller.js";
import AppConfig from "@/app-config";
import sourceActions from "@/mixins/sourceActions";
import {
  GlDropdownItem,
  GlDropdown,
  GlForm,
  GlLoadingIcon,
  GlButton,
  GlSearchBoxByType
} from "@gitlab/ui";

export default {
  name: "YourAll",
  mixins: [
    dateUtils,
    sourceApiClient,
    streamProfileClient,
    debouncedInput,
    sourceActions
  ],
  components: {
    ArqDataGrid,
    StatusCell,
    GlButton,
    ArqDataGridDetailsPane,
    GlDropdown,
    GlDropdownItem,
    GlForm,
    GlLoadingIcon,
    GlSearchBoxByType
  },
  props: {
    status: {
      type: String,
      default: "all"
    },
    accessLevel: {
      type: String,
      default: "user"
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  mounted() {
    this.hls = new Hls();
    this.videoRef = this.$refs["video-player"];
    this.rowPoller = new ResourcePoller(
      id => {
        return this.sourceApiClient.getById(id);
      },
      AppConfig.instance.config.API_OPTIONS.ROW_UDPATE_THRESHOLD,
      "previewStatus",
      "previewUrl",
      "previewEndTime"
    );
  },
  destroyed() {
    this.clearVideoTimer();
  },
  methods: {
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `clone`: {
          this.clone(row);
          break;
        }
        case `update`:
          this.$router.push(`${EDIT}/${row.id}`);
          break;
        case `createContribution`:
          this.createContribution(row.id);
          break;
        case `archive`:
          this.archive(row).then(() => {
            this.onDataNeeded(this.sortModel, this.pageModel, this.phrase);
          });
          break;
        case `preview`:
          this.requestPreview(row.id);
          break;
        case `sendDetailsToAdmin`:
          this.sendDetailsToAdmin(row);
          break;
      }
    },
    updateVideoData() {
      const previewAvailable =
        this.selectedRow &&
        this.selectedRow.previewUrl &&
        this.selectedRow.previewStatus === "STARTED";
      const mediaUnattached = !this.hls.media;
      const timeRemainingUpdateInterval = 200;
      /* istanbul ignore next: Bartek is having Kamil write tests for this later */
      if (previewAvailable && mediaUnattached) {
        // We have a preview source available but we're currently
        // not playing and should be
        this.hls.loadSource(this.selectedRow.previewUrl);
        this.hls.attachMedia(this.videoRef);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.videoRef.play();
        });
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal && data.type === Hls.ErrorTypes.NETWORK_ERROR) {
            // When the preview URL is first available, sometimes it will
            // throw a CORS error for the first thirty seconds or so while
            // it is still setting up. If we get this error, we detach the
            // media here so that next time this `updateVideoData` method is
            // called it can attempt to set up from scratch again.
            this.hls.detachMedia();
          }
        });
        this.clearVideoTimer();
        const endTime = Date.parse(this.selectedRow.previewEndTime);
        this.videoTimer = setInterval(() => {
          if (!isNaN(endTime)) {
            const totalSecondsLeft = Math.round((endTime - new Date()) / 1000);
            const minutes = Math.max(0, Math.floor(totalSecondsLeft / 60));
            const seconds = Math.max(0, totalSecondsLeft - minutes * 60);
            let formattedMinutes = `${minutes}`;
            let formattedSeconds = `${seconds}`;
            if (formattedMinutes.length === 1) {
              formattedMinutes = `0${formattedMinutes}`;
            }
            if (formattedSeconds.length === 1) {
              formattedSeconds = `0${formattedSeconds}`;
            }
            this.timeRemaining = `${formattedMinutes}:${formattedSeconds}`;
          }
        }, timeRemainingUpdateInterval);
      } else if (!previewAvailable && !mediaUnattached) {
        // We do not a preview source available but we're currently
        // still playing and need to stop
        this.videoRef.pause();
        this.hls.detachMedia();
        this.clearVideoTimer();
      }
    },
    clearVideoTimer() {
      if (this.videoTimer) {
        clearInterval(this.videoTimer);
        this.videoTimer = undefined;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
        if (!this.selectedRow.profile) {
          this.streamProfileClient
            .getById(this.selectedRow.streamProfileId)
            .then(profile => {
              this.selectedRow.profile = this.streamProfileEvaler(profile);
              this.selectedRow = JSON.parse(JSON.stringify(this.selectedRow));
            });
        }
      } else {
        this.selectedRow = undefined;
      }
      this.updateVideoData();
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.sourceApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query.filterBy(phrase);

      if (this.status !== "all") {
        query = query.param("status", this.status);
      }
      if (this.accessLevel !== "user") {
        query = query.param("accessLevel", this.accessLevel);
      }
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        if (response.items) {
          response.items.forEach(i => {
            i.sourceType =
              i.protocol === "rist" ? SOURCE_TYPES.RIST : i.sourceType;
          });
        }
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    },
    getNameOfTypeSource(sourceType) {
      const option = SOURCE_TYPE_OPTIONS.find(
        option => option.value === sourceType
      );
      return option ? option.text : sourceType;
    },
    sendDetailsToAdmin(rows) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to send the details of this source to the admin?`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value) {
            this.sourceApiClient
              .sendSourcesDetails(rows.map(row => row.id))
              .then(() => {
                this.handleApiSuccess(
                  `The details of this source(s) have been sent to the admin`
                );
              })
              .catch(e => {
                this.handleApiError(e);
              });
          }
        });
    }
  },
  computed: {
    previewVisible() {
      return (
        this.selectedRow &&
        (this.selectedRow.previewStatus === "STARTING" ||
          this.selectedRow.previewStatus === "STARTED")
      );
    },
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = this.contextMenuItems;
          if (row.status && row.status.toLowerCase() === "archived") {
            out[row.id].splice(3, 1);
          }
          if (this.accessLevel === "arqade_admin") {
            out[row.id].splice(3, 1);
          }
        }
      }
      return out;
    },
    isCreateContributionDisabled() {
      return (
        this.selectedRows.length > 1 ||
        this.selectedRows.length === 0 ||
        this.selectedRows[0].status === "ARCHIVED"
      );
    },
    isPreviewButtonDisabled() {
      return !(
        this.selectedRows.length === 1 &&
        this.selectedRows[0].previewStatus === "STOPPED" &&
        this.selectedRows[0].status !== "ARCHIVED"
      );
    },
    bulkContextMenuItems() {
      const bulkContextMenu = [];
      if (!this.isCreateContributionDisabled) {
        bulkContextMenu.push({
          id: "createContribution",
          text: "Create contribution"
        });
      }
      if (this.selectedRows.length >= 1) {
        bulkContextMenu.push({
          id: "sendDetailsToAdmin",
          text: "Send sources details"
        });
      }
      return bulkContextMenu;
    }
  },
  data() {
    return {
      videoTimer: undefined,
      rowPoller: undefined,
      hls: undefined,
      videoRef: undefined,
      toCreate: `#${ADD_FIRST}`,
      searchPhrase: "",
      sortModel: {},
      pageModel: {},
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      timeRemaining: "",
      contextMenuItems: [
        {
          id: "update",
          text: "Edit"
        },
        {
          id: "clone",
          text: "Clone"
        },
        {
          id: "createContribution",
          text: "Create contribution"
        },
        {
          id: "archive",
          text: "Archive"
        },
        {
          id: "preview",
          text: "Request preview"
        }
      ],
      columns: [
        {
          text: "Name",
          field: "name",
          isSortable: true,
          clicked: row => {
            this.$router.push(`${SHOW}/${row.id}`);
          }
        },
        {
          text: "Date created",
          field: "created",
          isSortable: true,
          defaultSort: true
        },
        {
          text: "IP",
          field: "host",
          center: true
        },
        {
          text: "Preview status",
          field: "previewStatus",
          center: true
        },
        {
          text: "Status",
          field: "status",
          isSortable: true,
          center: true
        }
      ],
      detailsColumns: [
        {
          text: "Name",
          field: "name"
        },
        {
          text: "Owner",
          field: "owner"
        },
        {
          text: "Description",
          field: "description"
        },
        {
          text: "Type",
          field: "sourceType"
        },
        {
          text: "Host",
          field: "host"
        },
        {
          text: "Status",
          field: "status"
        },
        {
          text: "Zone",
          field: "availabilityZone"
        },
        {
          text: "Flow ARN",
          field: "flowArn"
        },
        {
          text: "Source ARN",
          field: "sourceArn"
        },
        {
          text: "ARN",
          field: "arn"
        },
        {
          text: "Port",
          field: "port"
        },
        {
          text: "Stream ID",
          field: "streamId"
        },
        {
          text: "Profile",
          field: "profile"
        }
      ]
    };
  }
};
</script>
<style scoped>
.video-player {
  width: 334px;
  height: 188px;
  background-color: #f0f0f0;
  background-image: url("~@/assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.preview-tab {
  background-color: #1f1f1f;
  opacity: 0.9;
  color: #ffffff;
  width: 145px;
  height: 40px;
  position: absolute;
  bottom: 0;
}

.preview-tab p {
  line-height: 40px;
  font-size: 12px;
  text-align: center;
}

.video-container {
  position: relative;
  display: flex;
}
</style>
