var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedContribution),expression:"selectedContribution"}]},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',{staticClass:"mt-3"},[_vm._v("Delivery")]),_c('gl-button',{attrs:{"icon":"ellipsis_v","category":"tertiary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.contextMenuClick()}}})],1),_c('div',{staticClass:"mt-4 opsPanel__delivery"},[_c('gl-filtered-search',{staticClass:"mb-2",attrs:{"available-tokens":_vm.tokens},on:{"submit":_vm.activate,"clear":_vm.clear},scopedSlots:_vm._u([{key:"history-item",fn:function(ref){
var historyItem = ref.historyItem;
return [_vm._l((historyItem),function(token,idx){return [_c('span',{key:idx,staticClass:"px-1"},[_vm._v(" "+_vm._s(token.type)+" = "),_c('strong',[_vm._v(_vm._s(token.value))])])]})]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c('gl-loading-icon',{attrs:{"label":"Loading","size":"xl"}})],1),_c('ArqDataGrid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"data":_vm.data,"phrase":_vm.searchPhrase,"columns":_vm.columns,"itemsPerPage":_vm.itemsPerPage,"showContextMenu":true,"contextMenuItems":_vm.contextMenuItems,"showHeader":false,"selectOnClick":true},on:{"dataNeeded":_vm.onDataNeeded,"rowSelected":_vm.onRowSelected,"contextMenuClick":_vm.contextMenuClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var r = ref.row;
var field = ref.field;
return [(field === 'name')?_c('div',{staticClass:"deliverySingle",class:{
            'deliverySingle--inactive': _vm.isInactive(r)
          }},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('div',{staticClass:"ml-2"},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mb-0 deliverySingle__name",attrs:{"title":r.contributionName}},[_vm._v(" "+_vm._s(r.contributionName)+" ")]),_c('span',[_vm._v(_vm._s(r.status))])])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-badge',{attrs:{"variant":_vm.getBadgeVariant(r.source0_health)}},[_c('gl-icon',{attrs:{"name":_vm.getBadgeIcon(r.source0_health)}}),_vm._v("Source")],1),_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(r.source1_health != 'N/A'),expression:"r.source1_health != 'N/A'"}],attrs:{"variant":_vm.getBadgeVariant(r.source1_health)}},[_c('gl-icon',{attrs:{"name":_vm.getBadgeIcon(r.source1_health)}}),_vm._v("Redundancy")],1)],1)]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }