<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard
      :leftSectionTitle="leftSectionTitle"
      :buttons="buttons"
      :rightSectionTitle="rightSectionTitle"
    >
      <template v-slot:left-section>
        <gl-form-group v-if="isUpdate">
          <div class="d-flex align-items-center">
            <span class="mt-1 mr-2"
              >Status:
              <strong>{{ itemUnderEdit.status }}</strong>
            </span>
            <gl-button
              @click="toggleStatus()"
              :variant="
                itemUnderEdit.status !== 'ENABLED' ? 'confirm' : 'default'
              "
            >
              <span v-show="itemUnderEdit.status === 'ENABLED'">Disable</span>
              <span v-show="itemUnderEdit.status !== 'ENABLED'">Enable</span>
            </gl-button>
          </div>
          <p v-show="alertStatus" class="text-danger mt-2 mb-1">
            This will disable all contributions from this Source without further
            warning, until the Source is re-enabled
          </p>
        </gl-form-group>
        <gl-form-group label="Source Type" label-size="sm" label-for="type">
          <gl-form-radio-group
            id="type"
            v-model="itemUnderEdit.sourceType"
            :options="sourceTypeOptions"
            :disabled="isUpdate"
            class="mt-1 d-flex form-radio-group-inline justify-content-between"
          />
        </gl-form-group>
        <div class="gray-section" v-show="!!itemUnderEdit.sourceType">
          <gl-form-group
            class="m-4"
            label="Max Bitrate bits/sec"
            label-size="sm"
            label-for="maxBitrateBps"
            :invalid-feedback="invaliMaxBitrateBps"
          >
            <gl-form-select
              id="maxBitrateBps"
              v-model="itemUnderEdit.maxBitrateBps"
              :options="bitrateOptions"
              class="mt-1"
              :disabled="isUpdate"
              :required="true"
              :state="!!itemUnderEdit.maxBitrateBps"
            />
          </gl-form-group>

          <div
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.MEDIACONNECT"
            id="IpMediaConnectTypeBox"
          >
            <gl-form-group
              class="m-4"
              label="Entitlement ARN"
              label-size="sm"
              label-for="entitlementArn"
              :invalid-feedback="invalidArnFeedback"
            >
              <gl-form-input
                id="entitlementArn"
                type="text"
                v-model="itemUnderEdit.entitlementArn"
                placeholder="arn:aws:mediaconnect:us-east-1:123456789012:entitlement:flow-id:entitlement-name"
                maxlength="250"
                class="mt-1"
                :state="isValidArn"
              />
              <p class="mb-0">
                <small
                  >Use Arqade's account ID
                  <strong>{{ awsAccountId }}</strong> when creating MediaConnect
                  Entitlements</small
                >
              </p>
            </gl-form-group>
            <Crypto
              @change="onCryptoChange"
              :cryptoKey="itemUnderEdit.cryptoKey"
              :cryptoAlgo="itemUnderEdit.cryptoAlgo"
              :originalCryptoAlgo="originalCryptoAlgo"
            />
          </div>
          <div
            id="IpSourceTypeBox"
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.RIST"
          >
            <gl-form-group
              class="m-4"
              label="Allowed list Cidr"
              label-size="sm"
              label-for="whitelistCidr"
              :invalid-feedback="invalidCidrFeedback"
            >
              <gl-form-input
                id="whitelistCidr"
                type="text"
                v-model="itemUnderEdit.whitelistCidr"
                placeholder="192.168.0.1/24"
                maxlength="250"
                class="mt-1"
                :state="isValidCidr"
              />
            </gl-form-group>
            <hr />
            <IpProfile
              prefix="rist_"
              @change="onIpProfileChange"
              :portValue="itemUnderEdit.port"
              :ipTypeValue="'rist'"
              :showIpAddress="false"
              :showIpType="false"
            />
          </div>
          <div
            id="IpSourceTypeBox"
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.IP"
          >
            <gl-form-group
              class="m-4"
              label="Allowed list Cidr"
              label-size="sm"
              label-for="whitelistCidr"
              :invalid-feedback="invalidCidrFeedback"
            >
              <gl-form-input
                id="whitelistCidr"
                type="text"
                v-model="itemUnderEdit.whitelistCidr"
                placeholder="192.168.0.1/24"
                maxlength="250"
                class="mt-1"
                :state="isValidCidr"
              />
            </gl-form-group>
            <hr />
            <IpProfile
              @change="onIpProfileChange"
              :portValue="itemUnderEdit.port"
              :ipTypeValue="itemUnderEdit.protocol"
              :showIpAddress="false"
            />
          </div>
          <div
            id="ZixiSourceTypeBox"
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.ZIXI"
          >
            <gl-form-group
              class="m-4"
              label="Allowed list Cidr"
              label-size="sm"
              label-for="whitelistCidr"
              :invalid-feedback="invalidCidrFeedback"
            >
              <gl-form-input
                id="whitelistCidr"
                type="text"
                v-model="itemUnderEdit.whitelistCidr"
                placeholder="192.168.0.1/24"
                maxlength="250"
                class="mt-1"
                :state="isValidCidr"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              label="Max Latency (ms)"
              label-size="sm"
              :invalid-feedback="invalidMaxLatencyFeedback"
              label-for="maxLatencyMs"
            >
              <gl-form-input
                type="number"
                id="maxLatencyMs"
                v-model="itemUnderEdit.maxLatencyMs"
                placeholder="Latency (ms)"
                maxlength="6"
                class="mt-1"
                :state="isValidMaxLatency"
                min="0"
                max="60000"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              label="Stream ID"
              label-size="sm"
              label-for="streamId"
              :invalid-feedback="invalidStreamIdFeedback"
            >
              <gl-form-input
                id="streamId"
                type="text"
                v-model="itemUnderEdit.streamId"
                placeholder=""
                :state="isValidStreamId"
                class="mt-1"
              />
            </gl-form-group>
            <Crypto
              @change="onCryptoChange"
              :cryptoKey="itemUnderEdit.cryptoKey"
              :cryptoAlgo="itemUnderEdit.cryptoAlgo"
              :originalCryptoAlgo="originalCryptoAlgo"
            />
          </div>

          <div
            id="SrtSourceListenerTypeBox"
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.SRT_LISTENER"
          >
            <gl-form-group
              class="m-4"
              label="Allowed list Cidr"
              label-size="sm"
              label-for="whitelistCidr"
              :invalid-feedback="invalidCidrFeedback"
            >
              <gl-form-input
                id="whitelistCidr"
                type="text"
                v-model="itemUnderEdit.whitelistCidr"
                placeholder="192.168.0.1/24"
                maxlength="250"
                class="mt-1"
                :state="isValidCidr"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              label="Min Latency (ms)"
              label-size="sm"
              :invalid-feedback="invalidMinLatencyFeedback"
              label-for="minLatencyMs"
            >
              <gl-form-input
                type="number"
                id="minLatencyMs"
                v-model="itemUnderEdit.minLatencyMs"
                placeholder="Latency (ms)"
                maxlength="6"
                class="mt-1"
                :state="isValidMinLatency"
                min="100"
                max="15000"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              label="Port"
              label-size="sm"
              label-for="port"
              :invalid-feedback="invalidPortFeedback"
            >
              <gl-form-input
                type="number"
                id="port"
                v-model="itemUnderEdit.port"
                placeholder="Port"
                maxlength="5"
                required
                class="mt-1"
                :state="isValidPort"
                min="1024"
                max="65535"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              :label="
                itemUnderEdit.isEncrypted
                  ? 'SRT Password (already set)'
                  : 'SRT Password (optional)'
              "
              label-size="sm"
              label-for="passphrase"
            >
              <p
                v-if="
                  itemUnderEdit.isEncrypted && isUpdate && !cryptoKeyRemoved
                "
              >
                <small
                  >Password has already been set. You can either enter a new
                  password in the field below or
                  <gl-button
                    size="small"
                    category="secondary"
                    variant="danger"
                    @click="resetCryptoKey"
                    >remove it</gl-button
                  ></small
                >
              </p>
              <p v-else-if="cryptoKeyRemoved">
                <small
                  >Password set to be removed or type new password to
                  override</small
                >
              </p>
              <gl-form-input
                type="text"
                id="passphrase"
                v-model="itemUnderEdit.cryptoKey"
                placeholder="SRT Password"
                maxlength="255"
                class="mt-1"
              />
            </gl-form-group>
          </div>

          <div
            id="SrtSourceCallerTypeBox"
            v-if="itemUnderEdit.sourceType === SOURCE_TYPES.SRT_CALLER"
          >
            <gl-form-group
              class="m-4"
              label="Min Latency (ms)"
              label-size="sm"
              :invalid-feedback="invalidMinLatencyFeedback"
              label-for="minLatencyMs"
            >
              <gl-form-input
                type="number"
                id="minLatencyMs"
                v-model="itemUnderEdit.minLatencyMs"
                placeholder="Latency (ms)"
                maxlength="6"
                class="mt-1"
                :state="isValidMinLatency"
                min="100"
                max="15000"
              />
            </gl-form-group>
            <gl-form-group
              class="m-4"
              label="Port"
              label-size="sm"
              label-for="port"
              :invalid-feedback="invalidPortFeedback"
            >
              <gl-form-input
                type="number"
                id="port"
                v-model="itemUnderEdit.port"
                placeholder="Port"
                maxlength="5"
                required
                class="mt-1"
                :state="isValidPort"
                min="1024"
                max="65535"
              />
            </gl-form-group>

            <gl-form-group
              class="m-4"
              label="Listener Address"
              label-size="sm"
              label-for="listenerAddress"
              :invalid-feedback="invalidListenerAddressFeedback"
            >
              <gl-form-input
                type="text"
                id="listenerAddress"
                v-model="itemUnderEdit.listenerAddress"
                placeholder="192.0.2.0 or example.com"
                maxlength="255"
                class="mt-1"
                :state="isValidListenerAddress"
              />
            </gl-form-group>

            <gl-form-group
              class="m-4"
              label="Stream Id (optional)"
              label-size="sm"
              label-for="streamId"
              :invalid-feedback="invalidStreamIdFeedback"
            >
              <gl-form-input
                type="text"
                id="streamId"
                v-model="itemUnderEdit.streamId"
                placeholder="Stream Id"
                maxlength="255"
                class="mt-1"
                :state="isValidStreamId"
              />
            </gl-form-group>

            <gl-form-group
              class="m-4"
              :label="
                itemUnderEdit.isEncrypted
                  ? 'SRT Password (already set)'
                  : 'SRT Password (optional)'
              "
              label-size="sm"
              label-for="passphrase"
            >
              <p
                v-if="
                  itemUnderEdit.isEncrypted && isUpdate && !cryptoKeyRemoved
                "
              >
                <small
                  >Password has already been set. You can either enter a new
                  password in the field below or
                  <gl-button
                    size="small"
                    category="secondary"
                    variant="danger"
                    @click="resetCryptoKey"
                    >remove it</gl-button
                  ></small
                >
              </p>
              <p v-else-if="cryptoKeyRemoved">
                <small
                  >Password set to be removed or type new password to
                  override</small
                >
              </p>
              <gl-form-input
                type="text"
                id="passphrase"
                v-model="itemUnderEdit.cryptoKey"
                placeholder="SRT Password"
                maxlength="255"
                class="mt-1"
              />
            </gl-form-group>
          </div>
        </div>
      </template>
      <template v-slot:right-section>
        <gl-button-group class="mb-3">
          <gl-button v-gl-collapse-toggle.presetProfiles>
            Preset profiles
          </gl-button>
          <gl-button v-gl-collapse-toggle.customProfile>
            New custom profile
          </gl-button>
          <gl-button v-gl-collapse-toggle.customProfiles>
            Custom profiles
          </gl-button>
        </gl-button-group>
        <gl-collapse id="presetProfiles" accordion="profile" visible>
          <smart-select
            :dataSource="streamProfileClient"
            :textFormatter="streamProfileEvaler"
            @itemSelected="setStreamProfileId($event)"
            :selected="itemUnderEdit.streamProfileId"
            :filters="{ nonCustom: true }"
          ></smart-select>
        </gl-collapse>
        <gl-collapse
          id="customProfile"
          accordion="profile"
          v-model="isCustomProfile"
        >
          <gl-card>
            <stream-profile
              v-if="isCustomProfile"
              @change="updateStreamProfileForm"
              :streamProfile="currentProfile"
            />
          </gl-card>
        </gl-collapse>
        <gl-collapse id="customProfiles" accordion="profile">
          <smart-select
            :dataSource="streamProfileClient"
            :textFormatter="streamProfileEvaler"
            @itemSelected="setStreamProfileId($event)"
            :selected="itemUnderEdit.streamProfileId"
            :filters="{ nonCustom: false }"
          ></smart-select>
        </gl-collapse>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import {
  GlFormSelect,
  GlFormInput,
  GlForm,
  GlFormGroup,
  GlButtonGroup,
  GlButton,
  GlCollapse,
  GlCard,
  GlFormRadioGroup
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import IpProfile from "@/components/IpProfile";
import Crypto from "@/components/Crypto";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import streamProfileClient from "@/mixins/streamProfileClient";
import cidrRegex from "cidr-regex";
import currentItem from "@/mixins/currentItem";
import { SOURCE_TYPES, SOURCE_TYPE_OPTIONS } from "@/constants.js";
import StreamProfile from "@/components/StreamProfile.vue";
import { ALIAS_ITEMS_SOURCE } from "@/constants.js";
import AppConfig from "@/app-config";
import ipRegex from "ip-regex";

const { EDIT_FIRST } = ALIAS_ITEMS_SOURCE;

export default {
  name: "AddSecond",
  mixins: [currentItem, streamProfileClient],
  components: {
    Wizard,
    GlFormSelect,
    GlFormInput,
    GlForm,
    GlCollapse,
    GlButtonGroup,
    GlFormGroup,
    IpProfile,
    GlButton,
    Crypto,
    GlCard,
    SmartSelect,
    StreamProfile,
    GlFormRadioGroup
  },
  created() {
    if (!this.itemUnderEdit.isEncrypted) {
      // This enforcement is needed because currently the GET /sources/:id
      // is returning the cryptoAlgo is never null, because the source model default it to "aes256"
      // if in the future we avoid that defaulting, then we can remove this hack
      this.itemUnderEdit.cryptoAlgo = null;
    }

    if (this.itemUnderEdit.streamProfileId) {
      this.streamProfileClient
        .getById(this.itemUnderEdit.streamProfileId)
        .then(profile => {
          this.currentProfile = profile;
        });
    }

    this.originalCryptoAlgo = this.itemUnderEdit.cryptoAlgo;
  },
  computed: {
    invaliMaxBitrateBps() {
      return this.itemUnderEdit.maxBitrateBps
        ? ""
        : "Please select Max Bitrate";
    },
    invalidMaxLatencyFeedback() {
      return this.isValidMaxLatency
        ? ""
        : "Maximum latency should be between 0 and 60,000 ms";
    },
    invalidMinLatencyFeedback() {
      return this.isValidMinLatency
        ? ""
        : "Minimum latency should be between 100 and 15,000 ms";
    },
    invalidListenerAddressFeedback() {
      return this.isValidListenerAddress
        ? ""
        : "Must be a valid IP address or fully qualified domain name";
    },
    invalidStreamIdFeedback() {
      return this.isValidStreamId
        ? ""
        : "The streamId isn't valid. Enter a value that adheres to the following rules: The first and last characters must be letters (a-z, A-Z) or numbers (0-9); all other characters must be letters, numbers, dashes (-), or underscores (_); and the entire value can have up to 255 characters.";
    },
    invalidPortFeedback() {
      return this.isValidPort ? "" : "Please enter valid port: 1024-65535";
    },
    isValidPort() {
      return (
        this.itemUnderEdit.port >= 1024 && this.itemUnderEdit.port <= 65535
      );
    },
    isValidMaxLatency() {
      return (
        this.itemUnderEdit.maxLatencyMs !== "" &&
        this.itemUnderEdit.maxLatencyMs >= 0 &&
        this.itemUnderEdit.maxLatencyMs <= 60000
      );
    },
    isValidMinLatency() {
      return (
        this.itemUnderEdit.minLatencyMs !== "" &&
        this.itemUnderEdit.minLatencyMs >= 100 &&
        this.itemUnderEdit.minLatencyMs <= 15000
      );
    },
    isValidRistSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.RIST &&
        this.isValidCidr &&
        this.isIpProfileValid
      );
    },
    isValidIpSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.IP &&
        this.isValidCidr &&
        this.isIpProfileValid
      );
    },
    isValidMediaConnectSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.MEDIACONNECT &&
        this.isValidArn &&
        (!this.itemUnderEdit.isEncrypted || this.isCryptoValid)
      );
    },
    isValidZixiSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.ZIXI &&
        (!this.itemUnderEdit.isEncrypted || this.isCryptoValid) &&
        this.isValidMaxLatency &&
        this.isValidStreamId
      );
    },
    isValidListenerAddress() {
      const domain_regexp = /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i;
      return (
        domain_regexp.test(this.itemUnderEdit.listenerAddress) ||
        ipRegex({ exact: true }).test(this.itemUnderEdit.listenerAddress)
      );
    },
    isValidSrtListenerSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.SRT_LISTENER &&
        this.isValidPort &&
        this.isValidCidr &&
        this.isValidMinLatency
      );
    },
    isValidSrtCallerSource() {
      return (
        this.itemUnderEdit.sourceType === SOURCE_TYPES.SRT_CALLER &&
        this.isValidMinLatency &&
        this.isValidPort &&
        this.isValidListenerAddress
      );
    },
    // TODO: Revisit this logic
    isValidStreamId() {
      const regexp = /^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$/;
      if (this.itemUnderEdit.streamId) {
        return (
          regexp.test(this.itemUnderEdit.streamId) &&
          this.itemUnderEdit.streamId.length <= 256
        );
      }
      return false;
    },
    isFormValid() {
      const dataOk =
        !!this.itemUnderEdit.sourceType &&
        !!this.itemUnderEdit.maxBitrateBps &&
        (this.isValidIpSource ||
          this.isValidMediaConnectSource ||
          this.isValidZixiSource ||
          this.isValidSrtListenerSource ||
          this.isValidSrtCallerSource ||
          this.isValidRistSource);

      if (!dataOk) {
        return false;
      }

      if (this.isCustomProfile) {
        return this.isNewFormValid;
      } else {
        return !!this.itemUnderEdit.streamProfileId;
      }
    },
    isValidCidr() {
      return cidrRegex({ exact: true }).test(this.itemUnderEdit.whitelistCidr);
    },

    isValidArn() {
      //e.g. arn:aws:mediaconnect:eu-west-1:834951661701:entitlement:1-VFMMVgddV1wGUQRV-07643e05a76c:TP66-MUX-EGRESS-dev-1
      const reg = /^arn:aws:mediaconnect:(.+):([0-9]+):entitlement:(.+):(.+)$/;
      return reg.test(this.itemUnderEdit.entitlementArn);
    },
    invalidArnFeedback() {
      return this.isValidArn ? "" : "Please enter a valid ARN";
    },
    invalidCidrFeedback() {
      return this.isValidCidr ? "" : "Please enter valid CIDR.";
    },
    buttons() {
      return [
        {
          text: `Previous`,
          onClick: () => this.$router.push(EDIT_FIRST)
        },
        {
          text: `Next Step`,
          type: `submit`,
          disabled: !this.isFormValid || this.isBusy
        }
      ];
    }
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SOURCE_TYPES: SOURCE_TYPES,
      alertStatus: false,
      awsAccountId: AppConfig.instance.config.awsmobile.aws_account_id,
      bitrateOptions: [
        { value: "500000", text: "500 kb/s" },
        { value: "1000000", text: "1 Mb/s" },
        { value: "10000000", text: "10 Mb/s" },
        { value: "100000000", text: "100 Mb/s" },
        { value: "200000000", text: "200  Mb/s" }
      ],
      cryptoKeyRemoved: false,
      currentProfile: undefined,
      customStreamProfile: {},
      isBusy: false,
      isCryptoValid: false,
      isCustomProfile: false,
      isNewFormValid: false,
      isIpProfileValid: false,
      leftSectionTitle: `Specification`,
      listenerAddress: null,
      originalCryptoAlgo: undefined,
      rightSectionTitle: `Profiles`,
      sourceTypeOptions: SOURCE_TYPE_OPTIONS
    };
  },
  methods: {
    toggleStatus() {
      this.itemUnderEdit.status =
        this.itemUnderEdit.status === "ENABLED" ? "DISABLED" : "ENABLED";
      this.alertStatus = this.itemUnderEdit.status === "DISABLED";
    },
    setStreamProfileId(streamProfileId) {
      this.itemUnderEdit.streamProfileId = streamProfileId;
      this.itemUnderEdit = JSON.parse(JSON.stringify(this.itemUnderEdit));
    },
    updateStreamProfileForm(e) {
      this.isNewFormValid = e.isValid;
      this.customStreamProfile = e.profile;
    },
    onIpProfileChange(ipDetails) {
      const { port, type, isValid } = ipDetails;
      this.isIpProfileValid = isValid;
      this.itemUnderEdit.port = port;
      this.itemUnderEdit.ipType = type;
    },
    resetCryptoKey() {
      this.itemUnderEdit.cryptoKey = "";
      this.cryptoKeyRemoved = true;
    },
    onCryptoChange(cryptoDetails) {
      const { cryptoAlgo, cryptoKey, isValid } = cryptoDetails;
      this.isCryptoValid = cryptoAlgo ? isValid : true;
      this.itemUnderEdit.cryptoAlgo = cryptoAlgo;
      this.itemUnderEdit.cryptoKey = cryptoKey;
    },
    async nextStep() {
      this.isBusy = true;
      if (this.isCustomProfile) {
        try {
          const profileResponse = await this.streamProfileClient.create(
            this.customStreamProfile
          );
          this.itemUnderEdit.streamProfileId = profileResponse.id;
        } catch (e) {
          this.handleApiError(e);
          this.isBusy = false;
          return;
        }
      }
      this.itemUnderEdit.protocol = this.itemUnderEdit.ipType;
      this.$emit("goToStep3", this.itemUnderEdit);
      this.isBusy = false;
    }
  }
};
</script>
