<template>
  <div class="dashboard__contributions pt-1 pr-1">
    <div class="d-flex justify-content-between">
      <h4>Contributions</h4>
      <gl-button
        v-show="selectedPackage"
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4">
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="selectedPackage && !isLoading"
        :data="data"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        @rowSelected="onRowSelected"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center mb-2">
                <b-badge class="contributionSingle__type">{{
                  r.contributionType
                }}</b-badge>
                <div class="ml-2">
                  <p class="mb-0 contributionSingle__name" :title="r.name">
                    {{ r.name }}
                  </p>
                </div>
              </div>
              <div>
                <b-badge
                  variant="secondary"
                  class="dashboard__contribution__badge"
                  >{{ r.delivery_count }}</b-badge
                >
              </div>
            </div>
            <div class="d-flex align-items-center contributionSingle__bottom">
              <b-badge :variant="getBadgeVariant(r.source0_health)"
                ><gl-icon :name="getBadgeIcon(r.source0_health)" />{{
                  getBitrate(r.source0_bitrate)
                }}</b-badge
              >
              <b-badge
                :variant="getBadgeVariant(r.source1_health)"
                v-show="r.source1Id"
                ><gl-icon :name="getBadgeIcon(r.source1_health)" />{{
                  getBitrate(r.source1_bitrate)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
      <p v-show="!selectedPackage">
        Select a package and contribution to display connected deliveries to
        other organizations and to your own destinations
      </p>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient, contributionApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlButton } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";

export default {
  name: "Contributions",
  mixins: [metricsApiClient, contributionApiClient, userInfoMixin],
  components: { ArqDataGrid, GlLoadingIcon, GlButton },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    selectedPackage: {
      type: Object
    }
  },
  watch: {
    selectedPackage: function() {
      this.pageModel.currentPage = 0;
      this.onDataNeeded({}, this.pageModel);
    }
  },
  methods: {
    contextMenuClick() {},
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(health) {
      switch (health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(health) {
      switch (health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    getBitrate(bitrate) {
      if (bitrate > 1000000) {
        return (bitrate / 1000000).toFixed(1) + " Mb/s";
      } else if (bitrate > 1000) {
        return (bitrate / 1000).toFixed(1) + " kb/s";
      } else {
        return parseFloat(bitrate).toFixed(1) + " b/s";
      }
    },
    async onDataNeeded(_, pageModel) {
      this.pageModel = pageModel;
      if (!this.selectedPackage) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      this.isLoading = true;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;

      let query = (this.isArqadeAdminRoleActive
        ? this.getMetricsHealth("contributions")
        : this.getMetricsHealth("contributions/own")
      )
        .list()
        .page(currentPage)
        .perPage(itemsPerPage)
        .param("packageId", this.selectedPackage.id);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();

        for (const key in response.items) {
          const type = response.items[key].contributionType;
          const typeName = this.contributionTypes.find(t => t.value === type);
          response.items[key].contributionType = typeName
            ? typeName.text
            : type;
        }
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
    }
  },
  data() {
    return {
      pageModel: {
        itemsPerPage: this.itemsPerPage
      },
      selectedRow: undefined,
      data: {},
      isLoading: false,
      columns: [
        {
          text: "",
          field: "name"
        }
      ]
    };
  }
};
</script>
<style scoped>
h4 {
  color: var(--theme-color);
}
.dashboard__contributions {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.contributionSingle__bottom .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.contributionSingle__bottom .badge .gl-icon {
  margin-right: 5px;
}
.contributionSingle__bottom .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.contributionSingle__bottom .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.contributionSingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.contributionSingle__name {
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
.dashboard__contribution__badge {
  font-size: 12px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
</style>
