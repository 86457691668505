<template>
  <div>
    <Actions>
      <ContributionActions />
      <ExpandToggles v-if="data.items && itemsReady" :items="$refs.items" />
      <ClearSearch @clearSearchResults="clearSearchResults" />
      <slot name="moreActions"></slot>
    </Actions>
    <Table :pageModel="pageModel" @changePage="changePage" :items="data.items">
      <TableHead
        slot="head"
        :multiSelection="enableMultiSelection"
        :status="status"
        :searchFields="searchFields"
        :allSelected="allSelected"
        @changeSearchParams="changeSearchParams"
        @selectAll="selectAll"
      ></TableHead>
      <TableItem
        slot="body"
        v-for="row in data.items"
        v-bind:key="row.id"
        ref="items"
        :isSelected="selectedRows.includes(row.id)"
        :row="row"
        :multiSelection="enableMultiSelection"
        @selectRow="selectRow"
        @rowClick="handleClick"
      ></TableItem>
    </Table>
  </div>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import TableItem from "./TableItem.vue";
import TableHead from "./TableHead.vue";
import ContributionActions from "./Actions.vue";
import Table from "@/components/Table/Table.vue";
import Actions from "@/components/Table/Actions/Actions.vue";
import ClearSearch from "@/components/Table/Actions/ClearSearch.vue";
import ExpandToggles from "@/components/Table/Expand/Toggles.vue";
import {
  entitlementApiClient,
  organizationApiClient,
  packageApiClient,
  metricsApiClient
} from "@/mixins/apiClients";

import msgUtils from "@/mixins/msgUtils.js";

export default {
  name: "ContributionsAllPage",
  mixins: [
    msgUtils,
    metricsApiClient,
    userInfo,
    entitlementApiClient,
    organizationApiClient,
    packageApiClient
  ],
  components: {
    TableItem,
    TableHead,
    Table,
    ContributionActions,
    Actions,
    ClearSearch,
    ExpandToggles
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    contributionType: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    enableMultiSelection: {
      type: Boolean,
      default: false
    },
    packageId: {
      type: String,
      default: null
    }
  },
  watch: {
    "data.items": {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.items && this.$refs.items.length > 0) {
            this.itemsReady = true;
          }
        });
      }
    }
  },
  methods: {
    handleClick(e) {
      this.$router.push(`/contributions/show/${e}`);
    },
    selectRow(e, row) {
      if (e) {
        this.selectedRows.push(row.id);
      } else {
        this.selectedRows = this.selectedRows.filter(id => id !== row.id);
      }
      this.allSelected = this.selectedRows.length === this.data.items.length;
      this.$emit("selectRow", e, row);
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.data.items.map(item => item.id);
      }
      this.allSelected = !this.allSelected;
      for (const item of this.data.items) {
        this.$emit("selectRow", this.allSelected, item);
      }
    },
    async onDataNeeded(pageModel, searchFields, packageId = null) {
      let emptyResponse = this.getEmptyPagedResponse();
      let query;
      if (packageId) {
        query = await this.getMetricsHealth("contributions")
          .list()
          .page(pageModel.currentPage || 0)
          .perPage(pageModel.itemsPerPage || 10);
        query.param("packageId", packageId);
        query.param("contributionType", "event_contribution");
      } else {
        query = this.getMetricsHealth("contributions/own")
          .list()
          .page(pageModel.currentPage || 0)
          .perPage(pageModel.itemsPerPage || 0);
      }

      for (let [key, value] of Object.entries(searchFields)) {
        if (value) query.param(key, value);
      }

      if (this.contributionType) {
        query.param("contributionType", this.contributionType);
      }

      if (this.status) {
        const urlParam =
          this.status === "scheduled" || this.status === "cancelled"
            ? "scheduleStatus"
            : "deliveryStatus";
        const val =
          this.status === "scheduled" || this.status === "cancelled"
            ? this.status.toUpperCase()
            : this.status;
        query.param(urlParam, val);
      }

      let response = null;
      try {
        response = await query.exec();
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = response ?? emptyResponse;
      this.pageModel.currentPage = this.data.page;
      this.pageModel.itemsPerPage = this.itemsPerPage;
      this.pageModel.totalPages = this.data.totalPages;
      this.pageModel.totalItems = this.data.totalItems;
      this.searchFields = searchFields;
    },
    async changePage(pageModel) {
      this.pageModel = pageModel;
      await this.onDataNeeded(
        this.pageModel,
        this.searchFields,
        this.packageId
      );
      this.selectedRows = [];
      this.allSelected = false;
      this.$emit("changePage", pageModel);
    },
    async changeSearchParams(searchFields) {
      this.searchFields = searchFields;
      await this.onDataNeeded(
        this.pageModel,
        this.searchFields,
        this.packageId
      );
    },
    async clearSearchResults() {
      this.searchFields = {};
      await this.onDataNeeded(
        this.pageModel,
        this.searchFields,
        this.packageId
      );
    }
  },
  async created() {
    await this.onDataNeeded(this.pageModel, this.searchFields, this.packageId);
  },
  data() {
    return {
      selectedRows: [],
      allSelected: false,
      selectedDeliveriesForDetails: [],
      searchFields: {},
      pageModel: {
        currentPage: 0,
        itemsPerPage: this.itemsPerPage,
        totalPages: 0,
        totalItems: 0
      },
      data: {
        items: null
      },
      itemsReady: false
    };
  }
};
</script>
<style scoped></style>
