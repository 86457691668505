<template>
  <div>
    <p v-if="currentItem.destinationType === DESTINATION_TYPES.RTMP">
      RTMP destinations do not support the HEVC codec
    </p>
    <smart-select
      :dataSource="streamProfileClient"
      :textFormatter="streamProfileEvaler"
      @itemSelected="setStreamProfileId($event)"
      :selected="currentItem.streamProfileId"
      :filters="{ nonCustom: false }"
      :disabledItemCondition="
        currentItem.destinationType === DESTINATION_TYPES.RTMP
          ? item => item.codec === 'hevc'
          : () => false
      "
    ></smart-select>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import { streamProfileClient } from "@/mixins/apiClients";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import Destination from "@/models/Destination";

export default {
  components: { SmartSelect },
  mixins: [streamProfileClient],
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    setStreamProfileId(streamProfileId) {
      this.currentItem.streamProfileId = streamProfileId;
      this.$emit("change", { isValid: true, isNewCustomProfile: true });
    }
  },
  mounted() {
    this.currentItem.isPassthrough = false;
    this.$emit("change", {
      isValid: !!this.currentItem.streamProfileId,
      isNewCustomProfile: false
    });
  },
  data() {
    return {
      dataSource: streamProfileClient
    };
  },
  props: {
    currentItem: Destination
  }
};
</script>
