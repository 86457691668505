<script>
export default {
  name: "RegistrationError",
  props: {
    errorMessage: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
  <div>
    <h1>
      {{ errorMessage }}
    </h1>
  </div>
</template>

<style scoped></style>
