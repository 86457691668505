<template>
  <b-badge
    :variant="getVariantForStatus(status)"
    class="status-badge"
    :class="`status-${getVariantForStatus(status)}`"
  >
    <gl-icon
      v-if="showIcon"
      :name="getIconNameForStatus(status)"
      class="status-icon"
    />
    <span class="status-text">{{
      status !== "Requested" ? status.replace("_", " ") : "Pending"
    }}</span>
  </b-badge>
</template>

<script>
import { GlIcon } from "@gitlab/ui";
export default {
  name: "StatusCell",
  components: {
    GlIcon
  },
  props: {
    status: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getIconNameForStatus(status) {
      let iconName = "check";
      switch (status.toLowerCase()) {
        case "check":
        case "ready":
        case "enabled":
          iconName = "check";
          break;
        case "error":
        case "alert":
          iconName = "error";
          break;
        case "pending":
          iconName = "clock";
          break;
        case "cross region":
          iconName = "earth";
          break;
        case "stopped":
          iconName = "status-stopped";
          break;
        case "started":
          iconName = "play";
          break;
        case "cancelled":
          iconName = "canceled-circle";
          break;
        case "healthy":
        case "in_progress":
        case "complete":
        case "starting":
        case "stopping":
          iconName = "status_success";
          break;
        case "on-going":
          iconName = "status_running";
          break;
        default:
          iconName = "check";
      }
      return iconName;
    },
    getVariantForStatus(status) {
      let variant = "primary";
      switch (status.toLowerCase()) {
        case "check":
        case "ready":
        case "approved":
        case "healthy":
        case "on-going":
        case "starting":
          variant = "primary";
          break;
        case "pending":
        case "disabled":
        case "requested":
        case "alert":
        case "cancelled":
          variant = "warning";
          break;
        case "error":
        case "rescinded":
        case "rejected":
          variant = "danger";
          break;
        case "enabled":
          variant = "success";
          break;
        case "stopped":
          variant = "tip";
          break;
        default:
          variant = "primary";
      }
      return variant;
    }
  }
};
</script>
<style lang="scss">
.status-badge {
  border-radius: 15px !important;
}

.status-text {
  padding: 2px;
  padding-top: 3px;
}

.status-badge-alt {
  border-radius: 15px !important;
  padding: 0 !important;
}

.status-icon-alt {
  background-color: white !important;
  border-radius: 10px;
  border: 1px solid;
  padding: 1px;
}

.status-text-alt {
  padding: 2px;
  padding-top: 3px;
}
</style>
