<template>
  <div>
    <div class="text-right">
      <gl-button-group>
        <gl-button
          @click="resetConfiguration()"
          :disabled="!selectedOrganization"
          >Reset</gl-button
        >
        <div class="position-relative">
          <gl-button
            :disabled="!canGenerateMetrics"
            v-gl-collapse-toggle.collapse-save-configuration
            >Save Configuration</gl-button
          >
          <div class="opsPanel__collapse-save-configuration">
            <gl-collapse
              id="collapse-save-configuration"
              v-model="collapseSaveConfiguration"
            >
              <gl-form @submit.prevent="saveConfiguration" class="d-flex">
                <gl-form-input
                  type="text"
                  v-model="newConfigurationName"
                  placeholder="Name"
                  maxlength="250"
                  class="mt-1"
                  required
                />
                <gl-button
                  type="submit"
                  variant="confirm"
                  :disabled="!newConfigurationName"
                  >Save</gl-button
                >
              </gl-form>
            </gl-collapse>
          </div>
        </div>
        <gl-button
          :disabled="!canGenerateMetrics"
          variant="confirm"
          @click="metricGenerated = true"
          >Generate Metrics</gl-button
        >
      </gl-button-group>
    </div>
    <div class="mx-3 my-1">
      <div v-if="metricGenerated">
        <Metrics
          :selectedOrganization="selectedOrganization"
          :selectedContribution="selectedContribution"
          :selectedDelivery="selectedDelivery"
          @changeMetric="changeMetric"
        />
      </div>
      <div v-show="!metricGenerated">
        <b-row>
          <b-col cols="4">
            <Organizations
              @rowSelected="onRowSelectedOrganization"
              :selectedOrganization="selectedOrganization"
            />
            <div
              class="opsPanel__line"
              :style="line0Style"
              v-show="
                (showLines || selectedContribution) && selectedOrganization
              "
            ></div>
          </b-col>
          <b-col cols="4">
            <div>
              <Contributions
                @rowSelected="onRowSelectedContributions"
                :selectedOrganization="selectedOrganization"
                @generateLines="generateLines"
                ref="contributions"
              />
            </div>
            <div
              class="opsPanel__line"
              :style="line1Style"
              v-show="(showLines || selectedDelivery) && selectedContribution"
            ></div>
          </b-col>
          <b-col cols="4">
            <Delivery
              @rowSelected="onRowSelectedDelivery"
              :selectedContribution="selectedContribution"
              @generateLines="generateLines"
              ref="delivery"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Organizations from "@/components/OpsPanel/Organizations.vue";
import Contributions from "@/components/OpsPanel/Contributions.vue";
import Delivery from "@/components/OpsPanel/Delivery.vue";
import Metrics from "@/components/OpsPanel/Metrics.vue";
import {
  GlButton,
  GlButtonGroup,
  GlCollapse,
  GlForm,
  GlFormInput
} from "@gitlab/ui";

export default {
  name: "NetworkBrowser",
  components: {
    Organizations,
    Contributions,
    Metrics,
    Delivery,
    GlButton,
    GlButtonGroup,
    GlCollapse,
    GlForm,
    GlFormInput
  },
  computed: {
    canGenerateMetrics() {
      return (
        !this.metricGenerated &&
        this.selectedOrganization &&
        this.selectedContribution &&
        this.selectedDelivery
      );
    }
  },
  methods: {
    saveConfiguration() {
      this.collapseSaveConfiguration = false;
      this.newConfigurationName = "";
    },
    resetConfiguration() {
      this.metricGenerated = false;
      this.selectedOrganization = undefined;
      this.selectedContribution = undefined;
      this.selectedDelivery = undefined;
      this.$emit("clearOrganization");
      this.showLines = false;
    },
    onRowSelectedOrganization(row) {
      if (this.selectedOrganization != row) {
        this.selectedContribution = undefined;
        this.selectedDelivery = undefined;
      }
      this.selectedOrganization = row;
      this.showLines = false;
    },
    onRowSelectedContributions(row) {
      if (this.selectedContribution != row) {
        this.selectedDelivery = undefined;
      }
      this.selectedContribution = row;
      this.showLines = false;
    },
    onRowSelectedDelivery(row) {
      this.selectedDelivery = row;
      this.generateLines();
    },
    changeMetric() {
      this.metricGenerated = false;
    },
    generateLines() {
      this.collapseSaveConfiguration = false;
      setTimeout(() => {
        this.showLines = true;
        if (this.selectedOrganization) {
          const topOrganization =
            this.selectedOrganization.ref[0].offsetTop +
            this.selectedOrganization.ref[0].clientHeight / 2;
          const topContribution =
            this.$refs.contributions.$el.clientHeight > 210 ? 141 : 131.5;
          const heightOrganization =
            this.selectedOrganization.ref[0].offsetTop -
            this.selectedOrganization.ref[0].clientHeight / 2 -
            (this.$refs.contributions.$el.clientHeight > 220 ? 84 : 74);
          const heightConribution =
            this.$refs.contributions.$el.clientHeight -
            (this.selectedOrganization.ref[0].offsetTop > 150 ? 228 : 221);
          this.line0Style = {
            top: Math.min(topOrganization, topContribution) + "px",
            height: Math.max(heightOrganization, heightConribution) + "px"
          };
        }
        if (this.selectedContribution) {
          const topContribution =
            this.selectedContribution.ref[0].offsetTop +
            this.selectedContribution.ref[0].clientHeight / 2;
          const topDelivery =
            this.$refs.delivery.$el.clientHeight <= 212 ? 130 : 136;
          const heightContribution =
            this.selectedContribution.ref[0].offsetTop -
            this.selectedContribution.ref[0].clientHeight / 2 -
            (this.$refs.delivery.$el.clientHeight <= 212 ? 52 : 58);
          const heightDelivery = this.$refs.delivery.$el.clientHeight - 220;
          this.line1Style = {
            top: Math.min(topContribution, topDelivery) + "px",
            height: Math.max(heightContribution, heightDelivery) + "px"
          };
        }
      }, 0);
    }
  },
  data() {
    return {
      showLines: false,
      selectedOrganization: undefined,
      selectedContribution: undefined,
      selectedDelivery: undefined,
      metricGenerated: false,
      collapseSaveConfiguration: false,
      newConfigurationName: "",
      line0Style: {},
      line1Style: {}
    };
  }
};
</script>
<style>
.opsPanel__organizations__status {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 15px;
}
.opsPanel__organizations__status--success {
  background-color: #91d4a8;
}
.opsPanel__organizations__status--warning {
  background-color: #f5d9a8;
}
.opsPanel__organizations__status--danger {
  background-color: #f56c7e;
}
.opsPanel__collapse-save-configuration {
  position: absolute;
  top: 28px;
  z-index: 10;
  width: 180px;
  right: 0;
}
.opsPanel__organizations tr,
.opsPanel__packages tr,
.opsPanel__contributions tr,
.opsPanel__delivery tr,
.opsPanel__destination tr {
  position: relative;
}
.opsPanel__organizations .selected-row:after,
.opsPanel__packages .selected-row:after,
.opsPanel__contributions .selected-row:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: calc(50% - 1px);
  right: -16px;
  background-color: #999999;
  height: 2px;
  width: 16px;
}
.opsPanel__contributions tr:before,
.opsPanel__delivery tr:before,
.opsPanel__destination tr:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: calc(50% - 1px);
  left: -16px;
  background-color: #999999;
  height: 2px;
  width: 16px;
}
.opsPanel__line {
  display: inline-block;
  position: absolute;
  background-color: #999999;
  width: 2px;
  right: -1px;
}
</style>
