<template>
  <div>
    <gl-form-group
      class="m-4"
      label="Allowed list Cidr"
      label-size="sm"
      label-for="whitelistCidr"
      :invalid-feedback="invalidCidrFeedback()"
    >
      <gl-form-input
        id="whitelistCidr"
        type="text"
        v-model="currentItem.whitelistCidr"
        placeholder="192.168.0.1/24"
        maxlength="250"
        required
        class="mt-1"
        :state="isCidrValid()"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import Destination from "@/models/Destination";
import cidrRegex from "cidr-regex";

export default {
  components: { GlFormGroup, GlFormInput },
  methods: {
    invalidCidrFeedback() {
      return this.isCidrValid() ? "" : "Please enter valid CIDR.";
    },
    isCidrValid() {
      let isValid = cidrRegex({ exact: true }).test(
        this.currentItem.whitelistCidr
      );
      if (!this.currentItem.whitelistCidr) {
        isValid = false;
      }
      this.$emit("change", isValid);
      return isValid;
    }
  },
  data() {
    return {};
  },
  props: {
    currentItem: Destination // 2-way binding to the parent, name can be different
  }
};
</script>
