<template>
  <gl-nav class="h36">
    <template v-for="(item, index) in menuItems">
      <gl-nav-item-dropdown
        :active="activeIndex === index"
        v-if="item.options && item.options.length > 0"
        :key="`naItem${index}`"
        class="my-0"
        :text="item.text"
        :icon="item.icon"
        :right="item.right"
        :disabled="item.disabled"
      >
        <gl-dropdown-item
          v-for="(option, index) in item.options"
          :key="`item${index}`"
          @click="onClick"
          >{{ option }}</gl-dropdown-item
        >
      </gl-nav-item-dropdown>
      <gl-nav-item
        v-else
        :active="activeIndex === index"
        class="my-0"
        :key="`naItem${index}`"
        :icon="item.icon"
        :right="item.right"
        @click="onClick"
      >
        {{ item.text }}
      </gl-nav-item>
    </template>
    <slot />
  </gl-nav>
</template>
<script>
import {
  GlNav,
  GlNavItem,
  GlNavItemDropdown,
  GlDropdownItem
} from "@gitlab/ui";

export default {
  components: {
    GlDropdownItem,
    GlNav,
    GlNavItemDropdown,
    GlNavItem
  },
  props: {
    menuItems: {
      type: Array
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onClick(event) {
      let emitValue = event.target.innerText;
      if (event.target.innerText === "Virtual Connect") {
        emitValue = "Connect";
      }
      this.$emit(`onClick`, emitValue);
    }
  }
};
</script>
<style lang="scss">
nav .nav-link.active {
  background-color: rgba(255, 255, 255, 0.3);
}
.nav-item a {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.h36 {
  height: 36px;
}
</style>
