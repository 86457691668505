<template>
  <your-all access-level="arqade_admin"></your-all>
</template>
<script>
import YourAll from "./YourAll.vue";
export default {
  components: { YourAll },
  name: "AdminOnly"
};
</script>
