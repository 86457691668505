<template>
  <div>
    <p class="title">Corresponding with</p>
    <div id="timeHeaders">
      <label class="font-weight-bold">From</label>
      <label v-if="end" class="font-weight-bold">To</label>
      <label class="font-weight-bold">Timezone</label>
    </div>
    <div
      v-for="time in additionalTimes"
      :key="time.tz"
      id="correspondingSchedules"
    >
      <div class="correspondingSchedule">
        <label>{{ time.start }}</label>
        <label v-if="end">{{ time.end }}</label>
        <label>{{ time.tz }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dateUtils from "@/mixins/dateUtils";

dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  mixins: [dateUtils],
  props: {
    start: Date,
    end: Date,
    originalTimezone: String,
    timezones: Array
  },

  data() {
    return {
      additionalTimes: [],
      localTimeStart: null,
      localTimeEnd: null
    };
  },
  mounted() {
    this.calcTimes();
  },
  watch: {
    end() {
      if (!this.end) {
        return;
      }
      this.calcTimes();
    },
    start() {
      this.calcTimes();
    },
    originalTimezone() {
      this.calcTimes();
    }
  },
  methods: {
    calcTimes() {
      // get timezones not selected
      const additionalTimezones = this.timezones
        .filter(tz => tz.value !== this.originalTimezone)
        .map(tz => tz.value);

      this.additionalTimes = [];
      for (const tz of additionalTimezones) {
        let start = this.changeTimezone(this.start, this.originalTimezone, tz);
        if (start) {
          start = dayjs(start).format("DD/MM/YYYY HH:mm:ss");
        }
        let end = this.changeTimezone(this.end, this.originalTimezone, tz);
        if (end) {
          end = dayjs(end).format("DD/MM/YYYY HH:mm:ss");
        }

        this.additionalTimes.push({
          tz: tz,
          start: start,
          end: end
        });
      }
    }
  }
};
</script>

<style lang="scss">
p.title {
  color: grey;
  font-size: 13px;
  font-weight: 600;
}

div#timeHeaders,
div.correspondingSchedule {
  display: inline-flex;
}

div#timeHeaders > label,
div.correspondingSchedule > label {
  width: 160px;
}

div#timeHeaders > label {
  padding-left: 10px;
}

div#correspondingSchedules {
  width: 100%;

  > div.correspondingSchedule {
    //width: 100%;
    margin-bottom: 4px;
    padding: 14px;
  }

  > div:nth-child(odd) {
    background-color: #f0f0f0;
  }

  > div:nth-child(even) {
    background-color: #333;
  }
}
</style>
