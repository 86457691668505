<template>
  <div>
    <div id="timezonePicker" v-if="showAdditionalTimes">
      <label class="font-weight-bold">Timezone</label>
      <gl-form-select
        class=""
        :options="timezones()"
        v-model="selectedTz"
        @change="onTzChange()"
      />
    </div>
    <div class="d-flex" id="fromToDates">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <label class="red-label mr-2">{{ header }}</label>
          <label class="font-weight-bold">{{ fromLabel }}</label>
        </div>

        <date-picker
          v-model="start"
          :type="type"
          ref="startDateTime"
          :placeholder="placeholder"
          :format="format"
          :default-value="defaultStartValue"
          :disabled-date="disabledStartDate"
          :disabled-time="disabledStartTime"
          :get-classes="getRangeClasses"
          :disabled="startDisabled || disabled"
          :input-attr="{ required: true }"
          data-testid="from-date"
        />
      </div>
      <div class="d-flex flex-column ml-4">
        <label class="font-weight-bold">{{ toLabel }}</label>
        <date-picker
          v-model="end"
          ref="endDateTime"
          :type="type"
          :placeholder="placeholder"
          :format="format"
          :default-value="start"
          :disabled-date="disabledEndDate"
          :disabled-time="disabledEndTime"
          :get-classes="getRangeClasses"
          :disabled="endDisabled || disabled || !start"
          :input-attr="{ required: true }"
          data-testid="to-date"
        />
      </div>
    </div>

    <CorrespondingTimes
      v-if="showAdditionalTimes && preferredTimezonesPresent && start"
      :start="start"
      :end="end"
      :originalTimezone="selectedTz"
      :timezones="timezones()"
    ></CorrespondingTimes>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import currentItem from "@/mixins/currentItem";
import contributionApiClient from "@/mixins/contributionApiClient";
import { GlFormSelect } from "@gitlab/ui";
import UserInfo from "@/mixins/userInfo";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CorrespondingTimes from "@/components/Date/CorrespondingTimes.vue";
import dateUtils from "@/mixins/dateUtils";

dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  mixins: [currentItem, contributionApiClient, dateUtils, UserInfo],
  components: {
    CorrespondingTimes,
    DatePicker,
    GlFormSelect
  },
  props: {
    favTimezone: {
      type: String
    },
    showAdditionalTimes: {
      type: Boolean,
      default: false
    },
    dateRangeType: {
      type: String,
      default: ""
    },
    header: {
      type: String,
      default: ""
    },
    fromLabel: {
      type: String,
      default: "From"
    },
    toLabel: {
      type: String,
      default: "To"
    },
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    disabledStart: null,
    disabledEnd: null,
    startDisabled: {
      type: Boolean
    },
    endDisabled: {
      type: Boolean
    },
    showTime: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    removeDateEndWhenDateStartIsEmpty: {
      type: Boolean,
      default: true
    },
    contributionType: {
      type: String,
      default: ""
    }
  },
  computed: {
    preferredTimezonesPresent() {
      const timezones = this.getOptTimezones();
      return timezones.length >= 1;
    },
    defaultStartValue() {
      let dt = new Date(this.disabledStart);
      dt.setMinutes(dt.getMinutes() + 1);
      dt.setSeconds(0);
      return dt;
    }
  },
  created() {
    setInterval(() => {
      this.calcStartEndLimits();
    }, 500);

    this.selectedTz = this.getDefaultTimezone();
    this.oldTz = this.selectedTz;
    this.start = this.getStart();
    this.end = this.getEnd();
  },
  data() {
    return {
      disabledStartInSelectedTz: null,
      disabledEndInSelectedTz: null,
      selectedTz: null,
      start: null,
      end: null,
      oldTz: this.selectedTz,
      additionalTimes: [],
      placeholder: this.showTime ? `DD/MM/YYYY | HH:MM:SS` : `DD/MM/YYYY`,
      format: this.showTime ? `D/MM/YYYY HH:mm:ss` : `D/MM/YYYY`,
      type: this.showTime ? `datetime` : `date`
    };
  },
  watch: {
    start(val) {
      if (val === null && this.removeDateEndWhenDateStartIsEmpty) {
        this.end = null;
      }
      this.calcStartEndLimits();
      this.$emit(`dateRangeSet`, {
        start: this.fromTZtoUTC(val, this.selectedTz),
        end: this.fromTZtoUTC(this.end, this.selectedTz),
        type: this.dateRangeType
      });
    },
    end(val) {
      this.calcStartEndLimits();
      this.$emit(`dateRangeSet`, {
        start: this.fromTZtoUTC(this.start, this.selectedTz),
        end: this.fromTZtoUTC(val, this.selectedTz),
        type: this.dateRangeType
      });
    },
    endDisabled(val) {
      if (val) {
        this.end = null;
      }
    }
  },
  methods: {
    timezones() {
      const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const optTzs = this.getOptTimezones();
      let tzOptions = [
        { text: defaultTimezone, value: defaultTimezone },
        { text: optTzs[0], value: optTzs[0] },
        { text: optTzs[1], value: optTzs[1] }
      ];
      // remove duplicated
      tzOptions = tzOptions.filter(
        (v, i, a) => a.findIndex(t => t.value === v.value) === i
      );
      // remove null values
      tzOptions = tzOptions.filter(tz => tz.value);

      tzOptions.sort((a, b) => a.text.localeCompare(b.text));

      return tzOptions;
    },
    getEnd() {
      if (!this.endDate || this.endDisabled) {
        return null;
      }
      const ed = this.fromUTCtoTZ(this.endDate, this.selectedTz);
      const dt = this.convertToDate(ed);
      return dt;
    },
    getStart() {
      if (!this.startDate) {
        return null;
      }
      const st = this.fromUTCtoTZ(this.startDate, this.selectedTz);
      const dt = this.convertToDate(st);
      return dt;
    },
    getRangeClasses(cellDate, currentDates, classnames) {
      const classes = [];
      const start = this.start && new Date(this.start).setHours(0, 0, 0, 0);
      const end = this.end && new Date(this.end).setHours(0, 0, 0, 0);
      if (
        !/disabled|active|not-current-month/.test(classnames) &&
        start &&
        end &&
        cellDate.getTime() >= start &&
        cellDate.getTime() <= end
      ) {
        classes.push("in-range");
      }
      return classes;
    },
    disabledStartDate(date) {
      // it's triggered when we open the picker, for each displayed day (date).
      // returning true will disable that day

      const date00 = new Date(date).setHours(0, 0, 0, 0);
      switch (true) {
        case this.disabledStart &&
          date00 <
            new Date(this.disabledStartInSelectedTz).setHours(0, 0, 0, 0):
        case this.end && date00 > new Date(this.end).setHours(0, 0, 0, 0):
        case this.disabledEndInSelectedTz &&
          new Date(date) >
            new Date(this.disabledEndInSelectedTz).setHours(0, 0, 0, 0):
          return true;
        default:
          return false;
      }
    },
    disabledEndDate(date) {
      const maxEndDate = new Date(this.start);
      maxEndDate.setHours(this.start.getHours() + 720);

      return (
        (this.disabledEnd &&
          new Date(date) > new Date(this.disabledEnd).setHours(0, 0, 0, 0)) ||
        (this.start &&
          new Date(date).setHours(0, 0, 0, 0) <
            new Date(this.start).setHours(0, 0, 0, 0)) ||
        (this.contributionType === "event_contribution" &&
          new Date(date) > maxEndDate)
      );
    },
    disabledStartTime(date) {
      switch (true) {
        case this.disabledStartInSelectedTz &&
          date < this.disabledStartInSelectedTz:
        case this.disabledEndInSelectedTz &&
          date >= this.disabledEndInSelectedTz:
        case this.end && date > this.end:
          return true;
        default:
          return false;
      }
    },
    defaultEnd() {
      return new Date(this.start).setHours(
        this.start.getHours(),
        this.start.getMinutes(),
        this.start.getSeconds() + 1
      );
    },
    disabledEndTime(date) {
      const maxEndTime = new Date(this.start);
      maxEndTime.setHours(this.start.getHours() + 720);

      return (
        (this.start && date < this.start) ||
        (this.disabledEnd && date >= this.disabledEnd) ||
        (this.contributionType === "event_contribution" && date > maxEndTime)
      );
    },

    onTzChange() {
      this.start = this.changeTimezone(this.start, this.oldTz, this.selectedTz);
      this.end = this.changeTimezone(this.end, this.oldTz, this.selectedTz);
      this.calcStartEndLimits();
      this.$emit(`dateRangeSet`, {
        start: this.fromTZtoUTC(this.start, this.selectedTz),
        end: this.fromTZtoUTC(this.end, this.selectedTz),
        type: this.dateRangeType
      });

      this.oldTz = this.selectedTz;
    },

    calcStartEndLimits() {
      this.disabledStartInSelectedTz = this.changeTimezone(
        this.disabledStart,
        this.getLocalTimezone(),
        this.selectedTz
      );

      if (this.disabledEnd) {
        this.disabledEndInSelectedTz = this.changeTimezone(
          this.disabledEnd,
          this.getLocalTimezone(),
          this.selectedTz
        );
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/vue2-datepicker/scss/index.scss";

div#timezonePicker {
  margin-bottom: 12px;
}
</style>
