/* istanbul ignore file */
<template>
  <div id="timezones" v-if="user">
    <h2>Additional Timezones</h2>
    <p>To show your schedule times in different TZs</p>
    <div
      class="timezone"
      v-for="(tz, index) in additionalTimezones"
      :key="index"
    >
      <gl-form-select
        v-if="index === 0 || additionalTimezones[index - 1]"
        v-model="additionalTimezones[index]"
        :options="timezonesOptions[index]"
        @change="onTzChange()"
      />
    </div>

    <div id="tzRadio">
      <p>
        Default timezone:
        <gl-icon
          name="information-o"
          v-b-tooltip.hover
          :title="radioSelectHelpText"
        />
      </p>

      <gl-form-radio-group v-model="favTimezone" @change="onTzChange()">
        <gl-form-radio
          v-if="additionalTimezones[0]"
          :value="additionalTimezones[0]"
          >{{ additionalTimezones[0] }}
        </gl-form-radio>
        <gl-form-radio
          v-if="additionalTimezones[1]"
          :value="additionalTimezones[1]"
          >{{ additionalTimezones[1] }}
        </gl-form-radio>
        <gl-form-radio :value="defaultTimezone">Local</gl-form-radio>
      </gl-form-radio-group>
    </div>
  </div>
</template>
<style scoped lang="scss">
div#tzRadio {
  margin-top: 30px;

  .gl-form-radio {
    display: inline;
    margin-right: 16px;
  }

  .gl-form-radio-group {
    margin-top: 40px;
  }
}

div#timezones {
  margin-top: 20px;
  width: 400px;

  > div.timezone {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 200px;
  }
}
</style>
<script>
import {
  GlFormRadio,
  GlFormRadioGroup,
  GlFormSelect,
  GlIcon
} from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";
import { userMgmtApiClient } from "@/mixins/apiClients";
import timezones from "@/components/Account/timezones.js";
import userInfo from "@/mixins/userInfo";

const STORE = `auth`;

export default {
  name: "TimezonesComponent",
  mixins: [userInfoMixin, userMgmtApiClient],
  components: {
    GlFormRadio,
    GlFormRadioGroup,
    GlFormSelect,
    GlIcon
  },
  methods: {
    onTzChange() {
      const body = {
        optTimezone1:
          !this.additionalTimezones[0] && this.additionalTimezones[1]
            ? this.additionalTimezones[1]
            : this.additionalTimezones[0],
        optTimezone2:
          this.additionalTimezones[0] && this.additionalTimezones[1]
            ? this.additionalTimezones[1]
            : null,
        favTimezone: this.favTimezone
      };

      if (!this.additionalTimezones.includes(this.favTimezone)) {
        body.favTimezone = this.favTimezone = this.defaultTimezone;
      }
      if (JSON.stringify(this.updatingBody) === JSON.stringify(body)) {
        return; // avoid multiple calls
      }
      this.updatingBody = body;

      this.userMgmtApiClient
        .putCustomPath(this.user.id, "timezones", body)
        .then(
          () => {
            userInfo.optTimezone1 = body.optTimezone1;
            userInfo.optTimezone2 = body.optTimezone2;
            userInfo.favTimezone = this.favTimezone;
            this.$store.commit(`${STORE}/userInfo`, userInfo);

            if (!this.additionalTimezones[0] && this.additionalTimezones[1]) {
              this.additionalTimezones[0] = this.additionalTimezones[1];
              this.additionalTimezones[1] = null;
            }

            this.dynamicUpdateOptions();
            this.$toast.showSuccess("Timezones preferences updated");
          },
          error => {
            console.error("Error updating timezones", error);
            this.$toast.showError("Error updating timezones");
          }
        );
    },
    dynamicUpdateOptions() {
      for (let i = 0; i < this.timezonesOptions.length; i++) {
        this.timezonesOptions[i] = this.tzOptions;
        // we remove the values present on the other timezones
        this.additionalTimezones.forEach((tz, index) => {
          if (index !== i && tz) {
            this.timezonesOptions[i] = this.timezonesOptions[i].filter(
              option => option.value !== tz
            );
          }
        });
        // ensure reactivity to refresh the options
        this.$set(this.timezonesOptions, i, this.timezonesOptions[i]);
      }
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.dynamicUpdateOptions();
  },
  data() {
    let tzOptions = timezones.map(tz => ({ text: tz, value: tz }));
    tzOptions.unshift({ text: " ---- ", value: null });
    return {
      additionalTimezones: [this.user.optTimezone1, this.user.optTimezone2],
      defaultTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      favTimezone: this.user.favTimezone
        ? this.user.favTimezone
        : this.defaultTimezone,
      updatingBody: {},
      timezonesOptions: [tzOptions, tzOptions],
      tzOptions: tzOptions,
      radioSelectHelpText:
        "Choose between your local time or any of the additional timezones you have set up." +
        "‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎ " +
        "‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎ " +
        "‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎ " +
        "‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎ " +
        "‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎  ‎" +
        "This will be the one displayed as the default or main one in your lists or when creating, showing or editing a resource."
    };
  }
};
</script>
