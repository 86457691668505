<template>
  <requests-all status="Requested"></requests-all>
</template>
<script>
import RequestsAll from "./RequestsAll.vue";
export default {
  components: { RequestsAll },
  name: "Requested"
};
</script>
