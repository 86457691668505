import PartnerGrants from "@/views/PartnerGrants";
import { MAIN_ITEMS, ITEMS_PARTNER_GRANTS } from "@/constants.js";
import { All, Granted, NotGranted } from "@/components/PartnerGrants";

const { PARTNER_GRANTS } = MAIN_ITEMS;
const { ALL, GRANTED, NOT_GRANTED } = ITEMS_PARTNER_GRANTS;

const HOST = `Partner-grants`;

const partnerGrants = [
  {
    path: PARTNER_GRANTS,
    name: `${HOST}`,
    redirect: PARTNER_GRANTS,
    component: PartnerGrants,
    children: [
      {
        path: ALL,
        component: All
      },
      {
        path: GRANTED,
        component: Granted
      },
      {
        path: NOT_GRANTED,
        component: NotGranted
      }
    ]
  }
];

export default partnerGrants;
