<template>
  <div class="mt-3">
    <hr />
    <b-row class="mt-4 mb-4">
      <b-col cols="2">
        <gl-form-select v-model="date" :options="dateOptions" />
        <div class="d-flex mt-3">
          <gl-button
            variant="confirm"
            :disabled="isDownloading || isBusy"
            @click="downloadAsCsv()"
            >Download as CSV</gl-button
          >
          <gl-loading-icon
            label="Loading"
            v-show="isDownloading"
            size="md"
            class="mt-1 ml-3"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <h3>Monthly Total</h3>
        <p>
          Please note that costs are shown here the day after they are accrued.
        </p>
      </b-col>
      <b-col cols="4" class="text-right">
        <h3>{{ formatPrice(this.totalPrice) }}</h3>
      </b-col>
    </b-row>
    <ArqDataGrid
      :data="data"
      :columns="columns"
      @dataNeeded="onDataNeeded"
      :itemsPerPage="itemsPerPage"
      :selectable="false"
    >
      <template v-slot="{ row: r, field }">
        <div
          v-if="
            field === 'recurringPrice' ||
              field === 'setupPrice' ||
              field === 'totalPrice'
          "
        >
          <div
            class="text-right"
            :class="{ 'font-weight-bold': field === 'totalPrice' }"
          >
            {{ formatPrice(r[field], field) }}
          </div>
        </div>
      </template>
    </ArqDataGrid>
  </div>
</template>
<script>
import { GlFormSelect, GlButton, GlLoadingIcon } from "@gitlab/ui";
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import billingApiClient from "@/mixins/billingApiClient.js";
import { BCol, BRow } from "bootstrap-vue";

export default {
  name: "BillingList",
  mixins: [billingApiClient],
  components: {
    ArqDataGrid,
    GlFormSelect,
    GlLoadingIcon,
    GlButton,
    BCol,
    BRow
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 100
    }
  },
  methods: {
    formatPrice(price, field) {
      return typeof price === "number"
        ? Intl.NumberFormat(navigator ? navigator.language : "en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits:
              field === "recurringPrice" || field === "setupPrice" ? 4 : 2,
            currencyDisplay: "symbol"
          }).format(price)
        : "";
    },
    async downloadAsCsv() {
      this.isDownloading = true;
      let dataToCsv = [];
      let page = 0;
      let hasNextPage = true;
      try {
        do {
          const response = await this.billingApiClient
            .list()
            .param("startDate", this.getStartDate())
            .param("endDate", this.getEndDate())
            .page(page)
            .perPage(500)
            .exec();
          if (response.items && response.items.length > 0) {
            dataToCsv.push(...response.items);
            if (response.totalPages > page) {
              page++;
            } else {
              hasNextPage = false;
            }
          } else {
            hasNextPage = false;
          }
        } while (hasNextPage);

        let csvContent = "data:text/csv;charset=utf-8,";
        const fields = this.columns.map(column => column.field);

        csvContent += [
          this.columns.map(column => column.text).join(","),
          dataToCsv
            .map(item => {
              let fieldsToCsv = [];
              fields.forEach(field => {
                let value =
                  field === "recurringPrice" ||
                  field === "setupPrice" ||
                  field === "totalPrice"
                    ? JSON.stringify(this.formatPrice(item[field], field))
                    : item[field];

                fieldsToCsv.push(value);
              });
              return fieldsToCsv.join(",");
            })
            .join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute(
          "download",
          this.dateOptions.find(d => d.value === this.date).text + ".csv"
        );
        link.click();
      } catch (e) {
        this.handleApiError(e);
      }

      this.isDownloading = false;
    },
    getStartDate() {
      return (
        this.date.getFullYear() +
        "-" +
        ("0" + (this.date.getMonth() + 1)).slice(-2) +
        "-01"
      );
    },
    getEndDate() {
      const today = new Date();
      const endDate =
        this.date.getFullYear() == today.getFullYear() &&
        this.date.getMonth() == today.getMonth()
          ? today
          : new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
      return (
        endDate.getFullYear() +
        "-" +
        ("0" + (endDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endDate.getDate()).slice(-2)
      );
    },
    async onDataNeeded(sortModel, pageModel) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      if (!this.date) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      this.isBusy = true;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;

      let query = this.billingApiClient
        .list()
        .param("startDate", this.getStartDate())
        .param("endDate", this.getEndDate())
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        this.totalPrice = response.totalPrice || 0;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isBusy = false;
    }
  },
  watch: {
    date: function() {
      this.onDataNeeded(this.sortModel, this.pageModel);
    }
  },
  created() {
    let d = new Date();
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      this.dateOptions.push({
        text: d.toLocaleString("default", { month: "long", year: "numeric" }),
        value: new Date(d)
      });
      d.setMonth(d.getMonth() - 1);
    }
    this.date = this.dateOptions[0].value;
  },
  data() {
    return {
      sortModel: {},
      pageModel: {
        itemsPerPage: 100
      },
      data: {},
      isDownloading: false,
      totalPrice: undefined,
      isBusy: true,
      date: undefined,
      dateOptions: [],
      columns: [
        {
          text: "Date",
          field: "date",
          isSortable: true
        },
        {
          text: "Originator Name",
          field: "originatorName",
          isSortable: true
        },
        {
          text: "Project",
          field: "projectNumber",
          isSortable: true
        },
        {
          text: "Billable Status",
          field: "billableStatus",
          isSortable: true
        },
        {
          text: "Source Name",
          field: "sourceName",
          isSortable: true
        },
        {
          text: "Contribution Name",
          field: "contributionName",
          isSortable: true
        },
        {
          text: "Charge Interval",
          field: "chargeInterval",
          isSortable: true
        },
        {
          text: "Recurring Price",
          field: "recurringPrice",
          isSortable: true
        },
        {
          text: "Recurring Units",
          field: "recurringUnits",
          isSortable: true
        },
        {
          text: "Setup Price",
          field: "setupPrice",
          isSortable: true
        },
        {
          text: "Total Price",
          field: "totalPrice",
          isSortable: true
        }
      ]
    };
  }
};
</script>
