<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <Description
          :labelName="labelName"
          :valueName="itemUnderEdit.name"
          :valueDesc="itemUnderEdit.description"
          @change="onDescriptionChange"
        />
        <gl-form-group
          label="Availability zone"
          label-size="sm"
          label-for="availabilityZone"
        >
          <gl-form-select
            id="availabilityZone"
            v-model="itemUnderEdit.availabilityZone"
            :options="availabilityZoneOptions"
            :disabled="isUpdate"
            placeholder="Availability zone"
            required
            class="mt-1"
          />
        </gl-form-group>
        <gl-toggle
          id="accessLevel"
          label="Admin Only"
          v-if="canEditAccessLevel"
          v-model="changeIsAdminOnly"
        ></gl-toggle>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlFormGroup, GlFormSelect, GlToggle } from "@gitlab/ui";
import Description from "@/components/Description";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import { ALIAS_ITEMS_SOURCE, ITEMS_SOURCES } from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo.js";
import AppConfig from "@/app-config";

const { EDIT } = ITEMS_SOURCES;
const { ADD_SECOND, EDIT_SECOND } = ALIAS_ITEMS_SOURCE;
export default {
  name: "AddFirst",
  mixins: [currentItem, userInfoMixin],
  components: {
    Wizard,
    GlForm,
    Description,
    GlFormGroup,
    GlFormSelect,
    GlToggle
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDescFulfilled:
        this.currentItem.name &&
        this.currentItem.description &&
        this.currentItem.availabilityZone,
      labelName: `Source name`,
      leftSectionTitle: `Description`,
      availabilityZoneOptions: {},
      adminOnly: false,
      buttons: [{ text: `Next Step`, type: `submit` }],
      listOfFieldsToExclude: ["sourceArn", "flowArn"]
    };
  },
  created() {
    this.getAvailabilityZones();
    this.adminOnly = this.itemUnderEdit.accessLevel === "arqade_admin";
  },
  mounted() {
    if (this.listOfFieldsToExclude.length > 0) {
      this.listOfFieldsToExclude.forEach(field => {
        if (this.itemUnderEdit[field]) {
          delete this.itemUnderEdit[field];
        }
      });
    }
  },

  computed: {
    canEditAccessLevel() {
      return (
        (this.isArqadeAdminRoleActive || this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    },
    changeIsAdminOnly: {
      get() {
        if (this.isUpdate) {
          return this.itemUnderEdit.accessLevel === "arqade_admin";
        }
        return this.adminOnly;
      },
      set(value) {
        value
          ? (this.itemUnderEdit.accessLevel = "arqade_admin")
          : (this.itemUnderEdit.accessLevel = "user");
        this.adminOnly = value;
      }
    }
  },
  watch: {
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name &&
          this.currentItem.description &&
          this.currentItem.availabilityZone;
      },
      deep: true
    }
  },
  methods: {
    getAvailabilityZones() {
      const zones = {};
      for (const zone in AppConfig.instance.config.options.availabilityZones) {
        zones[
          zone
        ] = `${zone} (${AppConfig.instance.config.options.availabilityZones[zone]})`;
      }
      this.availabilityZoneOptions = zones;
    },
    nextStep() {
      if (this.isDescFulfilled) {
        if (this.isUpdate) {
          this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`);
        } else {
          this.$router.push(ADD_SECOND);
        }
      }
    },
    onDescriptionChange(data) {
      const { name, description } = data;
      this.itemUnderEdit.name = name;
      this.itemUnderEdit.description = description;
    }
  }
};
</script>
