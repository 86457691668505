<template>
  <DetailScreenComponent
    :action-icons="actionsIcons"
    :item="item"
    :go-to-list="goToList"
    :fields-to-include="fieldNames"
  />
</template>
<script>
import AppConfig from "@/app-config";
import AddFirst from "@/components/Destinations/AddFirst.vue";
import DetailScreenComponent from "@/components/DetailScreenComponent.vue";
import { DESTINATION_TYPES, ITEMS_DESTINATIONS } from "@/constants.js";
import { organizationApiClient } from "@/mixins/apiClients";
import deliveryApiClient from "@/mixins/deliveryApiClient.js";
import destinationActions from "@/mixins/destinationActions";
import destinationApiClient from "@/mixins/destinationApiClient.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
import userInfo from "@/mixins/userInfo.js";
import wizardGuard from "@/mixins/wizardGuard.js";

const { EDIT, YOUR } = ITEMS_DESTINATIONS;
export default {
  components: { DetailScreenComponent },
  mixins: [
    wizardGuard,
    destinationApiClient,
    destinationActions,
    deliveryApiClient,
    organizationApiClient,
    streamProfileClient,
    userInfo
  ],

  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.goToList();
      }
      if (evt.code === "Space") {
        this.goToEdit();
      }
    },
    async arch() {
      await this.archive(this.item);
      await this.fetchData();
    },
    duplicate() {
      this.clone(this.item);
    },
    remove() {
      this.delete(this.item);
    },
    goToEdit() {
      this.$router.push(`${EDIT}/${this.item.id}`);
    },
    goToList() {
      this.$router.push(`${YOUR}`);
    },

    async fetchData() {
      try {
        const id = this.$route.params.id;
        const dest = await this.destinationApiClient.getById(id);

        dest.cryptoAlgo = dest.cryptoAlgo || null;
        dest.isEncrypted = dest.cryptoAlgo ? "YES" : "NO";
        dest.destinationType =
          dest.ipDestinationType === "rist"
            ? DESTINATION_TYPES.RIST
            : dest.destinationType;
        dest.regionName =
          AppConfig.instance.config.options.regions[dest.region];

        if (dest.streamProfileId) {
          const profile = await this.streamProfileClient.getById(
            dest.streamProfileId
          );
          dest.profileName = profile.profileName;
        }

        if (dest.organisationId !== undefined) {
          const org = await this.organizationApiClient.getById(
            dest.organisationId
          );
          dest.organisationName = org.shortName;
        }

        try {
          const result = await this.getDestinationDeliveryStatus(id);
          dest.destinationDeliveryId = result.data.id;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            dest.destinationDeliveryId = null;
          } else {
            console.error(error);
          }
        }

        this.item = dest;
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  created() {
    this.fetchData();
  },

  watch: {
    $route(to) {
      this.fetchData();
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    item: {
      handler() {
        this.isDescFulfilled = this.item.name && this.item.description;
      },
      deep: true
    }
  },
  computed: {
    pageTitle() {
      return " Destination Details";
    },
    actionsIcons() {
      const actions = [
        {
          icon: "pencil",
          action: this.goToEdit,
          title: "Click or press the Space Bar to edit this Destination",
          label: "Edit",
          id: "edit",
          disabled: false
        },
        {
          icon: "duplicate",
          action: this.duplicate,
          title: "Clone",
          label: "Clone",
          id: "clone",
          disabled: false
        },
        {
          icon: "archive",
          action: this.arch,
          title: "Archive",
          label: "Archive",
          id: "archive",
          disabled: this.item.status === "ARCHIVED"
        },
        {
          icon: "remove",
          action: this.remove,
          title: "Delete",
          label: "Delete",
          id: "delete",
          disabled: false
        }
      ];

      if (this.item.isPrecreated) {
        const index = actions.findIndex(a => a.id === "remove");
        actions.splice(index, 1);
      }

      return actions;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      item: {},
      isDescFulfilled: false,
      fieldNames: [
        "accessLevel",
        "availabilityZone",
        "bucketName",
        "cdnSettingsType",
        "cryptoAlgo",
        "destinationAddress",
        "destinationType",
        "folderPath",
        "ipAddress",
        "ipAddressAwsRegion",
        "ipDestinationType",
        "isEncrypted",
        "isPrecreated",
        "listenerAddress",
        "maxLatencyMs",
        "minLatencyMs",
        "nameModifier",
        "organisationName",
        "port",
        "profileName",
        "publicOutboundIp",
        "region",
        "streamId",
        "url",
        "whitelistCidr"
      ]
    };
  }
};
</script>
