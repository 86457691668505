import Billing from "@/views/Billing/Billing";
import { BillingList } from "@/components/Billing";
import { MAIN_ITEMS, ITEMS_BILLING } from "@/constants.js";
const { BILLING } = MAIN_ITEMS;
const { LIST } = ITEMS_BILLING;

const billing = [
  {
    path: BILLING,
    name: `Billing`,
    component: Billing,
    children: [
      {
        path: LIST,
        component: BillingList
      }
    ]
  }
];

export default billing;
