<template>
  <div>
    <p v-if="currentItem.destinationType === DESTINATION_TYPES.RTMP">
      RTMP destinations do not support the HEVC codec
    </p>
    <gl-card>
      <stream-profile
        @change="updateStreamProfileForm"
        :streamProfile="currentProfile"
        :disabledCodecs="
          currentItem.destinationType === DESTINATION_TYPES.RTMP ? ['hevc'] : []
        "
      />
    </gl-card>
  </div>
</template>
<script>
import StreamProfile from "@/components/StreamProfile.vue";
import Destination from "@/models/Destination";
import { DESTINATION_TYPES } from "@/constants";
import { GlCard } from "@gitlab/ui";

export default {
  name: "NewCustomProfileComponent",
  components: { StreamProfile, GlCard },
  methods: {
    updateStreamProfileForm(e) {
      this.$emit("change", e);
    }
  },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  mounted() {
    this.currentItem.isPassthrough = false;
    this.$emit("change", { isValid: false, isNewCustomProfile: true });
  },
  data() {
    return {
      currentProfile: undefined,
      customStreamProfile: undefined
    };
  },
  props: {
    currentItem: Destination
  }
};
</script>

<style scoped></style>
