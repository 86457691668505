import Report from "@/views/Report/Report";
import { Bandwidth } from "@/components/Report";
import { MAIN_ITEMS, ITEMS_REPORT } from "@/constants.js";
const { REPORT } = MAIN_ITEMS;
const { BANDWIDTH } = ITEMS_REPORT;

const report = [
  {
    path: REPORT,
    name: `Bandwidth`,
    component: Report,
    children: [
      {
        path: BANDWIDTH,
        component: Bandwidth
      }
    ]
  }
];

export default report;
