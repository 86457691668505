<template>
  <div>
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { ALIAS_ITEMS_CONTRIBUTION, MAIN_ITEMS } from "@/constants.js";

const {
  EVENTS_ALL,
  EVENTS_PENDING,
  EVENTS_COMPLETED,
  EVENTS_CURRENT,
  EVENTS_CANCELLED
} = ALIAS_ITEMS_CONTRIBUTION;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [
        { title: `All events`, subPageName: EVENTS_ALL },
        { title: `Current`, subPageName: EVENTS_CURRENT },
        { title: `Completed`, subPageName: EVENTS_COMPLETED },
        { title: `Pending`, subPageName: EVENTS_PENDING },
        { title: `Cancelled`, subPageName: EVENTS_CANCELLED }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Events`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Contributions`, href: EVENTS_ALL },
        { text: `Events`, href: EVENTS_ALL }
      ]
    };
  }
};
</script>
