<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3 mb-3">{{ pageTitle }}</h1>
    <gl-tabs>
      <gl-tab
        v-for="(item, index) in tabsItems"
        :key="`tab${index}`"
        @click="openTab(item.subPageName, item.subComponent, item.data)"
        :active="isSelected(item.subPageName)"
        :disabled="item.disabled"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
          <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge">{{
            item.badge
          }}</gl-badge>
        </template>
      </gl-tab>
    </gl-tabs>
    <component
      v-if="!isEdit || currentItem.id"
      v-bind:is="currentComponent"
      :currentItem="currentItem"
      :isUpdate="isEdit"
      @created="organizationCreated"
    ></component>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import organizationApiClient from "@/mixins/organizationApiClient.js";
import AddOrganization from "@/components/UserManagement/AddOrganization.vue";
import AddOrganizationLogo from "@/components/UserManagement/AddOrganizationLogo.vue";
import VirtualConnectStreamAll from "@/components/UserManagement/VirtualConnectStreamAll.vue";
import * as eq from "deep-equal";
import { GlTabs, GlTab } from "@gitlab/ui";
import Organisation from "@/models/Organisation";
const { EDIT_ORGANIZATION } = ITEMS_USER_MGMT;
const {
  ADD_ORGANIZATION_FIRST,
  ADD_ORGANIZATION_SECOND,
  ORGANIZATIONS_ALL,
  EDIT_ORGANIZATION_FIRST,
  EDIT_ORGANIZATION_SECOND,
  VIRTUAL_CONNECT_STREAMS_ALL
} = ALIAS_ITEMS_USER_MGMT;

export default {
  components: { Breadcrumb, AddOrganization, GlTabs, GlTab },
  mixins: [wizardGuard, organizationApiClient],
  methods: {
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    selectTabAfterPageLoad() {
      for (const item of this.tabsItems) {
        if (this.isSelected(item.subPageName)) {
          this.openTab(item.subPageName, item.subComponent, item.data);
          break;
        }
      }
    },
    organizationCreated() {
      this.created = true;
      this.$router.push(ORGANIZATIONS_ALL);
    }
  },
  created() {
    if (this.$route.params.id) {
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = true;
      const id = this.$route.params.id;
      this.organizationApiClient.getById(id).then(org => {
        this.currentItem = org;
        this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
        this.selectTabAfterPageLoad();
      });
    } else {
      if (this.$route.params && this.$route.params.cloning) {
        this.currentItem = this.$route.params.cloning;
      } else {
        this.currentItem = { ...Organisation };
      }
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
    }
  },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.shortName && this.currentItem.legalName;
      },
      deep: true
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Organizations`, href: ORGANIZATIONS_ALL },
        {
          text: this.isEdit ? "Edit" : "Create new",
          href: ADD_ORGANIZATION_FIRST
        }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `Profile `,
          subPageName: this.isEdit
            ? `${EDIT_ORGANIZATION}/${this.currentItem.id}/${EDIT_ORGANIZATION_FIRST}`
            : ADD_ORGANIZATION_FIRST,
          subComponent: AddOrganization
        },
        {
          title: `Logo `,
          subPageName: this.isEdit
            ? `${EDIT_ORGANIZATION}/${this.currentItem.id}/${EDIT_ORGANIZATION_SECOND}`
            : ADD_ORGANIZATION_SECOND,
          disabled: !this.isEdit || !this.currentItem.id,
          subComponent: AddOrganizationLogo
        },
        {
          title: `VirtualConnect Streams `,
          subPageName: this.isEdit
            ? `${EDIT_ORGANIZATION}/${this.currentItem.id}/${VIRTUAL_CONNECT_STREAMS_ALL}`
            : undefined,
          disabled: !this.isEdit || !this.currentItem.id,
          subComponent: VirtualConnectStreamAll
        }
      ];
    },
    pageTitle() {
      return this.isEdit ? "Edit organization" : "Create organization";
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddOrganization,
      isEdit: false,
      selectedTab: null,
      currentItem: Organisation,
      originalItem: Organisation,
      isDescFulfilled: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isPristine) {
      next();
    } else {
      this.$bvModal
        .msgBoxConfirm("You have unsaved changes. Do you want to leave?")
        .then(value => {
          if (value) {
            next();
          }
        });
    }
  }
};
</script>
