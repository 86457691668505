<template>
  <div>
    <gl-form-group
      label="Template"
      label-size="sm"
      label-for="templateDestination"
      v-if="showTemplate"
    >
      <gl-form-select
        id="templateDestination"
        v-model="template"
        :options="templateOptions"
        :disabled="true"
        class="mt-1"
      />
    </gl-form-group>
    <gl-form-group :label="labelName" label-size="sm" label-for="name">
      <gl-form-input
        id="name"
        type="text"
        v-model="name"
        :placeholder="labelName"
        maxlength="250"
        required
        class="mt-1"
      />
    </gl-form-group>
    <gl-form-group label="Description" label-size="sm" label-for="description">
      <gl-form-textarea
        id="description"
        v-model="description"
        placeholder="Description"
        maxlength="250"
        required
        class="mt-1"
      />
    </gl-form-group>
  </div>
</template>

<script>
import {
  GlFormSelect,
  GlFormInput,
  GlFormTextarea,
  GlFormGroup
} from "@gitlab/ui";
const EVENT_NAME = "change";
export default {
  components: {
    GlFormSelect,
    GlFormInput,
    GlFormTextarea,
    GlFormGroup
  },
  props: {
    labelName: {
      type: String
    },
    valueName: {
      type: String
    },
    valueDesc: {
      type: String
    },
    showTemplate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    description: {
      get() {
        return this.valueDesc;
      },
      set(value) {
        this.$emit(EVENT_NAME, {
          name: this.name,
          description: value,
          template: this.template
        });
      }
    },
    name: {
      get() {
        return this.valueName;
      },
      set(value) {
        this.$emit(EVENT_NAME, {
          name: value,
          description: this.description,
          template: this.template
        });
      }
    }
  },
  data() {
    return {
      template: "Default",
      templateOptions: ["Default"]
    };
  }
};
</script>

<style></style>
