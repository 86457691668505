<template>
  <EntitlementConfig />
</template>
<script>
import EntitlementConfig from "@/components/Packages/EntitlementConfig.vue";

export default {
  name: "EntitlementConfigView",
  components: { EntitlementConfig }
};
</script>
<style></style>
