var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-grid"},[_c('table',[(_vm.showHeader)?_c('thead',[_c('th',{staticClass:"min-h"},[(_vm.selectable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allRowsSelected),expression:"allRowsSelected"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allRowsSelected)?_vm._i(_vm.allRowsSelected,null)>-1:(_vm.allRowsSelected)},on:{"change":[function($event){var $$a=_vm.allRowsSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allRowsSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allRowsSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allRowsSelected=$$c}},_vm.onAllRowsSelected]}}):_vm._e()]),_vm._l((_vm.columns),function(column){return _c('th',{key:column.text,class:{
          clickable: column.isSortable,
          'text-center': column.center,
          'text-right': column.right
        },on:{"click":function($event){return _vm.onColumnClick(column.field)}}},[_vm._v(" "+_vm._s(column.text)+" "),(
            _vm.sortModel &&
              (_vm.sortModel.field === column.field ||
                _vm.sortModel.field === column.sortField) &&
              _vm.sortModel.order === 'asc'
          )?_c('gl-icon',{attrs:{"name":"chevron-lg-up"}}):_vm._e(),(
            _vm.sortModel &&
              (_vm.sortModel.field === column.field ||
                _vm.sortModel.field === column.sortField) &&
              _vm.sortModel.order === 'desc'
          )?_c('gl-icon',{attrs:{"name":"chevron-lg-down"}}):_vm._e()],1)}),(_vm.showContextMenu)?_c('th',{staticClass:"min-h"}):_vm._e()],2):_vm._e(),(!_vm.loading && _vm.rows.length === 0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.totalNumColumns}},[_vm._v(" No data for this query... ")])])]):(_vm.loading)?_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.totalNumColumns}},[_vm._v("Loading...")])])]):_c('tbody',[_vm._l((_vm.rows),function(row,index){return [_c('tr',{key:row.data.id,ref:'row_' + row.data.id,refInFor:true,class:{ 'selected-row': row.selected, 'open-row': row.open },attrs:{"id":("row_" + index)},on:{"click":function($event){return _vm.rowClicked(row)}}},[_c('td',{staticClass:"min-h"},[(_vm.selectable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.selected),expression:"row.selected"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(row.selected)?_vm._i(row.selected,null)>-1:(row.selected)},on:{"change":[function($event){var $$a=row.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(row, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(row, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(row, "selected", $$c)}},function($event){return _vm.selectRow(row)}]}}):_vm._e(),(row.data.children && row.data.children.length > 0)?_c('span',{staticClass:"drawer-opener ml-2 clickable",on:{"click":function($event){return _vm.toggleOpenRow(row)}}},[(row.open)?_c('gl-icon',{attrs:{"name":"chevron-down"}}):_c('gl-icon',{attrs:{"name":"chevron-right"}}),(_vm.showFolderIcon)?_c('gl-icon',{attrs:{"name":"folder-open"}}):_vm._e()],1):_vm._e()]),_vm._l((_vm.columns),function(column){return _c('td',{key:column.text,class:{
              clickable: typeof column.clicked === 'function',
              'text-center': column.center,
              'text-right': column.right
            },on:{"click":function($event){return _vm.cellClicked(column, row.data)}}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(row.data[column.field])+" ")]},{"row":row.data,"field":column.field})],2)}),(
              _vm.showContextMenu &&
                _vm.contextMenuItems &&
                _vm.contextMenuItems[row.data.id] &&
                _vm.contextMenuItems[row.data.id].length > 0
            )?_c('td',{staticClass:"kebab clickable",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.contextMenuClick($event, row.data)}}},[_c('gl-icon',{attrs:{"name":"ellipsis_v"}})],1):_vm._e(),(
              !_vm.showContextMenu ||
                !_vm.contextMenuItems ||
                !_vm.contextMenuItems[row.data.id] ||
                _vm.contextMenuItems[row.data.id].length === 0
            )?_c('td'):_vm._e()],2),(row.open)?_c('tr',{key:((row.data.id) + "_drawer"),staticClass:"drawer"},[_c('td',{staticClass:"drawer pl-3",attrs:{"colspan":_vm.totalNumColumns + 1}},[_c('arq-data-grid-drawer',{ref:"gridDrawer",refInFor:true,attrs:{"columns":_vm.subColumns,"items":row.data.children,"deselectAll":_vm.deselectChildren,"showContextMenu":_vm.showContextMenu,"selectable":_vm.selectable},on:{"rowSelected":_vm.selectDrawerRow,"contextMenuClick":_vm.contextMenuClick},scopedSlots:_vm._u([{key:"drawerSlot",fn:function(ref){
            var row = ref.row;
            var field = ref.field;
return [_vm._t("drawer",function(){return [_vm._v(" "+_vm._s(row[field])+" ")]},{"drawerRow":row,"drawerField":field})]}}],null,true)})],1)]):_vm._e()]})],2)]),(_vm.showPager)?_c('ArqDataGridPaginator',{staticClass:"mt-2",attrs:{"pagingModel":_vm.pagingModel},on:{"firstPage":_vm.changePage,"lastPage":_vm.changePage,"previousPage":_vm.changePage,"nextPage":_vm.changePage}}):_vm._e(),(_vm.showContextMenu)?_c('vue-context',{ref:"menu",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((_vm.getContextItemsByRow(data)),function(menuItem){return _c('li',{key:menuItem.id},[_c('a',{class:{ emphasized: menuItem.emphasize },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onMenuClick(menuItem.id, data)}}},[_vm._v(_vm._s(menuItem.text))])])})}}],null,false,2968768108)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }