<template>
  <gl-form @submit.prevent="generate">
    <Wizard
      :leftSectionTitle="leftSectionTitle"
      :buttons="buttons"
      :rightSectionTitle="rightSectionTitle"
    >
      <template v-slot:left-section>
        <p>
          The details of your source can be found below. Please note that Source
          Type and Availability Zone can not be updated if you choose to edit
          your source
        </p>
        <gl-table :items="tableSummaryItems" :fields="tableSummaryFields">
        </gl-table>
      </template>
      <template v-slot:right-section>
        <p>
          Please note, sources are not billable unless added to a contribution,
          subsequently delivered and have become live.
        </p>
        <div v-if="showBillableStatus" class="billableFormSelect">
          <br />
          <h2>Billable Status</h2>
          <gl-form-select
            :options="billableOptions"
            value="default"
            v-model="currentItem.billableStatus"
          />
        </div>

        <div v-if="isWaitingForPrice">
          <gl-loading-icon label="Loading" />
        </div>
        <div v-else>
          <gl-table :items="tablePricingItems" :fields="tablePricingFields">
          </gl-table>
        </div>
        <gl-form-group
          class="m-4 form-group-confirmed"
          label=""
          label-size="sm"
          label-for="confirmed"
        >
          <gl-form-checkbox
            id="confirmed"
            v-model="confirmed"
            required
            :disabled="isUpdate || isWaitingForPrice"
            ><strong
              >I confirm all the details are correct and the pricing shown above
              will be incurred as an Order per the agreement.</strong
            ></gl-form-checkbox
          >
        </gl-form-group>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import {
  GlLoadingIcon,
  GlForm,
  GlFormCheckbox,
  GlFormGroup,
  GlFormSelect,
  GlTable
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import {
  streamProfileClient,
  sourceApiClient,
  pricingQuoteApiClient
} from "@/mixins/apiClients";
import AppConfig from "@/app-config";
import {
  SOURCE_TYPES,
  SOURCE_TYPE_OPTIONS,
  ALIAS_ITEMS_SOURCE
} from "@/constants.js";
import userInfoMixin from "@/mixins/userInfo";
import pricingUtils from "@/mixins/pricingUtils";
const { EDIT_SECOND } = ALIAS_ITEMS_SOURCE;

export default {
  name: "AddThird",
  mixins: [
    sourceApiClient,
    currentItem,
    streamProfileClient,
    pricingQuoteApiClient,
    userInfoMixin,
    pricingUtils
  ],
  components: {
    GlLoadingIcon,
    Wizard,
    GlForm,
    GlTable,
    GlFormCheckbox,
    GlFormGroup,
    GlFormSelect
  },
  computed: {
    showBillableStatus() {
      if (this.isArqadeAdmin) {
        return !(this.isOrgAdminRoleActive || this.isUserRoleActive);
      }
      return false;
    },
    buttons() {
      return [
        {
          text: `Previous`,
          onClick: () => this.$router.push(EDIT_SECOND)
        },
        {
          text: this.isUpdate ? `Update Source` : `Generate Source`,
          type: `submit`,
          disabled: this.isBusy || !this.confirmed
        }
      ];
    },
    tableSummaryItems() {
      return [
        {
          column_one: "Name",
          column_two: this.currentItem.name
        },
        {
          column_one: "Source Type",
          column_two: SOURCE_TYPE_OPTIONS.find(
            item => item.value === this.currentItem.sourceType
          ).text
        },
        {
          column_one: "Profile",
          column_two: this.streamProfileEvaler(this.streamProfile)
        },
        {
          column_one: "Availability Zone",
          column_two: AppConfig.instance.config.options.availabilityZones[
            this.currentItem.availabilityZone
          ]
            ? `${this.currentItem.availabilityZone} (${
                AppConfig.instance.config.options.availabilityZones[
                  this.currentItem.availabilityZone
                ]
              })`
            : this.currentItem.availabilityZone
        }
      ];
    }
  },
  mounted() {
    Object.keys(this.currentItem).forEach(key => {
      if (this.currentItem[key] == null || this.currentItem[key] == undefined) {
        delete this.currentItem[key];
      }
    });
    if (!this.currentItem.billableStatus) {
      this.currentItem.billableStatus = "DEFAULT";
    }
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: false,
      isWaitingForPrice: true,
      leftSectionTitle: `Summary`,
      rightSectionTitle: `Pricing`,
      streamProfile: {},
      pricing: {
        price: 0,
        unit: ""
      },
      confirmed: this.isUpdate,
      billableOptions: [
        { text: "Default", value: "DEFAULT" },
        { text: "Billable", value: "BILLABLE" },
        { text: "Non-Billable", value: "NON_BILLABLE" },
        { text: "Bundled", value: "BUNDLED" }
      ],

      tableSummaryFields: [
        {
          key: "column_one",
          label: "",
          tdClass: "font-weight-bold"
        },
        {
          key: "column_two",
          label: "",
          tdClass: "text-right"
        }
      ],
      tablePricingFields: [
        {
          key: "column_one",
          label: "",
          tdClass: "font-weight-bold"
        },
        {
          key: "column_two",
          label: "",
          tdClass: "text-right"
        }
      ]
    };
  },
  created() {
    if (this.currentItem.streamProfileId) {
      this.streamProfileClient
        .getById(this.currentItem.streamProfileId)
        .then(profile => {
          this.streamProfile = profile;
        });
    }

    const source = JSON.parse(JSON.stringify(this.currentItem));
    if (source.sourceType === SOURCE_TYPES.RIST) {
      source.sourceType = SOURCE_TYPES.IP;
    }

    this.pricingQuoteApiClient
      .update("source", source)
      .then(pricing => {
        this.pricing = pricing.offer;
        this.isWaitingForPrice = false;
        this.currentItem.quote = pricing;
      })
      .catch(e => this.handleApiError(e));
  },
  methods: {
    async tablePricingItems() {
      return [
        {
          column_one: "Cost",
          column_two: await this.formatPricingDisplay(
            this.pricing.price,
            this.pricing.unit
          )
        }
      ];
    },

    async generate() {
      this.isBusy = true;
      let msg = ``;
      // zixi
      if (this.currentItem.sourceType === SOURCE_TYPES.ZIXI) {
        this.currentItem.port = 2088;
        delete this.currentItem.ipType;
        delete this.currentItem.protocol;
      }
      if (this.currentItem.sourceType === SOURCE_TYPES.RIST) {
        this.currentItem.sourceType = SOURCE_TYPES.IP;
      }
      try {
        if (this.isUpdate) {
          if (this.isOrgAdminRoleActive || this.isUserRoleActive) {
            delete this.currentItem.accessLevel;
          }
          await this.sourceApiClient.patch(
            this.currentItem.id,
            this.currentItem
          );
          msg = `The source "${this.currentItem.name}" was updated successfully.`;
        } else {
          const response = await this.sourceApiClient.create(this.currentItem);
          this.currentItem.id = response.id;
          this.currentItem.host = response.host;
          msg = `The source "${this.currentItem.name}" was created successfully.`;
        }
        this.handleApiSuccess(msg);
        this.$emit("create");
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
<style scoped>
.form-group-confirmed {
  border: solid 1px var(--theme-color);
}
.billableFormSelect {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
}
</style>
