<template>
  <div>
    <gl-navbar class="nav-main ml-0 pl-0 py-0 my-0 fixed-top">
      <Logo />
      <div class="mr-auto push-left pl-0 py-0">
        <Nav
          :menuItems="leftMenuItems"
          @onClick="redirect"
          :activeIndex="topMenuIndexActive"
        />
      </div>
      <div class="d-flex">
        <div
          id="globalSearch"
          v-if="allowSearch"
          class="d-flex align-items-center"
        >
          <gl-search-box-by-type
            class="d-none d-lg-flex my-auto"
            v-model="searchTerm"
            type="text"
            @input="debounceSearch"
            v-on:blur="hideResults"
            v-on:focus="visibleResults = true"
            placeholder="Search or jump to..."
          />
          <div id="searchDropdown" v-if="visibleResults">
            <div>
              <div class="item result" v-for="item in results" :key="item.id">
                <div @click="onClickResult(item)" class="row">
                  <div class="org col-md-4" v-if="item.orgName">
                    {{ item.orgName }}
                  </div>
                  <div class="name col-md-6">{{ item.name }}</div>
                  <div class="type col-md-2">{{ item.resourceType }}</div>
                </div>
              </div>
            </div>

            <div class="item msg" v-if="searchTerm && !results.length">
              <p>{{ searchMsg }}</p>
            </div>
          </div>
        </div>

        <Nav :menuItems="rightMenuItems" @onClick="redirect">
          <SignOut @onClick="redirect" />
        </Nav>
      </div>
    </gl-navbar>
  </div>
</template>

<script>
import { GlNavbar, GlSearchBoxByType } from "@gitlab/ui";
import Logo from "@/components/Logo.vue";
import Nav from "@/components/TopMenu/Nav.vue";
import amplify from "@/mixins/amplify";
import utils from "@/mixins/utils";
import {
  ITEMS_CONTRIBUTIONS,
  ITEMS_DELIVERY,
  ITEMS_DESTINATIONS,
  ITEMS_PACKAGES,
  ITEMS_SOURCES,
  MENU_OPTIONS,
  TOP_MENU_OPTIONS
} from "@/constants.js";
import SignOut from "@/components/TopMenu/SignOut";
import { mapState } from "vuex";
import userInfo from "@/mixins/userInfo.js";
import AppConfig from "@/app-config";
import HttpClient from "@/classes/HttpClient.js";
import { userMgmtApiClient } from "@/mixins/apiClients";

const { SHOW: DESTINATION_SHOW } = ITEMS_DESTINATIONS;
const { SHOW: SOURCE_SHOW } = ITEMS_SOURCES;
const { SHOW: CONTRIBUTION_SHOW } = ITEMS_CONTRIBUTIONS;
const { SHOW: PACKAGE_SHOW } = ITEMS_PACKAGES;
const { ALL } = ITEMS_DELIVERY;

const { SIGN_OUT, CHANGE_THEME } = MENU_OPTIONS;
const { CONNECT, ADMIN } = TOP_MENU_OPTIONS;
const topMenuOptions = [CONNECT, ADMIN];
const STORE = "topMenu";

export default {
  mixins: [amplify, utils, userInfo, userMgmtApiClient],
  components: {
    SignOut,
    GlNavbar,
    GlSearchBoxByType,
    Logo,
    Nav
  },
  data() {
    return {
      baseUrl: AppConfig.instance.config.API_URLS.SEARCH,
      apiClient: new HttpClient(this.baseUrl),
      topMenuIndexActive: 0,
      leftMenuItems: [{ text: "Virtual Connect" }, { text: ADMIN }],
      searchTerm: ``,
      debounce: null,
      results: [],
      searchMsg: "",
      visibleResults: false
    };
  },
  computed: {
    ...mapState({
      currentTopMenuOption: state => state[STORE].currentOption
    }),
    rightMenuItems() {
      return [
        {
          icon: `settings`,
          options: [CHANGE_THEME],
          disabled: this.isMasquerading
        }
      ];
    },

    allowSearch() {
      return AppConfig.instance.config.options.features.allowGlobalSearchInput;
    }
  },
  mounted() {
    this.topMenuIndexActive = topMenuOptions.indexOf(this.currentTopMenuOption);
  },
  methods: {
    hideResults() {
      // s the results are not hidden when clicking on them
      setTimeout(() => {
        this.visibleResults = false;
      }, 200);
    },

    onClickResult(item) {
      let route = this.getResultRoute(item);
      if (route.includes("/delivery/all")) {
        let _route = { path: "/delivery/all", query: { id: item.id } };
        route = _route;
      }
      if (this.getUserOrganizationId !== item.organisationId) {
        this.userMgmtApiClient
          .update("me/masquerade-org/" + item.organisationId)
          .then(() => {
            this.$router.push(route).then(() => {
              window.location.reload();
            });
          });
      } else {
        this.$router.push(route);
      }
    },

    getResultRoute(item) {
      switch (item.resourceType.toLowerCase()) {
        case "contribution":
          return `${CONTRIBUTION_SHOW}/${item.id}`;
        case "delivery":
          return `${ALL}`;
        case "destination":
          return `${DESTINATION_SHOW}/${item.id}`;
        case "package":
          return `${PACKAGE_SHOW}/${item.id}`;
        case "source":
          return `${SOURCE_SHOW}/${item.id}`;
        default:
      }
      return null;
    },
    redirect(target, value) {
      if (topMenuOptions.includes(target)) {
        this.topMenuIndexActive = topMenuOptions.indexOf(target);
        this.$store.commit(`${STORE}/currentOption`, target);
      }
      switch (target) {
        case SIGN_OUT:
          this.signOut();
          break;
        case CHANGE_THEME:
          this.changeTheme(value);
          break;
        default:
          break;
      }
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchForResource(event);
      }, 600);
    },
    async searchForResource(e) {
      this.visibleResults = true;
      if (!e || !e.trim()) {
        this.results = [];
        this.searchMsg = "";
        return;
      }
      this.searchMsg = `Searching...`;
      this.searchTerm = e;
      const q = this.searchTerm.toLowerCase().trim();

      // TODO: Adjust this API call to follow others
      this.apiClient
        .get(this.baseUrl + "search?q=" + q)
        .then(response => {
          this.results = response.data.items;
          if (!this.results.length) {
            this.searchMsg = `No results found for "${this.searchTerm}"`;
          } else {
            this.searchMsg = "";
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.nav-main {
  background-color: var(--theme-color);
  color: $t-red-50;
  font-weight: bold;

  :hover {
    color: #000000;
  }

  a {
    color: $t-red-50;
  }

  .dropdown-item {
    @include font-regular-inter;

    &:hover {
      font-weight: bold;
    }
  }

  .gl-form-input,
  .gl-form-input.form-control {
    width: 300px;
    @include font-regular-inter;
  }

  .push-left {
    margin-left: 210px;
  }

  div#searchDropdown {
    position: absolute;
    top: 36px;
    right: 10px;
    background-color: white;
    border: 1px solid #e1e4e8;

    .item:hover {
      background-color: lightgrey !important;
    }

    > div {
      max-height: 350px;
      overflow-y: scroll;
    }

    .item:nth-child(odd) {
      background: linear-gradient(
        90deg,
        rgba(179, 178, 194, 0.1) 0%,
        rgba(226, 229, 236, 0.4) 34%,
        rgba(233, 236, 242, 0.1) 73%
      );
    }

    .item:nth-child(even) {
      background: #fff;
    }

    .item {
      background-color: whitesmoke;
      width: 435px;
      margin: 4px auto 0 auto;
      padding: 10px 20px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

      &.result {
        color: #333333;
        cursor: pointer;

        div {
          font-weight: normal;
          // vertical centering :
          display: flex;
          align-items: center;
          height: auto;

          &.org {
            color: grey;
            font-weight: normal;
          }

          &.name {
            color: black;
          }

          &.type {
            color: grey;
            font-weight: normal;
            text-align: left !important;
          }
        }
      }

      &.msg {
        color: grey;
      }
    }
  }
}
</style>
