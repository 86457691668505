import ResourceApiClient from "@/classes/ResourceApiClient";
import HttpClient from "@/classes/HttpClient.js";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      baseUrl: AppConfig.instance.config.API_URLS.METRICS,
      apiClient: new HttpClient(this.baseUrl)
    };
  },
  methods: {
    async getMetrics(
      entity,
      id,
      metric,
      startDate = undefined,
      endDate = undefined
    ) {
      const path = `metrics/${entity}/${id}/${metric}`;
      const url = new URL(this.baseUrl + path);
      if (startDate) {
        url.searchParams.append(`start`, startDate);
      }
      if (endDate) {
        url.searchParams.append(`end`, endDate);
      }

      return this.apiClient.get(url);
    },
    getMetricsHealth(entity) {
      return new ResourceApiClient(
        AppConfig.instance.config.API_URLS.METRICS,
        entity
      );
    }
  }
};
