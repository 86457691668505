<template>
  <div>
    <gl-toggle
      label="Open Ended"
      v-if="showOpenEnded"
      v-model="isOpenEndedData"
      @change="openEnded($event)"
    ></gl-toggle>
    <p class="m-4">
      <DateRange
        :showAdditionalTimes="true"
        header="BROADCAST:"
        @dateRangeSet="rangeDateSet"
        :startDate="eventStart ? eventStart : null"
        :endDate="eventEnd ? eventEnd : null"
        :disabledStart="new Date()"
        :endDisabled="this.isOpenEndedData"
        :startDisabled="disableStarts"
        :contributionType="contributionType"
      />
    </p>
  </div>
</template>

<script>
import DateRange from "@/components/Date/DateRange.vue";
import { GlToggle } from "@gitlab/ui";
export default {
  components: {
    DateRange,
    GlToggle
  },
  props: {
    disableStarts: {
      type: Boolean,
      default: false
    },
    disableEnds: {
      type: Boolean,
      default: false
    },
    eventType: {
      type: String
    },
    eventStart: {
      type: String
    },
    eventEnd: {
      type: String
    },
    isOpenEnded: {
      type: Boolean,
      default: false
    },
    showOpenEnded: {
      type: Boolean,
      default: false
    },
    contributionType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: true,
      date: null,
      isOpenEndedData: this.isOpenEnded,
      currentItem: {}
    };
  },
  methods: {
    rangeDateSet(event) {
      this.$emit(`dateRangeSet`, event);
    },
    openEnded(val) {
      this.isOpenEndedData = val;
      this.$emit(`openEnded`, val);
    }
  }
};
</script>
<style>
#collapse-date-range {
  min-width: 480px;
}
</style>
