<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button variant="confirm" class="mr-3" :href="`#${ADD}`"
          >Create stream profile</gl-button
        >
        <gl-search-box-by-type
          class="d-none d-lg-flex ml-2"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          @rowSelected="onRowSelected"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import { streamProfileClient } from "@/mixins/apiClients";
import { CODECS, SCAN_TYPES, ITEMS_STREAM_PROFILE } from "@/constants.js";
import debouncedInput from "@/mixins/debouncedInput.js";
import { GlForm, GlSearchBoxByType, GlButton } from "@gitlab/ui";

const { ADD } = ITEMS_STREAM_PROFILE;

export default {
  name: "YourAll",
  mixins: [debouncedInput, streamProfileClient],
  components: {
    ArqDataGrid,
    GlForm,
    ArqDataGridDetailsPane,
    GlSearchBoxByType,
    GlButton
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    onRowSelected(row, selectedRows) {
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 10;
      const query = this.streamProfileClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query.sortBy(field);
        if (order) {
          query.sortBy(field, order);
        }
      }
      query.filterBy(phrase);
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        for (const item of response.items) {
          const codec = CODECS.find(c => c.value === item.codec);
          item.codec = codec ? codec.text : item.codec;
          const scanType = SCAN_TYPES.find(c => c.value === item.scanType);
          item.scanType = scanType ? scanType.text : item.scanType;
        }
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  data() {
    return {
      ADD: ADD,
      searchPhrase: "",
      sortModel: {},
      pageModel: {},
      selectedRow: undefined,
      data: {},
      columns: [
        {
          text: "Name",
          field: "profileName"
        },
        {
          text: "Bitrate (bps)",
          field: "bitrateBps"
        },
        {
          text: "Codec",
          field: "codec"
        },
        {
          text: "Framerate Numerator",
          field: "framerateNumerator"
        },
        {
          text: "Framerate Denominator",
          field: "framerateDenominator"
        },
        {
          text: "Height",
          field: "height"
        },
        {
          text: "Width",
          field: "width"
        },
        {
          text: "Scan Type",
          field: "scanType"
        }
      ],
      detailsColumns: [
        {
          text: "Name",
          field: "profileName"
        },
        {
          text: "Bitrate (bps)",
          field: "bitrateBps"
        },
        {
          text: "Codec",
          field: "codec"
        },
        {
          text: "Colorspace",
          field: "colourSpace"
        },
        {
          text: "Framerate Numerator",
          field: "framerateNumerator"
        },
        {
          text: "Framerate Denominator",
          field: "framerateDenominator"
        },
        {
          text: "GOP (Frames)",
          field: "gopFrames"
        },
        {
          text: "Height",
          field: "height"
        },
        {
          text: "Width",
          field: "width"
        },
        {
          text: "Rate Control Mode",
          field: "rateControlMode"
        },
        {
          text: "Scan Type",
          field: "scanType"
        }
      ]
    };
  }
};
</script>
