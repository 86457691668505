<template>
  <div id="expired" v-if="orgId && isExpired">
    <div id="toggleAlert" v-if="!showAlert" @click="toggleAlert()">
      <span>{{ shortMessage }}</span>
    </div>
    <div id="blocker" v-if="showAlert">
      <div id="banner">
        <div>
          <h1>SUBSCRIPTION CANCELLED</h1>
          <p>
            Your Arqade subscription has been cancelled in AWS Marketplace by
            <b>{{ canceller }}</b>
          </p>
          <p></p>
          <ul>
            <li>
              All live channel orders will continue for their remaining
              committed duration (maximum one month).
            </li>
            <li>{{ event_termination }}</li>
            <li>
              Content arrangements with other organisations will cease at the
              same time as the sources cease and they will be notified.
            </li>
          </ul>
          <h2>You can re-subscribe to Arqade within AWS Marketplace.</h2>
          <p>Thank you for your business.</p>
          <button @click="toggleAlert()">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { organizationApiClient } from "@/mixins/apiClients";
import awsMarketplaceApiClient from "@/mixins/awsMarketplaceApiClient";

export default {
  components: {},
  mixins: [organizationApiClient, awsMarketplaceApiClient],
  props: {
    orgId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpired: false,
      showAlert: true,
      canceller: "",
      event_termination: null,
      shortMessage:
        "Your subscription has been cancelled. Click here for more information."
    };
  },
  created() {
    this.getMarketplaceInfo(this.orgId);
    setInterval(() => {
      this.getMarketplaceInfo(this.orgId);
    }, 60000); // 1min
  },
  methods: {
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async getMarketplaceInfo(orgId) {
      await this.organizationApiClient.getById(orgId).then(async org => {
        if (org.awsMarketplaceRegistrationId) {
          await this.awsMarketplaceApiClient
            .getById(org.awsMarketplaceRegistrationId)
            .then(reg => {
              if (reg.registrationEnd) {
                const regEnd = new Date(reg.registrationEnd);
                if (regEnd < new Date()) {
                  this.canceller = reg.userGivenName + " " + reg.userFamilyName;
                  this.event_termination = this.getEventTerminationMessage(
                    regEnd
                  );
                  this.isExpired = true;
                } else {
                  this.isExpired = false;
                }
              } else {
                this.isExpired = false;
              }
            });
        } else {
          this.isExpired = false;
        }
      });
    },
    getEventTerminationMessage(regEnd) {
      const minutesRemainingOneHour = Math.floor((regEnd - new Date()) / 60000);
      if (minutesRemainingOneHour > 0) {
        return (
          "All live event orders will terminate in " +
          minutesRemainingOneHour +
          " minutes"
        );
      } else {
        return "All live event orders were terminated an hour after the cancellation";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
div#toggleAlert {
  background-color: rgba(0, 0, 0, 0.6);
  //border-radius: 0px 0px 6px 6px;
  border: 1px solid black;
  cursor: pointer;
  height: 36px;
  padding: 8px;
  position: fixed;
  //right: 160px !important;
  text-align: left;
  top: 36px;
  width: 100%;
  z-index: 9 !important;

  > span {
    top: 17px;
    margin-left: 6px;
    color: lightgrey;
    font-weight: bold;
  }
}

div#blocker {
  color: black;
  height: 100%;
  width: 100%;
  z-index: 999 !important;
  border: 3px solid black;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);

  > div#banner {
    align: center;
    //background-color: yellow;
    background: repeating-linear-gradient(
      45deg,
      black,
      black 40px,
      yellow 40px,
      yellow 80px
    );
    border: 3px solid black;
    color: black;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 110px;
    padding: 20px;
    //position: relative;
    //top: 200px;
    width: 60%;
    z-index: 999 !important;

    > div {
      margin: 10px;
      padding: 20px;
      border: 3px solid black;
      background-color: rgba(255, 255, 255, 0.95);
      position: relative;

      ul {
        li {
          margin-bottom: 8px !important;
        }
      }

      > h1 {
        color: black;
        //-webkit-text-stroke-width: 2px;
        //-webkit-text-stroke-color: black;
      }

      > h2 {
        color: grey;
      }

      > p:last-of-type {
        text-align: center;
        color: grey;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 18px;
      }

      > button {
        position: absolute;
        padding: 14px;
        right: 30px;
        bottom: 20px;

        &:hover {
          background-color: #c80032;
          color: white;
        }
      }
    }
  }
}
</style>
