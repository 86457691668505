import * as configLocal from "@/assets/config.json";
class AppConfig {
  static instance =
    process.env.NODE_ENV === "test"
      ? new AppConfig(configLocal.default)
      : undefined;

  constructor(config) {
    if (typeof config === "undefined") {
      throw new Error("Cannot be called directly");
    } else {
      if (!AppConfig.instance) {
        this.config = config;
        AppConfig.instance = this;
      }
      return AppConfig.instance;
    }
  }
}

export default AppConfig;
