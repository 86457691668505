<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <gl-form-group
      class="m-4"
      label="Output Destination Type"
      label-size="sm"
      label-for="outputDestinationType"
      :invalid-feedback="invalidHLSOutputDestinationFeedback()"
    >
      <gl-form-select
        id="outputDestinationType"
        v-model="currentItem.outputDestinationType"
        :options="outputDestinationTypeOptions"
        placeholder="Output Destination Type"
        class="mt-1"
        :state="isValidHLSOutputDestination()"
      />
    </gl-form-group>
    <gl-form-group
      v-if="currentItem.outputDestinationType"
      class="m-4"
      label="URL"
      label-size="sm"
      :invalid-feedback="invalidUrlFeedback()"
      label-for="url"
    >
      <gl-form-input
        type="text"
        id="url"
        v-model="currentItem.url"
        placeholder="URL"
        class="mt-1"
        :state="isValidUrl()"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="CDN Settings Type"
      label-size="sm"
      label-for="cdnSettingsType"
      :invalid-feedback="invalidCdnSettingsTypeFeedback()"
    >
      <gl-form-select
        id="cdnSettingsType"
        v-model="currentItem.cdnSettingsType"
        :options="cdnSettingsTypeOptions"
        placeholder="CDN Settings Type"
        class="mt-1"
        :state="isValidCdnSettingsType()"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="Name Modifier"
      label-size="sm"
      :invalid-feedback="invalidNameModifierFeedback()"
      label-for="nameModifier"
    >
      <gl-form-input
        type="text"
        id="nameModifier"
        v-model="currentItem.nameModifier"
        placeholder="Name Modifier"
        class="mt-1"
        :state="isNameModifierValid()"
      />
    </gl-form-group>
  </div>
</template>

<script>
import {
  DESTINATION_TYPES,
  ECDN_SETTINGS_TYPES,
  OUTPUT_DESTINATION_TYPES
} from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { GlFormGroup, GlFormInput, GlFormSelect } from "@gitlab/ui";

export default {
  components: { GlFormGroup, GlFormInput, GlFormSelect, RegionComponent },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  mounted() {
    this.setCdnSettingsTypeOptions();
  },
  methods: {
    invalidCdnSettingsTypeFeedback() {
      return this.isValidCdnSettingsType()
        ? ""
        : "Please select a CDN Settings Type";
    },
    invalidNameModifierFeedback() {
      return this.isNameModifierValid()
        ? ""
        : "Please enter valid Name Modifier";
    },
    invalidUrlFeedback() {
      let message = "";
      if (!this.isValidUrl()) {
        if (
          this.currentItem.outputDestinationType === OUTPUT_DESTINATION_TYPES.S3
        ) {
          message = "Please enter URL with protocol s3 and with complete path";
        } else {
          message =
            "Please enter valid URL with protocol http or https and with complete path";
        }
      }
      return message;
    },
    invalidHLSOutputDestinationFeedback() {
      return this.isValidHLSOutputDestination()
        ? ""
        : "Please select a valid output destination type";
    },
    isNameModifierValid() {
      return !!this.currentItem.nameModifier;
    },
    isValidUrl() {
      let url;
      try {
        url = new URL(this.currentItem.url);
      } catch (_) {
        return false;
      }

      if (!url.pathname || url.pathname.length < 2) {
        return false;
      }

      let protocols = [];

      switch (this.currentItem.outputDestinationType) {
        case OUTPUT_DESTINATION_TYPES.S3:
          protocols.push("s3:", "s3ssl:");
          break;
        case OUTPUT_DESTINATION_TYPES.HTTP_SERVER:
          protocols.push("https:", "http:");
          break;
      }

      return protocols.includes(url.protocol);
    },
    isValidHLSOutputDestination() {
      if (
        this.currentItem.outputDestinationType &&
        this.outputDestinationTypeOptions.length === 3
      ) {
        // after first assigment we remove the empty option
        this.outputDestinationTypeOptions.splice(0, 1);
      }
      return !!this.currentItem.outputDestinationType;
    },
    isValidCdnSettingsType() {
      return !!this.cdnSettingsTypeOptions.find(
        o => o.value === this.currentItem.cdnSettingsType
      );
    },

    isDestinationValid() {
      switch (true) {
        case !this.isRegionValid:
        case !this.currentItem.outputDestinationType:
        case !this.isNameModifierValid():
        case !this.isValidCdnSettingsType():
        case !this.isValidUrl():
          return false;
        default:
          return true;
      }
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    setCdnSettingsTypeOptions() {
      if (
        this.currentItem.outputDestinationType === OUTPUT_DESTINATION_TYPES.S3
      ) {
        this.cdnSettingsTypeOptions = [
          {
            text: "S3",
            value: ECDN_SETTINGS_TYPES.S3
          }
        ];
      } else if (
        this.currentItem.outputDestinationType ===
        OUTPUT_DESTINATION_TYPES.MEDIASTORE
      ) {
        this.cdnSettingsTypeOptions = [
          {
            text: "MEDIASTORE",
            value: ECDN_SETTINGS_TYPES.MEDIASTORE
          }
        ];
      } else if (
        this.currentItem.outputDestinationType ===
        OUTPUT_DESTINATION_TYPES.MEDIAPACKAGE
      ) {
        this.cdnSettingsTypeOptions = [
          {
            text: "WEBDAV",
            value: ECDN_SETTINGS_TYPES.WEBDAV
          }
        ];
      } else if (
        this.currentItem.outputDestinationType ===
        OUTPUT_DESTINATION_TYPES.HTTP_SERVER
      ) {
        this.cdnSettingsTypeOptions = [
          {
            text: "AKMAI",
            value: ECDN_SETTINGS_TYPES.AKMAI
          },
          {
            text: "PUT",
            value: ECDN_SETTINGS_TYPES.PUT
          },
          {
            text: "WEBDAV",
            value: ECDN_SETTINGS_TYPES.WEBDAV
          }
        ];
      }
    }
  },
  data() {
    return {
      isRegionValid: false,
      cdnSettingsTypeOptions: [],
      outputDestinationTypeOptions: [
        { text: "", value: null },
        { text: `S3`, value: OUTPUT_DESTINATION_TYPES.S3 },
        { text: `HTTP_SERVER`, value: OUTPUT_DESTINATION_TYPES.HTTP_SERVER }
      ]
    };
  },
  props: {
    currentItem: Destination,
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    currentItem: {
      //triggered when a non child component attribute is changed
      handler() {
        this.setCdnSettingsTypeOptions();
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
