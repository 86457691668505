<template>
  <div id="assistanceComponent">
    <div id="assistancePanel" v-if="visible" class="mt-3">
      <span
        id="close"
        v-if="visible"
        @click="
          visible = false;
          activeFaq = null;
        "
      >
        <gl-icon name="close" />
      </span>
      <div id="assistanceText" v-if="activeFaq == null">
        <h1>{{ title }}</h1>
        <p v-if="!activeFaq">
          {{ body }}
        </p>
      </div>

      <div id="assistanceFaqs" v-if="!activeFaq">
        <ul>
          <li v-for="faq in faqs" :key="faq.id" @click="activeFaq = faq">
            {{ faq.question }}
          </li>
        </ul>
      </div>

      <div id="activeFaq" v-if="activeFaq">
        <h1>{{ activeFaq.question }}</h1>
        <p>{{ activeFaq.answer }}</p>
        <span @click="activeFaq = null"> go back</span>
      </div>
    </div>
    <div
      id="assistanceTab"
      v-if="title || body"
      v-bind:class="[visible ? 'visible' : 'hidden']"
      role="button"
      @click="visible = true"
    >
      <span>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 25.8438C19.9062 25.8438 25.5938 20.207 25.5938 13.25C25.5938 6.34375 19.9062 0.65625 13 0.65625C6.04297 0.65625 0.40625 6.34375 0.40625 13.25C0.40625 20.207 6.04297 25.8438 13 25.8438ZM7.71875 21.9844L10.4609 19.2422C12.0352 19.9531 13.9141 19.9531 15.4883 19.2422L18.2305 21.9844C15.0312 23.9141 10.918 23.9141 7.71875 21.9844ZM17.0625 13.25C17.0625 15.5352 15.2344 17.3125 13 17.3125C10.7148 17.3125 8.9375 15.5352 8.9375 13.25C8.9375 11.0156 10.7148 9.1875 13 9.1875C15.2344 9.1875 17.0625 11.0156 17.0625 13.25ZM21.6836 18.5312L18.9414 15.7891C19.6523 14.2148 19.6523 12.3359 18.9414 10.7617L21.6836 8.01953C23.6133 11.2188 23.6133 15.332 21.6836 18.5312ZM18.2305 4.56641L15.4883 7.30859C13.9141 6.59766 12.0352 6.59766 10.4609 7.30859L7.71875 4.56641C10.918 2.63672 15.0312 2.63672 18.2305 4.56641ZM4.26562 8.01953L7.00781 10.7617C6.29688 12.3359 6.29688 14.2148 7.00781 15.7891L4.26562 18.5312C2.33594 15.332 2.33594 11.2188 4.26562 8.01953Z"
            fill="#444444"
          />
        </svg>
        Assistance
      </span>
    </div>
  </div>
</template>
<script>
import { GlIcon } from "@gitlab/ui";
import * as assistanceLocal from "@/assets/assistance.json";

export default {
  name: "Assistance",
  components: {
    GlIcon
  },
  methods: {
    setAssistance() {
      this.resetAssistance();
      if (!this.assistance) {
        return;
      }
      const page = this.assistance.pages[this.pageName];
      if (!page) {
        return;
      }

      const faqs = this.assistance.faqs;
      let pageFaqs = [];
      for (let faq of faqs) {
        // check if any of the fag tags is included on the page,tags
        const tagsMatching = faq.tags.some(faq => page.tags.includes(faq));
        if (tagsMatching) {
          pageFaqs.push(faq);
        }
      }
      this.body = page.description;
      this.title = page.title;
      this.faqs = pageFaqs;
      this.activeFaq = null;

      return page;
    },
    resetAssistance() {
      this.body = "";
      this.title = "";
      this.faqs = [];
      this.activeFaq = null;
    }
  },
  created() {
    this.assistance = assistanceLocal.default;
    if (!this.assistance) {
      return;
    }
    this.pageName = this.$router.history.current.path.split("/")[1];
    this.pageName = this.pageName ? this.pageName : "dashboard";
    this.setAssistance();
  },
  watch: {
    $route(to) {
      this.pageName = to.path.split("/")[1];
      this.pageName = this.pageName ? this.pageName : "dashboard";
    },
    pageName: {
      handler() {
        this.visible = false;
        this.setAssistance();
      }
    }
  },
  data() {
    return {
      pageName: "",
      assistance: "",
      body: "",
      title: "",
      activeFaq: null,
      visible: false,
      faqs: []
    };
  }
};
</script>
<style lang="scss" scoped>
.gl-breadcrumbs {
  display: none;
}

div#assistanceComponent {
  $back: #dbdbdb;
  cursor: default;

  h1 {
    color: #2e2e2e;
    font-size: 18pt;
    padding-bottom: 6px;
  }

  p {
    padding-top: 12px;
  }

  div#assistancePanel {
    background-color: $back;
    display: flex;
    height: 100%;
    padding-bottom: 30px;
    padding-left: 14px;
    padding-top: 40px;
    right: 0px;
    top: 20px;
    width: 100%;

    > span#close {
      color: grey;
      cursor: pointer;
      font-weight: bold;
      position: fixed;
      right: 14px;

      text-align: center;
    }

    > div#assistanceText {
      flex: 35%;
      padding-top: 6px;
    }

    > div#assistanceFaqs {
      flex: 65%;
      padding-top: 20px;

      ul {
        li {
          cursor: pointer;
          font-weight: bold;
          padding-bottom: 10px;
        }
      }
    }

    > div#activeFaq {
      width: 100%;
      padding-top: 6px;

      span {
        cursor: pointer;
        color: grey;
        font-weight: bold;
        margin-left: 320px;
        margin-top: 10px;
      }
    }
  }

  div#assistanceTab {
    background-color: $back;
    border-radius: 0px 0px 5px 5px;
    font-weight: bold;
    padding: 14px;
    padding-left: 0px;
    width: auto;
    z-index: 99 !important;

    &.hidden {
      position: fixed;
      right: 20px;
      top: 30px;
    }

    &.visible {
      margin-right: 20px;
      position: absolute;
      right: 0px;
      width: 134px;
    }

    svg {
      margin-right: 4px;
      margin-left: 10px;
    }
  }
}
</style>
