import OpsPanel from "@/views/OpsPanel/OpsPanel";
import NetworkBrowser from "@/components/OpsPanel/NetworkBrowser";
import { MAIN_ITEMS, ITEMS_OPS_PANEL } from "@/constants.js";

const { OPS_PANEL } = MAIN_ITEMS;
const { NETWORK_BROWSER } = ITEMS_OPS_PANEL;
const HOST = "OPS-PANEL";

const panel = [
  {
    path: OPS_PANEL,
    name: `${HOST}`,
    component: OpsPanel,
    children: [
      {
        path: NETWORK_BROWSER,
        component: NetworkBrowser
      }
    ]
  }
];

export default panel;
