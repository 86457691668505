import axios from "axios";
import * as configLocal from "@/assets/config.json";
import AppConfig from "./app-config";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import setConfigs from "@gitlab/ui/dist/config";
import Amplify from "aws-amplify";

import "bootstrap/dist/css/bootstrap.css";
import "@/scss/index.css";
import "@/scss/shared.scss";
import "@/scss/cropper.css";
import Toasted from "@gitlab/vue-toasted";

import { GlCollapseToggleDirective, GlIcon } from "@gitlab/ui";
import { BootstrapVue } from "bootstrap-vue";
import AsyncComputed from "vue-async-computed";
import { injectJwtTokenOverrideMethods } from "@/jwtInjection";
import DropdownMenu from "v-dropdown-menu/vue2";

const readConfig = async () => {
  let config = configLocal.default;
  if (process.env.VUE_APP_ENV !== "local" && process.env.NODE_ENV !== "test") {
    const c = axios.create();
    const configBody = await c.get("config.json");
    config = eval(configBody.data);
  }
  new AppConfig(config);
};

readConfig().then(() => {
  Amplify.configure(AppConfig.instance.config.awsmobile);

  setConfigs();

  Vue.config.productionTip = false;
  Vue.use(BootstrapVue);
  Vue.component("gl-icon", GlIcon);
  Vue.use(DropdownMenu);

  Vue.use(AsyncComputed);
  Vue.directive("gl-collapse-toggle", GlCollapseToggleDirective);

  const DEFAULT_OPTIONS = {
    iconPack: "callback",
    position: "top-right",
    duration: 15000,
    singleton: false,
    className: "gl-toast",
    keepOnHover: true
  };

  /* eslint-disable no-param-reassign */
  Vue.use(Toasted, DEFAULT_OPTIONS);

  const showMsg = (message, options) => {
    Vue.toasted.show(`<span role="alert">${message}</span>`, {
      ...options,
      ...DEFAULT_OPTIONS
    });
  };

  Vue.prototype.$toast = {
    showError: message => {
      const options = {
        type: "error"
      };
      showMsg(message, options);
    },
    showSuccess: message => {
      const options = {
        type: "success"
      };
      showMsg(message, options);
    }
  };

  injectJwtTokenOverrideMethods(window);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
