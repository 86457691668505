<template>
  <div>
    <div class="text-right">
      <gl-button-group>
        <gl-button @click="resetConfiguration()" :disabled="!selectedPackage"
          >Reset</gl-button
        >
        <gl-button
          :disabled="!canGenerateMetrics"
          variant="confirm"
          @click="metricGenerated = true"
          >Generate Metrics</gl-button
        >
      </gl-button-group>
    </div>
    <div class="mx-3 my-1">
      <div v-if="metricGenerated">
        <Metrics
          :selectedPackage="selectedPackage"
          :selectedContribution="selectedContribution"
          :selectedDelivery="selectedDelivery"
          :selectedDestination="selectedDestination"
          @changeMetric="changeMetric"
        />
      </div>
      <div v-show="!metricGenerated">
        <b-row
          class="position-relative"
          :class="{ 'monitoring-row-with-destinations': showDestinations }"
        >
          <b-col v-show="showDestinations" class="monitoring-column-prev"
            ><div
              class="d-flex justify-content-center align-items-center text-nowrap monitoring-text-rotate-left h-100"
            >
              <gl-button
                @click.prevent.stop="hideDestinations()"
                icon="chevron-lg-up"
              >
              </gl-button>
              <h4 class="ml-3">My Packages</h4>
            </div>
          </b-col>
          <b-col cols="4" v-show="!showDestinations">
            <Packages
              @rowSelected="onRowSelectedPackage"
              :selectedPackage="selectedPackage"
            />
            <div
              class="monitoring__line"
              :style="line0Style"
              v-show="(showLines || selectedContribution) && selectedPackage"
            ></div>
          </b-col>
          <b-col cols="4">
            <div>
              <Contributions
                @rowSelected="onRowSelectedContributions"
                :selectedPackage="selectedPackage"
                @generateLines="generateLines"
                ref="contributions"
              />
            </div>
            <div
              class="monitoring__line"
              :style="line1Style"
              v-show="(showLines || selectedDelivery) && selectedContribution"
            ></div>
          </b-col>
          <b-col cols="4">
            <Delivery
              @rowSelected="onRowSelectedDelivery"
              :selectedContribution="selectedContribution"
              @generateLines="generateLines"
              ref="delivery"
            />
            <div
              class="monitoring__line"
              :style="line2Style"
              v-show="showLines && selectedDelivery && showDestinations"
            ></div>
          </b-col>
          <b-col cols="4" v-show="showDestinations">
            <Destination
              @rowSelected="onRowSelectedDestination"
              :selectedDelivery="selectedDelivery"
              @generateLines="generateLines"
              ref="destination"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Packages from "@/components/Monitoring/Packages.vue";
import Contributions from "@/components/Monitoring/Contributions.vue";
import Delivery from "@/components/Monitoring/Delivery.vue";
import Metrics from "@/components/Monitoring/Metrics.vue";
import Destination from "@/components/Monitoring/Destination.vue";
import { GlButton, GlButtonGroup } from "@gitlab/ui";

export default {
  name: "NetworkBrowser",
  components: {
    Packages,
    Contributions,
    Metrics,
    Delivery,
    Destination,
    GlButton,
    GlButtonGroup
  },
  computed: {
    canGenerateMetrics() {
      return (
        !this.metricGenerated &&
        this.selectedPackage &&
        this.selectedContribution &&
        this.selectedDelivery &&
        this.selectedDestination
      );
    }
  },
  methods: {
    resetConfiguration() {
      this.metricGenerated = false;
      this.selectedPackage = undefined;
      this.selectedContribution = undefined;
      this.selectedDelivery = undefined;
      this.selectedDestination = undefined;
      this.showDestinations = false;
      this.showLines = false;
      this.$emit("clearPackages");
    },
    onRowSelectedPackage(row) {
      if (this.selectedPackage != row) {
        this.selectedContribution = undefined;
        this.selectedDelivery = undefined;
        this.selectedDestination = undefined;
      }
      this.selectedPackage = row;
      this.showLines = false;
    },
    onRowSelectedContributions(row) {
      if (this.selectedContribution != row) {
        this.selectedDelivery = undefined;
        this.selectedDestination = undefined;
      }
      this.selectedContribution = row;
      this.showLines = false;
    },
    onRowSelectedDelivery(row) {
      if (this.selectedDelivery != row) {
        this.selectedDestination = undefined;
      }
      this.selectedDelivery = row;
      this.showDestinations = !!this.selectedDelivery;
      this.showLines = false;
    },
    onRowSelectedDestination(row) {
      this.selectedDestination = row;
      this.generateLines();
    },
    changeMetric() {
      this.metricGenerated = false;
    },
    hideDestinations() {
      this.showDestinations = false;
      this.generateLines();
    },
    generateLines() {
      setTimeout(() => {
        this.showLines = true;
        if (this.selectedPackage) {
          const topPackage =
            this.selectedPackage.ref[0].offsetTop +
            this.selectedPackage.ref[0].clientHeight / 2;
          const topContribution =
            this.$refs.contributions.$el.clientHeight > 210 ? 135 : 131.5;
          const heightPackage =
            this.selectedPackage.ref[0].offsetTop -
            this.selectedPackage.ref[0].clientHeight / 2 -
            78;
          const heightConribution =
            this.$refs.contributions.$el.clientHeight - 221;
          this.line0Style = {
            top: Math.min(topPackage, topContribution) + "px",
            height: Math.max(heightPackage, heightConribution) + "px"
          };
        }
        if (this.selectedContribution) {
          const topContribution =
            this.selectedContribution.ref[0].offsetTop +
            this.selectedContribution.ref[0].clientHeight / 2;
          const topDelivery =
            this.$refs.delivery.$el.clientHeight > 210 ? 133 : 131.5;
          const heightContribution =
            this.selectedContribution.ref[0].offsetTop -
            this.selectedContribution.ref[0].clientHeight / 2 -
            68;
          const heightDelivery = this.$refs.delivery.$el.clientHeight - 212;
          this.line1Style = {
            top: Math.min(topContribution, topDelivery) + "px",
            height: Math.max(heightContribution, heightDelivery) + "px"
          };
        }
        if (this.selectedDelivery && this.showDestinations) {
          const heightDelivery =
            this.selectedDelivery.ref[0].offsetTop -
            this.selectedDelivery.ref[0].clientHeight / 2 -
            39;
          const heightDestination =
            this.$refs.destination.$el.clientHeight - 130;
          this.line2Style = {
            top: "98px",
            height: Math.max(heightDelivery, heightDestination) + "px"
          };
        }
      }, 0);
    }
  },
  data() {
    return {
      showLines: false,
      selectedPackage: undefined,
      selectedContribution: undefined,
      selectedDelivery: undefined,
      selectedDestination: undefined,
      metricGenerated: false,
      newConfigurationName: "",
      showDestinations: false,
      line0Style: {},
      line1Style: {},
      line2Style: {}
    };
  }
};
</script>
<style scoped>
.monitoring-save-configuration {
  position: absolute;
  top: 28px;
  z-index: 10;
  width: 180px;
  right: 0;
}
.monitoring__line {
  display: inline-block;
  position: absolute;
  background-color: #999999;
  width: 2px;
  right: -1px;
}
.monitoring-column-prev {
  width: 50px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  background-color: #fff;
  z-index: 10;
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.2);
}
.monitoring-text-rotate-left {
  transform: rotate(-90deg);
}
.monitoring-row-with-destinations {
  padding-left: 35px;
}
</style>
