<template>
  <b-row>
    <b-col cols="8">
      <smart-select-on-off
        class="mt-2"
        :disabled="isBusy"
        @itemOnOff="enableGrant"
        :selected="granted"
        :dataSource="organizationApiClient"
        :dataSourceParams="dataSourceParams"
        :textFormatter="organizationNameFormatter"
        :disabledItems="disabledItems"
      ></smart-select-on-off>
    </b-col>
    <b-col cols="4"> </b-col>
  </b-row>
</template>
<script>
import organizationApiClient from "@/mixins/organizationApiClient";
import partnerGrantsApiClient from "@/mixins/partnerGrantsApiClient";
import partnerGrantsHelper from "@/mixins/parnterGrantsHelper";
import SmartSelectOnOff from "@/components/SmartSelectOnOff/SmartSelectOnOff.vue";
import userInfoMixin from "@/mixins/userInfo";
import { BCol, BRow } from "bootstrap-vue";

export default {
  name: "GrantsAll",
  mixins: [
    userInfoMixin,
    organizationApiClient,
    partnerGrantsApiClient,
    partnerGrantsHelper
  ],
  components: { SmartSelectOnOff, BCol, BRow },
  props: {
    isGranted: null
  },
  data() {
    return {
      granted: [],
      grants: [],
      disabledItems: [],
      isBusy: true
    };
  },
  created() {
    this.disabledItems.push(this.getUserOrganizationId);
  },
  mounted() {
    this.dataReload();
  },
  computed: {
    dataSourceParams() {
      switch (this.isGranted) {
        case true:
          return [{ name: "granted", value: true }];
        case false:
          return [{ name: "granted", value: false }];
        default:
          return [];
      }
    }
  },
  methods: {
    dataReload() {
      this.isBusy = true;
      this.partnerGrantsApiClient
        .list()
        .perPage(100)
        .page(0)
        .exec()
        .then(d => {
          this.grants = d.items;
          this.granted = d.items.map(ent => ent.grantedOrganisationId);
          this.isBusy = false;
        })
        .catch(e => {
          this.handleApiError(e);
        });
    },
    async enableGrant(org) {
      this.isBusy = true;
      try {
        if (org.selected) {
          await this.partnerGrantsApiClient.create({
            grantedOrganisationId: org.id
          });
          this.handleApiSuccess("The grant has been added");
        } else {
          const grantId = this.grants.find(
            grant => grant.grantedOrganisationId === org.id
          ).id;
          await this.partnerGrantsApiClient.remove(grantId);
          this.handleApiSuccess("The grant has been revoked");
        }
      } catch (e) {
        this.handleApiError(e);
      } finally {
        this.isBusy = false;
      }
    },
    organizationNameFormatter(row) {
      return `${row.shortName} (${row.legalName})`;
    }
  }
};
</script>
