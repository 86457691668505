import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      virtualConnectStreamApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.VIRTUAL_CONNECT_STREAMS,
        "virtualconnect-streams"
      )
    };
  },
  methods: {
    streamIdEvaler(row) {
      return `${row.id} | ${row.virtualconnectSite.name}`;
    }
  }
};
