<template>
  <gl-form @submit.prevent="generate">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <gl-form-group
          label="Name"
          label-size="sm"
          label-for="name"
          :invalid-feedback="invalidNameFeedback"
        >
          <gl-form-input
            id="name"
            type="text"
            v-model="itemUnderEdit.name"
            placeholder="Name"
            maxlength="250"
            required
            :state="isValidName"
          />
        </gl-form-group>
        <gl-form-group
          label="IP Address"
          label-size="sm"
          label-for="ipAddress"
          :invalid-feedback="invalidIpFeedback"
        >
          <gl-form-input
            id="ipAddress"
            type="text"
            v-model="itemUnderEdit.ipAddress"
            placeholder="IP Address"
            maxlength="250"
            required
            :state="isValidIp"
          />
        </gl-form-group>
        <gl-form-group
          label="Port"
          label-size="sm"
          label-for="port"
          :invalid-feedback="invalidPortFeedback"
        >
          <gl-form-input
            type="number"
            id="port"
            v-model="itemUnderEdit.port"
            placeholder="Port"
            maxlength="5"
            step="1"
            required
            :state="isValidPort"
            min="1024"
            max="65535"
          />
        </gl-form-group>
        <gl-form-group
          label="Max Latency (ms)"
          label-size="sm"
          :invalid-feedback="invalidMaxLatencyFeedback"
          label-for="maxLatencyMs"
        >
          <gl-form-input
            type="number"
            id="maxLatencyMs"
            v-model="itemUnderEdit.maxLatencyMs"
            placeholder="Latency (ms)"
            maxlength="6"
            :state="isValidMaxLatency"
            min="0"
            max="60000"
          />
        </gl-form-group>
        <Crypto
          @change="onCryptoChange"
          :cryptoKey="itemUnderEdit.cryptoKey"
          :cryptoAlgo="itemUnderEdit.cryptoAlgo"
          :originalCryptoAlgo="originalCryptoAlgo"
        />
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlFormGroup, GlFormInput } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import Crypto from "@/components/Crypto";
import ipRegex from "ip-regex";
import virtualConnectSiteApiClient from "@/mixins/virtualConnectSiteApiClient.js";

export default {
  name: "AddFirst",
  mixins: [currentItem, virtualConnectSiteApiClient],
  components: {
    Wizard,
    GlForm,
    GlFormGroup,
    GlFormInput,
    Crypto
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      leftSectionTitle: `Description`,
      originalCryptoAlgo: undefined,
      isCryptoValid: false,
      isBusy: false
    };
  },
  created() {
    this.originalCryptoAlgo = this.itemUnderEdit.cryptoAlgo;
  },
  computed: {
    invalidNameFeedback() {
      return this.isValidName ? "" : "Please enter name";
    },
    isValidName() {
      return !!this.itemUnderEdit.name;
    },
    invalidMaxLatencyFeedback() {
      return this.isValidMaxLatency
        ? ""
        : "Maximum latency should be between 0 and 60,000 ms";
    },
    isValidMaxLatency() {
      return (
        this.itemUnderEdit.maxLatencyMs !== "" &&
        this.itemUnderEdit.maxLatencyMs >= 0 &&
        this.itemUnderEdit.maxLatencyMs <= 60000
      );
    },
    invalidPortFeedback() {
      return this.isValidPort ? "" : "Please enter valid IP Port 1024-65535.";
    },
    isValidPort() {
      return (
        this.itemUnderEdit.port >= 1024 && this.itemUnderEdit.port <= 65535
      );
    },
    isValidIp() {
      return ipRegex({ exact: true }).test(this.itemUnderEdit.ipAddress);
    },
    invalidIpFeedback() {
      return this.isValidIp ? "" : "Please enter valid IPv4 or IPv6 address.";
    },
    isValid() {
      return (
        this.isValidName &&
        this.isValidMaxLatency &&
        this.isValidPort &&
        this.isValidIp &&
        this.isCryptoValid
      );
    },
    buttons() {
      return [
        {
          text: this.isUpdate ? `Update` : `Create`,
          type: `submit`,
          disabled: !this.isValid || this.isBusy
        }
      ];
    }
  },
  methods: {
    onCryptoChange(cryptoDetails) {
      const { cryptoAlgo, cryptoKey, isValid } = cryptoDetails;
      this.isCryptoValid = isValid;
      this.itemUnderEdit.cryptoAlgo = cryptoAlgo;
      this.itemUnderEdit.cryptoKey = cryptoKey;
    },
    async generate() {
      this.isBusy = true;
      let msg = ``;
      const itemCopy = JSON.parse(JSON.stringify(this.itemUnderEdit));
      try {
        if (this.isUpdate) {
          await this.virtualConnectSiteApiClient.patch(itemCopy.id, itemCopy);
          msg = `The vitual connect site "${itemCopy.name}" was updated successfully`;
        } else {
          await this.virtualConnectSiteApiClient.create(itemCopy);
          msg = `The vitual connect site "${itemCopy.name}" was created successfully`;
        }
        this.handleApiSuccess(msg);
        this.$emit("create");
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
