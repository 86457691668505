<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";

export default {
  components: { RegionComponent },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    isDestinationValid() {
      switch (false) {
        case this.isRegionValid:
          return false;
        default:
          return true;
      }
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },

  data() {
    return {
      isRegionValid: false
    };
  },
  props: {
    currentItem: Destination
  }
};
</script>
