<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">Organizations</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__organizations">
      <gl-filtered-search
        :available-tokens="tokens"
        @submit="activate"
        @clear="clear"
        class="mb-2"
      >
        <template #history-item="{ historyItem }">
          <template v-for="(token, idx) in historyItem">
            <span :key="idx" class="px-1">
              {{ token.type }} =
              <strong>{{ token.value }}</strong>
            </span>
          </template>
        </template>
      </gl-filtered-search>
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItemsByRow"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'shortName'">
            <div class="org-box">
              <div class="d-flex align-items-center">
                <div
                  class="opsPanel__organizations__status"
                  :class="{
                    'opsPanel__organizations__status--success':
                      r.health === 'OK',
                    'opsPanel__organizations__status--warning':
                      r.health === 'WARN',
                    'opsPanel__organizations__status--danger':
                      r.health === 'NOK'
                  }"
                ></div>
              </div>
              <img
                class="mr-3"
                v-bind:src="'data:image/jpeg;base64,' + logos[r.id]"
                width="32"
                @error="fixLogo(r.id)"
              />
              <div class="org-info mt-2">
                <span class="cellTitle">{{ r.shortName }}</span>
              </div>
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient, organizationApiClient } from "@/mixins/apiClients";
import {
  orgDefaultLogoData,
  ORGANIZATIONS_SEARCH_BAR_TOKENS,
  ITEMS_USER_MGMT
} from "@/constants.js";
import { GlLoadingIcon, GlFilteredSearch, GlButton } from "@gitlab/ui";
const { EDIT_ORGANIZATION } = ITEMS_USER_MGMT;

export default {
  name: "Organizations",
  mixins: [metricsApiClient, organizationApiClient],
  components: { ArqDataGrid, GlButton, GlFilteredSearch, GlLoadingIcon },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  created: function() {
    this.$parent.$on("clearOrganization", this.clearOrganization);
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      this.searchParams = {};
      this.searchPhrase = "";
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    clearOrganization() {
      this.data = {};
      this.searchParams = {};
      this.searchPhrase = "";
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT_ORGANIZATION}/${row.id}`);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    async onDataNeeded(sortModel, pageModel) {
      this.isLoading = true;
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.getMetricsHealth("organisations")
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        const logoPromises = [];
        for (const org of response.items) {
          this.logos[org.id] = orgDefaultLogoData;
          logoPromises.push(
            this.getLogo(org.id).then(l => {
              return {
                id: org.id,
                logo: l
              };
            })
          );
        }
        Promise.allSettled(logoPromises).then(res => {
          res.forEach(r => {
            if (r.value) {
              this.logos[r.value.id] = r.value.logo;
            }
          });
          this.logos = JSON.parse(JSON.stringify(this.logos));
          this.$forceUpdate();
        });
      } catch (e) {
        this.handleApiError(e);
      }
      this.isLoading = false;
      this.data = d;
    },
    fixLogo(id) {
      this.logos[id] = orgDefaultLogoData;
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = JSON.parse(JSON.stringify(this.contextMenuItems));
        }
      }
      return out;
    },
    tokens() {
      return ORGANIZATIONS_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      isLoading: true,
      logos: {},
      sortModel: {},
      pageModel: {
        itemsPerPage: 10
      },
      selectedRow: undefined,
      data: {},
      searchParams: {},
      searchPhrase: "",
      columns: [
        {
          text: "",
          field: "shortName"
        }
      ],
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        }
      ]
    };
  }
};
</script>
