<template>
  <div>
    <gl-form-group
      class="m-4"
      label="Stream ID"
      label-size="sm"
      label-for="streamId"
      :invalid-feedback="invalidStreamIdFeedback()"
    >
      <gl-form-input
        id="streamId"
        type="text"
        v-model="currentItem.streamId"
        placeholder=""
        :state="isStreamIdValid()"
        class="mt-1"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import Destination from "@/models/Destination";
import { DESTINATION_TYPES } from "@/constants";

export default {
  components: { GlFormGroup, GlFormInput },
  methods: {
    invalidStreamIdFeedback() {
      const msg =
        "The streamId isn't valid. Enter a value that adheres to the following rules: The first and last characters must be letters (a-z, A-Z) or numbers (0-9); all other characters must be letters, numbers, dashes (-), or underscores (_); and the entire value can have up to 128 characters.";
      return this.isStreamIdValid() ? "" : msg;
    },
    isStreamIdValid() {
      let isValid = false;
      if (this.currentItem.streamId) {
        const regexp = /^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$/;
        isValid =
          regexp.test(this.currentItem.streamId) &&
          this.currentItem.streamId.length <= 128;
      } else {
        isValid =
          this.currentItem.destinationType !== DESTINATION_TYPES.ZIXI_PULL;
      }
      this.$emit("change", isValid);
      return isValid;
    }
  },
  data() {
    return {};
  },
  props: {
    currentItem: Destination // 2-way binding to the parent, name can be different
  }
};
</script>
