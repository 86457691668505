import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  created() {
    this.contributionApiClient.getForPackage = id => {
      return this.contributionApiClient.getByCustomParam("packageId", id);
    };
  },
  methods: {
    isPending(item) {
      const now = new Date().getTime();
      const s = new Date(Date.parse(item.plannedStart)).getTime();
      const e = new Date(Date.parse(item.plannedEnd)).getTime();
      return s > now && e > now;
    },
    isCompleted(item) {
      if (item.scheduleStatus === "cancelled") {
        return true;
      }
      const now = new Date().getTime();
      const s = new Date(Date.parse(item.plannedStart)).getTime();
      const e = new Date(Date.parse(item.plannedEnd)).getTime();
      return now > s && now > e;
    },
    isCurrent(item) {
      const now = new Date().getTime();
      const s = new Date(Date.parse(item.plannedStart)).getTime();
      const e = new Date(Date.parse(item.plannedEnd)).getTime();
      return s <= now && e > now;
    }
  },
  data() {
    return {
      contributionApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.CONTRIBUTIONS,
        "contributions"
      ),
      contributionTypes: [
        { text: `Event`, value: `event_contribution` },
        { text: "Channel", value: `channel_contribution` }
      ]
    };
  }
};
