import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      partnerGrantsApiClientExt: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.PARTNER_GRANTS,
        "partner-grants"
      ),
      organizationApiClientExt: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.ENTITLEMENTS,
        "organisations"
      ),
      partnerGrantsHelper: this,
      query: {},
      copyQuery: {}
    };
  },
  created() {
    this.query = this.organizationApiClientExt.list();
    this.copyQuery = this.organizationApiClientExt.list();
    Object.assign(this.query, {
      exec: async () => {
        const orgs = await this.copyQuery.exec();
        if (this.kind !== "all") {
          const grants = await this.partnerGrantsApiClientExt.list().exec();
          if (this.kind === "granted") {
            orgs.items = orgs.items.filter(
              org =>
                grants.items.map(g => g.grantedOrganisationId).indexOf(org.id) >
                -1
            );
          } else {
            orgs.items = orgs.items.filter(
              org =>
                grants.items.map(g => g.grantedOrganisationId).indexOf(org.id) <
                0
            );
          }
        }
        return orgs;
      }
    });
  },
  methods: {
    list() {
      return this.query;
    },
    page(page) {
      this.copyQuery.page(page);
      this.query.page(page);
      return this.query;
    },

    perPage(pageSize) {
      this.copyQuery.perPage(pageSize);
      this.query.perPage(pageSize);
      return this.query;
    },

    sortBy(field, order = "asc") {
      this.copyQuery.sortBy(field, order);
      this.query.sortBy(field, order);
      return this.query;
    },

    filterBy(phrase) {
      this.copyQuery.filterBy(phrase);
      this.query.filterBy(phrase);
      return this.query;
    }
  }
};
