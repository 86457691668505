<template>
  <div>
    <gl-form-group
      class="m-4"
      label="Min Latency (ms)"
      label-size="sm"
      :invalid-feedback="invalidMinLatencyFeedback()"
      label-for="minLatencyMs"
    >
      <gl-form-input
        type="number"
        id="minLatencyMs"
        v-model="currentItem.minLatencyMs"
        placeholder="Min latency (ms)"
        maxlength="5"
        class="mt-1"
        :state="isMinLatencyValid()"
        min="100"
        max="15000"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import Destination from "@/models/Destination";

export default {
  components: { GlFormGroup, GlFormInput },
  methods: {
    invalidMinLatencyFeedback() {
      return this.isMinLatencyValid()
        ? ""
        : "Minimum latency should be between 100 and 15000 ms";
    },
    isMinLatencyValid() {
      const isValid =
        this.currentItem.minLatencyMs !== "" &&
        this.currentItem.minLatencyMs >= 100 &&
        this.currentItem.minLatencyMs <= 15000;
      this.$emit("change", isValid);
      return isValid;
    }
  },
  data() {
    return {};
  },
  props: {
    currentItem: Destination // 2-way binding to the parent, name can be different
  }
};
</script>
