<template>
  <gl-button @click="clearSearchResults" data-testid="clear_search"
    >Clear Search</gl-button
  >
</template>
<script>
import { GlButton } from "@gitlab/ui";

export default {
  name: "TableActionClearSearch",
  components: {
    GlButton
  },
  methods: {
    clearSearchResults() {
      this.$emit("clearSearchResults");
    }
  }
};
</script>
