<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <gl-form-group
      class="m-4"
      label="Account ID"
      label-size="sm"
      label-for="accountId"
      :invalid-feedback="invalidAccountIdFeedback()"
    >
      <gl-form-input
        id="accountId"
        type="text"
        v-model="currentItem.accountId"
        required
        placeholder="250098920224"
        maxlength="12"
        class="mt-1"
        :state="isAccountIdValid()"
        @change="onAccountChange"
      />
    </gl-form-group>

    <Crypto
      @change="onCryptoChange"
      :cryptoKey="currentItem.cryptoKey"
      :cryptoAlgo="currentItem.cryptoAlgo"
      :originalCryptoAlgo="originalItem ? originalItem.cryptoAlgo : null"
    />
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import Crypto from "@/components/Crypto.vue";
import { GlFormGroup, GlFormInput } from "@gitlab/ui";

export default {
  components: { Crypto, GlFormGroup, GlFormInput, RegionComponent },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    invalidAccountIdFeedback() {
      return this.isAccountIdValid()
        ? ""
        : "Please enter a valid account number";
    },
    isDestinationValid() {
      switch (false) {
        case this.isCryptoValid:
        case this.isRegionValid:
        case this.isAccountIdValid():
          return false;
        default:
          return true;
      }
    },
    isAccountIdValid() {
      const reg = /^[0-9]{12}$/;
      return reg.test(this.currentItem.accountId);
    },

    onAccountChange() {
      this.$emit("change", this.isDestinationValid()); // emit the event to the parent
    },

    onCryptoChange(cryptoDetails) {
      const { cryptoAlgo, cryptoKey, isValid } = cryptoDetails;
      this.isCryptoValid = isValid;
      this.currentItem.cryptoAlgo = cryptoAlgo;
      this.currentItem.cryptoKey = cryptoKey;
      this.$emit("change", this.isDestinationValid()); // emit the event to the parent
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      originalCryptoAlgo: undefined,
      isCryptoValid: false,
      isRegionValid: false
    };
  },
  props: {
    currentItem: Destination,
    originalItem: Destination
  }
};
</script>
