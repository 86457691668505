import { mapState } from "vuex";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default {
  computed: {
    ...mapState({
      theme: state => (cookies.get("arqade-org-id") ? "dark" : state.theme)
    })
  },
  mounted() {
    // check for active theme
    let htmlElement = document.documentElement;
    htmlElement.setAttribute("theme", this.theme);
  },
  methods: {
    changeTheme(newTheme) {
      let htmlElement = document.documentElement;
      const currentTheme = this.theme;
      if (!newTheme) {
        newTheme = currentTheme === `red` ? `blue` : `red`;
      }
      this.$store.commit("changeTheme", newTheme);
      htmlElement.setAttribute("theme", newTheme);
    }
  }
};
