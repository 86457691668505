<template>
  <div>
    <Wizard :buttons="buttons">
      <template v-slot:left-section>
        <p
          class="d-flex align-items-center mt-4 mb-5"
          data-testid="contribution_creation_confirmation"
        >
          <gl-icon name="check" class="mr-2 color-primary" :size="32" />
          The {{ itemUnderEdit.name }} {{ contributionTypeName }} is now
          scheduled. You can now share this as a package or alternatively
          connect this directly to your own destination.
        </p>
      </template>
    </Wizard>
  </div>
</template>
<script>
import Wizard from "@/components/Wizard";
import { ITEMS_CONTRIBUTIONS } from "@/constants.js";
import { contributionApiClient } from "@/mixins/apiClients";
import { GlIcon } from "@gitlab/ui";
import currentItem from "@/mixins/currentItem";

const { SHOW } = ITEMS_CONTRIBUTIONS;

export default {
  name: "AddFirst",
  mixins: [currentItem, contributionApiClient],
  components: {
    Wizard,
    GlIcon
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: `Create another Contribution`,
          onClick: () => {
            this.$emit("redirect", "new");
          }
        },
        {
          text: `Clone this Contribution`,
          onClick: () => {
            this.$emit("redirect", "clone");
          }
        },
        {
          text: `Show this Contribution`,
          onClick: () => {
            this.$router.push(`${SHOW}/${this.itemUnderEdit.id}`);
          }
        }
      ];
    },
    contributionTypeName() {
      const contributionTypeName = this.contributionTypes.find(
        item => this.itemUnderEdit.contributionType === item.value
      );
      return contributionTypeName
        ? contributionTypeName.text.toLowerCase()
        : "contribution";
    }
  }
};
</script>
