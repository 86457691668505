<template>
  <div>
    <table class="table">
      <tr>
        <th rowspan="2" class="align-middle">Region</th>
        <th rowspan="2" class="text-center align-middle">AWS Cost (USD/hr)</th>
        <th colspan="2" class="text-center">Arqade Price (USD/hr)</th>
      </tr>
      <tr>
        <th class="text-center">Published</th>
        <th class="text-center">Draft</th>
      </tr>
      <tr v-for="price in tablePricing" :key="price.id">
        <td class="align-middle">{{ price.region_name }}</td>
        <td class="text-center align-middle">
          {{ parseFloat(price.price).toFixed(4) }}
        </td>
        <td
          class="text-center align-middle"
          :class="{
            'text-danger': price.price_override < price.price,
            'text-success': price.price_override > price.price
          }"
        >
          {{ parseFloat(price.price_override).toFixed(4) }}
        </td>
        <td class="text-center align-middle">
          <gl-form-input
            type="number"
            step="0.0001"
            min="0"
            :value="price.price_override_draft | trailingZeros"
            @change="price.price_override_draft = $event"
            required
            class="pricing-input-number"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { GlFormInput } from "@gitlab/ui";

export default {
  name: "MediaConnectActiveResources",
  components: { GlFormInput },
  filters: {
    trailingZeros: function(value) {
      return value || value === 0 ? parseFloat(value).toFixed(4) : null;
    }
  },
  watch: {
    tablePricing: {
      handler: function(tablePricing) {
        this.$emit("tablePricingWasChanged", tablePricing);
      },
      deep: true
    }
  },
  props: {
    tablePricing: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>
<style scoped>
.pricing-input-number {
  width: 100px;
  display: inline-block;
  text-align: right;
}
</style>
