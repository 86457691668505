<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <WhiteListCidrComponent
      :currentItem="currentItem"
      @change="onCidrChange"
    ></WhiteListCidrComponent>
    <StreamIdComponent
      :currentItem="currentItem"
      @change="onStreamIdChange"
    ></StreamIdComponent>
    <gl-form-group
      class="m-4"
      label="Remote ID"
      label-size="sm"
      label-for="remoteId"
      :invalid-feedback="invalidRemoteIdFeedback()"
    >
      <gl-form-input
        id="remoteId"
        type="text"
        required
        v-model="currentItem.remoteId"
        placeholder=""
        :state="isRemoteIdValid()"
        class="mt-1"
      />
    </gl-form-group>
    <MaxLatencyComponent
      :currentItem="currentItem"
      @change="onMaxLatencyChange"
    ></MaxLatencyComponent>
    <Crypto
      @change="onCryptoChange"
      :cryptoKey="currentItem.cryptoKey"
      :cryptoAlgo="currentItem.cryptoAlgo"
      :originalCryptoAlgo="originalItem ? originalItem.cryptoAlgo : null"
    />
  </div>
</template>

<script>
import Crypto from "@/components/Crypto.vue";
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import WhiteListCidrComponent from "@/components/Destinations/AddSecond/Specs/Sections/WhiteListCidrComponent.vue";
import StreamIdComponent from "@/components/Destinations/AddSecond/Specs/Sections/StreamIdComponent.vue";
import MaxLatencyComponent from "@/components/Destinations/AddSecond/Specs/Sections/MaxLatencyComponent.vue";

export default {
  components: {
    MaxLatencyComponent,
    StreamIdComponent,
    WhiteListCidrComponent,
    Crypto,
    GlFormGroup,
    GlFormInput,
    RegionComponent
  },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    invalidRemoteIdFeedback() {
      const msg =
        "The remoteId isn't valid. Enter a value that adheres to the following rules: The first and last characters must be letters (a-z, A-Z) or numbers (0-9); all other characters must be letters, numbers, dashes (-), periods (.), colons (:), or underscores (_); and the entire value can have up to 128 characters.";
      return this.isRemoteIdValid() ? "" : msg;
    },
    isDestinationValid() {
      switch (false) {
        case this.isCryptoValid:
        case this.isCidrValid:
        case this.isRegionValid:
        case this.isMaxLatencyValid:
        case this.isRemoteIdValid():
        case this.isStreamIdValid:
          return false;
        default:
          return true;
      }
    },
    isRemoteIdValid() {
      const regexp = /^[a-zA-Z0-9][a-zA-Z0-9_\-,:.]*[a-zA-Z0-9]$/;
      return (
        !!this.currentItem.remoteId &&
        regexp.test(this.currentItem.remoteId) &&
        this.currentItem.remoteId.length <= 128
      );
    },
    onCidrChange(isValid) {
      this.isCidrValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onCryptoChange(cryptoDetails) {
      const { cryptoAlgo, cryptoKey, isValid } = cryptoDetails;
      this.isCryptoValid = isValid;
      this.currentItem.cryptoAlgo = cryptoAlgo;
      this.currentItem.cryptoKey = cryptoKey;
      this.$emit("change", this.isDestinationValid()); // emit the event to the parent
    },
    onMaxLatencyChange(isValid) {
      this.isMaxLatencyValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onStreamIdChange(isValid) {
      this.isStreamIdValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      originalCryptoAlgo: undefined,
      isCidrValid: false,
      isCryptoValid: false,
      isMaxLatencyValid: false,
      isRegionValid: false,
      isStreamIdValid: false
    };
  },
  props: {
    currentItem: Destination,
    originalItem: Destination
  },

  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
