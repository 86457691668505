<template>
  <gl-form @submit.prevent="generate">
    <Wizard
      :leftSectionTitle="leftSectionTitle"
      :rightSectionTitle="rightSectionTitle"
      :buttons="buttons"
    >
      <template v-slot:left-section>
        <p>
          The details of your contribution can be found below. All parameters
          can be updated before your contribution starts but not after.
          <br />
          <strong v-if="currentItem.is_open_ended">
            Warning: This contribution is open ended. The delivery will need to
            be cancelled manually when the contribution is no longer required.
          </strong>
        </p>
        <gl-table
          :items="tableSummaryItems"
          :fields="tableSummaryFields"
          :stacked="true"
        >
          <template #cell(plannedStart)="data">
            <time-zones-component
              :date-time="data.item.plannedStart.toString()"
              isAccordion
              class="timezone"
            />
          </template>

          <template #cell(plannedEnd)="data">
            <span v-if="data.item.isOpenEnded">Open Ended</span>
            <time-zones-component
              v-else
              :date-time="data.item.plannedEnd.toString()"
              isAccordion
              class="timezone"
            />
          </template>
        </gl-table>
      </template>
      <template v-slot:right-section>
        <p>
          <strong
            >Contributions are not chargable until they are delivered and are
            live.
          </strong>
        </p>
        <div v-if="isWaitingForPrice">
          <gl-loading-icon label="Loading" />
        </div>
        <div v-else>
          <gl-table :items="tablePricingItems" :fields="tablePricingFields">
          </gl-table>
        </div>
        <gl-form-group
          class="m-4 form-group-confirmed"
          label=""
          label-size="sm"
          label-for="confirmed"
        >
          <gl-form-checkbox
            id="confirmed"
            v-model="confirmed"
            required
            :disabled="isUpdate || isWaitingForPrice"
            ><strong
              >I confirm all the details are correct and the pricing shown above
              will be incurred as an Order per the agreement.</strong
            ></gl-form-checkbox
          >
        </gl-form-group>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import Wizard from "@/components/Wizard";
import {
  GlLoadingIcon,
  GlForm,
  GlFormCheckbox,
  GlFormGroup,
  GlTable
} from "@gitlab/ui";
import {
  contributionApiClient,
  deliveryApiClient,
  sourceApiClient,
  pricingQuoteApiClient
} from "@/mixins/apiClients";
import currentItem from "@/mixins/currentItem";
import dateUtils from "@/mixins/dateUtils";
import pricingUtils from "@/mixins/pricingUtils";
import TimeZonesComponent from "@/components/Date/TimeZones.vue";

export default {
  name: "AddFirst",
  mixins: [
    dateUtils,
    contributionApiClient,
    deliveryApiClient,
    sourceApiClient,
    pricingQuoteApiClient,
    currentItem,
    pricingUtils
  ],
  components: {
    GlLoadingIcon,
    Wizard,
    GlForm,
    GlTable,
    GlFormCheckbox,
    GlFormGroup,
    TimeZonesComponent
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: this.isUpdate
            ? `Update ${this.contributionTypeName}`
            : `Generate ${this.contributionTypeName}`,
          type: `submit`,
          disabled: this.isBusy || !this.confirmed
        }
      ];
    },
    contributionTypeName() {
      const contributionTypeName = this.contributionTypes.find(
        item => this.itemUnderEdit.contributionType === item.value
      );
      return contributionTypeName ? contributionTypeName.text : "contribution";
    },
    tableSummaryFields() {
      let fields = [
        {
          key: "name",
          label: "Event Name"
        },
        {
          key: "plannedStart",
          label: "Broadcast Start"
        },
        {
          key: "plannedEnd",
          label: "Broadcast End"
        },
        {
          key: "duration",
          label: `${this.contributionTypeName} Duration`
        },
        {
          key: "sourceName",
          label: "Source"
        }
      ];
      if (this.source1) {
        fields.push({
          key: "redundancySourceName",
          label: "Redundancy Source"
        });
      }
      return fields;
    },
    tableSummaryItems() {
      return [
        {
          name: this.currentItem.name,
          plannedStart: this.currentItem.plannedStart,
          plannedEnd: this.currentItem.plannedEnd,
          isOpenEnded: this.currentItem.isOpenEnded,
          sourceName: this.source0 ? this.source0.name : "",
          duration: this.currentItem.isOpenEnded
            ? `Open Ended`
            : this.secondsToHms(this.getDuration()),
          redundancySourceName: this.source1 ? this.source1.name : ""
        }
      ];
    }
  },
  data() {
    return {
      isBusy: false,
      destinationsChecked: [],
      leftSectionTitle: `Summary`,
      rightSectionTitle: "Pricing",
      confirmed: this.isUpdate,
      isWaitingForPrice: true,
      source0: {},
      source1: {},
      pricing: {
        source0: {
          price: 0,
          unit: ""
        }
      },
      tablePricingFields: [
        {
          key: "column_one",
          label: "",
          tdClass: "font-weight-bold"
        },
        {
          key: "column_two",
          label: "",
          tdClass: "text-center red-label"
        },
        {
          key: "column_three",
          label: "",
          tdClass: "text-right"
        }
      ]
    };
  },
  created() {
    let promises = [this.sourceApiClient.getById(this.currentItem.source0Id)];
    if (this.currentItem.source1Id) {
      promises.push(this.sourceApiClient.getById(this.currentItem.source1Id));
    }
    Promise.all(promises).then(results => {
      this.source0 = results[0];
      this.source1 = results[1];
      let promises = [
        this.pricingQuoteApiClient.update("source", this.source0)
      ];
      if (this.source1) {
        promises.push(
          this.pricingQuoteApiClient.update("source", this.source1)
        );
      }
      Promise.all(promises)
        .then(results => {
          this.pricing.source0 = results[0].offer;
          this.pricing.source1 = results[1] ? results[1].offer : undefined;
          this.isWaitingForPrice = false;
        })
        .catch(e => this.handleApiError(e));
    });
  },
  methods: {
    getDuration() {
      return (
        (new Date(this.currentItem.plannedEnd).getTime() -
          new Date(this.currentItem.plannedStart).getTime()) /
        1000
      );
    },
    async tablePricingItems() {
      let items = await this.getHourlyPricingItems();
      if (this.contributionTypeName === "Channel") {
        items.push(await this.getChannelMonthlyCost());
      } else {
        items.push(await this.getEventTotalCost());
      }
      return items;
    },
    async getEventTotalCost() {
      const durationHours = (this.getDuration() / 3600).toFixed(1);
      let total = parseFloat(this.pricing.source0.price) * durationHours;
      if (this.pricing.source1) {
        total += this.pricing.source1.price * durationHours;
      }
      return {
        column_one: `Total (${
          durationHours > 1 ? durationHours + " hours" : "1 hour"
        })`,
        column_three: await this.formatPricingDisplay(
          parseFloat(parseFloat(this.pricing.source0.price).toFixed(2)) +
            parseFloat(
              parseFloat(
                this.pricing.source1 ? this.pricing.source1.price : "0"
              ).toFixed(2)
            ),
          "USD",
          parseFloat(total.toFixed(2))
        )
      };
    },

    async getChannelMonthlyCost() {
      let month = parseFloat(this.pricing.source0.price) * 730;
      if (this.pricing.source1) {
        month += month;
      }
      return {
        column_one: "Monthly Cost",
        column_two: "",
        column_three: await this.formatPricingDisplay(
          parseFloat(parseFloat(this.pricing.source0.price).toFixed(2)) +
            parseFloat(
              parseFloat(
                this.pricing.source1 ? this.pricing.source1.price : "0"
              ).toFixed(2)
            ),
          "USD",
          month
        )
      };
    },
    async getHourlyPricingItems() {
      let items = [
        {
          column_one: "Source",
          column_two: "Confirmed",
          column_three: await this.formatPricingDisplay(
            this.pricing.source0.price,
            this.pricing.source0.unit
          )
        }
      ];
      if (this.pricing.source1) {
        items.push({
          column_one: "Redundancy Source",
          column_two: "Confirmed",
          column_three: await this.formatPricingDisplay(
            this.pricing.source1.price,
            this.pricing.source0.unit
          )
        });
      }
      return items;
    },
    async createDelivery(contribution) {
      if (this.destinationsChecked && this.destinationsChecked.length) {
        try {
          const delivery = {};
          delivery.destinationId0 = this.destinationsChecked[0];
          delivery.destinationId1 = this.destinationsChecked[1];
          delivery.contributionId = contribution.id;
          await this.deliveryApiClient.create(delivery);
          this.handleApiSuccess("The delivery was created successfully!");
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async generate() {
      this.isBusy = true;
      delete this.itemUnderEdit.editLevel;
      try {
        if (this.isUpdate) {
          await this.contributionApiClient.patch(
            this.itemUnderEdit.id,
            this.itemUnderEdit
          );
          this.handleApiSuccess(
            `The ${this.contributionTypeName} "${this.itemUnderEdit.name}" was updated successfully.`
          );
        } else {
          const contribution = await this.contributionApiClient.create(
            this.itemUnderEdit
          );
          this.handleApiSuccess(
            `The ${this.contributionTypeName} "${this.itemUnderEdit.name}" was created successfully.`
          );
          this.itemUnderEdit.id = contribution.id;

          await this.createDelivery(contribution);
        }
        this.$emit("create", this.itemUnderEdit);
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
<style scoped>
.form-group-confirmed {
  border: solid 1px var(--theme-color);
}
.timezone {
  width: fit-content;
  margin-left: auto;
}
</style>
