import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      streamProfileClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.STREAM_PROFILES,
        "stream-profiles"
      )
    };
  },
  methods: {
    streamProfileEvaler(row) {
      const {
        profileName,
        codec,
        bitrateBps,
        framerateNumerator,
        framerateDenominator,
        height,
        width,
        gopFrames,
        colourSpace,
        rateControlMode
      } = row;
      if (profileName) {
        return profileName;
      }
      if (!codec) {
        return "";
      }
      let bitrate = `${bitrateBps}bps`;
      if (bitrateBps >= 1000000) {
        const rounded = Math.round(bitrateBps / 10000) / 100;
        bitrate = `${rounded}Mbps`;
      } else if (bitrateBps >= 1000) {
        const rounded = Math.round(bitrateBps / 10) / 100;
        bitrate = `${rounded}kbps`;
      }

      const frameRateFps = Math.round(
        framerateNumerator / framerateDenominator
      );

      return `${width}x${height} (${codec.toUpperCase()}${
        colourSpace ? " " + colourSpace : ""
      }) ${frameRateFps}fps ${gopFrames ? gopFrames + "GOP " : ""}${bitrate}${
        rateControlMode ? " " + rateControlMode : ""
      }`;
    }
  }
};
