import VirtualConnectSites from "@/views/VirtualConnectSites/VirtualConnectSites";
import Add from "@/views/VirtualConnectSites/Add.vue";
import { List } from "@/components/VirtualConnectSites";
import {
  MAIN_ITEMS,
  ITEMS_VIRTUAL_CONNECT_SITES,
  ALIAS_ITEMS_VIRTUAL_CONNECT_SITES
} from "@/constants.js";

const { VIRTUAL_CONNECT_SITES } = MAIN_ITEMS;
const { ADD, EDIT, LIST } = ITEMS_VIRTUAL_CONNECT_SITES;
const { ADD_FIRST, EDIT_FIRST } = ALIAS_ITEMS_VIRTUAL_CONNECT_SITES;
const HOST = `VirtualConnectSites`;

const virtualConnectSites = [
  {
    path: VIRTUAL_CONNECT_SITES,
    name: `VirtualConnectSites`,
    component: VirtualConnectSites,
    children: [
      {
        path: LIST,
        component: List
      }
    ]
  },
  {
    path: ADD,
    name: `${HOST}CreateADD`,
    redirect: ADD_FIRST
  },
  {
    path: ADD_FIRST,
    name: `${HOST}Create`,
    component: Add
  },

  {
    path: `${EDIT}/:id`,
    name: `${HOST}Edit`,
    component: Add,
    alias: [`${EDIT}/:id/${EDIT_FIRST}`]
  }
];

export default virtualConnectSites;
