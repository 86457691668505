<template>
  <gl-form-group
    v-if="isAdmin()"
    class="billing_status"
    label="Billable Status"
    label-size="sm"
    label-for="billableStatus"
  >
    <gl-form-select
      id="billableStatus"
      v-model="computedBillableStatus"
      :options="billableOptions"
      class="mt-1"
    />
  </gl-form-group>
</template>

<script>
import { GlFormGroup, GlFormSelect } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";

export default {
  name: "BillableStatus",
  mixins: [userInfoMixin],
  components: {
    GlFormGroup,
    GlFormSelect
  },
  props: {
    billableStatus: {
      type: String,
      default: null
    }
  },
  computed: {
    computedBillableStatus: {
      get() {
        return this.billableStatus;
      },
      set(newValue) {
        this.$emit("update-billing-status", newValue);
      }
    }
  },
  data() {
    return {
      billableOptions: [
        { text: "Default", value: "DEFAULT" },
        { text: "Billable", value: "BILLABLE" },
        { text: "Non-billable", value: "NON_BILLABLE" },
        { text: "Bundled", value: "BUNDLED" }
      ]
    };
  },
  methods: {
    isAdmin() {
      return this.isArqadeAdminRoleActive || this.isMasquerading;
    }
  }
};
</script>
<style scoped>
.billing_status {
  max-width: 300px;
  margin-block: 1.4rem;
}
</style>
