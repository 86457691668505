<template>
  <gl-form @submit.prevent="generate" id="addSecond">
    <Wizard
      :leftSectionTitle="leftSectionTitle"
      :buttons="buttons"
      :rightSectionTitle="rightSectionTitle"
    >
      <template v-slot:left-section id="left">
        <SpecsComponent
          id="left"
          :isUpdate="isUpdate"
          :currentItem="currentItem"
          :isPassthrough="isPassthrough"
          :originalItem="originalItem"
          @change="onSpecsChange"
        >
        </SpecsComponent>
      </template>

      <template v-slot:right-section id="right">
        <ProfilesComponent
          id="right"
          :currentItem="currentItem"
          :isUpdate="isUpdate"
          @change="onProfileChange"
        ></ProfilesComponent>
      </template>
    </Wizard>
  </gl-form>
</template>

<style lang="scss" scoped>
@import "AddSecond/AddSecond";
</style>
<script>
import Wizard from "@/components/Wizard.vue";
import { GlForm } from "@gitlab/ui";

import {
  destinationApiClient,
  streamProfileClient,
  virtualConnectStreamApiClient
} from "@/mixins/apiClients";

import ProfilesComponent from "@/components/Destinations/AddSecond/ProfilesComponent.vue";
import SpecsComponent from "@/components/Destinations/AddSecond/SpecsComponent.vue";
import { DESTINATION_TYPES } from "@/constants";
import userInfo from "@/mixins/userInfo.js";
import Destination from "@/models/Destination";
import _ from "lodash";

export default {
  name: "AddSecond",
  mixins: [
    destinationApiClient,
    streamProfileClient,
    virtualConnectStreamApiClient,
    userInfo
  ],
  components: {
    SpecsComponent,
    ProfilesComponent,
    GlForm,
    Wizard
  },
  created() {},
  computed: {
    buttons() {
      return [
        {
          text: this.isUpdate ? `Update Destination` : `Generate Destination`,
          type: `submit`,
          disabled: !this.isValid || this.isBusy
        }
      ];
    }
  },
  props: {
    currentItem: Destination,
    isNameOrDescModified: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    originalItem: { ...Destination }
  },
  data() {
    return {
      isBusy: false,
      isValid: false,
      isPassthrough: this.currentItem.isPassthrough,
      isProfileValid: false,
      isSpecsValid: false,
      isNewCustomProfile: false,
      customStreamProfile: null,
      leftSectionTitle: `Specification`,
      rightSectionTitle: `Profiles`
    };
  },
  watch: {
    "currentItem.isPassthrough": function(val) {
      if (val) {
        this.isProfileValid = val;
      } else {
        this.isProfileValid = !!this.currentItem.streamProfileId;
      }
      this.verify();
    }
  },
  methods: {
    isPristine() {
      return _.isEqual(this.originalItem, this.currentItem);
    },
    onProfileChange(e) {
      this.isProfileValid = e.isValid;
      this.isNewCustomProfile = e.isNewCustomProfile;
      if (this.isNewCustomProfile) {
        this.customStreamProfile = e.profile;
      }
      this.isPassthrough = this.currentItem.isPassthrough;
      this.verify();
    },
    onSpecsChange(valid) {
      this.isSpecsValid = valid;
      this.verify();
    },

    verify() {
      switch (true) {
        case !this.isSpecsValid:
        case !this.isProfileValid:
        case this.isUpdate &&
          this.isPristine(this.originalItem, this.currentItem):
          this.isValid = false;
          break;
        default:
          this.isValid = true;
      }
    },

    nullifyEmptyFields() {
      const fieldsToRemove = [
        "isEncrypted",
        "organisationId",
        "profileName",
        "organisationName",
        "destinationDeliveryId",
        "regionName",
        "enabled",
        "listenerAddress",
        "zixiUrl",
        "zixiPort",
        "ipAddressAwsRegion",
        "ref",
        "deliveryName",
        "profile"
      ];
      Object.entries(this.currentItem).forEach(([key, value]) => {
        if (value === null) {
          delete this.currentItem[key];
        }
      });
      Object.entries(this.currentItem).forEach(([key, value]) => {
        if (value === "") {
          this.currentItem[key] = null;
        }
      });
      delete this.currentItem.redundant;
      if (!this.isUpdate) {
        delete this.currentItem.id;
      }
      for (const field of fieldsToRemove) {
        delete this.currentItem[field];
      }
    },

    async handleProfile() {
      if (this.isNewCustomProfile) {
        try {
          this.currentItem.streamProfileId = (
            await this.streamProfileClient.create(this.customStreamProfile)
          ).id;
        } catch (e) {
          this.isBusy = false;
          this.handleApiError(e);
        }
      } else if (this.currentItem.isPassthrough) {
        this.currentItem.streamProfileId = null;
      }
      delete this.currentItem.isPassthrough;
    },

    async generate() {
      this.nullifyEmptyFields();
      this.isBusy = true;
      await this.handleProfile();
      if (!this.isBusy) {
        return;
      }
      const itemCopy = JSON.parse(JSON.stringify(this.currentItem));
      if (itemCopy.destinationType === DESTINATION_TYPES.RIST) {
        itemCopy.destinationType = DESTINATION_TYPES.RTP;
      }
      try {
        let msg = "";
        let id = null;
        if (this.isUpdate && this.currentItem.id) {
          id = this.currentItem.id;
          await this.destinationApiClient.patch(itemCopy.id, itemCopy);
          msg = `The destination "${itemCopy.name}" was updated successfully`;
          this.handleApiSuccess(
            "Changes confirmed but will not affect running deliveries"
          );
        } else {
          await this.destinationApiClient.create(itemCopy).then(data => {
            id = data.id;
          });
          msg = `The destination "${itemCopy.name}" was created successfully`;
        }
        this.handleApiSuccess(msg);
        this.$emit("create", id);
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
