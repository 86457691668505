<template>
  <div>
    <b-row class="mt-4">
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="cellTitle">{{ selectedPackage.name }}</span>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span
                class="cellTitle"
                v-b-tooltip
                :title="selectedContribution.name"
                >{{ selectedContribution.name }}</span
              >
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="cellTitle">{{
                selectedDelivery.contributionName
              }}</span>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
      <b-col>
        <gl-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="cellTitle">{{ selectedDestination.name }}</span>
            </div>
            <div>
              <gl-button
                @click.prevent.stop="changeMetric()"
                icon="chevron-lg-down"
              >
              </gl-button>
            </div>
          </div>
        </gl-card>
      </b-col>
    </b-row>
    <Charts
      :selectedContribution="selectedContribution"
      :selectedDelivery="selectedDelivery"
    />
  </div>
</template>

<script>
import { GlCard, GlButton } from "@gitlab/ui";
import Charts from "@/components/Monitoring/Charts.vue";

export default {
  name: "Metrics",
  components: {
    GlButton,
    GlCard,
    Charts
  },
  props: {
    selectedPackage: {
      type: Object
    },
    selectedContribution: {
      type: Object
    },
    selectedDelivery: {
      type: Object
    },
    selectedDestination: {
      type: Object
    }
  },
  methods: {
    changeMetric() {
      this.$emit(`changeMetric`);
    },
    getBadgeVariant(health) {
      switch (health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(health) {
      switch (health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    getBitrate(bitrate) {
      if (bitrate > 1000000) {
        return (bitrate / 1000000).toFixed(1) + " Mb/s";
      } else if (bitrate > 1000) {
        return (bitrate / 1000).toFixed(1) + " kb/s";
      } else {
        return parseFloat(bitrate).toFixed(1) + " b/s";
      }
    }
  }
};
</script>
<style>
.cellTitle {
  font-size: 16px;
  font-weight: bold;
}
.contributionSingle__type.badge {
  font-size: 11px;
  margin-right: 7px;
  padding: 3px 10px;
  font-weight: normal;
  border-radius: 12px;
}
</style>
