var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th'),(_vm.multiSelection)?_c('th',[_c('gl-form-checkbox',{attrs:{"checked":_vm.allSelected},on:{"change":function($event){return _vm.selectAll()}}})],1):_vm._e(),_c('Search',{attrs:{"value":_vm.searchFields.q || ''},on:{"updateSearch":function (newValue) { return _vm.updateSearchParams('q', newValue); }}}),_c('th',[_vm._v("Redundant")]),_c('DateRangePicker',{attrs:{"searchFields":_vm.searchFields},on:{"changeSearchParams":_vm.onSearchParamsChange}}),_c('th',[_c('span',{staticClass:"nowrap"},[_vm._v("Schedule Status")]),_c('gl-form-select',{attrs:{"disabled":_vm.isScheduleStatusPrefiltered(),"data-testid":"schedule--status","value":_vm.searchFields.scheduleStatus,"options":[
        {
          text: 'All',
          value: ''
        },
        {
          text: 'Scheduled',
          value: 'SCHEDULED'
        },
        {
          text: 'Cancelled',
          value: 'CANCELLED'
        }
      ]},on:{"input":function($event){return _vm.updateSearchParams('scheduleStatus', $event)}}})],1),_c('th',[_c('span',{staticClass:"nowrap"},[_vm._v("Delivery Status")]),_c('gl-form-select',{attrs:{"disabled":_vm.isDeliveryStatusPrefiltered(),"data-testid":"delivery--status","value":_vm.searchFields.deliveryStatus,"options":[
        {
          text: 'All',
          value: ''
        },
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Current',
          value: 'current'
        },
        {
          text: 'Completed',
          value: 'completed'
        }
      ]},on:{"input":function($event){return _vm.updateSearchParams('deliveryStatus', $event)}}})],1),_c('th')],1)}
var staticRenderFns = []

export { render, staticRenderFns }