<template>
  <b-row>
    <b-col cols="6">
      <div class="page-indicator" v-if="pageModel.totalItems > 0">
        <p class="ml-1 pt-2">
          <span
            >{{ fromItemNum }} - {{ toItemNum }} of
            {{ pageModel.totalItems }} items
          </span>
        </p>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="text-right">
        <b-button-group>
          <gl-button
            data-testid="paginator--first"
            icon="chevron-double-lg-left"
            @click="firstPage"
            :disabled="
              pageModel.totalPages === 0 || pageModel.currentPage === 0
            "
          />
          <gl-button
            data-testid="paginator--previous"
            icon="chevron-left"
            @click="previousPage"
            :disabled="
              pageModel.totalPages === 0 || pageModel.currentPage === 0
            "
          />
          <gl-button
            data-testid="paginator--next"
            icon="chevron-right"
            @click="nextPage"
            :disabled="
              pageModel.totalPages === 0 ||
                pageModel.currentPage === pageModel.totalPages - 1
            "
          />
          <gl-button
            data-testid="paginator--last"
            icon="chevron-double-lg-right"
            @click="lastPage"
            :disabled="
              pageModel.totalPages === 0 ||
                pageModel.currentPage === pageModel.totalPages - 1
            "
          />
        </b-button-group>
      </div> </b-col
  ></b-row>
</template>
<script>
import { BCol, BRow, BButtonGroup } from "bootstrap-vue";
import { GlButton } from "@gitlab/ui";
export default {
  name: "Paginator",
  components: {
    BCol,
    BRow,
    BButtonGroup,
    GlButton
  },
  props: {
    pageModel: {
      type: Object,
      default: () => {
        return {
          totalPages: 0,
          currentPage: 0,
          itemsPerPage: 10
        };
      }
    }
  },
  computed: {
    fromItemNum() {
      return this.pageModel.currentPage * this.pageModel.itemsPerPage + 1;
    },

    toItemNum() {
      if (this.pageModel.currentPage === this.pageModel.totalPages - 1) {
        return this.pageModel.totalItems;
      } else {
        return (this.pageModel.currentPage + 1) * this.pageModel.itemsPerPage;
      }
    }
  },
  methods: {
    firstPage() {
      this.$emit("changePage", { ...this.pageModel, currentPage: 0 });
    },
    nextPage() {
      if (this.pageModel.currentPage < this.pageModel.totalPages - 1) {
        this.$emit("changePage", {
          ...this.pageModel,
          currentPage: this.pageModel.currentPage + 1
        });
      }
    },
    previousPage() {
      if (this.pageModel.currentPage > 0) {
        this.$emit("changePage", {
          ...this.pageModel,
          currentPage: this.pageModel.currentPage - 1
        });
      }
    },
    lastPage() {
      this.$emit("changePage", {
        ...this.pageModel,
        currentPage: this.pageModel.totalPages - 1
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/datagrid.scss";
</style>
