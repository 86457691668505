<template>
  <tr
    v-bind:class="{
      invalid: isRowInvalid()
    }"
  >
    <td>
      {{ row.name }}
      <span v-if="generationError" class="error"
        >Generation error: {{ generationError }}</span
      >
      <span v-if="quoteError" class="error">Quote error: {{ quoteError }}</span>
    </td>
    <td>
      {{ row.destination0 ? row.destination0.name : "" }}
    </td>
    <td>
      {{ row.destination1 ? row.destination1.name : "" }}
    </td>
    <DateTime :date="row.plannedStart" />
    <DateTime :date="row.plannedEnd" :isOpenEnded="row.isOpenEnded" />
    <template v-if="row.quote">
      <td>${{ row.quote.offer.setupPrice }}</td>
      <td>${{ row.quote.offer.hourlyPrice }}</td>
      <td>${{ row.quote.offer.price }}</td>
    </template>
    <template v-else>
      <td></td>
      <td></td>
      <td>Missing quote</td>
    </template>
    <td>
      <gl-button
        data-testid="remove-button"
        icon="remove"
        @click="removeItem(row)"
      />
    </td>
  </tr>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import dateUtils from "@/mixins/dateUtils";
import DateTime from "@/components/Table/TD/DateTime.vue";
import { ITEMS_DESTINATIONS } from "@/constants.js";
import { GlButton } from "@gitlab/ui";
import { deliveryApiClient } from "@/mixins/apiClients";
import msgUtils from "@/mixins/msgUtils.js";

const { SHOW } = ITEMS_DESTINATIONS;

export default {
  mixins: [msgUtils, dateUtils, userInfo, deliveryApiClient],
  components: {
    GlButton,
    DateTime
  },
  props: {
    row: {
      type: Object
    },
    generationError: {
      type: String,
      default: null
    },
    quoteError: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      contributionType: "",
      SHOW_DESTINATION: SHOW
    };
  },
  methods: {
    removeItem(row) {
      this.$emit("removeItem", row);
    },
    isRowInvalid() {
      return (
        this.row._validation.isOverlapping ||
        this.row._validation.isInPast ||
        this.generationError ||
        this.quoteError
      );
    }
  }
};
</script>
<style lang="scss">
$warning-bg: #f5d9a8;
$warning-color: #8f4700;

.invalid {
  color: $warning-color;
}
.error {
  color: #b9240c;
  font-weight: bold;
  display: block;
  margin-top: 0.6rem;
}
</style>
