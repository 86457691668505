<template>
  <div class="container-fluid w-100">
    <div class="row p-3">
      <div class="col-md-1">
        <div class="btn-group btn-group-sm btn-group-vertical" role="group">
          <gl-icon
            name="text-description"
            class="clickable mb-3 inlineDetailSwitch"
            :class="{
              active: activeTab === 'info'
            }"
            :size="24"
            @click="toggleTab('info')"
          ></gl-icon>
          <gl-icon
            name="eye"
            class="clickable mb-3 inlineDetailSwitch"
            :class="{
              active: activeTab === 'preview'
            }"
            :size="24"
            @click="toggleTab('preview')"
          ></gl-icon>
          <gl-icon
            name="diagram"
            v-if="isArqadeAdminRoleActive || isMasquerading"
            class="clickable mb-3 inlineDetailSwitch"
            :class="{
              active: activeTab === 'diagram'
            }"
            :size="24"
            @click="toggleTab('diagram')"
          ></gl-icon>
        </div>
      </div>
      <div class="col-md-11" v-if="activeTab === 'info'">
        <div class="row">
          <div class="col-md-2">
            <div class="mb-3">
              <p class="m-0 info-title">
                {{
                  determineContributionType(deliveryDetails.contributionType)
                }}
                Contribution
              </p>

              <p>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                >
                  <path
                    d="M0 7C0 6.44772 0.447715 6 1 6H5.58579L4.29289 4.70711C3.90237 4.31658 3.90237 3.68342 4.29289 3.29289C4.68342 2.90237 5.31658 2.90237 5.70711 3.29289L9.41421 7L5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L5.58579 8H1C0.447715 8 0 7.55228 0 7Z"
                    fill="#525252"
                  />
                  <path
                    d="M9 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H9C8.44772 0 8 0.447715 8 1C8 1.55229 8.44771 2 9 2H12V12H9C8.44771 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
                    fill="#525252"
                  />
                </svg>
                {{ deliveryDetails.contributionName }}
              </p>
            </div>
            <p class="m-0 info-title">
              {{ determineContributionType(deliveryDetails.contributionType) }}
              Destination
            </p>
            <p class="mb-0" v-if="deliveryDetails.destinationName0">
              <a
                :href="
                  '#' + SHOW_DESTINATION + '/' + deliveryDetails.destinationId0
                "
                class="inline-details-value"
              >
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                >
                  <path
                    d="M4.71079 7C4.71079 6.44772 5.1585 6 5.71079 6L10.2966 6L9.00368 4.70711C8.61316 4.31658 8.61316 3.68342 9.00368 3.29289C9.3942 2.90237 10.0274 2.90237 10.4179 3.29289L14.125 7L10.4179 10.7071C10.0274 11.0976 9.3942 11.0976 9.00368 10.7071C8.61316 10.3166 8.61316 9.68342 9.00368 9.29289L10.2966 8H5.71079C5.1585 8 4.71079 7.55228 4.71079 7Z"
                    fill="#525252"
                  />
                  <path
                    d="M5.125 14H2.125C1.02043 14 0.125 13.1046 0.125 12V2C0.125 0.895431 1.02043 0 2.125 0H5.125C5.67728 0 6.125 0.447715 6.125 1C6.125 1.55229 5.67729 2 5.125 2H2.125V12H5.125C5.67729 12 6.125 12.4477 6.125 13C6.125 13.5523 5.67728 14 5.125 14Z"
                    fill="#525252"
                  />
                </svg>

                <strong>{{ deliveryDetails.destinationName0 }}</strong>
              </a>
            </p>
            <p v-if="deliveryDetails.destinationName1">
              <a
                :href="
                  '#' + SHOW_DESTINATION + '/' + deliveryDetails.destinationId1
                "
                class="inline-details-value"
              >
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                >
                  <path
                    d="M4.71079 7C4.71079 6.44772 5.1585 6 5.71079 6L10.2966 6L9.00368 4.70711C8.61316 4.31658 8.61316 3.68342 9.00368 3.29289C9.3942 2.90237 10.0274 2.90237 10.4179 3.29289L14.125 7L10.4179 10.7071C10.0274 11.0976 9.3942 11.0976 9.00368 10.7071C8.61316 10.3166 8.61316 9.68342 9.00368 9.29289L10.2966 8H5.71079C5.1585 8 4.71079 7.55228 4.71079 7Z"
                    fill="#525252"
                  />
                  <path
                    d="M5.125 14H2.125C1.02043 14 0.125 13.1046 0.125 12V2C0.125 0.895431 1.02043 0 2.125 0H5.125C5.67728 0 6.125 0.447715 6.125 1C6.125 1.55229 5.67729 2 5.125 2H2.125V12H5.125C5.67729 12 6.125 12.4477 6.125 13C6.125 13.5523 5.67728 14 5.125 14Z"
                    fill="#525252"
                  />
                </svg>
                <strong>{{ deliveryDetails.destinationName1 }}</strong>
              </a>
            </p>
          </div>
          <div class="col-md-6">
            <p class="mb-0 info-title">Description</p>
            <p>
              {{ deliveryDetails.description }}
            </p>
            <div
              v-if="
                checkAlerts0(deliveryDetails) || checkAlerts1(deliveryDetails)
              "
            >
              <p class="info-title mb-0">
                <gl-icon
                  name="status-alert"
                  class="align-items-center justify-content-center mb-1"
                  :size="12"
                />
                Alerts Available
              </p>
              <div class="d-flex">
                <div
                  v-if="checkAlerts0(deliveryDetails)"
                  class="mr-3 alertContainer"
                >
                  <gl-badge size="md" variant="warning" icon="" iconSize="md"
                    >Primary Flow Alerts</gl-badge
                  >
                  <li
                    v-for="alert in deliveryDetails.currentAlerts0"
                    :key="alert"
                    class="mb-0"
                  >
                    {{ alert }}
                  </li>
                </div>
                <div
                  v-if="checkAlerts1(deliveryDetails)"
                  class="alertContainer"
                >
                  <gl-badge size="md" variant="warning" icon="" iconSize="md"
                    >Secondary Flow Alerts</gl-badge
                  >
                  <li
                    v-for="alert in deliveryDetails.currentAlerts1"
                    :key="alert"
                    class="mb-0"
                  >
                    {{ alert }}
                  </li>
                </div>
              </div>
            </div>
            <div
              v-if="
                deliveryDetails.source0Encrypted ||
                  deliveryDetails.source1Encrypted
              "
            >
              <p class="mb-0 info-title">
                <gl-icon
                  name="lock"
                  class="align-items-center justify-content-center mb-1"
                  :size="16"
                />
                Source
              </p>
              <p>
                This delivery includes the following encrypted source(s), please
                contact the originator for the encryption key:
              </p>
              <p class="mb-0" v-if="deliveryDetails.source0Encrypted">
                Source 1
              </p>
              <p v-if="deliveryDetails.source1Encrypted">
                Source 2
              </p>
            </div>
            <div class="timezones">
              <div class="timezonesTitle">
                <p class="info-title">Your times</p>
              </div>
              <div class="times">
                <div>
                  <h3>Start</h3>
                  <time-zones-component
                    :date-time="deliveryDetails.contributionPlannedStart"
                  />
                </div>
                <div>
                  <h3>End</h3>
                  <span v-if="deliveryDetails.isOpenEnded">On-Going</span>
                  <time-zones-component
                    v-else
                    :date-time="deliveryDetails.contributionPlannedEnd"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="mb-4"
                  v-if="fieldNotNull(deliveryDetails.egressBitrateBps0)"
                >
                  <p class="mb-0 info-title">
                    Egress bitrate 1
                  </p>
                  <p>{{ deliveryDetails.egressBitrateBps0 }} bps</p>
                </div>

                <div v-if="fieldNotNull(deliveryDetails.egressBitrateBps1)">
                  <p class="mb-0 info-title">Egress bitrate 2</p>
                  <p>{{ deliveryDetails.egressBitrateBps1 }} bps</p>
                </div>
              </div>
              <div
                class="col-md-4"
                v-if="
                  fieldNotNull(deliveryDetails.entitlementArn0) ||
                    fieldNotNull(deliveryDetails.entitlementArn1)
                "
              >
                <div v-if="fieldNotNull(deliveryDetails.entitlementArn0)">
                  <p class="info-title">Entitlement ARN 1</p>
                  <p class="text-break">
                    {{ deliveryDetails.entitlementArn0 }}
                  </p>
                </div>
                <div v-if="fieldNotNull(deliveryDetails.entitlementArn1)">
                  <p class="info-title">Entitlement ARN 2</p>
                  <p>
                    {{ deliveryDetails.entitlementArn1 }}
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="mb-4"
                  v-if="fieldNotNull(deliveryDetails.isStreamProfileLocked)"
                >
                  <p class="mb-0 info-title">Locked Stream Profile</p>
                  <p>
                    {{ deliveryDetails.isStreamProfileLocked }}
                  </p>
                </div>

                <div v-if="fieldNotNull(deliveryDetails.state)">
                  <p class="mb-0 info-title">Status</p>
                  <p>
                    {{ deliveryDetails.state }}
                  </p>
                  <p v-if="deliveryDetails.errorCode">
                    Delivery failed due to service limits, please contact Arqiva
                    support
                  </p>
                </div>

                <div>
                  <p class="mb-0 info-title">Dashboard</p>
                  <p>
                    <a
                      :href="getDashboardURL()"
                      target="_blank"
                      class="inline-details-value"
                      >link</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-11" v-if="activeTab === 'preview'">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-8 video-container">
                <video
                  class="video-player-1"
                  ref="vp1"
                  autoplay
                  controls
                  muted
                ></video>
              </div>
              <div class="col-md-4">
                <p class="info-title">Primary Preview</p>
                <p>{{ deliveryDetails.destinationName0 }}</p>
                <div
                  class="btn-group btn-group-vertical btn-group-md w-100"
                  role="group"
                >
                  <gl-button
                    class="mb-3 w-100"
                    title="Request Preview Pipeline 1"
                    v-if="isPreviewRequestAvailable(deliveryDetails, 0)"
                    @click="requestPreview(deliveryDetails, 0)"
                  >
                    Request Preview Pipeline 1
                  </gl-button>
                  <gl-button
                    class="mb-3 w-100"
                    title="Extend Preview Pipeline 1"
                    v-if="isPreviewExtendAvailable(deliveryDetails, 0)"
                    @click="requestPreview(deliveryDetails, 0, true)"
                  >
                    Extend Preview Pipeline 1
                  </gl-button>

                  <div class="preview-tab" v-if="preview0Visible">
                    <p v-if="deliveryDetails.previewStatus0 === 'STARTING'">
                      <gl-loading-icon
                        color="dark"
                        :inline="false"
                        size="sm"
                        label="Preparing Preview"
                      />
                      Preparing Preview
                    </p>
                    <p v-if="deliveryDetails.previewStatus0 === 'STARTED'">
                      {{ timeRemainingPreview0 }} Remaining
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="deliveryDetails.destinationId1">
            <div class="row ml-3">
              <div class="col-md-8 video-container">
                <video
                  class="video-player-2"
                  ref="vp2"
                  controls
                  autoplay
                  muted
                ></video>
              </div>
              <div class="col-md-4">
                <p class="info-title">Secondary Preview</p>
                <p>{{ deliveryDetails.destinationName1 }}</p>
                <div
                  class="btn-group btn-group-vertical btn-group-md w-100"
                  role="group"
                >
                  <gl-button
                    class="mb-3 w-100"
                    title="Request Preview Pipeline 2"
                    v-if="isPreviewRequestAvailable(deliveryDetails, 1)"
                    @click="requestPreview(deliveryDetails, 1)"
                  >
                    Request Preview Pipeline 2
                  </gl-button>
                  <gl-button
                    class="mb-3 w-100"
                    title="Extend Preview Pipeline 2"
                    v-if="isPreviewExtendAvailable(deliveryDetails, 1)"
                    @click="requestPreview(deliveryDetails, 1, true)"
                  >
                    Extend Preview Pipeline 2
                  </gl-button>
                  <div class="preview-tab" v-if="preview1Visible">
                    <p v-if="deliveryDetails.previewStatus1 === 'STARTING'">
                      <gl-loading-icon
                        color="dark"
                        :inline="false"
                        size="sm"
                        label="Preparing Preview"
                      />
                      Preparing Preview
                    </p>

                    <p v-if="deliveryDetails.previewStatus1 === 'STARTED'">
                      {{ timeRemainingPreview1 }} Remaining
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-11" v-if="activeTab === 'diagram'">
        <div>
          <gl-loading-icon v-if="rendering" label="Rendering" size="xl" />
          <PipelineDiagram v-else-if="pipeline" :pipeline="pipeline" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GlIcon, GlButton, GlLoadingIcon, GlBadge } from "@gitlab/ui";
import { ITEMS_DESTINATIONS } from "@/constants";
import {
  contributionApiClient,
  deliveryApiClient,
  metricsApiClient
} from "@/mixins/apiClients";
import userInfoMixin from "@/mixins/userInfo.js";
import Hls from "hls.js";
import PipelineDiagram from "@/components/DataGrid/PipelineDiagram.vue";
import TimeZonesComponent from "@/components/Date/TimeZones.vue";
import msgUtils from "@/mixins/msgUtils.js";

const { SHOW } = ITEMS_DESTINATIONS;

export default {
  components: {
    GlBadge,
    GlIcon,
    GlButton,
    GlLoadingIcon,
    PipelineDiagram,
    TimeZonesComponent
  },
  name: "ExpandedContent",
  mixins: [
    msgUtils,
    contributionApiClient,
    deliveryApiClient,
    metricsApiClient,
    userInfoMixin
  ],
  props: {
    deliveryDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeRemainingPreview0: "Dummy timer",
      timeRemainingPreview1: "Dummy timer",
      SHOW_DESTINATION: SHOW,
      videoTimer1: undefined,
      videoTimer2: undefined,
      activeTab: "info",
      rendering: false,
      pipeline: undefined
    };
  },
  mounted() {
    this.getPipeline();
  },
  methods: {
    checkAlerts0(deliveryDetails) {
      return !!(
        deliveryDetails.currentAlerts0 &&
        deliveryDetails.currentAlerts0.length > 0
      );
    },
    checkAlerts1(deliveryDetails) {
      return !!(
        deliveryDetails.currentAlerts1 &&
        deliveryDetails.currentAlerts1.length > 0
      );
    },
    getDashboardURL() {
      const r = this.deliveryDetails.sourceRegion0;
      const id = this.deliveryDetails.mediaPipelineId;
      const n = "dashboard-" + id;
      return (
        "https://" +
        r +
        ".console.aws.amazon.com/cloudwatch/home?region=" +
        r +
        "#dashboards:name=" +
        n
      );
    },
    toggleTab(tab) {
      this.activeTab = tab;
      if (tab === "preview") {
        this.updateVideoData();
      }
    },
    determineContributionType(contributionType) {
      if (contributionType === "event_contribution") {
        return "Event ";
      } else if (contributionType === "channel_contribution") {
        return "Channel ";
      }
    },
    isPreviewRequestAvailable(delivery, destinationId) {
      if (delivery && delivery[`previewStatus${destinationId}`]) {
        return (
          delivery[`previewStatus${destinationId}`] !== "STARTING" &&
          delivery[`previewStatus${destinationId}`] !== "STARTED"
        );
      }
    },
    isPreviewExtendAvailable(delivery, destinationId) {
      if (delivery && delivery[`previewStatus${destinationId}`]) {
        return (
          delivery[`previewStatus${destinationId}`] === "STARTING" ||
          delivery[`previewStatus${destinationId}`] === "STARTED"
        );
      }
    },
    async requestPreview(item, index, extend = false) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to ${
          extend ? "extend" : "request"
        } preview for "${item.name}" (pipeline ${index + 1})?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );

      if (userReply) {
        try {
          let url = `preview/${index}`;
          if (extend) {
            url += "/extend";
          }
          await this.deliveryApiClient.postCustomPath(item.id, url);
          this.handleApiSuccess(`The preview has been requested.`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    clearVideoTimer() {
      if (this.videoTimer1) {
        clearInterval(this.videoTimer1);
        this.videoTimer1 = undefined;
      }
      if (this.videoTimer2) {
        clearInterval(this.videoTimer2);
        this.videoTimer2 = undefined;
      }
    },
    updateVideoData() {
      const previewAvailable =
        (this.deliveryDetails.previewUrl0 &&
          this.deliveryDetails.previewStatus0 === "STARTED") ||
        (this.deliveryDetails.previewUrl1 &&
          this.deliveryDetails.previewStatus1 === "STARTED");
      const mediaUnattached1 = !this.hls1.media;
      const mediaUnattached2 = !this.hls2.media;
      const timeRemainingUpdateInterval = 200;
      if ((previewAvailable && mediaUnattached1) || mediaUnattached2) {
        if (
          this.deliveryDetails.previewUrl0 &&
          this.deliveryDetails.previewStatus0 === "STARTED"
        ) {
          this.hls1.loadSource(this.deliveryDetails.previewUrl0);
          this.hls1.on(Hls.Events.MANIFEST_PARSED, () => {
            this.hls1.attachMedia(this.vp1);
            this.vp1.play();
          });
          this.hls1.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal && data.type === Hls.ErrorTypes.NETWORK_ERROR) {
              // When the preview URL is first available, sometimes it will
              // throw a CORS error for the first thirty seconds or so while
              // it is still setting up. If we get this error, we detach the
              // media here so that next time this `updateVideoData` method is
              // called it can attempt to set up from scratch again.
              this.hls1.detachMedia();
            }
          });
          const endTime1 = Date.parse(this.deliveryDetails.previewEndTime0);
          this.videoTimer1 = setInterval(() => {
            if (!isNaN(endTime1)) {
              const totalSecondsLeft = Math.round(
                (endTime1 - new Date()) / 1000
              );
              const minutes = Math.max(0, Math.floor(totalSecondsLeft / 60));
              const seconds = Math.max(0, totalSecondsLeft - minutes * 60);
              let formattedMinutes = `${minutes}`;
              let formattedSeconds = `${seconds}`;
              if (formattedMinutes.length === 1) {
                formattedMinutes = `0${formattedMinutes}`;
              }
              if (formattedSeconds.length === 1) {
                formattedSeconds = `0${formattedSeconds}`;
              }
              this.timeRemainingPreview0 = `${formattedMinutes}:${formattedSeconds}`;
            }
          }, timeRemainingUpdateInterval);
        }
        if (
          this.deliveryDetails.previewUrl1 &&
          this.deliveryDetails.previewStatus1 === "STARTED"
        ) {
          this.hls2.loadSource(this.deliveryDetails.previewUrl1);
          this.hls2.on(Hls.Events.MANIFEST_PARSED, () => {
            this.hls2.attachMedia(this.vp2);
            this.vp2.play();
          });
          this.hls2.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal && data.type === Hls.ErrorTypes.NETWORK_ERROR) {
              // When the preview URL is first available, sometimes it will
              // throw a CORS error for the first thirty seconds or so while
              // it is still setting up. If we get this error, we detach the
              // media here so that next time this `updateVideoData` method is
              // called it can attempt to set up from scratch again.
              this.hls2.detachMedia();
            }
          });
          const endTime2 = Date.parse(this.deliveryDetails.previewEndTime1);
          this.clearVideoTimer();
          this.videoTimer2 = setInterval(() => {
            if (!isNaN(endTime2)) {
              const totalSecondsLeft = Math.round(
                (endTime2 - new Date()) / 1000
              );
              const minutes = Math.max(0, Math.floor(totalSecondsLeft / 60));
              const seconds = Math.max(0, totalSecondsLeft - minutes * 60);
              let formattedMinutes = `${minutes}`;
              let formattedSeconds = `${seconds}`;
              if (formattedMinutes.length === 1) {
                formattedMinutes = `0${formattedMinutes}`;
              }
              if (formattedSeconds.length === 1) {
                formattedSeconds = `0${formattedSeconds}`;
              }
              this.timeRemainingPreview1 = `${formattedMinutes}:${formattedSeconds}`;
            }
          }, timeRemainingUpdateInterval);
        }
      } else if (!previewAvailable && !mediaUnattached1 && !mediaUnattached2) {
        // We do not a preview source available but we're currently
        // still playing and need to stop
        this.vp1.pause();
        this.vp2.pause();
        this.hls1.detachMedia();
        this.hls2.detachMedia();
        this.clearVideoTimer();
      }
    },
    fieldNotNull(field) {
      return !(field === undefined || field === null || field === "");
    },
    getPipeline() {
      try {
        this.rendering = true;
        this.deliveryApiClient
          .getById(this.deliveryDetails.id, "visualize")
          .then(res => {
            this.pipeline = res;
            this.rendering = false;
          });
      } catch (e) {
        this.handleApiError(e);
        this.rendering = false;
      }
    }
  },
  destroyed() {
    this.clearVideoTimer();
  },
  computed: {
    hls1() {
      return new Hls();
    },
    hls2() {
      return new Hls();
    },
    vp1() {
      return this.$refs.vp1;
    },
    vp2() {
      return this.$refs.vp2;
    },
    preview0Visible() {
      return (
        this.deliveryDetails.previewStatus0 === "STARTING" ||
        this.deliveryDetails.previewStatus0 === "STARTED"
      );
    },
    preview1Visible() {
      return (
        this.deliveryDetails.previewStatus1 === "STARTING" ||
        this.deliveryDetails.previewStatus1 === "STARTED"
      );
    },
    playerOptions() {
      return [
        {
          text: "Redundant pipeline 1",
          value: 0,
          disabled: !this.preview0Visible
        },
        {
          text: "Redundant pipeline 2",
          value: 1,
          disabled: !this.preview1Visible
        }
      ];
    }
  }
};
</script>

<style scoped>
.alertContainer {
  inline-size: 150px;
  overflow-wrap: break-word;
}

.inlineDetailSwitch:hover,
.inlineDetailSwitch.active {
  color: red;
}

.video-player-1 {
  margin-top: 10%;
  width: 100%;
  height: 75%;
  background-color: #f0f0f0;
  background-image: url("~@/assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.video-player-2 {
  margin-top: 10%;
  width: 100%;
  height: 75%;
  background-color: #f0f0f0;
  background-image: url("~@/assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.video-container {
  position: relative;
  display: flex;
}
</style>
