export default {
  accessLevel: null,
  contributionType: null,
  description: null,
  editLevel: null,
  id: null,
  isOpenEnded: false,
  lockStreamProfile: false,
  name: null,
  organizationId: null,
  plannedEnd: null,
  plannedStart: null,
  scheduleStatus: null,
  source0Id: null,
  source1Id: null
};
