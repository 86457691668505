<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
    <gl-tabs>
      <gl-tab
        v-for="(item, index) in tabsItems"
        :key="`tab${index}`"
        @click="openTab(item.subPageName, item.subComponent, item.data)"
        :active="isSelected(item.subPageName)"
        :disabled="item.disabled"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
          <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge">{{
            item.badge
          }}</gl-badge>
        </template>
      </gl-tab>
    </gl-tabs>
    <component
      v-bind:is="currentComponent"
      :currentItem="currentItem"
      :isUpdate="isEdit"
      @create="virtualConnectSiteCreated"
    ></component>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  MAIN_ITEMS,
  ITEMS_VIRTUAL_CONNECT_SITES,
  ALIAS_ITEMS_VIRTUAL_CONNECT_SITES
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import { virtualConnectSiteApiClient } from "@/mixins/apiClients";
import { AddFirst } from "@/components/VirtualConnectSites";
import * as eq from "deep-equal";
import { GlTabs, GlTab } from "@gitlab/ui";

const { LIST } = ITEMS_VIRTUAL_CONNECT_SITES;
const { ADD_FIRST } = ALIAS_ITEMS_VIRTUAL_CONNECT_SITES;

export default {
  components: { Breadcrumb, GlTabs, GlTab },
  mixins: [wizardGuard, virtualConnectSiteApiClient],
  methods: {
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    virtualConnectSiteCreated() {
      this.created = true;
      this.$router.push(LIST);
    }
  },
  created() {
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      this.virtualConnectSiteApiClient.getById(id).then(virtualConnectSite => {
        this.currentItem = virtualConnectSite;
        this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
        this.isEdit = true;
        this.currentComponent = AddFirst;
        this.tabsItems.forEach(tab => {
          if (this.isSelected(tab.subPageName)) {
            this.openTab(tab.subPageName, tab.subComponent, tab.data);
          }
        });
      });
    } else {
      this.currentItem = {
        name: undefined,
        ipAddress: undefined,
        maxLatencyMs: undefined,
        port: undefined,
        cryptoKey: undefined,
        cryptoAlgo: null
      };
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
      this.currentComponent = AddFirst;
    }
  },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Virtual Connect Sites`, href: LIST },
        { text: this.isEdit ? "Edit" : "Create new", href: ADD_FIRST }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `Description`,
          subPageName: ADD_FIRST,
          subComponent: AddFirst
        }
      ];
    },
    pageTitle() {
      return this.isEdit
        ? "Edit virtual connect site"
        : `Create virtual connect site`;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: undefined,
      isEdit: false,
      selectedTab: null,
      currentItem: {},
      originalItem: undefined,
      isFormValid: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created && !this.isFormValid) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  }
};
</script>
