import Support from "@/views/Support/Support";
import { Help } from "@/components/Support";
import { MAIN_ITEMS, ITEMS_SUPPORT } from "@/constants.js";
const { SUPPORT } = MAIN_ITEMS;
const { HELP } = ITEMS_SUPPORT;

const support = [
  {
    path: SUPPORT,
    name: `Support`,
    component: Support,
    children: [
      {
        path: HELP,
        component: Help
      }
    ]
  }
];

export default support;
