<template>
  <div class="data-grid">
    <table>
      <thead v-if="showHeader">
        <th class="min-h">
          <input
            v-if="selectable"
            type="checkbox"
            v-model="allRowsSelected"
            @change="onAllRowsSelected"
          />
        </th>
        <th
          v-for="column in columns"
          :key="column.text"
          @click="onColumnClick(column.field)"
          :class="{
            clickable: column.isSortable,
            'text-center': column.center,
            'text-right': column.right
          }"
        >
          {{ column.text }}
          <gl-icon
            name="chevron-lg-up"
            v-if="
              sortModel &&
                (sortModel.field === column.field ||
                  sortModel.field === column.sortField) &&
                sortModel.order === 'asc'
            "
          />
          <gl-icon
            name="chevron-lg-down"
            v-if="
              sortModel &&
                (sortModel.field === column.field ||
                  sortModel.field === column.sortField) &&
                sortModel.order === 'desc'
            "
          />
        </th>
        <th v-if="showContextMenu" class="min-h"></th>
      </thead>
      <tbody v-if="!loading && rows.length === 0">
        <tr>
          <td :colspan="totalNumColumns" class="text-center">
            No data for this query...
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="loading">
        <tr>
          <td :colspan="totalNumColumns" class="text-center">Loading...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(row, index) in rows">
          <tr
            @click="rowClicked(row)"
            :key="row.data.id"
            :id="`row_${index}`"
            :class="{ 'selected-row': row.selected, 'open-row': row.open }"
            :ref="'row_' + row.data.id"
          >
            <td class="min-h">
              <input
                v-if="selectable"
                type="checkbox"
                v-model="row.selected"
                @change="selectRow(row)"
              />
              <span
                v-if="row.data.children && row.data.children.length > 0"
                class="drawer-opener ml-2 clickable"
                @click="toggleOpenRow(row)"
              >
                <gl-icon name="chevron-down" v-if="row.open"></gl-icon>
                <gl-icon name="chevron-right" v-else></gl-icon>
                <gl-icon name="folder-open" v-if="showFolderIcon"></gl-icon>
              </span>
            </td>
            <td
              v-for="column in columns"
              :key="column.text"
              :class="{
                clickable: typeof column.clicked === 'function',
                'text-center': column.center,
                'text-right': column.right
              }"
              @click="cellClicked(column, row.data)"
            >
              <slot :row="row.data" :field="column.field">
                {{ row.data[column.field] }}
              </slot>
            </td>
            <td
              v-if="
                showContextMenu &&
                  contextMenuItems &&
                  contextMenuItems[row.data.id] &&
                  contextMenuItems[row.data.id].length > 0
              "
              class="kebab clickable"
              @click.prevent.stop="contextMenuClick($event, row.data)"
            >
              <gl-icon name="ellipsis_v"></gl-icon>
            </td>
            <td
              v-if="
                !showContextMenu ||
                  !contextMenuItems ||
                  !contextMenuItems[row.data.id] ||
                  contextMenuItems[row.data.id].length === 0
              "
            ></td>
          </tr>
          <tr v-if="row.open" class="drawer" :key="`${row.data.id}_drawer`">
            <td :colspan="totalNumColumns + 1" class="drawer pl-3">
              <arq-data-grid-drawer
                ref="gridDrawer"
                :columns="subColumns"
                :items="row.data.children"
                @rowSelected="selectDrawerRow"
                :deselectAll="deselectChildren"
                @contextMenuClick="contextMenuClick"
                :showContextMenu="showContextMenu"
                :selectable="selectable"
              >
                <template v-slot:drawerSlot="{ row, field }">
                  <slot name="drawer" :drawerRow="row" :drawerField="field">
                    {{ row[field] }}
                  </slot>
                </template>
              </arq-data-grid-drawer>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <ArqDataGridPaginator
      v-if="showPager"
      :pagingModel="pagingModel"
      class="mt-2"
      @firstPage="changePage"
      @lastPage="changePage"
      @previousPage="changePage"
      @nextPage="changePage"
    />
    <vue-context ref="menu" v-if="showContextMenu" v-slot="{ data }">
      <li
        v-for="menuItem in getContextItemsByRow(data)"
        v-bind:key="menuItem.id"
      >
        <a
          :class="{ emphasized: menuItem.emphasize }"
          href="#"
          @click.prevent="onMenuClick(menuItem.id, data)"
          >{{ menuItem.text }}</a
        >
      </li>
    </vue-context>
  </div>
</template>

<script>
import dateUtils from "@/mixins/dateUtils";
import VueContext from "vue-context";
import ArqDataGridPaginator from "./DataGridPaginator.vue";
import ArqDataGridDrawer from "./DataGridDrawer.vue";
import { GlIcon } from "@gitlab/ui";

export default {
  components: {
    ArqDataGridPaginator,
    ArqDataGridDrawer,
    VueContext,
    GlIcon
  },
  name: "ArqDataGrid",
  mixins: [dateUtils],
  props: {
    showPager: {
      type: Boolean,
      default: true
    },
    searchPhrase: {
      type: String,
      default: ""
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    phrase: String,
    rowPoller: Object,
    data: Object,
    columns: Array,
    subColumns: Array,
    itemsPerPage: {
      type: Number,
      default: 0
    },
    showContextMenu: {
      type: Boolean,
      default: false
    },
    contextMenuItems: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectOnClick: {
      type: Boolean,
      default: false
    },
    showFolderIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleKey(evt) {
      if (evt.key === "Escape") {
        this.tempArray = [];
        this.tempSearch = "";
        this.$emit("searchPhraseChanged", this.tempSearch);
        return;
      }
      if (evt.target.tagName === "INPUT" || evt.target.tagName === "TEXTAREA") {
        return;
      }
      if (
        (evt.code >= "KeyA" && evt.code <= "KeyZ") ||
        (evt.code >= "Digit0" && evt.code <= "Digit9")
      ) {
        this.tempArray.push(evt.key);
        this.tempSearch = this.tempArray.join("");
        this.$emit("searchPhraseChanged", this.tempSearch);
      } else if (evt.code === "Escape" && this.tempArray.length > 0) {
        this.tempArray = [];
        this.tempSearch = "";
        this.$emit("searchPhraseChanged", this.tempSearch);
      }
    },
    deselectAllRows() {
      this.selectedRows = [];
      this.rows.forEach(r => {
        r.selected = false;
      });
    },
    rowClicked(row) {
      if (this.selectOnClick) {
        this.deselectAllRows();
        row.selected = true;
        this.selectRow(row);
      }
    },
    cellClicked(column, row) {
      if (typeof column.clicked === "function") {
        column.clicked(row);
      }
    },
    toggleOpenRow(row) {
      row.open = !row.open;
      this.$emit("toogleRowOpen", row);
    },
    selectDrawerRow(row, selectedRows) {
      this.allRowsSelected = false;
      this.onAllRowsSelected();
      this.$emit("drawerRowSelected", row, selectedRows);
      this.deselectChildren = false;
    },
    selectRow(row) {
      if (row.selected) {
        this.selectedRows[row.data.id] = row.data;
        this.deselectChildren = true;
      } else {
        delete this.selectedRows[row.data.id];
        this.deselectChildren = false;
      }
      const ref = this.$refs["row_" + row.data.id];
      row.data.ref = ref;
      this.$emit(
        "rowSelected",
        row.data,
        Object.keys(this.selectedRows).map(k => this.selectedRows[k])
      );
    },
    onMenuClick(option, data) {
      this.$emit("contextMenuClick", data.row, option);
    },
    getContextItemsByRow(rowData) {
      if (
        rowData &&
        rowData.row &&
        Object.prototype.hasOwnProperty.call(
          this.contextMenuItems,
          rowData.row.id
        )
      ) {
        return this.contextMenuItems[rowData.row.id];
      }
    },
    contextMenuClick(e, row) {
      if (
        this.contextMenuItems[row.id] &&
        this.contextMenuItems[row.id].length > 0
      ) {
        this.$refs.menu.open(e, { row });
      }
    },
    changePage(pagingModel) {
      this.deselectAllRows();
      this.$emit(
        "rowSelected",
        undefined,
        this.rows.filter(r => r.selected).map(r => r.data)
      );
      this.pagingModel = pagingModel;
      this.$emit("pageChanged", this.pagingModel);
      this.$emit("dataNeeded", this.sortModel, this.pagingModel, this.phrase);
    },
    onAllRowsSelected() {
      for (const row of this.rows) {
        row.selected = this.allRowsSelected;
      }
      this.$emit(
        "rowSelected",
        undefined,
        this.rows.filter(r => r.selected).map(r => r.data)
      );
    },
    areAllRowsSelected() {
      for (const row of this.rows) {
        if (!row.selected) {
          return false;
        }
      }
      return this.rows.length > 0;
    },
    onColumnClick(field) {
      const column = this.getColumnByField(field);
      if (column && column.isSortable) {
        field = column.sortField || field;
        if (this.sortModel && this.sortModel.field === field) {
          this.sortModel.order =
            this.sortModel.order === "asc" ? "desc" : "asc";
        } else {
          this.sortModel = {
            field,
            order: "asc"
          };
        }
      }
    },
    getColumnByField(field) {
      let result = undefined;
      for (const col of this.columns) {
        if (col.field === field) {
          result = col;
          break;
        }
      }
      return result;
    },
    reloadData() {
      this.rows = [];
      if (this.data.items) {
        this.rows = this.data.items.map(row => {
          return {
            selected: false,
            open: false,
            data: row
          };
        });
      }
      this.pagingModel.currentPage = this.data.page;
      this.pagingModel.totalPages = this.data.totalPages;
      this.pagingModel.totalItems = this.data.totalItems;
      if (this.rowPoller) {
        if (this.pollerIntervalId) {
          clearInterval(this.pollerIntervalId);
          this.pollerIntervalId = undefined;
        }
        this.pollerIntervalId = setInterval(async () => {
          for (let i = 0; i < this.rows.length; i++) {
            let data;
            try {
              data = await this.rowPoller.asyncHandler(this.rows[i].data.id);
              if (data) {
                if (this.rowPoller.fields.length > 0) {
                  for (const field of this.rowPoller.fields) {
                    this.rows[i].data[field] = data[field];
                  }
                } else {
                  this.rows[i].data = data;
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
          this.$emit("dataUpdated");
        }, this.rowPoller.threshold);
      }
    }
  },
  watch: {
    phrase: {
      handler() {
        this.pagingModel.currentPage = 0;
        this.$emit("dataNeeded", this.sortModel, this.pagingModel, this.phrase);
      }
    },
    data: {
      handler() {
        this.loading = false;
        this.reloadData();
      }
    },
    rows: {
      handler() {
        this.allRowsSelected = this.areAllRowsSelected();
      },

      deep: true
    },
    sortModel: {
      handler() {
        this.$emit("sortChanged", this.sortModel);
        this.$emit("dataNeeded", this.sortModel, this.pagingModel, this.phrase);
      },
      deep: true
    }
  },
  mounted() {
    this.reloadData();
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    if (this.pollerIntervalId) {
      clearInterval(this.pollerIntervalId);
      this.pollerIntervalId = undefined;
    }
    document.removeEventListener("keydown", this.handleKey);
  },
  created() {
    this.sortModel = {
      field: "",
      order: ""
    };
    for (const col of this.columns) {
      if (col.defaultSort === true) {
        this.sortModel.field = col.field;
        this.sortModel.order = "desc";
      }
    }
    this.pagingModel = {
      currentPage: 0,
      itemsPerPage: this.itemsPerPage,
      totalPages: 0,
      totalItems: 0
    };
  },
  data() {
    return {
      tempSearch: "",
      tempArray: [],
      totalNumColumns: this.columns.length + (this.showContextMenu ? 2 : 1),
      pollerIntervalId: undefined,
      deselectChildren: false,
      loading: true,
      rows: [],
      selectedRows: {},
      allRowsSelected: Boolean,
      sortModel: {
        field: String,
        order: String
      },
      pagingModel: {
        currentPage: Number,
        itemsPerPage: Number,
        totalPages: Number,
        totalItems: Number
      }
    };
  }
};
</script>
<style lang="scss">
@import "@/scss/datagrid.scss";
@import "~vue-context/dist/css/vue-context.css";

.v-context,
.v-context ul {
  background-color: rgba(
    $color: (
      #fff
    ),
    $alpha: 0.85
  ) !important;
  transform: translate(-100%, 0);
}
</style>
