<template>
  <div class="table__wrapper">
    <div class="table__wrapper__inner">
      <table class="table">
        <thead>
          <slot name="head"></slot>
        </thead>
        <tbody>
          <Loading v-if="!items || loading" />
          <Empty v-else-if="items.length === 0"
            ><slot name="empty"></slot
          ></Empty>
          <slot v-else name="body"></slot>
        </tbody>
      </table>
    </div>
    <Paginator
      v-if="pageModel"
      :pageModel="pageModel"
      class="mt-2"
      @changePage="changePage"
    />
    <p data-testid="timezone_message">
      <small>{{ messageLocalTime }}</small>
    </p>
  </div>
</template>

<script>
import userInfo from "@/mixins/userInfo";
import Paginator from "@/components/Table/Paginator.vue";
import Loading from "@/components/Table/Loading";
import Empty from "@/components/Table/Empty";

export default {
  name: "Table",
  mixins: [userInfo],
  components: { Paginator, Loading, Empty },
  props: {
    pageModel: {
      type: Object,
      default: () => null
    },
    items: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    messageLocalTime() {
      return `All dates/times are specified in timezone: ${this.getDefaultTimezone()}`;
    }
  },
  methods: {
    changePage(details) {
      this.$emit("changePage", details);
    }
  }
};
</script>
<style lang="scss" scoped>
$cell-padding-x: 0.4rem;
$cell-padding-y: 0.8rem;
$success-bg: #c3e6e1;
$warning-bg: #f5d9a8;
$error-bg: #f3969a;
$danger-bg: #fdd4cd;
$success-color: #24663b;
$warning-color: #8f4700;
$error-color: #dc3545;
$danger-color: #ae1800;

.table__wrapper__inner {
  overflow-x: auto;
}

.table ::v-deep {
  width: 100%;

  thead {
    background-color: #f0f0f0;
    border-collapse: collapse;

    th {
      height: 38px;
      padding: $cell-padding-y $cell-padding-x;
      color: #999999;
      font-size: small;
      vertical-align: middle;
      border: none;

      &.clickable {
        cursor: pointer;
      }

      &.right {
        text-align: right;
      }
    }
  }

  tbody {
    background-color: #fafafa;

    td {
      font-size: 12px;
      line-height: 12px;
      height: 52px;
      padding: $cell-padding-y $cell-padding-x;
      vertical-align: middle;
      border: none;

      &.right {
        text-align: right;
      }

      &.kebab {
        width: 40px;
        color: #868686;
      }

      &.xl {
        font-size: 16px;
      }

      .xl {
        font-size: 16px;
      }

      .l {
        font-size: 12px;
      }
    }

    tr {
      border-bottom: 1px solid #dbdbdb;
      height: 58px;
    }
  }

  .status-primary {
    background-color: $success-bg !important;
    color: $success-color !important;
  }

  .status-success {
    background-color: $success-bg !important;
    color: $success-color !important;
  }

  .status-warning {
    background-color: $warning-bg !important;
    color: $warning-color !important;
  }

  .status-danger {
    background-color: $danger-bg !important;
    color: $danger-color !important;
  }

  .status-error {
    background-color: $error-bg !important;
    color: $error-color !important;
  }
}
</style>
