<template>
  <div
    v-if="
      bulkContributions.contributions &&
        bulkContributions.contributions.length > 0
    "
    class="bulk_delivery"
  >
    <h4 data-testid="title">Bulk Delivery List</h4>
    <ul class="bulk_list">
      <li
        v-for="item in bulkContributions.contributions"
        :key="item.id"
        :class="
          item._validation.isOverlapping || item._validation.isInPast
            ? 'invalid'
            : null
        "
      >
        <div>
          <div class="title">
            <h5>{{ item.name }}</h5>
            <b-badge
              v-if="item.source1Id === null"
              variant="primary"
              class="single-source"
            >
              Single Source
            </b-badge>
            <b-badge
              v-if="item.source1Id !== null"
              variant="primary"
              class="multi-source"
            >
              Multi Source
            </b-badge>
            <b-badge
              v-if="item._validation.isInPast"
              variant="primary"
              class="error"
            >
              Is in the past
            </b-badge>
            <b-badge
              v-if="item._validation.isOverlapping"
              variant="primary"
              class="warning"
            >
              Overlapping
            </b-badge>
          </div>
          <div class="dates">
            <div>
              <dt>Planned Start</dt>
              <dd data-testid="plannedStart">
                <time-zones-component
                  :date-time="item.plannedStart"
                  isAccordion
                />
              </dd>
            </div>
            <div>
              <dt>Planned End</dt>
              <dd data-testid="plannedEnd">
                <time-zones-component
                  :date-time="item.plannedEnd"
                  isAccordion
                />
              </dd>
            </div>
          </div>
        </div>
        <div>
          <gl-button
            data-testid="bulk-remove-item"
            icon="remove"
            @click="removeItem(item.id)"
          />
        </div>
      </li>
    </ul>
    <p v-if="bulkContributions.hasInThePast" class="invalid-text">
      Some contributions are in the past, so you cannot deliver them together.
      Please remove them from the list.
    </p>
    <p v-if="bulkContributions.hasOverlapping" class="invalid-text">
      Some contributions are overlapping, so you cannot deliver them together.
      Please remove an item that overlaps.
    </p>
    <p v-if="bulkContributions.hasMixedRedundancies" class="invalid-text">
      You have a mix of single and multi source contributions. Currently we
      cannot bulk deliver when these are mixed. Please deliver single and multi
      contributions seperately.
    </p>
    <div class="buttons">
      <gl-button @click="clearList" data-testid="bulk-clear">Clear</gl-button>
      <gl-button
        :href="`#${BULK_DELIVERY}/${packageId}`"
        variant="confirm"
        :disabled="!bulkContributions.valid"
        >Deliver {{ bulkContributions.contributions.length }} contribution(s)
      </gl-button>
    </div>
  </div>
</template>
<script>
import { GlButton } from "@gitlab/ui";
import { mapGetters, mapMutations } from "vuex";
import { ITEMS_CONTRIBUTIONS } from "@/constants.js";
import TimeZonesComponent from "@/components/Date/TimeZones.vue";

const { BULK_DELIVERY } = ITEMS_CONTRIBUTIONS;

export default {
  name: "BulkSelectionPreview",
  components: {
    GlButton,
    TimeZonesComponent
  },
  props: {
    packageId: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapMutations("packageBulkContributions", [
      "clearContributions",
      "removeContribution"
    ]),
    removeItem(id) {
      this.removeContribution({
        packageId: this.packageId,
        contributionId: id
      });
    },
    clearList() {
      this.clearContributions(this.packageId);
    }
  },
  computed: {
    ...mapGetters("packageBulkContributions", ["validatedContributions"]),
    bulkContributions() {
      return this.validatedContributions(this.packageId);
    }
  },
  data() {
    return {
      invalidData: false,
      BULK_DELIVERY
    };
  }
};
</script>
<style scoped lang="scss">
.bulk_delivery {
  margin-top: 2.8rem;
  h4 {
    margin-bottom: 0.8rem;
  }
}

.bulk_list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
  padding: 0;
  margin: 0;
  border: 1px solid $gray-200;

  li {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;
    border-bottom: 1px solid $gray-200;
    padding: 0.8rem;
  }

  h5 {
    font-size: 14px;
  }
  .dates {
    display: flex;
    gap: 2.1rem;
    font-size: 12px;
  }
}

.buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: right;
  gap: 1.2rem;
}

.removeItem {
  display: flex;
  align-items: center;
}

$warning-bg: #f5d9a8;
$warning-color: #8f4700;

.invalid {
  background-color: $warning-bg;
  color: $warning-color;
}
.invalid-text {
  margin-top: 1.2rem;
  color: $warning-color;
}
.title {
  display: flex;
  align-items: baseline;
  gap: 1.2rem;
  margin-bottom: 0.8rem;
}
.warning {
  background-color: $warning-color;
  color: white;
}
$error-color: #dc3545;
.error {
  background-color: $error-color;
  color: white;
}
.single-source {
  background-color: #40b86a;
}
.multi-source {
  background-color: #24663b;
  color: white;
}
</style>
