<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <gl-form-group
          label="Contribution Type"
          label-size="sm"
          label-for="type"
        >
          <gl-form-select
            id="type"
            v-model="itemUnderEdit.contributionType"
            ref="type"
            @change="onContributionTypeChange()"
            :options="contributionTypes"
            required
            :disabled="isUpdate"
            class="mt-1"
          />
        </gl-form-group>
        <Description
          :labelName="labelName"
          @change="onDescriptionChange"
          :valueName="itemUnderEdit.name"
          :valueDesc="itemUnderEdit.description"
        />
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlFormSelect, GlForm, GlFormGroup } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import Description from "@/components/Description";
import { ALIAS_ITEMS_CONTRIBUTION, ITEMS_CONTRIBUTIONS } from "@/constants.js";
import currentItem from "@/mixins/currentItem";
import contributionApiClient from "@/mixins/contributionApiClient";
const { EDIT } = ITEMS_CONTRIBUTIONS;
const { ADD_SECOND, EDIT_SECOND } = ALIAS_ITEMS_CONTRIBUTION;

export default {
  name: "AddFirst",
  mixins: [currentItem, contributionApiClient],
  components: {
    Wizard,
    GlFormSelect,
    GlForm,
    GlFormGroup,
    Description
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.$route && this.$route.query.sourceType) {
      this.itemUnderEdit.source0Id = this.$route.query.sourceType;
    }
    if (this.$route && this.$route.query.contributionType) {
      this.itemUnderEdit.contributionType = this.$route.query.contributionType;
    }
  },
  computed: {
    isDescFulfilled() {
      return (
        !!this.currentItem.name &&
        !!this.currentItem.description &&
        !!this.currentItem.contributionType
      );
    }
  },
  data() {
    return {
      labelName: "Contribution name",
      leftSectionTitle: `Description`,
      buttons: [{ text: `Next Step`, type: `submit` }]
    };
  },
  methods: {
    onContributionTypeChange() {
      if (this.itemUnderEdit.contributionType === "event_contribution") {
        this.itemUnderEdit.isOpenEnded = false;
      }
    },
    nextStep() {
      if (this.isDescFulfilled) {
        if (this.isUpdate) {
          this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`);
        } else {
          this.$router.push(ADD_SECOND);
        }
      }
    },
    onDescriptionChange(data) {
      const { name, description } = data;
      this.itemUnderEdit.name = name;
      this.itemUnderEdit.description = description;
    }
  }
};
</script>
