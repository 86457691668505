<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <!-- <gl-dropdown
          text="Bulk actions"
          class="mr-3"
          :disabled="bulkContextMenuItems.length === 0"
        >
          <gl-dropdown-item
            v-for="item in bulkContextMenuItems"
            :key="item.key"
            @click="contextMenuClick(selectedRows, item.id)"
            >{{ item.text }}</gl-dropdown-item
          >
        </gl-dropdown> -->
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
          @searchPhraseChanged="onSearchPhraseChanged"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'name'">
              <span class="cellTitle">{{ r.name }}</span>
            </div>
            <RolesCell
              v-else-if="field === 'role'"
              :roles="[r.role]"
              class="l"
            />
            <div v-if="field === 'subscribed'">
              <strong
                :class="{
                  'text-success': r.subscribed,
                  'text-danger': !r.subscribed
                }"
                >{{ r.subscribed ? "Yes" : "No" }}</strong
              >
            </div>
          </template>
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import dateUtils from "@/mixins/dateUtils";
import RolesCell from "@/components/DataGrid/RolesCell.vue";
import { userMgmtApiClient } from "@/mixins/apiClients";
import { ITEMS_USER_MGMT } from "@/constants.js";
import debouncedInput from "@/mixins/debouncedInput.js";
import userInfoMixin from "@/mixins/userInfo";
const { EDIT_USER } = ITEMS_USER_MGMT;

import {
  // GlDropdownItem,
  // GlDropdown,
  GlForm,
  GlSearchBoxByType
} from "@gitlab/ui";

export default {
  name: "YourAll",
  mixins: [dateUtils, debouncedInput, userMgmtApiClient, userInfoMixin],
  components: {
    ArqDataGrid,
    RolesCell,
    ArqDataGridDetailsPane,
    // GlDropdown,
    // GlDropdownItem,
    GlForm,
    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  created() {
    this.orgId = this.$route.query.org;
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  methods: {
    onSearchPhraseChanged(newSearchPhrase) {
      this.searchPhrase = newSearchPhrase;
    },
    async contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT_USER}/${row.email}`);
          break;
        case `unsubscribe`:
          await this.unsubscribe(row);
          break;
        case `subscribe`:
          await this.subscribe(row);
          break;
        case `delete`:
          await this.delete(row);
          this.onDataNeeded(this.sortModel, this.pageModel, this.searchPhrase);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.userMgmtApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query.filterBy(phrase);
      if (this.orgId) {
        query.param("organisationId", this.orgId);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        d.items.forEach(
          user => (user.name = `${user.givenName} ${user.familyName}`)
        );
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    },
    async subscribe(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Please confirm you would like to subscribe user "${row.email}" to emails`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.userMgmtApiClient.patch(row.id + "/subscribe");
          row.subscribed = true;
          this.handleApiSuccess(`The user ${row.email} has been subscribed`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async unsubscribe(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Please confirm you would like to unsubscribe user "${row.email}" from emails`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.userMgmtApiClient.patch(row.id + "/unsubscribe");
          row.subscribed = false;
          this.handleApiSuccess(`The user ${row.email} has been unsubscribed`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async delete(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${row.email}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.userMgmtApiClient.remove(row.email);
          this.handleApiSuccess(`The user ${row.email} has been deleted`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = [
            this.contextMenuItems.find(item => item.id === "update")
          ];
          if (row.subscribed) {
            out[row.id].push(
              this.contextMenuItems.find(item => item.id === "unsubscribe")
            );
          } else {
            out[row.id].push(
              this.contextMenuItems.find(item => item.id === "subscribe")
            );
          }
          if (row.email !== this.userInfo.attributes.email) {
            out[row.id].push(
              this.contextMenuItems.find(item => item.id === "delete")
            );
          }
        }
      }
      return out;
    },

    bulkContextMenuItems() {
      const bulkContextMenu = [];
      return bulkContextMenu;
    }
  },
  data() {
    return {
      orgId: undefined,
      sortModel: undefined,
      pageModel: undefined,
      searchPhrase: "",
      tempSearch: "",
      tempArray: [],
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        },
        {
          id: "unsubscribe",
          text: "Unsubscribe from emails"
        },
        {
          id: "subscribe",
          text: "Subscribe to emails"
        },
        {
          id: "delete",
          text: "Delete user"
        }
      ],
      columns: [
        {
          text: "Name",
          field: "name",
          sortField: "familyName",
          isSortable: true,
          clicked: row => {
            this.$router.push(`${EDIT_USER}/${row.email}`);
          }
        },
        {
          text: "Email",
          field: "email",
          isSortable: true
        },
        {
          text: "Roles",
          field: "role"
        },
        {
          text: "Subscribed",
          field: "subscribed"
        }
      ],
      detailsColumns: [
        {
          text: "Family name",
          field: "familyName"
        },
        {
          text: "Given name",
          field: "givenName"
        },
        {
          text: "Email",
          field: "email"
        }
      ]
    };
  }
};
</script>
