import HttpClient from "@/classes/HttpClient.js";
import ApiUrl from "@/classes/ApiUrlBuilder.js";

class ResourceApiClient {
  static MAX_PER_PAGE = 100;
  constructor(baseUrl, resourceKind, storageProvider = window.localStorage) {
    this.resourceKind = resourceKind;
    this.baseUrl = baseUrl;
    this.storageProvider = storageProvider;
    this.apiClient = new HttpClient(this.baseUrl, this.storageProvider);
  }
  heartbeat() {
    return this.apiClient.get("heartbeat");
  }
  list() {
    const path = this.resourceKind;
    return new ApiUrl(this.baseUrl + path, this.apiClient);
  }
  getById(id, prop = undefined) {
    let path = `${this.resourceKind}/${id}`;
    if (prop && prop !== "") {
      path += `/${prop}`;
    }
    return this.apiClient.get(path).then(r => r.data);
  }
  getByCustomParam(param, id) {
    const path = `${this.resourceKind}?${param}=${id}`;
    return this.apiClient.get(path).then(r => r.data);
  }
  getCustomPath(id, customPath, body) {
    const path = `${this.resourceKind}/${id}/${customPath}`;
    return this.apiClient.get(path, JSON.stringify(body)).then(r => r.data);
  }
  create(resource) {
    const path = this.resourceKind;
    return this.apiClient
      .post(path, JSON.stringify(resource))
      .then(r => r.data);
  }
  update(id, resource) {
    const path = `${this.resourceKind}/${id}`;
    return this.apiClient.put(path, JSON.stringify(resource)).then(r => r.data);
  }
  remove(id, addPath = null) {
    let path = `${this.resourceKind}/${id}`;
    if (addPath) {
      path += `/${addPath}`;
    }
    return this.apiClient.delete(path).then(r => r.data);
  }
  archive(id) {
    const path = `${this.resourceKind}/${id}/archive`;
    return this.apiClient.post(path).then(r => r.data);
  }
  postCustomPath(id, customPath, body) {
    const path = `${this.resourceKind}/${id}/${customPath}`;
    return this.apiClient.post(path, JSON.stringify(body)).then(r => r.data);
  }

  post(url, body) {
    const path = `${this.resourceKind}/${url}`;
    return this.apiClient.post(path, JSON.stringify(body), true);
  }

  put(url, resource, { selfValidate = false }) {
    const path = `${this.resourceKind}/${url}`;
    return this.apiClient.put(path, JSON.stringify(resource), selfValidate);
  }

  putImage(id, customPath, body) {
    const path = `${this.resourceKind}/${id}/${customPath}`;
    return this.apiClient.putImage(path, body).then(r => r.data);
  }
  patch(id, body) {
    const path = `${this.resourceKind}/${id}`;
    return this.apiClient.patch(path, JSON.stringify(body)).then(r => r.data);
  }

  putCustomPath(id, customPath, body) {
    const path = `${this.resourceKind}/${id}/${customPath}`;
    return this.apiClient.put(path, JSON.stringify(body)).then(r => r.data);
  }
}

export default ResourceApiClient;
