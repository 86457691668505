<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3 mb-3">{{ pageTitle }}</h1>
    <Add />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Add from "@/components/StreamProfile/Add";
import { MAIN_ITEMS, ITEMS_STREAM_PROFILE } from "@/constants.js";
const { ADD } = ITEMS_STREAM_PROFILE;

export default {
  components: { Add, Breadcrumb },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Create Stream Profile`, href: ADD }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Create Stream Profile`
    };
  }
};
</script>
