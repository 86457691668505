import Organizations from "@/views/UserManagement/Organizations.vue";
import AddOrg from "@/views/UserManagement/AddOrg.vue";
import AddVirtualConnectStream from "@/views/UserManagement/AddVirtualConnectStream.vue";
import AddUser from "@/views/UserManagement/AddUser.vue";
import Users from "@/views/UserManagement/Users.vue";
import { OrganizationsAll } from "@/components/UserManagement";
import { UsersAll } from "@/components/UserManagement";
import { ITEMS_USER_MGMT, ALIAS_ITEMS_USER_MGMT } from "@/constants.js";

const {
  ORGANIZATIONS,
  USERS,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  EDIT_USER,
  ADD_VIRTUAL_CONNECT_STREAM
} = ITEMS_USER_MGMT;

const {
  ORGANIZATIONS_ALL,
  ADD_ORGANIZATION_FIRST,
  EDIT_ORGANIZATION_FIRST,
  EDIT_ORGANIZATION_SECOND,
  ADD_ORGANIZATION_SECOND,
  EDIT_USER_FIRST,
  USERS_ALL,
  VIRTUAL_CONNECT_STREAMS_ALL
} = ALIAS_ITEMS_USER_MGMT;

const HOST = `UserMgmt`;

const userMgmt = [
  {
    path: ORGANIZATIONS,
    name: `${HOST}OrgAll`,
    redirect: ORGANIZATIONS_ALL,
    component: Organizations,
    children: [
      {
        path: ORGANIZATIONS_ALL,
        component: OrganizationsAll
      }
    ]
  },
  {
    path: ADD_ORGANIZATION,
    name: `${HOST}OrgAdd`,
    redirect: ADD_ORGANIZATION_FIRST
  },
  {
    path: ADD_ORGANIZATION_FIRST,
    name: `${HOST}OrgCreate`,
    component: AddOrg,
    alias: [ADD_ORGANIZATION_SECOND]
  },
  {
    path: `${EDIT_ORGANIZATION}/:id`,
    name: `${HOST}OrgEdit`,
    component: AddOrg,
    alias: [
      `${EDIT_ORGANIZATION}/:id/${EDIT_ORGANIZATION_FIRST}`,
      `${EDIT_ORGANIZATION}/:id/${EDIT_ORGANIZATION_SECOND}`,
      `${EDIT_ORGANIZATION}/:id/${VIRTUAL_CONNECT_STREAMS_ALL}`
    ]
  },
  {
    path: `${ADD_VIRTUAL_CONNECT_STREAM}/:organizationId/`,
    name: `${HOST}VirtualConnectStreamAdd`,
    component: AddVirtualConnectStream
  },
  {
    path: USERS,
    name: `${HOST}UsersAll`,
    redirect: USERS_ALL,
    component: Users,
    children: [
      {
        path: USERS_ALL,
        component: UsersAll
      }
    ]
  },
  {
    path: `${EDIT_USER}/:id`,
    name: `${HOST}UserEdit`,
    component: AddUser,
    alias: [`${EDIT_USER}/:id/${EDIT_USER_FIRST}`]
  }
];

export default userMgmt;
