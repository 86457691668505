<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <gl-button variant="confirm" class="mr-3" :href="toCreate"
          >Create VisualConnect Stream</gl-button
        >
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          @input="debounceInput"
          v-bind:value="searchPhrase"
          type="text"
          placeholder="Search or filter"
        />
      </gl-form>
    </div>
    <b-row>
      <b-col cols="8" class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="true"
          @rowSelected="onRowSelected"
          :contextMenuItems="contextMenuItemsByRow"
          @contextMenuClick="contextMenuClick"
        >
        </ArqDataGrid>
      </b-col>
      <b-col cols="4">
        <ArqDataGridDetailsPane :columns="detailsColumns" :row="selectedRow" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import ArqDataGridDetailsPane from "@/components/DataGrid/DataGridDetailsPane.vue";
import dateUtils from "@/mixins/dateUtils";
import { virtualConnectStreamApiClient } from "@/mixins/apiClients";
import { ITEMS_USER_MGMT } from "@/constants.js";
import debouncedInput from "@/mixins/debouncedInput.js";
import currentItem from "@/mixins/currentItem";
const { ADD_VIRTUAL_CONNECT_STREAM } = ITEMS_USER_MGMT;

import { GlForm, GlButton, GlSearchBoxByType } from "@gitlab/ui";

export default {
  name: "VIRTUAL_CONNECT_STREAMS_ALL",
  mixins: [
    currentItem,
    dateUtils,
    debouncedInput,
    virtualConnectStreamApiClient
  ],
  components: {
    ArqDataGrid,
    GlButton,
    ArqDataGridDetailsPane,
    GlForm,
    GlSearchBoxByType
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    async contextMenuClick(row, option) {
      switch (option) {
        case "delete":
          await this.delete(row);
          this.onDataNeeded(this.sortModel, this.pageModel, this.searchPhrase);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    async onDataNeeded(sortModel, pageModel, phrase) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.virtualConnectStreamApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query.filterBy(phrase);
      query.param("organisationId", this.currentItem.id);

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        d.items.forEach(
          item => (item.virtualconnectSiteName = item.virtualconnectSite.name)
        );
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    },
    async delete(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${row.id}?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.virtualConnectStreamApiClient.remove(row.id);
          this.handleApiSuccess(
            `The Virtual Connnect Stream ${row.id} has been deleted`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = this.contextMenuItems;
        }
      }
      return out;
    },
    bulkContextMenuItems() {
      const bulkContextMenu = [];
      return bulkContextMenu;
    }
  },
  data() {
    return {
      sortModel: undefined,
      pageModel: undefined,
      toCreate: `#${ADD_VIRTUAL_CONNECT_STREAM}/${this.currentItem.id}`,
      searchPhrase: "",
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      contextMenuItems: [
        {
          id: "delete",
          text: "Delete"
        }
      ],
      columns: [
        {
          text: "Stream ID",
          field: "id",
          isSortable: true
        },
        {
          text: "Site Name",
          field: "virtualconnectSiteName",
          isSortable: true
        }
      ],
      detailsColumns: [
        {
          text: "Stream ID",
          field: "id"
        },
        {
          text: "Site ID",
          field: "virtualconnectSiteName"
        }
      ]
    };
  }
};
</script>
