<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_PACKAGES,
  ITEMS_PACKAGES
} from "@/constants.js";

const { BROWSE_ALL } = ALIAS_ITEMS_PACKAGES;
const { YOUR } = ITEMS_PACKAGES;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [{ title: `All packages`, subPageName: BROWSE_ALL }];
    }
  },
  data() {
    return {
      pageTitle: `Browse Other Packages`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Packages`, href: YOUR },
        { text: `Browse`, href: BROWSE_ALL }
      ]
    };
  }
};
</script>
