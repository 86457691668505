<template>
  <table class="drawer">
    <thead class="drawerRow" v-if="showHeader">
      <th></th>
      <th
        v-for="column in columns"
        :key="column.text"
        :class="{
          'text-center': column.center,
          'text-right': column.right
        }"
      >
        {{ column.text }}
      </th>
      <th class="min-h" v-if="showContextMenu"></th>
    </thead>
    <tbody v-if="rows.length === 0">
      <tr>
        <td :colspan="columns.length + 2" class="text-center">
          No data for this query...
        </td>
      </tr>
    </tbody>
    <tbody class="drawerRow" v-else>
      <tr
        :id="`row_${index}`"
        v-for="(row, index) in rows"
        :key="row.data.id"
        :class="{ 'selected-row': row.selected }"
      >
        <td class="min-h">
          <input
            v-if="selectable"
            type="checkbox"
            v-model="row.selected"
            @change="selectRow(row)"
          />
        </td>
        <td
          v-for="column in columns"
          :key="column.text"
          :class="{
            'text-right': column.right
          }"
        >
          <slot name="drawerSlot" :row="row.data" :field="column.field">
            {{ row.data[column.field] }}
          </slot>
        </td>
        <td
          class="kebab clickable"
          @click.prevent.stop="contextMenuClick($event, row.data)"
          v-if="showContextMenu"
        >
          <gl-icon name="ellipsis_v"></gl-icon>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "ArqDataGridDrawer",
  props: {
    items: Array,
    columns: Array,
    deselectAll: Boolean,
    showContextMenu: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    deselectAll: {
      handler(val) {
        if (val === true) {
          for (const r of this.rows) {
            r.selected = false;
          }
        }
      }
    }
  },
  methods: {
    contextMenuClick($event, data) {
      this.$emit("contextMenuClick", $event, data);
    },
    selectRow(row) {
      this.$emit(
        "rowSelected",
        row.data,
        this.rows.filter(r => r.selected)
      );
    }
  },
  created() {
    this.rows = this.items.map(row => {
      return {
        selected: false,
        data: row
      };
    });
    this.showHeader =
      this.columns.find(c => c.text) !== undefined || this.showContextMenu;
  },
  data() {
    return {
      rows: [],
      showHeader: false
    };
  }
};
</script>
