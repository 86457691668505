<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div v-if="!isLoading" class="mx-3 my-1">
      <Breadcrumb :items="breadcrumbItems" />
      <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
      <gl-tabs>
        <gl-tab
          v-for="(item, index) in tabsItems"
          :key="`tab${index}`"
          @click="openTab(item.subPageName, item.subComponent, item.data)"
          :active="isSelected(item.subPageName)"
          :disabled="item.disabled"
        >
          <template slot="title">
            <span>{{ item.title }}</span>
            <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge"
              >{{ item.badge }}
            </gl-badge>
          </template>
        </gl-tab>
      </gl-tabs>
      <component
        v-bind:is="currentComponent"
        :currentItem="currentItem"
        :isUpdate="isEdit"
        @create="sourceCreated"
        @redirect="redirect"
        @redirectToCreateContribution="redirectToCreateContribution"
        @goToStep3="goToStep3"
      ></component>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_SOURCE,
  ITEMS_SOURCES,
  SOURCE_TYPES,
  ALIAS_ITEMS_CONTRIBUTION
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import sourceApiClient from "@/mixins/sourceApiClient.js";
import { AddFirst, AddSecond, AddThird, AddFourth } from "@/components/Sources";
import * as eq from "deep-equal";
import { GlTabs, GlTab } from "@gitlab/ui";
import Loading from "@/components/Loading.vue";

const { EDIT, SHOW, YOUR } = ITEMS_SOURCES;
const {
  ADD_FIRST,
  ADD_SECOND,
  ADD_THIRD,
  ADD_FOURTH,
  ALL,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_THIRD,
  EDIT_FOURTH
} = ALIAS_ITEMS_SOURCE;

export default {
  components: { Loading, Breadcrumb, GlTabs, GlTab },
  mixins: [wizardGuard, sourceApiClient],
  methods: {
    handleKey(evt) {
      if (evt.code === "Escape" && this.currentItem.id) {
        this.goToShow();
      }
      if (evt.code === "Escape" && !this.currentItem.id) {
        this.goToList();
      }
    },
    goToShow() {
      this.$router.push(`${SHOW}/${this.currentItem.id}`);
    },
    goToList() {
      this.$router.push(`${YOUR}`);
    },
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    sourceCreated() {
      this.created = true;
      this.isEdit = true;
      this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_FOURTH}`);
    },
    redirect(type) {
      this.isFormValid = false;
      this.isEdit = false;
      this.created = true;
      if (type === "new") {
        this.currentItem = JSON.parse(JSON.stringify(this.defaultCurrentItem));
        this.originalItem = undefined;
        this.$router.push(ADD_FIRST);
      } else if (type === "clone") {
        this.currentItem.name += " (copy)";
        this.currentItem.id = undefined;
        this.currentItem = JSON.parse(JSON.stringify(this.currentItem));
        this.$router.push({
          name: `SourcesCreate`,
          params: { cloning: this.currentItem }
        });
      } else if (type === "redundancy") {
        this.currentItem.name += " (redundancy)";
        this.currentItem.id = undefined;
        this.currentItem.isRedundancy = true;
        this.currentItem = JSON.parse(JSON.stringify(this.currentItem));
        this.$router.push({
          name: `SourcesCreate`,
          params: { cloning: this.currentItem }
        });
      }
      this.created = false;
    },
    redirectToCreateContribution(contributionType) {
      this.created = true;
      this.$router.push(
        ALIAS_ITEMS_CONTRIBUTION.ADD_FIRST +
          `?contributionType=${contributionType}&sourceType=` +
          this.currentItem.id
      );
    },
    goToStep3(itemUnderEdit) {
      this.isFormValid = true;
      this.currentItem = JSON.parse(JSON.stringify(itemUnderEdit));
      if (this.isEdit) {
        this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_THIRD}`);
      } else {
        this.$router.push(ADD_THIRD);
      }
    }
  },
  created() {
    if (this.$route.path === ADD_SECOND) {
      this.$router.push(ADD_FIRST);
    }
    if (this.$route.params.id) {
      this.isEdit = true;
      const id = this.$route.params.id;
      this.sourceApiClient.getById(id).then(source => {
        source.cryptoAlgo = source.cryptoAlgo || null;
        source.sourceType =
          source.protocol === "rist" ? SOURCE_TYPES.RIST : source.sourceType;
        this.currentItem = source;
        this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
        this.isFormValid = true;
        this.tabsItems.forEach(tab => {
          if (this.isSelected(tab.subPageName)) {
            this.openTab(tab.subPageName, tab.subComponent, tab.data);
          }
        });
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    } else {
      if (this.$route.params && this.$route.params.cloning) {
        this.currentItem = this.$route.params.cloning;
      } else {
        this.currentItem = JSON.parse(JSON.stringify(this.defaultCurrentItem));
      }
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name &&
          this.currentItem.description &&
          this.currentItem.availabilityZone;
      },
      deep: true
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Sources`, href: ALL },
        { text: this.isEdit ? "Edit" : "Create new", href: ADD_FIRST }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `1. Description`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FIRST}`
            : ADD_FIRST,
          subComponent: AddFirst
        },
        {
          title: `2. Specification`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`
            : ADD_SECOND,
          subComponent: AddSecond,
          disabled: !this.isDescFulfilled
        },
        {
          title: `3. Generate`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_THIRD}`
            : ADD_THIRD,
          subComponent: AddThird,
          disabled: !this.isFormValid
        },
        {
          title: `4. Distribute`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FOURTH}`
            : ADD_FOURTH,
          subComponent: AddFourth,
          disabled: !this.isEdit
        }
      ];
    },
    pageTitle() {
      return this.isEdit ? "Edit Source" : `Create Source`;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      isEdit: false,
      isLoading: true,
      selectedTab: null,
      currentItem: {},
      defaultCurrentItem: {
        name: null,
        description: null,
        availabilityZone: null,
        streamProfileId: undefined,
        maxBitrateBps: null,
        sourceType: undefined,
        cryptoAlgo: null,
        status: "ENABLED",
        accessLevel: "user"
      },
      originalItem: undefined,
      isDescFulfilled: false,
      isSpecFulfilled: false,
      isFormValid: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created && !this.isFormValid) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  }
};
</script>
