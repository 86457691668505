<template>
  <gl-form @submit.prevent="generate">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <smart-select
          :showSelected="false"
          :dataSource="contributionApiClient"
          :selectedLimit="100"
          :selected="itemUnderEdit.contributions"
          @itemSelected="addContribution"
          controlType="checkbox"
        ></smart-select>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import { contributionApiClient, packageApiClient } from "@/mixins/apiClients";
import currentItem from "@/mixins/currentItem";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
export default {
  name: "AddFourth",
  mixins: [currentItem, contributionApiClient, packageApiClient],
  components: {
    Wizard,
    GlForm,
    SmartSelect
  },
  data() {
    return {
      leftSectionTitle: `Contributions`,
      contributions: [],
      isReady: true
    };
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: this.isUpdate ? `Update Package` : `Generate Package`,
          type: `submit`,
          disabled: !this.isReady
        }
      ];
    }
  },
  methods: {
    addContribution(contributions) {
      this.itemUnderEdit.contributions = contributions;
    },
    async generate() {
      this.isBusy = true;
      const { name, description, visibility } = this.itemUnderEdit;
      const newPackage = { name, description, visibility };
      let msg = "";
      let packageId = null;
      try {
        msg = `The package "${name}" was created successfully.`;
        if (this.isUpdate) {
          packageId = this.itemUnderEdit.id;
          await this.packageApiClient.updateContributions(
            packageId,
            this.itemUnderEdit.contributions
          );
          await this.packageApiClient.updateEntitlements(
            packageId,
            this.itemUnderEdit.entitlements
          );
          await this.packageApiClient.update(packageId, this.itemUnderEdit);
          msg = `The package "${name}" was updated successfully.`;
        } else {
          const createdPackage = await this.packageApiClient.create(newPackage);
          packageId = createdPackage.id;

          await this.packageApiClient.updateEntitlements(
            createdPackage.id,
            this.itemUnderEdit.entitlements
          );
          await this.packageApiClient.updateContributions(
            createdPackage.id,
            this.itemUnderEdit.contributions
          );
        }
        this.handleApiSuccess(msg);
        this.$emit("create", packageId);
      } catch (e) {
        this.handleApiError(e);
      } finally {
        this.isBusy = false;
      }
    }
  }
};
</script>
