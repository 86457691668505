<template>
  <div>
    <div class="my-2">
      <gl-form inline>
        <slot></slot>
      </gl-form>
    </div>
  </div>
</template>
<script>
import { GlForm } from "@gitlab/ui";

export default {
  name: "TableActions",
  components: {
    GlForm
  }
};
</script>
