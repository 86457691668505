<template>
  <ul class="timezones">
    <li>
      <date-time-component
        :date-time="dateTime"
        :time-zone="userInfo.favTimezone"
      />
      <button
        v-if="timezones.length > 0 && isAccordion"
        class="toggle"
        type="button"
        @click="toggleTimezones"
        v-bind:class="{ open: showAdditionalTimezones }"
        aria-label="toggle additional time zones"
      >
        <gl-icon name="chevron-down" />
      </button>
    </li>
    <template v-if="this.showAdditionalTimezones">
      <li v-for="timezone in timezones" :key="timezone">
        <date-time-component :date-time="dateTime" :time-zone="timezone" />
      </li>
    </template>
  </ul>
</template>
<style scoped lang="scss">
.timezones {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0;

  li {
    display: flex;
    gap: 0.8rem;
  }
}

.toggle {
  border: none;
  background-color: transparent;

  &.open {
    rotate: 180deg;
  }
}
</style>
<script>
import userInfo from "@/mixins/userInfo";
import DateTimeComponent from "@/components/Date/DateTime.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "TimeZonesComponent",
  components: {
    DateTimeComponent
  },
  data: function() {
    return {
      showAdditionalTimezones: !this.isAccordion
    };
  },
  methods: {
    toggleTimezones() {
      this.showAdditionalTimezones = !this.showAdditionalTimezones;
    }
  },
  mixins: [userInfo],
  props: {
    dateTime: {
      type: String
    },
    isAccordion: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    timezones() {
      const timezones = [];
      if (
        this.userInfo.optTimezone1 &&
        this.userInfo.optTimezone1 !== this.userInfo.favTimezone
      ) {
        timezones.push(this.userInfo.optTimezone1);
      }
      if (
        this.userInfo.optTimezone2 &&
        this.userInfo.optTimezone2 !== this.userInfo.favTimezone
      ) {
        timezones.push(this.userInfo.optTimezone2);
      }
      if (
        this.userInfo.favTimezone &&
        this.userInfo.favTimezone !== dayjs.tz.guess()
      ) {
        timezones.push(dayjs.tz.guess());
      }
      return timezones;
    }
  }
};
</script>
