<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="bradcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
    <Help />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Help from "@/components/Support/Help";
import { MAIN_ITEMS, ITEMS_SUPPORT } from "@/constants.js";
const { HELP } = ITEMS_SUPPORT;

export default {
  components: { Help, Breadcrumb },
  computed: {
    bradcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Support`, href: HELP }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Support`
    };
  }
};
</script>
