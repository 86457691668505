<template>
  <gl-breadcrumb :items="hashedItems">
    <template #separator>
      <gl-icon name="chevron-right" />
    </template>
  </gl-breadcrumb>
</template>

<script>
import { GlBreadcrumb, GlIcon } from "@gitlab/ui";
export default {
  name: "Breadcrumb",
  components: { GlBreadcrumb, GlIcon },
  props: {
    items: {
      type: Array,
      default: () => {
        return [{ text: `Arqade`, href: "#" }];
      }
    }
  },
  computed: {
    hashedItems() {
      return this.items.map(x => {
        return { text: x.text, href: `#${x.href}` };
      });
    }
  }
};
</script>

<style lang="scss">
.gl-breadcrumb-item {
  color: $gray-500;
}
.gl-breadcrumb-item:last-child > a {
  color: var(--theme-color) !important;
}
</style>
