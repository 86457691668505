<template>
  <th>
    <span>Name</span>
    <gl-search-box-by-type
      @input="debounceInput"
      data-testid="search--q"
      class="search"
      v-bind:value="value"
      type="text"
      placeholder="Search or filter"
    />
  </th>
</template>
<script>
import { GlSearchBoxByType } from "@gitlab/ui";
import { debounce } from "debounce";

export default {
  name: "SearchFieldsSearch",
  components: {
    GlSearchBoxByType
  },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    debounceInput: debounce(function(value) {
      this.$emit("updateSearch", value);
    }, 300)
  }
};
</script>
<style scoped lang="scss">
.search {
  min-width: 10rem;
}

th > span {
  display: block;
  margin-bottom: 0.5rem;
}
</style>
