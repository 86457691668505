<template>
  <b-row>
    <b-col cols="8" class="border-right border-light">
      <div class="d-flex justify-content-between align-items-start mt-3">
        <h1>Bandwidth Report</h1>
        <gl-button-group class="mr-2">
          <gl-button
            :variant="groupBy === 'organisations' ? 'confirm' : 'default'"
            @click="groupBy = 'organisations'"
            :disabled="isBusy"
            >Sort by Organization</gl-button
          >
          <gl-button
            :variant="groupBy === 'regions' ? 'confirm' : 'default'"
            @click="groupBy = 'regions'"
            :disabled="isBusy"
            >Sort by Region</gl-button
          >
        </gl-button-group>
      </div>
      <div class="mt-3">
        <div class="d-flex justify-content-between mb-4">
          <div class="d-flex">
            <DateRange
              @dateRangeSet="rangeDateSet"
              :showTime="false"
              :startDate="
                startDate instanceof Date ? startDate.toISOString() : null
              "
              :endDate="endDate instanceof Date ? endDate.toISOString() : null"
              :disabledEnd="disabledEnd"
              :disabled="isBusy"
              :removeDateEndWhenDateStartIsEmpty="false"
            />
            <div class="mt-4 ml-3 d-flex">
              <gl-button
                @click="onDataNeeded()"
                variant="confirm"
                :disabled="!startDate || !endDate || isBusy"
                >Generate</gl-button
              >
            </div>
            <div v-show="isBusy" class="mt-4 ml-3">
              <gl-loading-icon label="Loading" size="lg" />
            </div>
          </div>
          <div v-show="totalBytes" class="d-flex align-items-end">
            <p class="mb-0">
              Total <strong>{{ totalBytes }}</strong>
            </p>
          </div>
        </div>
        <ArqDataGrid
          :data="data"
          :columns="columns"
          :subColumns="subColumns"
          @dataNeeded="onDataNeeded"
          :showPager="false"
          :selectable="false"
          :showFolderIcon="false"
        >
          <template v-slot:drawer="{ drawerRow: r, drawerField: field }">
            <div v-if="field === 'name'">
              <span class="bandwidthCeilHeader2">{{ r.name }}</span>
            </div>
            <div v-else-if="field === 'totalBytes'">
              <strong class="bandwidthCeilHeader2">{{ r.totalBytes }}</strong>
            </div>
          </template>
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'name'">
              <span class="bandwidthCeilHeader1">{{ r.name }}</span>
            </div>
            <div v-else-if="field === 'totalBytes'">
              <strong class="bandwidthCeilHeader1">{{ r.totalBytes }}</strong>
            </div>
          </template>
        </ArqDataGrid>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import DateRange from "@/components/Date/DateRange.vue";
import { reportApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon } from "@gitlab/ui";
import { GlButton, GlButtonGroup } from "@gitlab/ui";
import { BCol, BRow } from "bootstrap-vue";

export default {
  name: "Bandwidth",
  mixins: [reportApiClient],
  components: {
    ArqDataGrid,
    DateRange,
    GlLoadingIcon,
    GlButton,
    GlButtonGroup,
    BCol,
    BRow
  },
  methods: {
    rangeDateSet(event) {
      this.startDate = new Date(event.start);
      this.endDate = new Date(event.end);
      if (this.startDate && this.endDate) {
        this.onDataNeeded();
      }
    },
    async onDataNeeded() {
      this.isBusy = true;
      let d = this.getEmptyPagedResponse();
      let response;
      try {
        response = await this.getReportBandwidth(
          this.startDate,
          this.endDate,
          this.groupBy
        );
        d.items =
          this.groupBy === "organisations"
            ? response.data.organisations
            : response.data.regions;
        for (let item of d.items) {
          item.totalBytes = this.formatValue(item.totalBytes);
          if (this.groupBy === "organisations") {
            if (item.regions) {
              for (let region of item.regions) {
                region.totalBytes = this.formatValue(region.totalBytes);
              }
              item.children = item.regions;
            }
          } else {
            if (item.organisations) {
              for (let organization of item.organisations) {
                organization.totalBytes = this.formatValue(
                  organization.totalBytes
                );
              }
              item.children = item.organisations;
            }
          }
        }
        this.totalBytes = this.formatValue(response.data.totalBytes);
        this.data = JSON.parse(JSON.stringify(d));
        this.isBusy = false;
      } catch (e) {
        this.handleApiError(e);
      }
    },
    formatValue(val) {
      if (val > 1000000) {
        return (
          new Intl.NumberFormat(
            navigator ? navigator.language : "en-US"
          ).format(val / 1000000) + " Mb"
        );
      } else if (val > 1000) {
        return (
          new Intl.NumberFormat(
            navigator ? navigator.language : "en-US"
          ).format(val / 1000) + " kb"
        );
      }
      return val;
    }
  },
  created() {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    this.startDate = startDate;
  },
  watch: {
    groupBy: {
      handler() {
        this.onDataNeeded();
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          text: this.groupBy === "organisations" ? "Organization" : "Region",
          field: "name"
        },
        {
          text: "Bandwidth Mbs",
          field: "totalBytes",
          right: true
        }
      ];
    },
    subColumns() {
      return [
        {
          field: "name"
        },
        {
          field: "totalBytes",
          right: true
        }
      ];
    }
  },
  data() {
    const endDate = new Date(); // Current date
    const startDate = new Date(); // Create a new date object for the start date
    startDate.setMonth(endDate.getMonth() - 1); // Set the start date to one month before the current date

    return {
      startDate: startDate,
      endDate: endDate,
      disabledEnd: new Date(),
      isBusy: true,
      data: {},
      totalBytes: undefined,
      groupBy: "organisations"
    };
  }
};
</script>
<style scoped>
.bandwidthCeilHeader1 {
  font-weight: 700;
  font-size: 15px;
}
.bandwidthCeilHeader2 {
  font-weight: 700;
  font-size: 13px;
}
</style>
