<template>
  <div class="container-fluid w-100">
    <div class="row inlineDetailsRow p-3">
      <div class="col-md-3">
        <dl class="summary">
          <dt class="info-title">Description</dt>
          <dd data-testid="description">{{ item.description }}</dd>
          <dt class="info-title">Percentage transcodes delivered</dt>
          <dd data-testid="transcode_deliveries_percentage">
            {{ item.transcode_deliveries_percentage }}
          </dd>
          <template v-if="item.currentAlerts0">
            <dt class="info-title">Current Alerts (Contribution 1)</dt>
            <dd data-testid="currentAlerts0">
              <b-badge
                v-for="alert in item.currentAlerts0"
                :key="alert"
                variant="danger"
                class="status-badge l"
              >
                {{ alert }}
              </b-badge>
            </dd>
          </template>

          <template v-if="item.allTimeAlerts0">
            <dt class="info-title">Historic Alerts (Contribution 1)</dt>
            <dd data-testid="allTimeAlerts0">
              <b-badge
                v-for="alert in item.allTimeAlerts0"
                :key="alert"
                variant="secondary"
                class="status-badge l"
              >
                {{ alert }}
              </b-badge>
            </dd>
          </template>

          <template v-if="item.currentAlerts1">
            <dt class="info-title">Current Alerts (Contribution 2)</dt>
            <dd data-testid="currentAlerts1">
              <b-badge
                v-for="alert in item.currentAlerts1"
                :key="alert"
                variant="danger"
                class="status-badge l"
              >
                {{ alert }}
              </b-badge>
            </dd>
          </template>

          <template v-if="item.allTimeAlerts1">
            <dt class="info-title">Historic Alerts (Contribution 2)</dt>
            <dd data-testid="allTimeAlerts1">
              <b-badge
                v-for="alert in item.allTimeAlerts1"
                :key="alert"
                variant="secondary"
                class="status-badge l"
              >
                {{ alert }}
              </b-badge>
            </dd>
          </template>

          <template v-if="item.transcode_deliveries">
            <dt class="info-title">Total transcodes</dt>
            <dd data-testid="transcode_deliveries">
              {{ item.transcode_deliveries }}
            </dd>
          </template>

          <template v-if="item.total_deliveries">
            <dt class="info-title">Total deliveries</dt>
            <dd data-testid="total_deliveries">{{ item.total_deliveries }}</dd>
          </template>
        </dl>
      </div>
      <div class="col-md-6 ">
        <div class="timezones">
          <div class="timezonesTitle">
            <p class="info-title">Your times</p>
          </div>
          <div class="times">
            <div>
              <h3>Start</h3>
              <time-zones-component :date-time="item.plannedStart" />
            </div>
            <div>
              <h3>End</h3>
              <span v-if="item.isOpenEnded">On-Going</span>
              <time-zones-component v-else :date-time="item.plannedEnd" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <dl class="summary">
          <div v-for="(sourceData, index) in sources" :key="index">
            <dt class="info-title">Source {{ index }}</dt>
            <dd data-testid="source">
              <table class="summary_table">
                <tbody>
                  <tr>
                    <th class="info-key">Name</th>
                    <td class="info-value link">
                      <a :href="getSourceHref(sourceData.source.id)">{{
                        sourceData.source.name
                      }}</a>
                    </td>
                  </tr>
                  <tr>
                    <th class="info-key">Price</th>
                    <td class="info-value">
                      ${{ roundDecimals(sourceData.source.quotedPrice) }}/hour
                    </td>
                  </tr>
                  <tr>
                    <th class="info-key">Hours</th>
                    <td class="info-value">
                      {{ roundDecimals(hours) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="info-key">Total</th>
                    <td class="info-value">
                      ${{ roundDecimals(sourceData.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import TimeZonesComponent from "@/components/Date/TimeZones.vue";
import SourceApiClient from "@/mixins/sourceApiClient";
import dateUtils from "@/mixins/dateUtils";
import { ITEMS_SOURCES } from "@/constants";

const { SHOW: sourceShowUrl } = ITEMS_SOURCES;

export default {
  name: "ItemSummary",
  components: {
    TimeZonesComponent
  },
  mixins: [dateUtils, SourceApiClient],
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      sources: [],
      hours: 0
    };
  },
  created() {
    this.getSourceDetails();
  },
  methods: {
    roundDecimals(price) {
      return parseFloat(price).toFixed(2);
    },
    async getSourceDetails() {
      this.hours = this.calculateHours(
        this.item.plannedStart,
        this.item.plannedEnd
      );
      const source0 = await this.sourceApiClient.getById(this.item.source0Id);
      const total0 = this.hours * source0.quotedPrice;
      this.sources.push({ source: source0, total: total0 });

      if (this.item.source1Id) {
        const source1 = await this.sourceApiClient.getById(this.item.source1Id);
        const total1 = this.hours * source1.quotedPrice;
        this.sources.push({ source: source1, total: total1 });
      }
    },
    getSourceHref(id) {
      return `#${sourceShowUrl}/${id}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.summary {
  dt {
    margin-bottom: 0.4rem;
    width: 100%;
  }

  dd {
    margin-bottom: 1.2rem;
  }

  .summary_table tbody {
    background-color: transparent;

    tr {
      height: auto;
    }
  }

  .summary_table tbody td,
  .summary_table tbody th {
    border: none;
    padding: 0.2rem;
    vertical-align: top;
    height: auto;
  }

  .summary_table tbody td {
    padding-left: 0.4rem;
    font-size: 12px;
    font-weight: normal;
  }
}

.timezones {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
}

tr {
  border: none !important;
}
</style>
