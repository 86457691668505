<template>
  <ExpandableItem ref="expand">
    <template>
      <td>
        <span class="font-weight-bold">
          <span
            v-if="row.isStreamProfileLocked === 'Yes'"
            data-testid="stream_profile_locked"
            title="Delivery stream profile is locked"
          >
            <gl-icon name="lock"></gl-icon>
          </span>
          {{ row.contributionName }}
        </span>
        <div class="d-flex">
          <p class="mb-0 mr-3 linkToDestination" v-if="row.destinationName0">
            <a
              data-testid="destination_0_link"
              :href="'#' + SHOW_DESTINATION + '/' + row.destinationId0"
              class="text-body"
            >
              <DestinationLogo />
              {{ row.destinationName0 }}
            </a>
          </p>
          <p class="mb-0 linkToDestination" v-if="row.destinationName1">
            <a
              data-testid="destination_1_link"
              :href="'#' + SHOW_DESTINATION + '/' + row.destinationId1"
              class="text-body"
            >
              <DestinationLogo />
              {{ row.destinationName1 }}
            </a>
          </p>
        </div>
      </td>
      <td>
        <StatusCell
          :status="
            row.scheduleStatus === 'CANCELLED' ? row.scheduleStatus : row.state
          "
          class="l"
        />
        <AlertsBadge :resource="row" />
      </td>
      <td>
        <StatusCell v-if="checkAlert(row)" :status="'ALERT'" class="l" />
        <StatusCell v-else :status="'HEALTHY'" class="l" />
      </td>
      <DateTime :date="row.contributionPlannedStart" />
      <DateTime
        :date="row.contributionPlannedEnd"
        :isOpenEnded="row.isOpenEnded"
      />
      <td>
        <gl-icon
          v-if="row.contributionType === 'channel_contribution'"
          name="calendar"
          v-b-tooltip.hover
          title="Channel contribution"
          class="center"
        />
        <gl-icon
          v-if="row.contributionType === 'event_contribution'"
          name="clock"
          v-b-tooltip.hover
          title="Event contribution"
          class="center"
        />
      </td>
      <td>
        <gl-icon
          name="eye"
          class="center"
          v-if="row['previewStatus0'] !== 'STOPPED'"
        />
        <gl-icon
          name="eye"
          class="center"
          v-if="row['previewStatus1'] !== 'STOPPED'"
        />
      </td>
      <td>
        <gl-icon
          v-if="row.isTranscode"
          name="chevron-double-lg-right"
          class="center"
        />
      </td>
      <td>
        <ContextMenu>
          <li v-if="row.state === 'PENDING' || row.state === 'IN_PROGRESS'">
            <button class="context-button" @click="cancelDelivery(row)">
              Cancel
            </button>
          </li>
          <template v-if="row.destinationId0">
            <li
              v-if="
                row.previewStatus0 !== 'STARTING' &&
                  row.previewStatus0 !== 'STARTED'
              "
            >
              <button class="context-button" @click="requestPreview(0)">
                Request preview (pipeline 1)
              </button>
            </li>
            <li v-else>
              <button class="context-button" @click="requestPreview(0, true)">
                Extend preview (pipeline 1)
              </button>
            </li>
          </template>
          <template v-if="row.destinationId1">
            <li
              v-if="
                row.previewStatus1 !== 'STARTING' &&
                  row.previewStatus1 !== 'STARTED'
              "
            >
              <button class="context-button" @click="requestPreview(1)">
                Request preview (pipeline 2)
              </button>
            </li>
            <li v-else>
              <button class="context-button" @click="requestPreview(1, true)">
                Extend preview (pipeline 2)
              </button>
            </li>
          </template>
        </ContextMenu>
      </td>
    </template>
    <template v-slot:expanded>
      <ExpandedContent :deliveryDetails="row" />
    </template>
  </ExpandableItem>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import DateTime from "@/components/Table/TD/DateTime.vue";
import AlertsBadge from "@/components/DataGrid/AlertsBadge.vue";
import ExpandableItem from "@/components/Table/Expand/ExpandableItem.vue";
import { ITEMS_DESTINATIONS } from "@/constants.js";
import DestinationLogo from "@/components/DestinationIcon.vue";
import ExpandedContent from "./ExpandedContent";
import ContextMenu from "@/components/ContextMenu/ContextMenu";
import { GlIcon } from "@gitlab/ui";
import { deliveryApiClient } from "@/mixins/apiClients";
import msgUtils from "@/mixins/msgUtils.js";

const { SHOW } = ITEMS_DESTINATIONS;

export default {
  mixins: [msgUtils, dateUtils, userInfo, deliveryApiClient],
  components: {
    StatusCell,
    AlertsBadge,
    GlIcon,
    DestinationLogo,
    ExpandedContent,
    ContextMenu,
    ExpandableItem,
    DateTime
  },
  props: {
    row: {
      type: Object
    }
  },
  data() {
    return {
      contributionType: "",
      SHOW_DESTINATION: SHOW
    };
  },
  methods: {
    checkAlert(row) {
      return !!(
        (row.currentAlerts0 && row.currentAlerts0.length > 0) ||
        (row.currentAlerts1 && row.currentAlerts1.length > 0)
      );
    },
    async cancelDelivery(item) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to cancel "${item.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        try {
          await this.deliveryApiClient.patch(item.id, {
            scheduleStatus: "CANCELLED"
          });
          this.handleApiSuccess(
            `The delivery "${item.name}" has been cancelled.`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async requestPreview(index, extend = false) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to ${
          extend ? "extend" : "request"
        } preview for "${this.row.name}" (pipeline ${index + 1})?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        try {
          let url = `preview/${index}`;
          if (extend) {
            url += "/extend";
          }
          await this.deliveryApiClient.postCustomPath(this.row.id, url);
          this.handleApiSuccess(`The preview has been requested.`);
        } catch (e) {
          this.handleApiError(e);
        }
      }
    }
  }
};
</script>
<style scoped>
@import "~vue-context/dist/css/vue-context.css";
.linkToDestination {
  font-size: 11px;
}
.context-button {
  border: none;
  background-color: transparent;
  max-width: none;
}
.center {
  margin: auto;
  display: block;
}
</style>
