<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div v-if="!isLoading" class="mx-3 my-1">
      <Breadcrumb :items="breadcrumbItems" />
      <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
      <gl-tabs>
        <gl-tab
          v-for="(item, index) in tabsItems"
          :key="`tab${index}`"
          @click="openTab(item.subPageName, item.subComponent, item.data)"
          :active="isSelected(item.subPageName)"
          :disabled="item.disabled"
        >
          <template slot="title">
            <span>{{ item.title }}</span>
            <gl-badge v-if="item.badge" size="sm" class="gl-tab-counter-badge"
              >{{ item.badge }}
            </gl-badge>
          </template>
        </gl-tab>
      </gl-tabs>
      <component
        v-bind:is="currentComponent"
        :currentItem="currentItem"
        :isUpdate="isEdit"
        @create="contributionCreated"
        @redirect="redirect"
      ></component>
    </div>
  </div>
</template>
<script>
import {
  ALIAS_ITEMS_CONTRIBUTION,
  MAIN_ITEMS,
  ITEMS_CONTRIBUTIONS,
  CONTRIBUTION_EDIT_LEVELS
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import Breadcrumb from "@/components/Breadcrumb";
import contributionApiClient from "@/mixins/contributionApiClient.js";
import {
  AddFirst,
  AddSecond,
  AddThird,
  AddFourth
} from "@/components/Contributions";
import { GlTabs, GlTab } from "@gitlab/ui";
import userInfoMixin from "@/mixins/userInfo";

const {
  ADD_FIRST,
  ADD_SECOND,
  ADD_THIRD,
  ADD_FOURTH,
  EVENTS_ALL,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_THIRD,
  EDIT_FOURTH
} = ALIAS_ITEMS_CONTRIBUTION;
const { EDIT, EVENTS, SHOW } = ITEMS_CONTRIBUTIONS;
import * as eq from "deep-equal";
import Loading from "@/components/Loading.vue";

export default {
  mixins: [wizardGuard, contributionApiClient, userInfoMixin],
  components: { Loading, Breadcrumb, GlTabs, GlTab },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name && this.currentItem.description;
        this.isDepFulfilled = this.currentItem.isOpenEnded
          ? this.currentItem.plannedStart && this.currentItem.source0Id
          : this.currentItem.plannedStart &&
            this.currentItem.plannedEnd &&
            this.currentItem.source0Id;
      },
      deep: true
    }
  },
  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        if (this.isEdit) {
          this.$router.push(`${SHOW}/${this.currentItem.id}`);
        } else {
          this.$router.push(`${EVENTS}`);
        }
      }
    },

    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    contributionCreated(createdItem) {
      this.created = true;
      this.isEdit = true;
      this.$router.push(`${EDIT}/${createdItem.id}/${EDIT_FOURTH}`);
    },
    setEditLevel() {
      this.currentItem.editLevel = CONTRIBUTION_EDIT_LEVELS.NAME_DESC_ONLY;
      const now = new Date().getTime();
      const plannedStart = new Date(this.currentItem.plannedStart).getTime();
      const plannedEnd = new Date(this.currentItem.plannedEnd).getTime();
      // plannedStart in the future
      if (plannedStart > now) {
        this.currentItem.editLevel = CONTRIBUTION_EDIT_LEVELS.ALL;
      } else {
        // plannedStart in the past but plannedEnd in the future;
        if (plannedEnd > now) {
          this.currentItem.editLevel = CONTRIBUTION_EDIT_LEVELS.END_DATES;
        }
      }
    },
    redirect(type) {
      this.isEdit = false;
      this.created = true;
      if (type === "new") {
        this.currentItem = JSON.parse(JSON.stringify(this.defaultCurrentItem));
        this.originalItem = undefined;
        this.$router.push(ADD_FIRST);
      } else if (type === "clone") {
        this.currentItem.name += " (copy)";
        this.currentItem.id = undefined;
        this.currentItem = JSON.parse(JSON.stringify(this.currentItem));
        this.$router.push({
          name: `ContributionsAdd`,
          params: { cloning: this.currentItem }
        });
      }
      this.created = false;
    }
  },
  created() {
    if (this.$route.path === ADD_SECOND) {
      this.$router.push(ADD_FIRST);
    }
    if (this.$route.params.id) {
      this.isEdit = true;
      const id = this.$route.params.id;
      this.contributionApiClient.getById(id).then(contribution => {
        if (contribution.isOpenEnded) {
          contribution.plannedEnd = null;
        }
        if (
          contribution.accessLevel === "arqade_admin" &&
          !this.isArqadeAdminRoleActive
        ) {
          this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
          this.$router.push(ADD_FIRST);
        } else {
          this.currentItem = contribution;
          this.setEditLevel();
          this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
          this.tabsItems.forEach(tab => {
            if (this.isSelected(tab.subPageName)) {
              this.openTab(tab.subPageName, tab.subComponent, tab.data);
            }
          });
        }
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    } else {
      if (this.$route.params && this.$route.params.cloning) {
        this.currentItem = this.$route.params.cloning;
        this.currentItem.id = undefined;
        this.currentItem.scheduleStatus = "scheduled";
      } else {
        this.currentItem = JSON.parse(JSON.stringify(this.defaultCurrentItem));
      }
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Contributions`, href: EVENTS_ALL },
        { text: this.isEdit ? "Edit" : "Create new", href: ADD_FIRST }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `1. Description`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FIRST}`
            : ADD_FIRST,
          subComponent: AddFirst
        },
        {
          title: `2. Dependencies`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`
            : ADD_SECOND,
          subComponent: AddSecond,
          disabled: !this.isDescFulfilled
        },
        {
          title: `3. Generate`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_THIRD}`
            : ADD_THIRD,
          subComponent: AddThird,
          disabled: !this.isDepFulfilled
        },
        {
          title: `4. Distribute`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FOURTH}`
            : ADD_FOURTH,
          subComponent: AddFourth,
          disabled: !this.created && !this.isEdit
        }
      ];
    },
    pageTitle() {
      return this.isEdit ? "Edit Contribution" : `Create Contribution`;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      isEdit: false,
      isLoading: true,
      selectedTab: null,
      currentItem: {},
      originalItem: undefined,
      defaultCurrentItem: {
        name: null,
        description: null,
        plannedStart: null,
        plannedEnd: null,

        scheduleStatus: "scheduled"
      },
      isDescFulfilled: false,
      isDepFulfilled: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.created) {
      this.beforeRouteLeaveWithCondition(!this.isPristine, to, from, next);
    } else {
      next();
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  }
};
</script>
