<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <MinLatencyComponent
      :currentItem="currentItem"
      @change="onMinLatencyChange"
    ></MinLatencyComponent>
    <gl-form-group
      class="m-4"
      label="Destination Address"
      label-size="sm"
      label-for="destinationAddress"
      :invalid-feedback="invalidDestinationAddressFeedback()"
    >
      <gl-form-input
        type="text"
        id="destinationAddress"
        v-model="currentItem.destinationAddress"
        placeholder="IP or String"
        class="mt-1"
        :state="isDestinationAddressValid()"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="Port"
      label-size="sm"
      label-for="port"
      :invalid-feedback="
        isPortValid() ? '' : 'Please enter valid port: 1024-65535'
      "
    >
      <gl-form-input
        type="number"
        id="port"
        v-model="currentItem.port"
        placeholder="Port"
        maxlength="5"
        required
        class="mt-1"
        :state="isPortValid()"
        min="1024"
        max="65535"
      />
    </gl-form-group>
    <stream-id-component
      :currentItem="currentItem"
      @change="onStreamIdChange"
    ></stream-id-component>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import MinLatencyComponent from "@/components/Destinations/AddSecond/Specs/Sections/MinLatencyComponent.vue";
import StreamIdComponent from "@/components/Destinations/AddSecond/Specs/Sections/StreamIdComponent.vue";

export default {
  components: {
    StreamIdComponent,
    MinLatencyComponent,
    GlFormGroup,
    GlFormInput,
    RegionComponent
  },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    invalidDestinationAddressFeedback() {
      return this.isDestinationAddressValid()
        ? ""
        : "Please enter a valid destination address";
    },
    invalidPortFeedback() {
      return this.isPortValid()
        ? ""
        : "Please enter valid IP Port 1024-65535 (except 2077 & 2088)";
    },

    isDestinationAddressValid() {
      return !!this.currentItem.destinationAddress;
    },

    isDestinationValid() {
      switch (false) {
        case this.isDestinationAddressValid():
        case this.isMinLatencyValid:
        case this.isRegionValid:
        case this.isStreamIdValid:
        case this.isPortValid():
          return false;
        default:
          return true;
      }
    },
    isPortValid() {
      const port = parseInt(this.currentItem.port);
      return port !== 2077 && port !== 2088 && port >= 1024 && port <= 65535;
    },
    onMinLatencyChange(isValid) {
      this.isMinLatencyValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    },
    onStreamIdChange(isValid) {
      this.isStreamIdValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      isRegionValid: false,
      isMinLatencyValid: false,
      isStreamIdValid: false
    };
  },
  props: {
    currentItem: Destination
  },

  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
