<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<style>
.noTabs {
  .gl-tabs-nav {
    display: none !important;
  }
}
</style>

<script>
import MainSection from "@/components/MainSection";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_PACKAGES,
  ITEMS_PACKAGES
} from "@/constants.js";

const { YOUR_ALL } = ALIAS_ITEMS_PACKAGES;
const { YOUR } = ITEMS_PACKAGES;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [{ title: `Owned Packages`, subPageName: YOUR_ALL }];
    }
  },
  data() {
    return {
      pageTitle: `Owned Packages`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Packages`, href: YOUR },
        { text: `Owned Packages`, href: YOUR_ALL }
      ]
    };
  }
};
</script>
