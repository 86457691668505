<template>
  <div class="body">
    <div class="container">
      <b-navbar type="dark" variant="danger">
        <b-navbar-brand>
          <img
            src="../../assets/logo.png"
            alt="logo"
            style="width: 100px; height: 40px;"
          />
        </b-navbar-brand>
      </b-navbar>
      <RegistrationForm
        @submit="submit"
        v-if="!isSuccessful && !isErrorPresent"
      />
      <SuccessfulRegistration v-if="isSuccessful" />
      <RegistrationError v-if="isErrorPresent" :error-message="errorMessage" />
    </div>
  </div>
</template>

<script>
import AppConfig from "@/app-config";
import "@/assets/logo.png";
import axios from "axios";
import RegistrationForm from "@/components/AwsMarketplaceRegistration/RegistrationForm.vue";
import RegistrationError from "@/components/AwsMarketplaceRegistration/RegistrationError.vue";
import SuccessfulRegistration from "@/components/AwsMarketplaceRegistration/SuccessfulRegistration.vue";
export default {
  name: "AwsMarketplaceRegistration",
  components: {
    RegistrationForm,
    SuccessfulRegistration,
    RegistrationError
  },
  created() {
    if (this.$route.query["msg"]) {
      this.errorMessage = this.$route.query["msg"];
      this.isErrorPresent = true;
    }
  },
  methods: {
    submit(e) {
      const BASE_URL = AppConfig.instance.config.API_URLS.ENTITLEMENTS;
      const PATH = "aws-marketplace";
      const url = `${BASE_URL + PATH}`;
      const id = this.$route.path.split("/")[2];
      const updateBody = {
        userGivenName: e.userGivenName,
        userFamilyName: e.userFamilyName,
        email: e.email,
        phoneNumber: e.phoneNumber,
        website: e.website,
        orgName: e.orgName
      };

      axios
        .patch(`${url}/${id}`, updateBody)
        .then(response => {
          if (response.status === 200) {
            this.isSuccessful = true;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  data() {
    return {
      errorMessage: "Oops! Something went wrong. Please try again later.",
      isSuccessful: false,
      isErrorPresent: false
    };
  }
};
</script>

<style scoped lang="scss">
.body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.container {
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
</style>
