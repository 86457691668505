<template>
  <div class="mx-3 my-1">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3 mb-3">{{ pageTitle }}</h1>
    <Your />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Your from "@/components/StreamProfile/Your";
import { MAIN_ITEMS, ITEMS_STREAM_PROFILE } from "@/constants.js";
const { YOUR } = ITEMS_STREAM_PROFILE;

export default {
  components: { Your, Breadcrumb },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Stream Profiles`, href: YOUR }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Stream Profiles`
    };
  }
};
</script>
