var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',{staticClass:"mt-3"},[_vm._v("Packages")]),_c('gl-button',{attrs:{"icon":"ellipsis_v","category":"tertiary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.contextMenuClick()}}})],1),_c('div',{staticClass:"mt-4 opsPanel__packages"},[_c('gl-filtered-search',{staticClass:"mb-2",attrs:{"available-tokens":_vm.tokens},on:{"submit":_vm.activate,"clear":_vm.clear},scopedSlots:_vm._u([{key:"history-item",fn:function(ref){
var historyItem = ref.historyItem;
return [_vm._l((historyItem),function(token,idx){return [_c('span',{key:idx,staticClass:"px-1"},[_vm._v(" "+_vm._s(token.type)+" = "),_c('strong',[_vm._v(_vm._s(token.value))])])]})]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c('gl-loading-icon',{attrs:{"label":"Loading","size":"xl"}})],1),_c('ArqDataGrid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"data":_vm.data,"phrase":_vm.searchPhrase,"columns":_vm.columns,"itemsPerPage":_vm.itemsPerPage,"showContextMenu":true,"contextMenuItems":_vm.contextMenuItemsByRow,"showHeader":false,"selectOnClick":true},on:{"dataNeeded":_vm.onDataNeeded,"rowSelected":_vm.onRowSelected,"contextMenuClick":_vm.contextMenuClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var r = ref.row;
var field = ref.field;
return [(field === 'name')?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"monitoring_packages__status",class:{
                    'monitoring_packages__status--success': r.health === 'OK',
                    'monitoring_packages__status--warning':
                      r.health === 'WARN',
                    'monitoring_packages__status--danger': r.health === 'NOK'
                  }})]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"cellTitle",attrs:{"title":r.name}},[_vm._v(_vm._s(r.name))])]),_c('b-badge',{staticClass:"monitoring_packages__badge",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(r.contributions_count))])],1)]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }