import { ITEMS_DESTINATIONS } from "@/constants";

const { YOUR } = ITEMS_DESTINATIONS;
export default {
  methods: {
    async archive(row) {
      const value = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to archive "${row.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (value) {
        try {
          await this.destinationApiClient.postCustomPath(row.id, "archive");
          this.handleApiSuccess(
            `The destination ${row.name} has been archived`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    async delete(row) {
      if (row.isPrecreated) {
        return;
      }
      const value = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete "${row.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );

      if (value) {
        try {
          await this.destinationApiClient.remove(row.id);
          this.handleApiSuccess(`The destination ${row.name} has been deleted`);
          const currentPath = this.$router.currentRoute;

          const samePath = currentPath.matched.some(
            route => route.path === YOUR
          );
          if (!samePath) {
            await this.$router.push(`${YOUR}`);
          }
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    clone(row) {
      const cloned = JSON.parse(JSON.stringify(row));
      cloned.name += " (copy)";
      this.$router.push({
        name: `DestinationsCreate`,
        params: { cloning: cloned }
      });
    }
  }
};
