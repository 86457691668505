<template>
  <div>
    <gl-form-group
      class="m-4"
      label="Max Latency (ms)"
      label-size="sm"
      :invalid-feedback="invalidMaxLatencyFeedback()"
      label-for="maxLatencyMs"
    >
      <gl-form-input
        type="number"
        id="maxLatencyMs"
        v-model="currentItem.maxLatencyMs"
        placeholder="Latency (ms)"
        maxlength="6"
        class="mt-1"
        :state="isMaxLatencyValid()"
        min="0"
        max="60000"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormInput } from "@gitlab/ui";
import Destination from "@/models/Destination";

export default {
  components: { GlFormGroup, GlFormInput },
  methods: {
    invalidMaxLatencyFeedback() {
      return this.isMaxLatencyValid()
        ? ""
        : "Maximum latency should be between 0 and 60,000 ms";
    },
    isMaxLatencyValid() {
      let isValid = true;
      if (this.currentItem.maxLatencyMs) {
        isValid =
          this.currentItem.maxLatencyMs >= 0 &&
          this.currentItem.maxLatencyMs <= 60000;
      }
      this.$emit("change", isValid);
      return isValid;
    }
  },
  data() {
    return {};
  },
  props: {
    currentItem: Destination // 2-way binding to the parent, name can be different
  }
};
</script>
