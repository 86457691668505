<template>
  <gl-card>
    <h4>{{ title }}</h4>
    <b-row class="border-bottom border-light py-3">
      <b-col cols="6">
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          v-model="searchPhrase"
          type="text"
          placeholder="Filter"
        />
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
    <div class="mt-3" v-if="loading">
      <p>Loading...</p>
    </div>
    <div class="mt-3" v-else-if="!loading && rows.length === 0">
      <p>No data for this query...</p>
    </div>

    <div class="mt-3">
      <table>
        <tr v-for="(row, index) in rows" :key="index">
          <td>
            {{ row.text }}
          </td>
          <td>
            <gl-toggle
              v-model="row.selected"
              class="ml-5 pt-1"
              :disabled="disabled || row.disabled"
              @change="onOffItem($event, row)"
              label-position="hidden"
              label=""
            />
          </td>
        </tr>
      </table>
    </div>
    <ArqDataGridPaginator
      :pagingModel="pagingModel"
      class="mt-2"
      @firstPage="changePage"
      @lastPage="changePage"
      @previousPage="changePage"
      @nextPage="changePage"
    />
  </gl-card>
</template>

<script>
import { GlCard, GlSearchBoxByType, GlToggle } from "@gitlab/ui";
import ArqDataGridPaginator from "@/components/DataGrid/DataGridPaginator.vue";
export default {
  components: {
    ArqDataGridPaginator,
    GlCard,
    GlSearchBoxByType,
    GlToggle
  },
  name: "SmartSelectOnOff",
  props: {
    title: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    },
    selectedLimit: {
      type: Number,
      default: Number.MAX_VALUE
    },
    selected: {
      type: Array,
      default: () => []
    },
    dataSource: Object,
    itemsPerPage: {
      type: Number,
      default: 20
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    textFormatter: {
      type: Function
    },
    dataSourceParams: {
      type: Array,
      default: () => []
    },
    disabledItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleKey(evt) {
      if (evt.target.tagName === "INPUT" || evt.target.tagName === "TEXTAREA") {
        return;
      }
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      if (
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 65 && evt.keyCode <= 90)
      ) {
        this.tempArray.push(evt.key);
        this.tempSearch = this.tempArray.join("");
        this.searchPhrase = this.tempSearch;
      } else if (evt.keyCode === 27 && this.tempArray.length > 0) {
        this.tempArray = [];
        this.tempSearch = "";
        this.searchPhrase = "";
      }
      this.timeoutID = setTimeout(() => {
        this.tempArray = [];
      }, 2000);
    },
    onOffItem($event, row) {
      row.selected = $event;
      if (this.reload) {
        this.reloadData();
      }
      this.$emit("itemOnOff", row);
    },
    changePage(pagingModel) {
      this.pagingModel = pagingModel;
      this.reloadData();
    },
    async reloadData() {
      const ds = this.dataSource
        .list()
        .perPage(this.pagingModel.itemsPerPage)
        .page(this.pagingModel.currentPage)
        .filterBy(this.searchPhrase);
      for (const param of this.dataSourceParams) {
        ds.param(param.name, param.value);
      }
      const data = await ds.exec();
      this.loading = false;
      this.rows = data.items.map(row => {
        return {
          selected: this.selected && this.selected.includes(row.id),
          text: this.textFormatter ? this.textFormatter(row) : row[this.field],
          value: row.id,
          id: row.id,
          disabled: this.disabledItems.includes(row.id)
        };
      });
      this.pagingModel.currentPage = data.page;
      this.pagingModel.totalPages = data.totalPages;
      this.pagingModel.totalItems = data.totalItems;
    }
  },
  watch: {
    rows: {
      handler() {
        this.$emit("selectedChanged", this.rows);
      },
      deep: true
    },
    selected: {
      async handler() {
        await this.reloadData();
      }
    },
    searchPhrase: {
      async handler() {
        await this.reloadData();
      }
    }
  },
  mounted() {
    this.reloadData();
    document.addEventListener("keydown", this.handleKey);
  },
  created() {
    this.pagingModel = {
      currentPage: 0,
      itemsPerPage: this.itemsPerPage,
      totalPages: 0,
      totalItems: 0
    };
  },
  data() {
    return {
      searchPhrase: "",
      tempSearch: "",
      tempArray: [],
      loading: true,
      rows: [],
      pagingModel: {
        currentPage: Number,
        itemsPerPage: Number,
        totalPages: Number,
        totalItems: Number
      }
    };
  }
};
</script>
