<template>
  <div>
    <b-row>
      <b-col class="mb-2">
        <gl-filtered-search
          :available-tokens="tokens"
          @submit="activate"
          @clear="clear"
        >
          <template #history-item="{ historyItem }">
            <template v-for="(token, idx) in historyItem">
              <span :key="idx" class="px-1">
                {{ token.type }} =
                <strong>{{ token.value }}</strong>
              </span>
            </template>
          </template>
        </gl-filtered-search>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="border-right border-light">
        <ArqDataGrid
          :data="data"
          :phrase="searchPhrase"
          :columns="columns"
          @dataNeeded="onDataNeeded"
          :itemsPerPage="itemsPerPage"
          :showContextMenu="false"
          @rowSelected="onRowSelected"
          :selectOnClick="true"
        >
          <template v-slot="{ row: r, field }">
            <div v-if="field === 'created'">
              <span class="f12">{{ toLocalDate(r.created) }}</span
              ><br />
              <span>{{ toLocalTime(r.created) }}</span>
            </div>
            <div v-else-if="field === 'operation'">
              <StatusCell :status="r.operation" class="l" :showIcon="false" />
            </div>
            <div v-else>
              <span class="f12">{{ r[field] }} </span>
            </div>
          </template>
        </ArqDataGrid>
        <div v-if="selectedRow">
          <b-row>
            <b-col>
              <p class="font-weight-bold field-header">Before</p>
            </b-col>
            <b-col>
              <p class="font-weight-bold field-header">After</p>
            </b-col>
          </b-row>
          <b-row
            v-for="(detailRow, index) in detailsItems(selectedRow)"
            :key="index"
            class="my-2"
          >
            <b-col>
              <div
                class="field-element p-2 h-100"
                :class="{
                  changed: !deepEq(detailRow.before, detailRow.after)
                }"
              >
                <p
                  class="field-name"
                  :class="{
                    'field-changed': !deepEq(detailRow.before, detailRow.after)
                  }"
                >
                  {{ detailRow.field }}
                </p>
                <p
                  class="field-value"
                  :class="{
                    'field-changed': !deepEq(detailRow.before, detailRow.after)
                  }"
                >
                  {{ detailRow.before }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div
                class="field-element p-2 h-100"
                :class="{
                  changed: !deepEq(detailRow.before, detailRow.after)
                }"
              >
                <p
                  class="field-name"
                  :class="{
                    'field-changed': !deepEq(detailRow.before, detailRow.after)
                  }"
                >
                  {{ detailRow.field }}
                </p>
                <p
                  class="field-value"
                  :class="{
                    'field-changed': !deepEq(detailRow.before, detailRow.after)
                  }"
                >
                  {{ detailRow.after }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import dateUtils from "@/mixins/dateUtils";
import StatusCell from "@/components/DataGrid/StatusCell.vue";
import { auditApiClient, userMgmtApiClient } from "@/mixins/apiClients";
import * as eq from "deep-equal";
import _ from "lodash";
import { LOGS_SEARCH_BAR_TOKENS } from "@/constants.js";
import { GlFilteredSearch } from "@gitlab/ui";
import { BCol, BRow } from "bootstrap-vue";

// import LogsFilterToken from "@/components/Audit/SearchTokens/LogsFilterToken.vue";

export default {
  name: "LogsAll",
  mixins: [dateUtils, auditApiClient, userMgmtApiClient],
  components: {
    ArqDataGrid,
    StatusCell,
    GlFilteredSearch,
    BCol,
    BRow
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["phrase"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    detailsItems(row) {
      if (!row) {
        return [];
      }

      const items = [];
      const after = row.data.updated || row.data;
      const before = row.data.original || {};

      for (const field of Object.keys(after)) {
        items.push({
          field: _.startCase(field),
          before: before[field] || "_",
          after: after[field]
        });
      }
      return items;
    },
    findLogName(log) {
      const nameLikeFields = ["name", "shortName", "contributionName", "email"];
      const nestedLogs = ["updated"];
      let field = nameLikeFields.find(f => Object.keys(log).includes(f));
      if (!field) {
        for (const nested of nestedLogs) {
          log = log[nested];
          field = nameLikeFields.find(f => Object.keys(log).includes(f));
        }
      }
      return field ? log[field] : "";
    },
    onRowSelected(row, selectedRows) {
      this.selectedRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = undefined;
      }
      return row;
    },
    deepEq(a, b) {
      return eq(a, b);
    },
    async onDataNeeded(sortModel, pageModel) {
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      this.selectedRow = undefined;
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.auditApiClient
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }
      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }
      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d = response;
        d.items.forEach(item => {
          try {
            const actionName = this.availableActions.find(
              action => action.value === item.operation
            );
            item.operation = actionName ? actionName.title : item.operation;

            switch (item.resourceType) {
              case "organisation":
                item.resourceType = "organization";
                break;
              case "partnerGrant":
                item.resourceType = "partner grant";
                break;
            }

            item.data = JSON.parse(item.data);
            item.target = this.findLogName(item.data);
          } catch (ex) {
            // continue
          }
        });
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
    }
  },
  computed: {
    tokens() {
      return LOGS_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      sortModel: undefined,
      searchParams: {},
      searchPhrase: "",
      selectedRow: undefined,
      selectedRows: [],
      data: {},
      availableActions: LOGS_SEARCH_BAR_TOKENS.find(
        item => item.type === "operation"
      ).options,
      columns: [
        {
          text: "Date",
          field: "created",
          isSortable: true,
          defaultSort: true
        },
        {
          text: "Type",
          field: "resourceType"
        },
        {
          text: "Target",
          field: "target"
        },
        {
          text: "User",
          field: "userEmail"
        },
        {
          text: "Action",
          field: "operation"
        }
      ],
      detailsColumns: [
        {
          text: "Name",
          field: "contributionName"
        },
        {
          text: "Owner",
          field: "owner"
        },
        {
          text: "Description",
          field: "description"
        },
        {
          text: "Current Alerts (Contribution 1)",
          field: "currentAlerts0"
        },
        {
          text: "Historic Alerts (Contribution 1)",
          field: "allTimeAlerts0"
        },
        {
          text: "Current Alerts (Contribution 2)",
          field: "currentAlerts1"
        },
        {
          text: "Historic Alerts (Contribution 2)",
          field: "allTimeAlerts1"
        },
        {
          text: "Type",
          field: "sourceType"
        },
        {
          text: "Status",
          field: "status"
        },
        {
          text: "Zone",
          field: "zone"
        },
        {
          text: "Profile",
          field: "profile"
        },
        {
          text: "Endpoint",
          field: "endpoint"
        },
        {
          text: "Entitlement ARN (1)",
          field: "entitlementArn0"
        },
        {
          text: "Entitlement ARN (2)",
          field: "entitlementArn1"
        }
      ]
    };
  }
};
</script>
<style>
.field-element {
  background-color: #f0f0f0;
}
.changed {
  background-color: #dbdbdb;
}

.field-name {
  color: var(--theme-color);
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.field-value {
  padding: 0;
  margin: 0;
}

.field-changed {
  font-weight: bold;
}

.field-header {
  font-size: 19px;
}

.f12 {
  font-size: 12px;
}
</style>
