<template>
  <div>
    <RegionComponent
      :currentItem="currentItem"
      @change="onRegionChange"
    ></RegionComponent>
    <gl-form-group
      class="m-4"
      label="URL"
      label-size="sm"
      label-for="url"
      :invalid-feedback="invalidUrlFeedback()"
    >
      <gl-form-input
        id="url"
        type="url"
        v-model="currentItem.url"
        placeholder=""
        maxlength="250"
        class="mt-1"
        :state="isUrlValid()"
      />
    </gl-form-group>
    <gl-form-group
      class="m-4"
      label="Stream Name"
      label-size="sm"
      label-for="streamName"
      :invalid-feedback="invalidStreamNameFeedback()"
    >
      <p v-if="isUpdate" class="mb-1">
        <small
          >Stream Name has been already set, if you want to change it you can
          enter the new key in the field below.</small
        >
      </p>
      <div v-if="isUpdate && !streamNameIsEdit">
        <small class="mr-2">[Hidden]</small>
        <gl-button @click="streamNameIsEdit = true" variant="default"
          >Update
        </gl-button>
      </div>
      <gl-form-input
        v-else
        id="streamName"
        type="text"
        v-model="currentItem.streamName"
        placeholder=""
        maxlength="250"
        class="mt-1"
        :state="isStreamNameValid()"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { DESTINATION_TYPES } from "@/constants";
import Destination from "@/models/Destination";
import RegionComponent from "@/components/Destinations/AddSecond/Specs/Sections/RegionComponent.vue";
import { GlButton, GlFormGroup, GlFormInput } from "@gitlab/ui";

export default {
  components: { GlButton, GlFormGroup, GlFormInput, RegionComponent },
  computed: {
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    }
  },
  methods: {
    invalidStreamNameFeedback() {
      return this.isStreamNameValid() ? "" : "Please enter a Stream Name";
    },
    invalidUrlFeedback() {
      let message = "";
      if (!this.isUrlValid()) {
        message =
          "Please enter valid URL with protocol rtmp or rtmps and with complete path";
      }
      return message;
    },
    isUrlValid() {
      const completeUrlRegexp = /^(rtmps?:\/\/)[^/]+(\/[^/]+)+$/;
      return (
        !!this.currentItem.url &&
        completeUrlRegexp.test(this.currentItem.url) &&
        this.currentItem.url.length <= 256
      );
    },
    isStreamNameValid() {
      return (
        (this.isUpdate && !this.streamNameIsEdit) ||
        !!this.currentItem.streamName
      );
    },

    isDestinationValid() {
      switch (false) {
        case this.isRegionValid:
        case this.isUrlValid():
        case this.isStreamNameValid():
          return false;
        default:
          return true;
      }
    },
    onRegionChange(isValid) {
      this.isRegionValid = isValid;
      this.$emit("change", this.isDestinationValid());
    }
  },
  data() {
    return {
      isRegionValid: false,
      streamNameIsEdit: false
    };
  },
  props: {
    currentItem: Destination,
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    currentItem: {
      handler() {
        //triggered when a non child component attribute is changed
        this.$emit("change", this.isDestinationValid()); // emit the event to the parent
      },
      deep: true
    }
  }
};
</script>
