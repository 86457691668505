<template>
  <gl-card>
    <b-row
      class="border-bottom border-light pb-3"
      v-if="showSelected && selectedItem"
    >
      <b-col cols="12" class="align-middle">
        <div class="d-flex inline align-items-center">
          <div class="font-weight-bold">Selected:</div>
          <gl-form-input
            size="lg"
            type="text"
            v-model="selectedRowName"
            :required="isRequired"
            readonly
            class="ml-2"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="border-bottom border-light py-3">
      <b-col cols="6">
        <gl-search-box-by-type
          class="d-none d-lg-flex"
          v-model="searchPhrase"
          type="text"
          placeholder="Filter"
        />
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
    <div class="mt-3" v-if="loading">
      <p>Loading...</p>
    </div>
    <div class="mt-3" v-else-if="!loading && rows.length === 0">
      <p>No data for this query...</p>
    </div>
    <div class="mt-3" v-else-if="controlType === `radio`">
      <gl-form-radio-group
        v-model="selectedItem"
        :options="rows"
        @change="updateDisabled"
      />
    </div>
    <div class="mt-3" v-else>
      <gl-form-checkbox-group
        v-model="selectedItem"
        :options="rows"
        @change="updateDisabled"
      />
    </div>
    <ArqDataGridPaginator
      :pagingModel="pagingModel"
      class="mt-2"
      @firstPage="changePage"
      @lastPage="changePage"
      @previousPage="changePage"
      @nextPage="changePage"
    />
  </gl-card>
</template>

<script>
import {
  GlCard,
  GlSearchBoxByType,
  GlFormRadioGroup,
  GlFormInput,
  GlFormCheckboxGroup
} from "@gitlab/ui";
import ArqDataGridPaginator from "@/components/DataGrid/DataGridPaginator.vue";
export default {
  components: {
    ArqDataGridPaginator,
    GlCard,
    GlSearchBoxByType,
    GlFormRadioGroup,
    GlFormInput,
    GlFormCheckboxGroup
  },
  name: "SmartSelect",
  props: {
    showSelected: {
      type: Boolean,
      default: true
    },
    keyField: {
      type: String,
      default: "id"
    },
    selectedLimit: {
      type: Number,
      default: 1
    },
    selected: null,
    disabledItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabledItemCondition: {
      type: Function,
      default: () => {
        return false;
      }
    },
    dataSource: Object,
    field: {
      type: String,
      default: "name"
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    controlType: {
      type: String,
      default: "radio"
    },
    textFormatter: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnSelectedRow: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    changePage(pagingModel) {
      this.pagingModel = pagingModel;
      this.reloadData();
    },
    async reloadData() {
      let query = this.dataSource
        .list()
        .perPage(this.pagingModel.itemsPerPage)
        .page(this.pagingModel.currentPage)
        .filterBy(this.searchPhrase);

      for (const [key, value] of Object.entries(this.filters)) {
        query.param(key, value);
      }

      const data = await query.exec();
      this.loading = false;
      this.rawData = data.items;
      this.rows = data.items.map(row => {
        return {
          availabilityZone: row.availabilityZone,
          disabled:
            this.disabled ||
            (this.disabledItems &&
              this.disabledItems.includes(row[this.keyField])) ||
            this.disabledItemCondition(row),
          selected: this.selected && this.selected.includes(row[this.keyField]),
          text: this.textFormatter ? this.textFormatter(row) : row.name,
          value: row[this.keyField]
        };
      });
      this.updateDisabled(this.rows.filter(r => r.selected).map(r => r.value));
      this.pagingModel.currentPage = data.page;
      this.pagingModel.totalPages = data.totalPages;
      this.pagingModel.totalItems = data.totalItems;
    },
    updateDisabled: function(items) {
      if (this.disabled) {
        this.rows.forEach(r => {
          r.disabled = true;
        });
        return;
      }
      if (this.controlType === `radio`) {
        const availabilityZoneToDisable = this.rows
          .filter(r => r.value === this.selected)
          .map(r => r.availabilityZone)[0];
        const disabledRows = [];
        this.rows.forEach(r => {
          if (r.availabilityZone === availabilityZoneToDisable) {
            disabledRows.push(r.value);
          }
        });
        this.$emit("disabledRows", disabledRows);
        return;
      }
      if (items.length === this.selectedLimit) {
        this.rows.forEach(r => {
          r.disabled = !items.includes(r.value);
        });
      } else {
        this.rows.forEach(r => {
          r.disabled = false;
        });
      }
    }
  },
  asyncComputed: {
    async selectedRowName() {
      let name = "";
      if (this.selectedItem) {
        const filtered = this.rows.filter(
          r => this.selected && this.selected.indexOf(r.value.toString()) > -1
        );
        if (filtered.length > 0) {
          name = filtered.map(r => r.text).join(`, `);
        } else {
          try {
            const selected = Array.isArray(this.selected)
              ? this.selected
              : [this.selected];
            const promises = selected.map(s => this.dataSource.getById(s));
            const results = await Promise.all(promises);
            const names = [];
            results.forEach(result => {
              const mapper = this.textFormatter
                ? this.textFormatter
                : r => r.name;
              names.push(mapper(result));
            });
            name = names.join(`, `);
          } catch (e) {
            console.error(e);
          }
        }
      }
      return name;
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit(
          "itemSelected",
          this.returnSelectedRow
            ? this.rawData.find(item => item[this.keyField] === newValue)
            : newValue
        );
      }
    }
  },
  watch: {
    selected: {
      handler(value) {
        this.updateDisabled(
          this.rows
            .filter(r => value && value.includes(r.value))
            .map(r => r.value)
        );
      }
    },
    searchPhrase: {
      async handler() {
        await this.reloadData();
      }
    },
    disabledItems: {
      handler() {
        if (
          this.disabledItems &&
          this.disabledItems.includes(this.selectedItem)
        ) {
          this.selectedItem = null;
        } else {
          this.rows.forEach(r => {
            r.disabled = this.disabled || this.disabledItems.includes(r.value);
          });
        }
      }
    },
    disabledItemCondition: {
      async handler() {
        await this.reloadData();
      }
    }
  },
  mounted() {
    this.reloadData();
  },
  created() {
    this.pagingModel = {
      currentPage: 0,
      itemsPerPage: this.itemsPerPage,
      totalPages: 0,
      totalItems: 0
    };
  },
  data() {
    return {
      searchPhrase: "",
      loading: true,
      rows: [],
      rawData: [],
      pagingModel: {
        currentPage: Number,
        itemsPerPage: Number,
        totalPages: Number,
        totalItems: Number
      }
    };
  }
};
</script>
<style lang="scss">
input:disabled + label {
  text-decoration: line-through;
}
</style>
