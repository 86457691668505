<template>
  <div>
    <MainSection
      :breadcrumbItems="breadcrumbItems"
      :pageTitle="pageTitle"
      :tabsItems="tabsItems"
    />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { MAIN_ITEMS, ALIAS_ITEMS_DESTINATIONS } from "@/constants.js";

const { YOUR, ARCHIVED } = ALIAS_ITEMS_DESTINATIONS;

export default {
  components: { MainSection },
  computed: {
    tabsItems() {
      return [
        {
          title: `All destinations`,
          subPageName: YOUR
        },
        {
          title: `Archived`,
          subPageName: ARCHIVED
        }
      ];
    }
  },
  data() {
    return {
      pageTitle: `Destinations`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Destinations`, href: YOUR },
        { text: `Destinations`, href: YOUR }
      ]
    };
  }
};
</script>
