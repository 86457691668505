<template>
  <Wizard>
    <template v-slot:left-section>
      <div class="text-right mt-2 mb-4">
        <gl-button-group>
          <gl-button @click="reset()" :disabled="!canSaveDraft || isBusy"
            >Reset</gl-button
          >
          <div class="position-relative">
            <gl-button @click="saveDraft()" :disabled="!canSaveDraft || isBusy"
              >Save draft</gl-button
            >
          </div>
          <gl-button
            :disabled="!canPublish || isBusy"
            variant="confirm"
            @click="publish()"
            >Publish</gl-button
          >
        </gl-button-group>
      </div>
      <div v-if="isLoading">
        <gl-loading-icon label="Loading" />
      </div>
      <div v-else>
        <table class="table">
          <tr>
            <th></th>
            <th class="text-center">Live</th>
            <th class="text-center">Draft</th>
          </tr>
          <tr v-for="price in itemUnderEdit" :key="price.key">
            <td class="align-middle">{{ price.name }}</td>
            <td class="text-center align-middle">
              {{
                typeof price.price_live == "number"
                  ? parseFloat(price.price_live).toFixed(price.fixed)
                  : "null"
              }}
            </td>
            <td class="text-center align-middle">
              <gl-form-input
                type="number"
                :step="price.step"
                min="0"
                :value="price.price_draft | trailingZeros(price.fixed)"
                @change="price.price_draft = $event"
                class="pricing-input-number"
              />
            </td>
          </tr>
        </table>
      </div>
    </template>
  </Wizard>
</template>
<script>
import {
  GlLoadingIcon,
  GlFormInput,
  GlButton,
  GlButtonGroup
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import { pricingConfigApiClient } from "@/mixins/apiClients";

export default {
  name: "AddThird",
  mixins: [pricingConfigApiClient],
  components: {
    GlLoadingIcon,
    Wizard,
    GlFormInput,
    GlButton,
    GlButtonGroup
  },
  filters: {
    trailingZeros: function(value, step) {
      return value ? parseFloat(value).toFixed(step) : null;
    }
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      draftSaved: false,
      itemUnderEdit: [
        {
          name: "Source Markup Pct",
          key: "source_margin_pct",
          step: "0.0001",
          fixed: 4,
          price_draft: undefined,
          price_live: undefined
        },
        {
          name: "Channel Delivery Markup Pct",
          key: "channel_delivery_margin_pct",
          step: "0.0001",
          fixed: 4,
          price_draft: undefined,
          price_live: undefined
        },
        {
          name: "Event Delivery Setup Cost USD",
          key: "event_delivery_setup_cost_usd",
          step: "0.01",
          fixed: 2,
          price_draft: undefined,
          price_live: undefined
        },
        {
          name: "Event Delivery Hourly Cost USD",
          key: "event_delivery_hourly_cost_usd",
          step: "0.01",
          fixed: 2,
          price_draft: undefined,
          price_live: undefined
        }
      ],
      originalItem: [],
      canSaveDraft: false
    };
  },
  watch: {
    itemUnderEdit: {
      handler: function(itemUnderEdit) {
        this.canSaveDraft =
          JSON.stringify(itemUnderEdit) != JSON.stringify(this.originalItem);
        this.draftSaved = false;
      },
      deep: true
    }
  },
  computed: {
    canPublish() {
      return (
        this.canSaveDraft ||
        this.itemUnderEdit.filter(
          price => price.price_draft != price.price_live
        ).length > 0
      );
    }
  },
  created() {
    const promises = [
      this.pricingConfigApiClient.list().exec(),
      this.pricingConfigApiClient
        .list()
        .param("live", true)
        .exec()
    ];

    Promise.all(promises)
      .then(results => {
        this.itemUnderEdit.map(item => {
          const key = this.snakeToCamel(item.key);
          item.price_draft = results[0]
            ? results[0].json[key]
            : results[1]
            ? results[1].json[key]
            : undefined;
          item.price_live = results[1] ? results[1].json[key] : undefined;
        });
        this.originalItem = JSON.parse(JSON.stringify(this.itemUnderEdit));
        this.isLoading = false;
      })
      .catch(e => {
        this.handleApiError(e);
      });
  },
  methods: {
    snakeToCamel(str) {
      return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
          .toUpperCase()
          .replace("-", "")
          .replace("_", "")
      );
    },

    async reset() {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to reset?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        this.itemUnderEdit = JSON.parse(JSON.stringify(this.originalItem));
      }
    },
    async saveDraft() {
      if (this.canSaveDraft) {
        this.isBusy = true;
        try {
          let payload = {};
          this.itemUnderEdit.map(item => {
            payload[item.key] = item.price_draft || null;
          });
          await this.pricingConfigApiClient.create(payload);
          this.handleApiSuccess(`Pricing draft was updated succesfully`);
          this.originalItem = JSON.parse(JSON.stringify(this.itemUnderEdit));
          this.draftSaved = true;
        } catch (e) {
          this.handleApiError(e);
        }
        this.isBusy = false;
      }
    },
    async publish() {
      if (!this.draftSaved) {
        await this.saveDraft();
      }
      this.isBusy = true;
      try {
        const result = await this.pricingConfigApiClient.update("publish");
        this.handleApiSuccess(
          `Pricing was updated succesfully. Updated records: ${result.published}`
        );

        this.itemUnderEdit.map(price => {
          price.price_live =
            price.price_draft > 0 ? parseFloat(price.price_draft) : null;
        });

        this.originalItem = JSON.parse(JSON.stringify(this.itemUnderEdit));
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
<style scoped>
.pricing-input-number {
  width: 100px;
  display: inline-block;
  text-align: right;
}
</style>
