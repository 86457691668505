<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard
      :leftSectionTitle="leftSectionTitle"
      :buttons="buttons"
      :rightSectionTitle="rightSectionTitle"
    >
      <template v-slot:left-section>
        Sources can be managed from the Source menu. You can create a
        contribution without a source but you cannot publish it without one.

        <div class="mt-3">
          <hr />
          <gl-toggle
            :disabled="new Date(itemUnderEdit.plannedEnd) < new Date()"
            id="locked"
            v-model="itemUnderEdit.lockStreamProfile"
            label="Force the use of this stream profile"
          ></gl-toggle>
        </div>

        <div class="mt-5">
          <gl-button-group>
            <gl-button v-gl-collapse-toggle.collapse-single-source>
              {{ currentItem.source1Id ? "First Source" : "Single Source" }}
            </gl-button>
            <gl-button v-gl-collapse-toggle.collapse-second-source>
              Second Source
            </gl-button>
          </gl-button-group>

          <div class="mt-5 d-flex" v-if="adminOnly">
            <h4>Admin only:</h4>
            <gl-form-checkbox
              v-on:change="handleAdminOnlyChange"
            ></gl-form-checkbox>
          </div>
          <gl-collapse
            id="collapse-single-source"
            class="gray-section mt-3"
            v-model="visible"
            accordion="source-accordion"
          >
            <smart-select
              v-if="accessLevel === 'arqade_admin'"
              :disabled="itemUnderEdit.editLevel > CONTRIBUTION_EDIT_LEVELS.ALL"
              :dataSource="sourceApiClient"
              :filters="{ status: 'ENABLED', accessLevel: accessLevel }"
              @itemSelected="setSingleSourceId"
              @disabledRows="handleDisabledRows"
              :selected="itemUnderEdit.source0Id"
            ></smart-select>
          </gl-collapse>
          <gl-collapse
            id="collapse-single-source"
            class="gray-section mt-3"
            v-model="visible"
            accordion="source-accordion"
          >
            <smart-select
              v-if="accessLevel === 'user'"
              :disabled="itemUnderEdit.editLevel > CONTRIBUTION_EDIT_LEVELS.ALL"
              :dataSource="sourceApiClient"
              :filters="{ status: 'ENABLED' }"
              @itemSelected="setSingleSourceId"
              @disabledRows="handleDisabledRows"
              :selected="itemUnderEdit.source0Id"
            ></smart-select>
          </gl-collapse>

          <gl-collapse
            id="collapse-second-source"
            class="gray-section mt-3"
            accordion="source-accordion"
          >
            <smart-select
              v-if="accessLevel === 'arqade_admin'"
              :dataSource="sourceApiClient"
              :filters="{ status: 'ENABLED', accessLevel: accessLevel }"
              @itemSelected="setSecondSourceId"
              :disabledItems="rowsToDisable"
              :selected="itemUnderEdit.source1Id"
              :disabled="itemUnderEdit.editLevel > CONTRIBUTION_EDIT_LEVELS.ALL"
              :isRequired="false"
            ></smart-select>
          </gl-collapse>
          <gl-collapse
            id="collapse-second-source"
            class="gray-section mt-3"
            accordion="source-accordion"
          >
            <smart-select
              v-if="accessLevel === 'user'"
              :dataSource="sourceApiClient"
              :filters="{ status: 'ENABLED' }"
              @itemSelected="setSecondSourceId"
              :disabledItems="rowsToDisable"
              :selected="itemUnderEdit.source1Id"
              :disabled="itemUnderEdit.editLevel > CONTRIBUTION_EDIT_LEVELS.ALL"
              :isRequired="false"
            ></smart-select>
          </gl-collapse>
          <gl-collapse id="collapse-new-source" accordion="source-accordion" />
          <gl-collapse id="collapse-none-source" accordion="source-accordion" />
        </div>
      </template>

      <template v-slot:right-section>
        <ScheduleSection
          :currentItem="currentItem"
          @change="onMultiDateTimeChange"
        ></ScheduleSection>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import {
  GlButton,
  GlButtonGroup,
  GlCollapse,
  GlForm,
  GlFormCheckbox,
  GlToggle
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import SmartSelect from "@/components/SmartSelect/SmartSelect.vue";
import sourceApiClient from "@/mixins/sourceApiClient.js";
import AppConfig from "../../app-config";
import userInfo from "../../mixins/userInfo";
import {
  ALIAS_ITEMS_CONTRIBUTION,
  CONTRIBUTION_EDIT_LEVELS,
  ITEMS_CONTRIBUTIONS
} from "@/constants.js";
import currentItem from "@/mixins/currentItem";
import ScheduleSection from "@/components/Contributions/addSecond/ScheduleSection.vue";

const { EDIT } = ITEMS_CONTRIBUTIONS;
const { ADD_THIRD, EDIT_THIRD } = ALIAS_ITEMS_CONTRIBUTION;

export default {
  mixins: [sourceApiClient, currentItem, userInfo],
  components: {
    ScheduleSection,
    Wizard,
    GlButtonGroup,
    GlButton,
    GlCollapse,
    GlForm,
    GlFormCheckbox,
    GlToggle,
    SmartSelect
  },
  computed: {
    adminOnly() {
      return (
        (this.isArqadeAdminRoleActive || this.isMasquerading) &&
        AppConfig.instance.config.options.features.adminOnlySources
      );
    },
    buttons() {
      return [
        {
          text: `Next Step`,
          type: `submit`,
          disabled: !this.isDepFulfilled
        }
      ];
    }
  },
  data() {
    return {
      CONTRIBUTION_EDIT_LEVELS: CONTRIBUTION_EDIT_LEVELS,
      accessLevel: "user",
      isDepFulfilled: false,
      leftSectionTitle: `Source`,
      rightSectionTitle: `Schedule`,
      rowsToDisable: [],
      visible: true
    };
  },
  async mounted() {
    if (this.itemUnderEdit.source0Id) {
      if (await this.isSourceArchived(this.itemUnderEdit.source0Id)) {
        this.itemUnderEdit.source0Id = null;
      }
    }
    if (this.itemUnderEdit.source1Id) {
      if (await this.isSourceArchived(this.itemUnderEdit.source1Id)) {
        this.itemUnderEdit.source1Id = null;
      }
    }
    this.setDepFulfilled();
  },
  methods: {
    async isSourceArchived(sourceId) {
      try {
        const result = await this.sourceApiClient.getById(sourceId);
        return result.status === "ARCHIVED";
      } catch (e) {
        return true; // if there's an error, we assume the source is archived and the user can select a new one
      }
    },
    handleDisabledRows(e) {
      this.rowsToDisable = e;
    },
    handleAdminOnlyChange(e) {
      this.accessLevel = e ? "arqade_admin" : "user";
      this.itemUnderEdit.source0Id = null;
      this.itemUnderEdit.source1Id = null;
      this.$forceUpdate();
    },
    setSingleSourceId(itemId) {
      // TODO: the event is emitted twice
      this.itemUnderEdit.source0Id = itemId;
      this.setDepFulfilled();
      this.$forceUpdate();
    },
    setSecondSourceId(itemId) {
      this.itemUnderEdit.source1Id = itemId;
      this.setDepFulfilled();
      this.$forceUpdate();
    },
    nextStep() {
      if (this.isDepFulfilled) {
        if (this.itemUnderEdit.id) {
          this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_THIRD}`);
        } else {
          this.$router.push(ADD_THIRD);
        }
      }
    },

    onMultiDateTimeChange(item) {
      this.itemUnderEdit.plannedStart = item.plannedStart;
      this.itemUnderEdit.plannedEnd = item.plannedEnd;
      this.itemUnderEdit.isOpenEnded = item.isOpenEnded;
      this.setDepFulfilled();
    },

    setDepFulfilled() {
      switch (true) {
        case !this.itemUnderEdit.source0Id:
        case !this.itemUnderEdit.plannedStart:
        case this.itemUnderEdit.contributionType === "event_contribution" &&
          !this.itemUnderEdit.plannedEnd:
        case this.itemUnderEdit.contributionType === "channel_contribution" &&
          !this.itemUnderEdit.isOpenEnded &&
          !this.itemUnderEdit.plannedEnd:
          this.isDepFulfilled = false;
          break;
        default:
          this.isDepFulfilled = true;
      }
    }
  }
};
</script>
