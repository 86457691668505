import { render, staticRenderFns } from "./ProfilesComponent.vue?vue&type=template&id=02008a1d&v-slot%3Aright-section=true"
import script from "./ProfilesComponent.vue?vue&type=script&lang=js"
export * from "./ProfilesComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports