<template v-slot:left-section>
  <div>
    <gl-form-group v-if="showPrecreatedDestination">
      <gl-form-checkbox
        id="precreate"
        :disabled="isUpdate"
        label="Pre Create Destination"
        type="checkbox"
        v-model="currentItem.isPrecreated"
      >
        Pre Create Destination
      </gl-form-checkbox>
    </gl-form-group>

    <gl-form-group label="Destination Type" label-size="sm" label-for="type">
      <gl-form-select
        id="type"
        v-model="currentItem.destinationType"
        :options="destinationTypeOptions"
        :disabled="isUpdate"
        placeholder="Destination Type"
        class="mt-1"
      />
    </gl-form-group>

    <div class="gray-section">
      <!-- Render the appropriate child component based on the selected item type -->
      <component
        :is="dynamicDestinationComponent"
        :currentItem="currentItem"
        :originalItem="originalItem"
        :isUpdate="isUpdate"
        @change="onFormChange"
      ></component>
    </div>
  </div>
</template>

<script>
import AppConfig from "@/app-config";

const name = "SpecsComponent";
import { DESTINATION_TYPES } from "@/constants.js";
import {
  GlButtonGroup,
  GlButton,
  GlFormGroup,
  GlFormSelect,
  GlFormCheckbox
} from "@gitlab/ui";
import RistDestinationComponent from "@/components/Destinations/AddSecond/Specs/RistDestinationComponent.vue";
import Destination from "@/models/Destination";
import IpDestinationComponent from "@/components/Destinations/AddSecond/Specs/RtpDestinationComponent.vue";
import MediaConnectDestinationComponent from "@/components/Destinations/AddSecond/Specs/MediaConnectDestinationComponent.vue";
import ZixiPushDestinationComponent from "@/components/Destinations/AddSecond/Specs/ZixiPushDestinationComponent.vue";
import ZixiPullDestinationComponent from "@/components/Destinations/AddSecond/Specs/ZixiPullDestinationComponent.vue";
import SrtListenerDestinationComponent from "@/components/Destinations/AddSecond/Specs/SrtListenerDestinationComponent.vue";
import SrtCallerDestinationComponent from "@/components/Destinations/AddSecond/Specs/SrtCallerDestinationComponent.vue";
import VirtualConnectDestinationComponent from "@/components/Destinations/AddSecond/Specs/VirtualConnectDestinationComponent.vue";
import RtmpDestinationComponent from "@/components/Destinations/AddSecond/Specs/RtmpDestinationComponent.vue";
import HlsDestinationComponent from "@/components/Destinations/AddSecond/Specs/HlsDestinationComponent.vue";
import S3ArchiveDestinationComponent from "@/components/Destinations/AddSecond/Specs/S3ArchiveDestinationComponent.vue";
import MediaShuttleDestinationComponent from "@/components/Destinations/AddSecond/Specs/MediaShuttleDestinationComponent.vue";

export default {
  name: name,
  mixins: [],
  components: {
    IpDestinationComponent,
    RistDestinationComponent,
    GlButton,
    GlButtonGroup,
    GlFormGroup,
    GlFormSelect,
    GlFormCheckbox,
    MediaConnectDestinationComponent,
    RtmpDestinationComponent,
    SrtCallerDestinationComponent,
    SrtListenerDestinationComponent,
    VirtualConnectDestinationComponent,
    ZixiPullDestinationComponent,
    ZixiPushDestinationComponent
  },
  computed: {
    showPrecreatedDestination() {
      if (
        AppConfig.instance.config.options.features.displayPreCreateDestination
      ) {
        return !this.needsMediaLive();
      }
      return false;
    },
    DESTINATION_TYPES() {
      return DESTINATION_TYPES;
    },
    dynamicDestinationComponent() {
      switch (this.currentItem.destinationType) {
        case DESTINATION_TYPES.HLS:
          return HlsDestinationComponent;
        case DESTINATION_TYPES.RTP:
          return IpDestinationComponent;
        case DESTINATION_TYPES.MEDIACONNECT:
          return MediaConnectDestinationComponent;
        case DESTINATION_TYPES.MEDIASHUTTLE:
          return MediaShuttleDestinationComponent;
        case DESTINATION_TYPES.RIST:
          return RistDestinationComponent;
        case DESTINATION_TYPES.RTMP:
          return RtmpDestinationComponent;
        case DESTINATION_TYPES.S3_ARCHIVE:
          return S3ArchiveDestinationComponent;
        case DESTINATION_TYPES.SRT_CALLER:
          return SrtCallerDestinationComponent;
        case DESTINATION_TYPES.SRT_LISTENER:
          return SrtListenerDestinationComponent;
        case DESTINATION_TYPES.VIRTUAL_CONNECT:
          return VirtualConnectDestinationComponent;
        case DESTINATION_TYPES.ZIXI_PULL:
          return ZixiPullDestinationComponent;
        case DESTINATION_TYPES.ZIXI_PUSH:
          return ZixiPushDestinationComponent;
        default:
          return IpDestinationComponent;
      }
    }
  },
  methods: {
    needsMediaLive() {
      const types = [
        DESTINATION_TYPES.HLS,
        DESTINATION_TYPES.MEDIASHUTTLE,
        DESTINATION_TYPES.RTMP,
        DESTINATION_TYPES.S3_ARCHIVE
      ];

      const type = this.currentItem.destinationType;
      return types.includes(type);
    },
    resetDestination() {
      if (
        this.previousDestinationType &&
        this.previousDestinationType === this.currentItem.destinationType
      ) {
        return;
      }
      this.previousDestinationType = this.currentItem.destinationType;
      const attributesToKeep = [
        "availabilityZone",
        "description",
        "destinationType",
        "isPassthrough",
        "streamProfileId",
        "name",
        "region"
      ];
      Object.keys(this.currentItem).forEach(key => {
        if (!attributesToKeep.includes(key)) {
          this.currentItem[key] = undefined;
        }
      });
    },
    onFormChange(isValid) {
      this.$emit("change", isValid); // emit the event to the parent
    }
  },
  data() {
    return {
      destinationTypeOptions: [
        {
          text: "HLS",
          value: DESTINATION_TYPES.HLS
        },

        { text: "RTP", value: DESTINATION_TYPES.RTP },
        { text: "MediaConnect", value: DESTINATION_TYPES.MEDIACONNECT },
        {
          text: "MediaShuttle",
          value: DESTINATION_TYPES.MEDIASHUTTLE
        },

        { text: "RIST", value: DESTINATION_TYPES.RIST },
        { text: "RTMP", value: DESTINATION_TYPES.RTMP },
        {
          text: "S3 Archive",
          value: DESTINATION_TYPES.S3_ARCHIVE
        },
        {
          text: "SRT Caller",
          value: DESTINATION_TYPES.SRT_CALLER
        },
        { text: "SRT Listener", value: DESTINATION_TYPES.SRT_LISTENER },
        {
          text: "Virtual Connect Stream",
          value: DESTINATION_TYPES.VIRTUAL_CONNECT
        },
        { text: "Zixi Pull", value: DESTINATION_TYPES.ZIXI_PULL },
        { text: "Zixi Push", value: DESTINATION_TYPES.ZIXI_PUSH }
      ],
      previousDestinationType: undefined
    };
  },
  props: {
    currentItem: Destination,
    originalItem: Destination,
    isUpdate: Boolean,
    isPassthrough: Boolean
  },

  watch: {
    "currentItem.destinationType": function() {
      this.resetDestination();
    }
  }
};
</script>
