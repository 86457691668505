var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('gl-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"opsPanel__organizations__status",class:{
                  'opsPanel__organizations__status--success':
                    _vm.selectedOrganization.health === 'OK',
                  'opsPanel__organizations__status--warning':
                    _vm.selectedOrganization.health === 'WARN',
                  'opsPanel__organizations__status--danger':
                    _vm.selectedOrganization.health === 'NOK'
                }})]),_c('img',{staticClass:"mr-3",attrs:{"src":'data:image/jpeg;base64,' + _vm.organizationLogo,"width":"32"},on:{"error":function($event){return _vm.fixOrganizationLogo()}}}),_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"cellTitle"},[_vm._v(_vm._s(_vm.selectedOrganization.shortName))])])]),_c('div',[_c('gl-button',{attrs:{"icon":"chevron-lg-down"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeMetric()}}})],1)])])],1),_c('b-col',[_c('gl-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"opsPanel__organizations__status",class:{
                  'opsPanel__organizations__status--success':
                    _vm.selectedContribution.source0_health === 'OK',
                  'opsPanel__organizations__status--warning':
                    _vm.selectedContribution.source0_health === 'WARN',
                  'opsPanel__organizations__status--danger':
                    _vm.selectedContribution.source0_health === 'NOK'
                }}),(_vm.selectedContribution.source1Id)?_c('div',{staticClass:"opsPanel__organizations__status",class:{
                  'opsPanel__organizations__status--success':
                    _vm.selectedContribution.source1_health === 'OK',
                  'opsPanel__organizations__status--warning':
                    _vm.selectedContribution.source1_health === 'WARN',
                  'opsPanel__organizations__status--danger':
                    _vm.selectedContribution.source1_health === 'NOK'
                }}):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('b-badge',{staticClass:"contributionSingle__type"},[_vm._v(_vm._s(_vm.selectedContribution.contributionType))]),_c('span',{staticClass:"cellTitle"},[_vm._v(_vm._s(_vm.selectedContribution.name))])],1)]),_c('div',[_c('gl-button',{attrs:{"icon":"chevron-lg-down"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeMetric()}}})],1)])])],1),_c('b-col',[_c('gl-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"cellTitle"},[_vm._v(_vm._s(_vm.selectedDelivery.contributionName))])]),_c('div',[_c('gl-button',{attrs:{"icon":"chevron-lg-down"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeMetric()}}})],1)])])],1)],1),_c('Charts',{attrs:{"selectedContribution":_vm.selectedContribution,"selectedDelivery":_vm.selectedDelivery}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }