<template>
  <DetailScreenComponent
    :fields-to-include="fieldNames"
    :go-to-list="goToList"
    :action-icons="actionIcons"
    :item="item"
    :alert-fields="alertFields"
    :links="links"
  />
</template>
<script>
import AppConfig from "@/app-config";
import AddFirst from "@/components/Destinations/AddFirst.vue";
import { ITEMS_CONTRIBUTIONS, ITEMS_SOURCES } from "@/constants.js";
import {
  organizationApiClient,
  reportApiClient,
  sourceApiClient
} from "@/mixins/apiClients";
import contributionApiClient from "@/mixins/contributionApiClient.js";
import destinationActions from "@/mixins/destinationActions";
import destinationApiClient from "@/mixins/destinationApiClient.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
import wizardGuard from "@/mixins/wizardGuard.js";

import DetailScreenComponent from "@/components/DetailScreenComponent.vue";
import userInfo from "@/mixins/userInfo.js";

const { EDIT, EVENTS, CHANNELS } = ITEMS_CONTRIBUTIONS;
const { DELIVERY } = ITEMS_CONTRIBUTIONS;

export default {
  components: { DetailScreenComponent },
  mixins: [
    wizardGuard,
    destinationApiClient,
    destinationActions,
    organizationApiClient,
    sourceApiClient,
    streamProfileClient,
    userInfo,
    contributionApiClient,
    reportApiClient
  ],

  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.goToList();
      }
      if (evt.code === "Space") {
        this.goToEdit();
      }
    },
    async getLinks() {
      if (!this.item.source0Id) {
        return null;
      }
      const sources = [this.item.source0Id];
      if (this.item.source1Id) {
        sources.push(this.item.source1Id);
      }
      let namesAndRoutes = [];

      for (const sourceId of sources) {
        const route = `#${ITEMS_SOURCES.SHOW}/${sourceId}`;
        const source = await this.sourceApiClient.getById(sourceId);
        namesAndRoutes.push({
          name: source.name,
          route: route
        });
      }
      return { name: "Sources included :", list: namesAndRoutes };
    },
    async deliver() {
      this.$router.push(`${DELIVERY}/${this.item.id}`);
    },
    duplicate() {
      const cloned = JSON.parse(JSON.stringify(this.item));
      cloned.name += " (copy)";
      this.$router.push({
        name: `ContributionsAdd`,
        params: { cloning: cloned }
      });
    },
    async cancelContribution(item) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to cancel "${item.name}"?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        try {
          await this.contributionApiClient.patch(item.id, {
            scheduleStatus: "cancelled"
          });
          item.scheduleStatus = "cancelled";
          this.handleApiSuccess(
            `The contribution "${item.name}" has been cancelled.`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
    },
    goToEdit() {
      this.$router.push(`${EDIT}/${this.item.id}`);
    },
    goToList() {
      if (this.item.contributionType === "channel_contribution") {
        this.$router.push(`${CHANNELS}`);
      } else if (this.item.contributionType === "event_contribution") {
        this.$router.push(`${EVENTS}`);
      }
    },

    async fetchData() {
      const id = this.$route.params.id;
      await this.contributionApiClient.getById(id).then(contrib => {
        contrib.regionName =
          AppConfig.instance.config.options.regions[contrib.region];
        contrib.transcodePercentage = this.getPercentage(contrib);
        contrib.plannedEnd = contrib.isOpenEnded
          ? "On-Going"
          : contrib.plannedEnd;
        this.item = contrib;
      });
    },
    getPercentage(contrib) {
      let percentage = 0;
      this.getReportTranscodes(contrib.id).then(report => {
        percentage = report.data.total_deliveries
          ? Math.round(
              (report.data.transcode_deliveries /
                report.data.total_deliveries) *
                100
            )
          : 0;
      });
      return percentage;
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  async created() {
    await this.fetchData();
    await this.getLinks().then(links => {
      this.links = links;
    });
  },

  watch: {
    $route(to) {
      this.fetchData();
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    item: {
      handler() {
        this.isDescFulfilled = this.item.name && this.item.description;
      },
      deep: true
    }
  },
  computed: {
    pageTitle() {
      return " Destination Details";
    },
    showDeliveryOption() {
      return (
        !this.isUserRoleActive &&
        this.item.scheduleStatus !== "cancelled" &&
        !this.isCompleted(this.item)
      );
    },
    showCancelOption() {
      return (
        !this.isUserRoleActive &&
        this.item.scheduleStatus !== "cancelled" &&
        (this.isPending(this.item) || this.isCurrent(this.item))
      );
    },
    actionIcons() {
      return [
        {
          icon: "pencil",
          title: "Click or press the Space Bar to edit this Contribution",
          label: "Edit",
          id: "edit",
          action: this.goToEdit,
          disabled: false
        },
        {
          icon: "duplicate",
          action: this.duplicate,
          title: "Clone",
          label: "Clone",
          id: "clone",
          disabled: false
        },
        {
          icon: "archive",
          action: this.deliver,
          title: "Deliver",
          label: "Deliver",
          id: "deliver",
          disabled: !this.showDeliveryOption
        },
        {
          icon: "cancel",
          action: this.cancelContribution,
          title: "Cancel",
          label: "Cancel",
          id: "cancel",
          disabled: !this.showCancelOption
        }
      ];
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      item: {},
      links: {},
      isDescFulfilled: false,
      alertFields: [
        "currentAlerts0",
        "currentAlerts1",
        "allTimeAlerts0",
        "allTimeAlerts1"
      ],
      fieldNames: [
        "contributionType",
        "accessLevel",
        "availabilityZone",
        "plannedStart",
        "plannedEnd",
        "currentAlerts0",
        "currentAlerts1",
        "allTimeAlerts0",
        "allTimeAlerts1",
        "transcodePercentage"
      ]
    };
  }
};
</script>
