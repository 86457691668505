<script>
export default {
  name: "SuccessfulRegistration",
  data() {
    return {
      show: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 500);
  }
};
</script>

<template>
  <Transition name="bounce">
    <h2 v-if="show" class="text-center p-3">
      Thanks for registering. We'll be in touch shortly. If you'd like to reach
      out to us, this is our email address: sales@arqiva.com
    </h2>
  </Transition>
</template>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
