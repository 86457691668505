<template>
  <gl-form @submit.prevent="persistOrganisation()" class="mb-5">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <gl-form-group
          label="ID"
          label-size="sm"
          label-for="id"
          :invalid-feedback="isInvalidIdFeedback()"
        >
          <gl-form-input
            id="id"
            type="text"
            v-model="itemUnderEdit.id"
            placeholder="ID"
            maxlength="250"
            required
            :disabled="isUpdate"
            class="mt-1"
            :state="isValidId()"
          />
        </gl-form-group>
        <gl-form-group label="Short name" label-size="sm" label-for="shortName">
          <gl-form-input
            id="shortName"
            type="text"
            v-model="itemUnderEdit.shortName"
            placeholder="Short name"
            maxlength="250"
            required
            :disabled="isUpdate"
            class="mt-1"
          />
        </gl-form-group>
        <gl-form-group label="Legal name" label-size="sm" label-for="legalName">
          <gl-form-textarea
            id="legalName"
            v-model="itemUnderEdit.legalName"
            placeholder="Legal name"
            :disabled="isUpdate"
            maxlength="250"
            required
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          class="mt-1"
          label="Is this an AWS Marketplace customer?"
          label-size="sm"
          label-for="isMarketPlace"
          :invalid-feedback="invalidIsMarketPlaceFeedback()"
        >
          <gl-form-select
            id="isMarketPlace"
            :disabled="isUpdate"
            v-model="itemUnderEdit.registrationType"
            :options="isMarketPlaceOptions"
            class="mt-1"
            :state="isValidIsMarketPlace()"
          />
        </gl-form-group>

        <gl-form-group
          label="AWS Customer ID"
          v-if="
            itemUnderEdit.registrationType ===
              ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
          "
          label-size="sm"
          label-for="awsCustomerNumber"
          :invalid-feedback="isInvalidCustomerFeedback()"
          required
        >
          <gl-form-input
            id="awsCustomerNumber"
            :disabled="isUpdate"
            type="text"
            v-model="itemUnderEdit.awsCustomerId"
            placeholder="E.g. 1122334455"
            maxlength="20"
            :state="isValidCustomerId()"
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          label="AWS Account Number"
          label-size="sm"
          label-for="awsAccountNumber"
          :invalid-feedback="isInvalidAccountFeedback()"
        >
          <gl-form-input
            id="awsAccountNumber"
            type="text"
            v-model="itemUnderEdit.awsAccountId"
            placeholder="E.g. 1122334455"
            maxlength="20"
            :state="isValidAccountId()"
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          label="Project Number"
          label-size="sm"
          label-for="awsProjectNumber"
          :invalid-feedback="isInvalidProjectNumberFeedback()"
        >
          <gl-form-input
            id="awsProjectNumber"
            type="text"
            v-model="itemUnderEdit.projectNumber"
            placeholder="E.g. 012345"
            maxlength="6"
            :state="isValidProjectNumber()"
            class="mt-1"
            required
          />
          <span
            class="inputHelp"
            v-if="
              !itemUnderEdit.projectNumber ||
                itemUnderEdit.projectNumber === '999999'
            "
          >
            The default Project Number is 999999. If you don't have a specific
            Project Number please get it in touch with
            <a href="mailto:billing@arqiva.com">billing@arqiva.com</a>
          </span>
        </gl-form-group>

        <gl-form-group
          label="MediaShuttle Folder Name"
          label-size="sm"
          label-for="mediaShuttleFolderName"
          :invalid-feedback="isInvalidMSFolderFeedback()"
        >
          <gl-form-input
            id="mediaShuttleFolderName"
            type="text"
            v-model="itemUnderEdit.mediaShuttleSubfolder"
            placeholder="mediashuttle/subfolder"
            :state="isValidMSFolder()"
            class="mt-1"
          />
        </gl-form-group>
        <gl-form-group
          label="Billing ID"
          label-size="sm"
          label-for="billingCustomerId"
        >
          <gl-form-input
            id="billingCustomerId"
            type="text"
            v-model="itemUnderEdit.billingCustomerId"
            placeholder="E.g. 1122334455"
            class="mt-1"
            required
          />
        </gl-form-group>

        <gl-form-group
          label="Billable Status"
          label-size="sm"
          label-for="billableStatus"
        >
          <gl-form-select
            id="billableStatus"
            v-model="itemUnderEdit.billableStatus"
            :options="billableStatusOptions"
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          label="Invoice Payment Terms"
          label-size="sm"
          label-for="invoicePmtTerms"
        >
          <gl-form-select
            id="invoicePmtTerms"
            v-model="itemUnderEdit.invoicePmtTerms"
            :options="invoicePmtTermsOptions"
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          label="Customer PO"
          label-size="sm"
          label-for="billingOrderId"
        >
          <gl-form-input
            id="billingOrderId"
            type="text"
            v-model="itemUnderEdit.billingOrderId"
            placeholder=""
            class="mt-1"
          />
        </gl-form-group>

        <gl-form-group
          class="m-4"
          label=""
          label-size="sm"
          label-for="overrideMargin"
        >
          <gl-form-checkbox id="overrideMargin" v-model="overrideMargin"
            ><strong
              >I want to override global settings of markup and setup
              cost.</strong
            ></gl-form-checkbox
          >
        </gl-form-group>
        <div v-if="overrideMargin">
          <gl-form-group
            label="Source Markup Pct"
            label-size="sm"
            label-for="sourceMarginPct"
          >
            <gl-form-input
              id="sourceMarginPct"
              type="number"
              v-model="itemUnderEdit.sourceMarginPct"
              placeholder=""
              class="mt-1"
              step="0.0001"
              min="0"
            />
          </gl-form-group>
          <gl-form-group
            label="Channel Delivery Markup Pct"
            label-size="sm"
            label-for="channelDeliveryMarginPct"
          >
            <gl-form-input
              id="channelDeliveryMarginPct"
              type="number"
              v-model="itemUnderEdit.channelDeliveryMarginPct"
              placeholder=""
              class="mt-1"
              step="0.0001"
              min="0"
            />
          </gl-form-group>
          <gl-form-group
            label="Event Delivery Setup Cost USD"
            label-size="sm"
            label-for="eventDeliverySetupCostUsd"
          >
            <gl-form-input
              id="eventDeliverySetupCostUsd"
              type="number"
              v-model="itemUnderEdit.eventDeliverySetupCostUsd"
              placeholder=""
              class="mt-1"
              step="0.01"
              min="0"
            />
          </gl-form-group>
          <gl-form-group
            label="Event Delivery Hourly Cost USD"
            label-size="sm"
            label-for="eventDeliveryHourlyCostUsd"
          >
            <gl-form-input
              id="eventDeliveryHourlyCostUsd"
              type="number"
              v-model="itemUnderEdit.eventDeliveryHourlyCostUsd"
              placeholder=""
              class="mt-1"
              step="0.01"
              min="0"
            />
          </gl-form-group>
        </div>
      </template>
    </Wizard>
  </gl-form>
</template>
<style lang="scss" scoped>
span.inputHelp {
  font-size: 0.8rem;
  color: grey;
}
</style>
<script>
import {
  GlForm,
  GlFormInput,
  GlFormSelect,
  GlFormTextarea,
  GlFormGroup,
  GlFormCheckbox
} from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import Organisation from "@/models/Organisation";
import {
  ITEMS_USER_MGMT,
  ORGANIZATION_REGISTRATION_TYPE
} from "@/constants.js";
import msgUtils from "@/mixins/msgUtils";
import { organizationApiClient } from "@/mixins/apiClients";

const { ORGANIZATIONS } = ITEMS_USER_MGMT;

export default {
  name: "AddFirst",
  components: {
    Wizard,
    GlForm,
    GlFormInput,
    GlFormSelect,
    GlFormTextarea,
    GlFormGroup,
    GlFormCheckbox
  },
  mixins: [organizationApiClient, msgUtils],
  props: {
    currentItem: Organisation, // the stored/original item
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: this.isUpdate ? "Update Organisation" : `Generate Organization`,
          type: `submit`,
          disabled: !this.isDescFulfilled
        }
      ];
    },
    invoicePmtTermsOptions() {
      return [
        {
          text: "14",
          value: "14"
        },
        {
          text: "15",
          value: "15"
        },
        {
          text: "21",
          value: "21"
        },
        {
          text: "30",
          value: "30"
        },
        {
          text: "33",
          value: "33"
        },
        {
          text: "35",
          value: "35"
        },
        {
          text: "45",
          value: "45"
        },
        {
          text: "60",
          value: "60"
        },
        {
          text: "67",
          value: "67"
        },
        {
          text: "90",
          value: "90"
        },
        {
          text: "06M",
          value: "06M"
        },
        {
          text: "20M",
          value: "20M"
        },
        {
          text: "EOFM",
          value: "EOFM"
        },
        {
          text: "EOM",
          value: "EOM"
        },
        {
          text: "EOM+2",
          value: "EOM+2"
        },
        {
          text: "FOM",
          value: "FOM"
        }
      ];
    },
    billableStatusOptions() {
      return [
        {
          text: "Billable",
          value: "BILLABLE"
        },
        {
          text: "Non-billable",
          value: "NON_BILLABLE"
        },
        {
          text: "Bundled",
          value: "BUNDLED"
        }
      ];
    }
  },
  data() {
    return {
      ORGANIZATION_REGISTRATION_TYPE: ORGANIZATION_REGISTRATION_TYPE,
      itemUnderEdit: Organisation,
      isMarketPlace: null,
      isMarketPlaceOptions: [
        { text: "", value: null },
        { text: `YES`, value: ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE },
        { text: `NO`, value: ORGANIZATION_REGISTRATION_TYPE.REGULAR }
      ],

      isDescFulfilled: false,
      leftSectionTitle: `Profile`,
      overrideMargin: undefined
    };
  },
  created() {
    this.itemUnderEdit = this.currentItem;
    this.isDescFulfilled = this.isValid();
    if (this.currentItem.id) {
      this.checkOverrideMargin();
    }
  },
  mounted() {
    if (!this.itemUnderEdit.invoicePmtTerms) {
      this.itemUnderEdit.invoicePmtTerms = "30";
    }

    document.addEventListener("keydown", this.handleKey);
  },
  watch: {
    itemUnderEdit: {
      handler() {
        this.checkOverrideMargin();
        this.isDescFulfilled = this.isValid();
      },
      deep: true
    },
    overrideMargin: {
      handler(newValue) {
        if (!newValue) {
          this.itemUnderEdit.sourceMarginPct = "";
          this.itemUnderEdit.channelDeliveryMarginPct = "";
          this.itemUnderEdit.eventDeliverySetupCostUsd = "";
          this.itemUnderEdit.eventDeliveryHourlyCostUsd = "";
        }
      }
    }
  },
  methods: {
    checkOverrideMargin() {
      if (this.overrideMargin === undefined) {
        this.overrideMargin =
          this.hasValue(this.currentItem.sourceMarginPct) ||
          this.hasValue(this.currentItem.channelDeliveryMarginPct) ||
          this.hasValue(this.currentItem.eventDeliverySetupCostUsd) ||
          this.hasValue(this.currentItem.eventDeliveryHourlyCostUsd);
      }
    },
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.$router.push(ORGANIZATIONS);
      }
    },
    hasValue(item) {
      return !!item || item === 0;
    },

    isValidId() {
      if (this.isUpdate) {
        return true;
      }
      const reg = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
      return reg.test(this.itemUnderEdit.id);
    },
    isInvalidIdFeedback() {
      return this.itemUnderEdit.id
        ? this.isValidId()
          ? ""
          : "Please enter a valid UUID"
        : "";
    },
    isInvalidProjectNumberFeedback() {
      return this.itemUnderEdit.projectNumber
        ? this.isValidProjectNumber()
          ? ""
          : "The project number needs to be 6 digits"
        : "";
    },
    isValidAccountId() {
      if (!this.itemUnderEdit.awsAccountId) {
        return true;
      }
      const reg = /^[0-9]{12}$/;
      return reg.test(this.itemUnderEdit.awsAccountId);
    },
    isInvalidAccountFeedback() {
      return this.isValidAccountId()
        ? ""
        : "Please enter a valid account number";
    },
    isValidCustomerId() {
      if (
        this.itemUnderEdit.registrationType !==
        ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
      ) {
        return true;
      }
      if (!this.itemUnderEdit.awsCustomerId) {
        return false;
      }
      const reg = /^(?!\s*$).{1,255}$/;
      return reg.test(this.itemUnderEdit.awsCustomerId);
    },
    isValidProjectNumber() {
      if (
        this.itemUnderEdit.projectNumber == null ||
        this.itemUnderEdit.projectNumber.length !== 6
      ) {
        return false;
      }
      // check that every character is a number
      return !isNaN(this.itemUnderEdit.projectNumber);
    },

    isInvalidCustomerFeedback() {
      return this.isValidCustomerId()
        ? ""
        : "Please enter a valid customer number";
    },
    invalidIsMarketPlaceFeedback() {
      return this.isValidIsMarketPlace() ? "" : "Please select an answer";
    },
    isValidIsMarketPlace() {
      if (this.itemUnderEdit.id && this.isMarketPlaceOptions.length === 3) {
        // after first assigment we remove the empty option
        this.isMarketPlaceOptions.splice(0, 1);
      }
      return this.itemUnderEdit.registrationType !== null;
    },
    isInvalidMSFolderFeedback() {
      return this.isValidMSFolder() ? "" : "Please enter a valid path";
    },
    isValidMSFolder() {
      if (!this.currentItem.mediaShuttleSubfolder) {
        return true;
      }
      const reg = /^$|^([^/])([-/a-zA-Z0-9!-_.*'()]{1,512})([^/])$/;
      return reg.test(this.itemUnderEdit.mediaShuttleSubfolder);
    },
    isValid() {
      return (
        this.isValidId() &&
        this.isValidAccountId() &&
        this.isValidCustomerId() &&
        this.isValidProjectNumber() &&
        !!this.itemUnderEdit.shortName &&
        !!this.itemUnderEdit.legalName &&
        !!this.itemUnderEdit.billingCustomerId
      );
    },
    async persistOrganisation() {
      this.isBusy = true;
      this.currentItem = this.itemUnderEdit;
      if (
        this.itemUnderEdit.registrationType !==
        ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
      ) {
        this.currentItem.awsMarketplaceRegistrationId = null;
      }

      let msg = "";
      try {
        const itemToUpdate = JSON.parse(JSON.stringify(this.currentItem));
        if (!itemToUpdate.sourceMarginPct) {
          delete itemToUpdate.sourceMarginPct;
        }
        if (!itemToUpdate.channelDeliveryMarginPct) {
          delete itemToUpdate.channelDeliveryMarginPct;
        }
        if (!itemToUpdate.eventDeliverySetupCostUsd) {
          delete itemToUpdate.eventDeliverySetupCostUsd;
        }
        if (!itemToUpdate.eventDeliveryHourlyCostUsd) {
          delete itemToUpdate.eventDeliveryHourlyCostUsd;
        }
        if (this.isUpdate) {
          await this.organizationApiClient.patch(
            this.currentItem.id,
            itemToUpdate
          );
          msg = `The organization "${this.currentItem.shortName}" was updated successfully.`;
        } else {
          await this.organizationApiClient.create(itemToUpdate);
          msg = `The organization "${this.currentItem.shortName}" was created successfully.`;
          this.$emit("created");
        }
        this.handleApiSuccess(msg);
      } catch (e) {
        this.handleApiError(e);
      }
      this.isBusy = false;
    }
  }
};
</script>
