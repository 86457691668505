<template>
  <Page contributionType="event_contribution" status="current" />
</template>
<script>
import Page from "@/components/Contributions/All/Page.vue";

export default {
  name: "EventsCurrent",
  components: {
    Page
  }
};
</script>
