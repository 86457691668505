<template>
  <div>
    <div class="d-flex">
      <div class="section">
        <div>
          <slot name="left-section"></slot>
          <div v-if="buttons">
            <gl-button
              style="float: right; margin-top: 20px"
              v-for="(item, index) in buttons"
              :key="index"
              class="mr-3 mb-3"
              :disabled="item.disabled"
              :type="item.type"
              :variant="setVariant(item.type)"
              @click="item.onClick ? item.onClick() : emptyFunc()"
              >{{ item.text }}
            </gl-button>
          </div>
        </div>
      </div>
      <div class="vl"></div>
      <div class="section">
        <h2 class="mt-3">{{ rightSectionTitle }}</h2>
        <div class="mt-4">
          <slot name="right-section"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GlButton } from "@gitlab/ui";
export default {
  components: { GlButton },
  props: {
    leftSectionTitle: {
      type: String
    },
    rightSectionTitle: {
      type: String
    },
    buttons: {
      type: Array
    }
  },
  methods: {
    doClick(item) {
      if (typeof item.onClick === "function") {
        item.onClick();
      }
    },
    emptyFunc() {},
    setVariant(type) {
      let variant = `default`;
      switch (type) {
        case `submit`:
          variant = `confirm`;
          break;
      }
      return variant;
    }
  }
};
</script>
<style lang="scss">
.vl {
  border: 1px solid $gray-200;
  margin: 0px 16px;
  margin-top: 16px;
}
.section {
  width: 40%;
  min-width: 400px;
}
.gl-tab-nav-item {
  padding: 10px !important;
  margin-right: 6px;
  border-radius: 5px 5px 0px 0px;
  //border: 1px 0px 0px 0px solid lightgrey;
}
.gl-tab-nav-item-active {
  background-color: lightgrey;
}
</style>
