<template>
  <div v-show="selectedOrganization">
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">Contributions</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__contributions">
      <gl-filtered-search
        :available-tokens="tokens"
        @submit="activate"
        @clear="clear"
        class="mb-2"
      >
        <template #history-item="{ historyItem }">
          <template v-for="(token, idx) in historyItem">
            <span :key="idx" class="px-1">
              {{ token.type }} =
              <strong>{{ token.value }}</strong>
            </span>
          </template>
        </template>
      </gl-filtered-search>
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItemsByRow"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
        :rowPoller="rowPoller"
      >
        <template v-slot="{ row: r, field }">
          <div
            v-if="field === 'name'"
            class="contributionSingle"
            :class="{
              'contributionSingle--inactive': isInactive(r)
            }"
          >
            <div class="d-flex align-items-center mb-2">
              <b-badge class="contributionSingle__type">{{
                r.contributionType
              }}</b-badge>
              <div class="ml-2">
                <p
                  class="mb-0 contributionSingle__name"
                  v-b-tooltip
                  :title="r.name"
                >
                  {{ r.name }}
                </p>
                <span>{{ r.status }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center contributionSingle__bottom">
              <b-badge :variant="getBadgeVariant(r.source0_health)"
                ><gl-icon :name="getBadgeIcon(r.source0_health)" />{{
                  getBitrate(r.source0_bitrate)
                }}</b-badge
              >
              <b-badge
                :variant="getBadgeVariant(r.source1_health)"
                v-show="r.source1Id"
                ><gl-icon :name="getBadgeIcon(r.source1_health)" />{{
                  getBitrate(r.source1_bitrate)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient, contributionApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlFilteredSearch, GlButton } from "@gitlab/ui";
import ResourcePoller from "@/classes/ResourcePoller.js";
import AppConfig from "@/app-config";
import {
  CONTRIBUTIONS_SEARCH_BAR_TOKENS,
  ITEMS_CONTRIBUTIONS
} from "@/constants.js";
const { EDIT } = ITEMS_CONTRIBUTIONS;

export default {
  name: "Contributions",
  mixins: [metricsApiClient, contributionApiClient],
  components: {
    ArqDataGrid,
    GlButton,
    GlFilteredSearch,
    GlLoadingIcon
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    selectedOrganization: {
      type: Object
    }
  },
  watch: {
    selectedOrganization: function() {
      this.onDataNeeded(this.sortModel, this.pageModel);
    }
  },
  mounted() {
    this.rowPoller = new ResourcePoller(
      id => {
        return this.getMetricsHealth("contributions").getById(id);
      },
      AppConfig.instance.config.API_OPTIONS.ROW_UDPATE_THRESHOLD,
      "source0_health",
      "source1_health",
      "source0_bitrate",
      "source1_bitrate"
    );
  },
  methods: {
    isString(val) {
      return typeof val === "string";
    },
    activate(ev) {
      this.searchParams = {};
      this.searchPhrase = "";
      for (const param of ev) {
        if (this.isString(param)) {
          this.searchParams["q"] = param;
        } else {
          if (param.value.data && param.value.data !== "") {
            this.searchParams[param.type] = param.value.data;
          } else {
            delete this.searchParams[param.type];
          }
        }
      }
      this.searchPhrase = JSON.stringify(this.searchParams);
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
    },
    clear() {
      this.searchParams = {};
      this.searchPhrase = "";
      this.selectedRow = undefined;
      this.$emit("rowSelected", this.selectedRow);
      this.onDataNeeded(this.sortModel, this.pageModel);
    },
    contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT}/${row.id}`);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(health) {
      switch (health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(health) {
      switch (health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    getBitrate(bitrate) {
      if (bitrate > 1000000) {
        return (bitrate / 1000000).toFixed(1) + " Mb/s";
      } else if (bitrate > 1000) {
        return (bitrate / 1000).toFixed(1) + " kb/s";
      } else {
        return parseFloat(bitrate).toFixed(1) + " b/s";
      }
    },
    isInactive(row) {
      return new Date(row.plannedEnd).getTime() < Date.now();
    },
    async onDataNeeded(sortModel, pageModel) {
      this.isLoading = true;
      this.sortModel = sortModel;
      this.pageModel = pageModel;
      if (!this.selectedOrganization) {
        this.data = this.getEmptyPagedResponse();
        return;
      }
      const { field, order } = sortModel;
      let { currentPage, itemsPerPage } = pageModel;
      currentPage = currentPage || 0;
      itemsPerPage = itemsPerPage || 0;
      let query = this.getMetricsHealth("contributions")
        .list()
        .page(currentPage)
        .perPage(itemsPerPage);
      if (field) {
        query = query.sortBy(field);
        if (order) {
          query = query.sortBy(field, order);
        }
      }

      query = query.param("organisationId", this.selectedOrganization.id);

      for (const param of Object.keys(this.searchParams)) {
        query.param(param, this.searchParams[param]);
      }

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();

        for (const key in response.items) {
          const type = response.items[key].contributionType;
          const typeName = this.contributionTypes.find(t => t.value === type);
          response.items[key].contributionType = typeName
            ? typeName.text
            : type;
        }
        d = response;
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
      this.$emit("generateLines");
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = JSON.parse(JSON.stringify(this.contextMenuItems));
        }
      }
      return out;
    },
    tokens() {
      return CONTRIBUTIONS_SEARCH_BAR_TOKENS;
    }
  },
  data() {
    return {
      sortModel: {},
      pageModel: {
        itemsPerPage: 10
      },
      selectedRow: undefined,
      data: {},
      searchParams: {},
      searchPhrase: "",
      isLoading: true,
      rowPoller: undefined,
      columns: [
        {
          text: "",
          field: "name"
        }
      ],
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        }
      ]
    };
  }
};
</script>
<style scoped>
.contributionSingle--inactive {
  opacity: 0.5;
}
.contributionSingle__bottom .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.contributionSingle__bottom .badge .gl-icon {
  margin-right: 5px;
}
.contributionSingle__bottom .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.contributionSingle__bottom .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.contributionSingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.contributionSingle__name {
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
</style>
