import ResourceApiClient from "@/classes/ResourceApiClient";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  mixins: [msgUtils],
  data() {
    return {
      entitlementApiClient: new ResourceApiClient(
        AppConfig.instance.config.API_URLS.ENTITLEMENTS,
        "entitlements"
      )
    };
  },
  created() {
    this.entitlementApiClient.getForPackage = id => {
      return this.entitlementApiClient.getByCustomParam("packageId", id);
    };
  },
  methods: {
    flatten(dataRow) {
      dataRow["package.name"] = dataRow.package.name;
      dataRow["organisation.shortName"] = dataRow.organisation.shortName;
      dataRow.description = dataRow.package.description;
      return dataRow;
    }
  }
};
