<template>
  <MainSection
    :breadcrumbItems="breadcrumbItems"
    :pageTitle="pageTitle"
    :tabsItems="computedTabItems"
  />
</template>
<script>
import MainSection from "@/components/MainSection";
import { MAIN_ITEMS, ALIAS_ITEMS_SOURCE } from "@/constants.js";
const { ALL, ENABLED, DISABLED, ARCHIVED, ADMIN_ONLY } = ALIAS_ITEMS_SOURCE;
import AppConfig from "@/app-config";
import userInfoMixin from "@/mixins/userInfo.js";

export default {
  components: { MainSection },
  mixins: [userInfoMixin],
  data() {
    return {
      pageTitle: `Sources`,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Sources`, href: ALL }
      ],
      tabsItems: [
        { title: `All sources`, subPageName: ALL },
        { title: `Enabled`, subPageName: ENABLED },
        { title: `Disabled`, subPageName: DISABLED },
        { title: `Archived`, subPageName: ARCHIVED },
        { title: `Admin Only`, subPageName: ADMIN_ONLY }
      ]
    };
  },
  computed: {
    computedTabItems() {
      if (
        !(
          this.isArqadeAdminRoleActive &&
          AppConfig.instance.config.options.features.adminOnlySources
        )
      ) {
        return this.tabsItems.filter(item => item.subPageName !== ADMIN_ONLY);
      }
      return this.tabsItems;
    }
  }
};
</script>
