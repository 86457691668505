import { TOP_MENU_OPTIONS } from "@/constants";

const topMenu = {
  namespaced: true,
  state: {
    currentOption: TOP_MENU_OPTIONS.CONNECT
  },
  mutations: {
    currentOption(state, currentOption) {
      state.currentOption = currentOption;
    }
  },
  actions: {},
  modules: {},
  getters: {}
};

export default topMenu;
