<template>
  <gl-form @submit.prevent="generate">
    <Wizard :buttons="buttons">
      <template v-slot:left-section>
        <gl-form-group
          label="Virtual Connect Stream ID"
          label-size="sm"
          label-for="id"
          :invalid-feedback="invalidIdFeedback"
        >
          <gl-form-input
            id="id"
            type="text"
            v-model="itemUnderEdit.id"
            placeholder="Virtual Connect Stream ID"
            maxlength="250"
            required
            class="mt-1"
            :state="isValidId"
          />
        </gl-form-group>
        <gl-form-group
          label="Site ID"
          label-size="sm"
          label-for="virtualconnectSiteId"
        >
          <gl-form-select
            id="virtualconnectSiteId"
            v-model="itemUnderEdit.virtualconnectSiteId"
            :options="virtualconnectSiteIdOptions"
            placeholder="Side ID"
            class="mt-1"
          />
        </gl-form-group>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlFormInput, GlFormSelect, GlFormGroup } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import currentItem from "@/mixins/currentItem";
import {
  virtualConnectStreamApiClient,
  virtualConnectSiteApiClient
} from "@/mixins/apiClients";
import { ALIAS_ITEMS_USER_MGMT, ITEMS_USER_MGMT } from "@/constants.js";
const { EDIT_ORGANIZATION } = ITEMS_USER_MGMT;
const { VIRTUAL_CONNECT_STREAMS_ALL } = ALIAS_ITEMS_USER_MGMT;

export default {
  name: "AddFirst",
  mixins: [
    currentItem,
    virtualConnectStreamApiClient,
    virtualConnectSiteApiClient
  ],
  components: {
    Wizard,
    GlForm,
    GlFormInput,
    GlFormSelect,
    GlFormGroup
  },
  props: {
    organizationId: {
      type: String
    }
  },
  computed: {
    buttons() {
      return [
        {
          text: `Back`,
          type: "button",
          onClick: () =>
            this.$router.push(
              `${EDIT_ORGANIZATION}/${this.organizationId}/${VIRTUAL_CONNECT_STREAMS_ALL}`
            )
        },
        {
          text: `Save`,
          type: `submit`,
          disabled: !this.isDescFulfilled
        }
      ];
    },
    isValidId() {
      return (
        !!this.currentItem.id && !!this.idPattern.test(this.currentItem.id)
      );
    },
    invalidIdFeedback() {
      return this.isValidId
        ? ""
        : "Stream ID should begin and end with a letter or a number and its length should be at least 2 characters. It should contain only characters a-z, numbers 0-9, hyphens and underscores";
    }
  },
  created() {
    this.getVirtualconnectSiteIdOptions();
  },
  data() {
    return {
      isDescFulfilled:
        this.isValidId && !!this.currentItem.virtualconnectSiteId,
      virtualconnectSiteIdOptions: [],
      idPattern: /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,126}[a-zA-Z0-9]$/i
    };
  },
  watch: {
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.isValidId && !!this.currentItem.virtualconnectSiteId;
      },
      deep: true
    }
  },
  methods: {
    async getVirtualconnectSiteIdOptions() {
      let hasNextPage = true;
      let page = 0;
      do {
        const response = await this.virtualConnectSiteApiClient
          .list()
          .page(page)
          .perPage(100)
          .exec();
        if (response.items && response.items.length > 0) {
          this.virtualconnectSiteIdOptions.push(
            ...response.items.map(site => ({
              value: site.id,
              text: site.name
            }))
          );
          if (response.totalPages > page) {
            page++;
          } else {
            hasNextPage = false;
          }
        } else {
          hasNextPage = false;
        }
      } while (hasNextPage);
    },
    async generate() {
      this.currentItem.organisationId = this.organizationId;
      try {
        await this.virtualConnectStreamApiClient.create(this.currentItem);
        const msg = `The Virtual Connect Stream was created successfully.`;
        this.handleApiSuccess(msg);
        this.$emit("create");
      } catch (e) {
        this.handleApiError(e);
      }
    }
  }
};
</script>
