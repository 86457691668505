<template>
  <div class="noTabs">
    <MainSection :breadcrumbItems="breadcrumbItems" :pageTitle="pageTitle" />
  </div>
</template>
<script>
import MainSection from "@/components/MainSection";
import { organizationApiClient } from "@/mixins/apiClients";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_USER_MGMT,
  ITEMS_USER_MGMT
} from "@/constants.js";
const { USERS_ALL } = ALIAS_ITEMS_USER_MGMT;
const { USERS } = ITEMS_USER_MGMT;
export default {
  mixins: [organizationApiClient],
  components: { MainSection },
  created() {
    this.orgId = this.$route.query.org;
    if (this.orgId) {
      this.organizationApiClient.getById(this.orgId).then(response => {
        this.organization = response;
      });
    }
  },
  computed: {
    tabsItems() {
      return [
        {
          title: `All users`,
          subPageName: this.orgId ? `${USERS_ALL}?org=${this.orgId}` : USERS_ALL
        }
      ];
    },
    pageTitle() {
      return this.organization
        ? `Users in ${this.organization.shortName}`
        : "Users";
    }
  },
  data() {
    return {
      orgId: undefined,
      organization: undefined,
      breadcrumbItems: [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Users`, href: USERS },
        { text: `All users`, href: USERS_ALL }
      ]
    };
  }
};
</script>
