export default {
  awsAccountId: null,
  awsCustomerId: null,
  billingCustomerId: null,
  disabled: false,
  grantedEntitlements: [],
  id: null,
  legalName: null,
  logo: null,
  mediaShuttleSubfolder: null,
  projectNumber: "999999",
  registrationType: null,
  shortName: null
};
