import Monitoring from "@/views/Monitoring/Monitoring";
import NetworkBrowser from "@/components/Monitoring/NetworkBrowser";
import { MAIN_ITEMS, ITEMS_MONITORING } from "@/constants.js";

const { MONITORING } = MAIN_ITEMS;
const { NETWORK_BROWSER } = ITEMS_MONITORING;

const panel = [
  {
    path: MONITORING,
    name: `Monitoring`,
    component: Monitoring,
    children: [
      {
        path: NETWORK_BROWSER,
        component: NetworkBrowser
      }
    ]
  }
];

export default panel;
