<template>
  <gl-form @submit.prevent="nextStep">
    <Wizard :leftSectionTitle="leftSectionTitle" :buttons="buttons">
      <template v-slot:left-section>
        <div class="mt-5">
          <gl-form-select
            id="visibilityLevel"
            v-model="itemUnderEdit.visibility"
            :options="visibilityLevelOptions"
            placeholder="Visibility level"
            required
          ></gl-form-select>
          <div
            v-if="
              itemUnderEdit.visibility === VISIBILITY_LEVELS.PARTNER ||
                itemUnderEdit.visibility === VISIBILITY_LEVELS.PUBLIC
            "
          >
            <smart-select-on-off
              class="mt-2"
              @selectedChanged="selectOrganizations"
              :selected="itemUnderEdit.entitlements"
              :dataSource="organizationApiClient"
              :textFormatter="organizationNameFormatter"
              :dataSourceParams="dataSourceParams"
              title="Entitled Organisations"
            ></smart-select-on-off>
          </div>
        </div>
      </template>
    </Wizard>
  </gl-form>
</template>
<script>
import { GlForm, GlFormSelect } from "@gitlab/ui";
import Wizard from "@/components/Wizard";
import organizationApiClient from "@/mixins/organizationApiClient";
import currentItem from "@/mixins/currentItem";
import {
  ALIAS_ITEMS_PACKAGES,
  VISIBILITY_LEVELS,
  ITEMS_PACKAGES
} from "@/constants.js";
import SmartSelectOnOff from "@/components/SmartSelectOnOff/SmartSelectOnOff.vue";
const { ADD_FOURTH, EDIT_FOURTH } = ALIAS_ITEMS_PACKAGES;
const { EDIT } = ITEMS_PACKAGES;
export default {
  name: "AddSecond",
  mixins: [organizationApiClient, currentItem],
  components: {
    Wizard,
    GlFormSelect,
    GlForm,
    SmartSelectOnOff
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dataSourceParams() {
      return [{ name: "owned", value: false }];
    },
    VISIBILITY_LEVELS() {
      return VISIBILITY_LEVELS;
    },
    visibilityLevelOptions() {
      return Object.keys(VISIBILITY_LEVELS).map(key => {
        return {
          text: VISIBILITY_LEVELS[key],
          value: VISIBILITY_LEVELS[key]
        };
      });
    }
  },
  data() {
    return {
      leftSectionTitle: `Visibility level`,
      selectedOrgs: [],
      buttons: [{ text: `Next Step`, type: `submit` }]
    };
  },
  methods: {
    selectOrganizations(newSelected) {
      const changed = {};
      for (const r of newSelected) {
        changed[r.id] = r;
      }
      for (let i = 0; i < this.selectedOrgs.length; i++) {
        const changedRow = changed[this.selectedOrgs[i]];
        if (changedRow && !changedRow.selected) {
          this.selectedOrgs.splice(i, 1);
        }
      }
      for (const r of newSelected.filter(r => r.selected)) {
        if (this.selectedOrgs.indexOf(r.id) < 0) {
          this.selectedOrgs.push(r.id);
        }
      }
      this.itemUnderEdit.entitlements = this.selectedOrgs;
    },
    organizationNameFormatter(row) {
      return `${row.shortName} (${row.legalName})`;
    },
    nextStep() {
      if (this.isUpdate) {
        this.$router.push(`${EDIT}/${this.currentItem.id}/${EDIT_FOURTH}`);
      } else {
        this.$router.push(ADD_FOURTH);
      }
    }
  }
};
</script>
