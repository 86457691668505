<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div v-if="!isLoading" class="mx-3 my-1">
      <Breadcrumb :items="breadcrumbItems" />
      <h1 class=" d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
      <gl-tabs>
        <gl-tab
          v-for="(item, index) in tabsItems"
          :key="`tab${index}`"
          @click="openTab(item.subPageName, item.subComponent, item.data)"
          :active="isSelected(item.subPageName)"
          :disabled="item.disabled"
        >
          <template slot="title">
            <span>{{ item.title }}</span>
            <gl-badge
              v-if="item.badge"
              size="sm"
              class="gl-tab-counter-badge"
              >{{ item.badge }}</gl-badge
            >
          </template>
        </gl-tab>
      </gl-tabs>
      <component
        v-bind:is="currentComponent"
        :currentItem="currentItem"
        :isUpdate="isEdit"
        @create="packageCreated"
      ></component>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import MainSection from "@/components/MainSection";
import {
  MAIN_ITEMS,
  ALIAS_ITEMS_PACKAGES,
  ITEMS_PACKAGES,
  VISIBILITY_LEVELS
} from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import * as eq from "deep-equal";
import {
  packageApiClient,
  entitlementApiClient,
  contributionApiClient
} from "@/mixins/apiClients";
import { GlTabs, GlTab } from "@gitlab/ui";
import { AddFirst, AddSecond, AddFourth } from "@/components/Packages/";
import Loading from "@/components/Loading.vue";
const {
  ADD_FIRST,
  ADD_SECOND,
  ADD_FOURTH,
  YOUR_ALL,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_FOURTH
} = ALIAS_ITEMS_PACKAGES;
const { EDIT, SHOW } = ITEMS_PACKAGES;
export default {
  components: { Loading, MainSection, Breadcrumb, GlTabs, GlTab },
  mixins: [
    wizardGuard,
    packageApiClient,
    entitlementApiClient,
    contributionApiClient
  ],
  methods: {
    handleKey(evt) {
      if (evt.code === "Escape") {
        if (this.isEdit) {
          this.$router.push(`${SHOW}/${this.currentItem.id}`);
        } else {
          this.$router.push(`${YOUR_ALL}`);
        }
      }
    },
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.currentData = data;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    packageCreated(id) {
      this.created = true;
      this.$router.push(`${SHOW}/${id}`);
    }
  },
  created() {
    if (this.$route.path === ADD_SECOND) {
      this.$router.push(ADD_FIRST);
    }
    if (this.$route.params.id) {
      const id = this.$route.params.id;
      this.isEdit = true;
      this.packageApiClient
        .getById(id)
        .then(pckg => {
          this.currentItem = pckg;
          // Replace the array of objects we get down from the API with the
          // array of IDs the UI uses to manage selected contributions:
          this.currentItem.contributions = this.currentItem.contributions.map(
            c => c.id
          );
          return this.entitlementApiClient.getForPackage(id);
        })
        .then(entitlements => {
          this.currentItem.entitlements = entitlements.items
            .filter(e => e.status === "Approved")
            .map(e => e.organisation.id);
          this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
        });
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    } else {
      if (this.$route.params && this.$route.params.cloning) {
        this.currentItem = this.$route.params.cloning;
      } else {
        this.currentItem = {
          name: null,
          description: null,
          contributions: [],
          entitlements: [],
          visibility: VISIBILITY_LEVELS.CLOSED
        };
      }
      this.originalItem = JSON.parse(JSON.stringify(this.currentItem));
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  watch: {
    $route(to) {
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    currentItem: {
      handler() {
        this.isDescFulfilled =
          this.currentItem.name && this.currentItem.description;
      },
      deep: true
    }
  },

  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Packages`, href: YOUR_ALL },
        { text: `Create New`, href: ADD_FIRST }
      ];
    },
    isPristine() {
      return eq(this.originalItem, this.currentItem);
    },
    tabsItems() {
      return [
        {
          title: `1. Description`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FIRST}`
            : ADD_FIRST,
          subComponent: AddFirst
        },
        {
          title: `2. Specification`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_SECOND}`
            : ADD_SECOND,
          subComponent: AddSecond,
          disabled: !this.isDescFulfilled
        },
        {
          title: `3. Contributions`,
          subPageName: this.isEdit
            ? `${EDIT}/${this.currentItem.id}/${EDIT_FOURTH}`
            : ADD_FOURTH,
          subComponent: AddFourth,
          disabled: !this.isDescFulfilled && !this.isEdit
        }
      ];
    },
    pageTitle() {
      return this.isEdit ? "Edit Package" : `Create Package`;
    }
  },
  data() {
    return {
      created: false,
      currentComponent: AddFirst,
      isEdit: false,
      isLoading: true,
      selectedTab: null,
      currentItem: {},
      originalItem: undefined,
      isDescFulfilled: false
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  }
};
</script>
