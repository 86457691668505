<template>
  <div>
    <b-badge
      v-for="role in roles"
      :key="role"
      variant="primary"
      class="status-badge l status-primary"
    >
      <span class="status-text">{{ role }}</span></b-badge
    >
  </div>
</template>

<script>
export default {
  name: "RolesCell",
  props: {
    roles: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
<style lang="scss">
.status-badge {
  border-radius: 15px !important;
  margin-right: 10px;
}
.status-text {
  padding: 2px;
  padding-top: 3px;
}
</style>
