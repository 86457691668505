export default {
  methods: {
    handleApiSuccess(msg) {
      this.$toast.showSuccess(msg);
    },

    handleApiError(error) {
      let message =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          error.response.data.detail.error) ||
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
        error;
      if (Array.isArray(message)) {
        message = message.map(m => m.msg).join("<br>");
      }
      this.$toast.showError(message);
    },

    getEmptyPagedResponse() {
      return {
        items: [],
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0
      };
    }
  }
};
