import { ORGANIZATION_REGISTRATION_TYPE } from "@/constants";
import { organizationApiClient } from "@/mixins/apiClients";
import Cookies from "universal-cookie";
import userInfo from "./userInfo";
const cookies = new Cookies();

export default {
  mixins: [organizationApiClient, userInfo],
  methods: {
    async isAwsMarketplaceOrg() {
      if (this.isMasquerading) {
        const orgId = cookies.get("arqade-org-id");
        const org = await this.organizationApiClient.getById(orgId);
        return (
          org &&
          org.registrationType === ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
        );
      } else {
        const org = await this.getUserOrganization();
        return (
          org &&
          org.registrationType === ORGANIZATION_REGISTRATION_TYPE.MARKETPLACE
        );
      }
    },
    async formatPricingDisplay(price, unit, total = null) {
      const usdValue = parseFloat(price).toFixed(2);
      const arqadeCreds = parseFloat(usdValue * 100).toFixed(2);
      if (await this.isAwsMarketplaceOrg()) {
        if (total) {
          return `${parseFloat(total).toFixed(
            2
          )} ${unit} (${arqadeCreds} Arqade Credits/hr)`;
        }
        return `${usdValue} ${unit} (${arqadeCreds} Arqade Credits/hr)`;
      } else {
        if (total) {
          return `${parseFloat(total).toFixed(2)} ${unit}`;
        }
        return `${usdValue} ${unit}`;
      }
    },
    async formatPricingDisplayDelivery(
      price,
      unit,
      total = null,
      oneTimeFee = null
    ) {
      const usdValue = parseFloat(price).toFixed(2);
      const arqadeCreds = parseFloat(usdValue * 100).toFixed(2);
      const arqadeCredUnit =
        unit === "USD" && total == null
          ? "Arqade Credits"
          : "Arqade Credits/hr";

      if (await this.isAwsMarketplaceOrg()) {
        if (total && oneTimeFee) {
          const newTotal = parseFloat(total).toFixed(2);
          const newArqadeCredOneTimeFee = parseFloat(oneTimeFee * 100).toFixed(
            2
          );
          return `${newTotal} ${unit} (${newArqadeCredOneTimeFee} Arqade Credits, ${arqadeCreds} ${arqadeCredUnit})`;
        }
        if (total) {
          return `${parseFloat(total).toFixed(
            2
          )} ${unit} (${arqadeCreds} ${arqadeCredUnit})`;
        }
        return `${usdValue} ${unit} (${arqadeCreds} ${arqadeCredUnit})`;
      } else {
        if (total) {
          return `${parseFloat(total).toFixed(2)} ${unit}`;
        }
        return `${usdValue} ${unit}`;
      }
    }
  }
};
