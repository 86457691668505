<template>
  <div v-show="selectedDelivery">
    <div class="d-flex justify-content-between">
      <h2 class="mt-3">My destinations</h2>
      <gl-button
        @click.prevent.stop="contextMenuClick()"
        icon="ellipsis_v"
        category="tertiary"
      >
      </gl-button>
    </div>
    <div class="mt-4 opsPanel__destination">
      <div v-show="isLoading">
        <gl-loading-icon label="Loading" size="xl" />
      </div>
      <ArqDataGrid
        v-show="!isLoading"
        :data="data"
        :phrase="searchPhrase"
        :columns="columns"
        @dataNeeded="onDataNeeded"
        :itemsPerPage="itemsPerPage"
        :showContextMenu="true"
        @rowSelected="onRowSelected"
        :contextMenuItems="contextMenuItemsByRow"
        @contextMenuClick="contextMenuClick"
        :showHeader="false"
        :selectOnClick="true"
        :showPager="false"
      >
        <template v-slot="{ row: r, field }">
          <div v-if="field === 'name'" class="destinationSingle">
            <div class="d-flex align-items-center mb-2">
              <p
                class="mb-0 destinationSingle__name"
                v-b-tooltip
                :title="r.name"
              >
                {{ r.name }}
              </p>
            </div>
            <div class="destinationSingle__bottom">
              <b-badge :variant="getBadgeVariant(r)"
                ><gl-icon :name="getBadgeIcon(r)" />{{
                  getBadgeText(r)
                }}</b-badge
              >
            </div>
          </div>
        </template>
      </ArqDataGrid>
    </div>
  </div>
</template>

<script>
import ArqDataGrid from "@/components/DataGrid/DataGrid.vue";
import { metricsApiClient } from "@/mixins/apiClients";
import { GlLoadingIcon, GlButton } from "@gitlab/ui";
import { ITEMS_DESTINATIONS } from "@/constants.js";
const { EDIT } = ITEMS_DESTINATIONS;

export default {
  name: "Destination",
  mixins: [metricsApiClient],
  components: {
    ArqDataGrid,
    GlButton,
    GlLoadingIcon
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 2
    },
    selectedDelivery: {
      type: Object
    }
  },
  watch: {
    selectedDelivery: function() {
      this.onDataNeeded();
    }
  },
  methods: {
    contextMenuClick(row, option) {
      switch (option) {
        case `update`:
          this.$router.push(`${EDIT}/${row.id}`);
          break;
      }
    },
    onRowSelected(row, selectedRows) {
      this.selectedRow = selectedRows.length ? row : undefined;
      this.$emit("rowSelected", this.selectedRow);
      return row;
    },
    getBadgeVariant(row) {
      switch (row.health) {
        case "OK":
          return "success";
        case "WARN":
          return "warning";
        case "NOK":
          return "danger";
        default:
          return "neutral";
      }
    },
    getBadgeIcon(row) {
      switch (row.health) {
        case "OK":
          return "check-circle";
        case "WARN":
          return "status-paused";
        case "NOK":
          return "status_warning";
        default:
          return "status-waiting";
      }
    },
    getBadgeText(row) {
      switch (row.health) {
        case "OK":
          return "Connected";
        case "WARN":
          return "Warning";
        case "NOK":
          return "Error";
        default:
          return "Unknown";
      }
    },
    async onDataNeeded() {
      this.isLoading = true;
      if (!this.selectedDelivery) {
        this.data = this.getEmptyPagedResponse();
        return;
      }

      const query = this.getMetricsHealth(
        `deliveries/${this.selectedDelivery.id}/destinations`
      ).list();

      let d = this.getEmptyPagedResponse();
      try {
        const response = await query.exec();
        d.items = [response.destination0];
        if (response.destination1) {
          d.items.push(response.destination1);
        }
      } catch (e) {
        this.handleApiError(e);
      }
      this.data = d;
      this.isLoading = false;
      this.$emit("generateLines");
    }
  },
  computed: {
    contextMenuItemsByRow() {
      const out = {};
      if (this.data && this.data.items) {
        for (const row of this.data.items) {
          out[row.id] = JSON.parse(JSON.stringify(this.contextMenuItems));
        }
      }
      return out;
    }
  },
  data() {
    return {
      selectedRow: undefined,
      data: {},
      isLoading: true,
      searchPhrase: "",
      columns: [
        {
          text: "",
          field: "name"
        }
      ],
      contextMenuItems: [
        {
          id: "update",
          text: "Update"
        }
      ]
    };
  }
};
</script>
<style scoped>
.destinationSingle__bottom .badge {
  font-size: 11px;
  margin-right: 10px;
  padding: 1px 10px 0 1px;
  font-weight: normal;
  border-radius: 12px;
}
.destinationSingle__bottom .badge .gl-icon {
  margin-right: 5px;
}
.destinationSingle .badge-success {
  background-color: #c3e6e1;
  color: #24663b;
}
.destinationSingle__bottom .badge-danger {
  background-color: #fdcdd3;
  color: #c24535;
}
.destinationSingle__type {
  font-size: 13px;
  background-color: #bfbfbf;
  color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.destinationSingle__name {
  font-size: 15px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
</style>
